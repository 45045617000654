<template>
  <el-dialog
    :visible.sync="show"
    width="360px"
    append-to-body
    v-move-outside
  >
    <div class="closeDialog" @click="close">
      <i class="el-icon-close"></i>
    </div>
    <div class="home">
      <div class="title">{{ statusTitle }}</div>
      <div class="feature">
        <div class="feature-box">
          <div
            class="pulse"
            @click="longClick" 
            :class="{
              noRecord: status == 0,
              recorded: status == 1,
              endRecord: status == 2,
            }"
          >
            <i v-if="status == 0" class="el-icon-microphone"></i>
            <span v-else-if="status == 1">{{
              formatTime(recordDuration)
            }}</span>
            <i v-else-if="status == 2" class="el-icon-refresh-left"></i>
          </div>
          <template v-if="status == 1">
            <div class="pulse1"></div>
            <div class="pulse2"></div>
          </template>
        </div>
      </div>
      <div class="progressBox">
        <mini-audio
          v-if="fileAudioUrl"
          :audio-source="fileAudioUrl"
          html5
        ></mini-audio>
        <el-progress
          v-else-if="show"
          :percentage="recordProgress"
          :show-text="false"
        ></el-progress>
        <div class="sendMessage" @click="sendMessage" v-if="status == 2">
          <i class="el-icon-position"></i>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import Recorder from "js-audio-recorder";
import lamejs from "lamejs";
import { uploadFileUrl } from "@/api/files";
const recorder = new Recorder({
  sampleBits: 16, // 采样位数，支持 8 或 16，默认是16
  sampleRate: 48000, // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，我的chrome是48000
  numChannels: 1, // 声道，支持 1 或 2， 默认是1
  // compiling: false,(0.x版本中生效,1.x增加中)  // 是否边录边转换，默认是false
});
export default {
  name: "home",
  data() {
    return {
      show: false,
      recordProgress: 0,
      status: 0, // 0:未录制 1:正在录制 2:试听状态
      recordDuration: 0,
      drawRecordId: null,
      playStatus: false,
      secondsValue: 0,
      fileAudio: null,
      fileAudioUrl: "",
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    statusTitle() {
      if (this.status == 0) return this.$t("Hold to talk");
      if (this.status == 1) return this.$t("Release the audition");
      if (this.status == 2) return this.$t("Click to redo");
    },
  },
  methods: {
    open() {
      this.show = true;
      this.getPermission();
      this.recordProgress = 0;
      this.recordDuration = 0;
    },
    close() {
      this.recordProgress = 0;
      this.recordDuration = 0;
      this.stopRecorder(0);
      this.show = false;
      this.drawRecordId = null;
      this.fileAudio = null;
      this.fileAudioUrl = "";
    },
    longClick() {
      if(this.status == 1) {
        this.recordProgress = 0;
        this.recordDuration = 0;
        this.stopRecorder();
        if (recorder.duration < 1) {
          this.status = 0;
          this.fileAudio = null;
          this.fileAudioUrl = "";
        }
        return;
      }
      this.drawRecordId = null;
      this.fileAudio = null;
      this.fileAudioUrl = "";
      setTimeout(() => {
        this.startRecorder();
      }, 10);
    },
    /**
     *  录音的具体操作功能
     * */
    // 开始录音
    startRecorder() {
      recorder.start().then(
        () => {
          this.status = 1;
          this.drawRecord(); //开始绘制图片
        },
        (error) => {
          this.$message.warning(
            this.$t("Please manually enable recording permission")
          );
        }
      );
    },
    // 结束录音
    stopRecorder(mode = 2) {
      recorder.stop();
      this.status = mode;
      this.drawRecordId && cancelAnimationFrame(this.drawRecordId);
      this.drawRecordId = null;
      let blobFile = recorder.getWAVBlob();
      this.fileAudio = new File([blobFile], "luyin.wav", {
        type: blobFile.type,
      });
      this.fileAudioUrl = URL.createObjectURL(this.fileAudio);
      this.recordProgress = 0;
      this.recordDuration = 0;
    },
    sendMessage() {
      const data_up = new FormData();
      data_up.append("files", this.fileAudio);
      data_up.append("configCode", "chatFile");
      uploadFileUrl(data_up)
        .then((response) => {
          if (!response) return;
          let time = new Date().getTime();
          let obj = {
            chatId: time,
            chatFileId: response[0].fileId,
            chatFileUrl: response[0].savePath,
            loading: true,
            content: `[${this.$t("voice")}]`,
            messageType: 11,
            current: true,
            read: 0,
            createTime: this.$dayjs().format("YYYY-MM-DD HH:mm:ss"),
            createUser: this.userInfo,
          };
          this.$emit("sendPublicMessage", obj);
          this.$message.success(this.$t('home.Sent successfully'));
        })
        .finally(() => {
          this.close();
        });
    },
    // 录音播放
    playRecorder() {
      // if(this.playStatus) {
      //   this.playStatus = false;
      //   this.pausePlayRecorder();
      //   return;
      // }
      // if(this.secondsValue > 0) {
      //   this.pausePlayRecorder();
      // } else {
      //   recorder.play();
      // }
      // this.drawPlay();
      // this.playStatus = true;
    },
    // 暂停录音播放
    pausePlayRecorder() {
      recorder.pausePlay();
    },
    // 恢复播放
    resumePlayRecorder() {
      recorder.resumePlay();
    },
    // 停止录音播放
    stopPlayRecorder() {
      recorder.stopPlay();
    },
    // 销毁录音
    destroyRecorder() {
      recorder.destroy().then(function () {
        recorder = null;
        this.drawRecordId && cancelAnimationFrame(this.drawRecordId);
        this.drawRecordId = null;
      });
    },
    /**
     *  获取录音文件
     * */
    getRecorder() {
      let toltime = recorder.duration; //录音总时长
      let fileSize = recorder.fileSize; //录音总大小
      //录音结束，获取取录音数据
      let PCMBlob = recorder.getPCMBlob(); //获取 PCM 数据
      let wav = recorder.getWAVBlob(); //获取 WAV 数据
      let channel = recorder.getChannelData(); //获取左声道和右声道音频数据
    },
    /**
     *  下载录音文件
     * */
    //下载pcm
    downPCM() {
      //这里传参进去的时文件名
      recorder.downloadPCM("新文件");
    },
    //下载wav
    downWAV() {
      //这里传参进去的时文件名
      recorder.downloadWAV("新文件");
    },
    /**
     *  获取麦克风权限
     * */
    getPermission() {
      Recorder.getPermission().then(
        (val) => {},
        (error) => {
          this.$message.warning(
            this.$t("Please manually enable recording permission")
          );
        }
      );
    },
    /**
     * 文件格式转换 wav-map3
     * */
    getMp3Data() {
      const mp3Blob = this.convertToMp3(recorder.getWAV());
      recorder.download(mp3Blob, "recorder", "mp3");
    },
    convertToMp3(wavDataView) {
      // 获取wav头信息
      const wav = lamejs.WavHeader.readHeader(wavDataView); // 此处其实可以不用去读wav头信息，毕竟有对应的config配置
      const { channels, sampleRate } = wav;
      const mp3enc = new lamejs.Mp3Encoder(channels, sampleRate, 128);
      // 获取左右通道数据
      const result = recorder.getChannelData();
      const buffer = [];
      const leftData =
        result.left &&
        new Int16Array(result.left.buffer, 0, result.left.byteLength / 2);
      const rightData =
        result.right &&
        new Int16Array(result.right.buffer, 0, result.right.byteLength / 2);
      const remaining = leftData.length + (rightData ? rightData.length : 0);
      const maxSamples = 1152;
      for (let i = 0; i < remaining; i += maxSamples) {
        const left = leftData.subarray(i, i + maxSamples);
        let right = null;
        let mp3buf = null;
        if (channels === 2) {
          right = rightData.subarray(i, i + maxSamples);
          mp3buf = mp3enc.encodeBuffer(left, right);
        } else {
          mp3buf = mp3enc.encodeBuffer(left);
        }
        if (mp3buf.length > 0) {
          buffer.push(mp3buf);
        }
      }
      const enc = mp3enc.flush();
      if (enc.length > 0) {
        buffer.push(enc);
      }
      return new Blob(buffer, { type: "audio/mp3" });
    },
    /**
     * 绘制波浪图-录音
     * */
    drawRecord() {
      let progress = Number(((recorder.duration / 60) * 100).toFixed(2));
      if (progress >= 100) {
        this.stopRecorder();
        return;
      }
      // 用requestAnimationFrame稳定60fps绘制
      this.drawRecordId = requestAnimationFrame(this.drawRecord);
      this.recordProgress = progress;
      this.recordDuration = Number(recorder.duration.toFixed(0));
    },
    drawPlay() {},
    formatTime(seconds) {
      const date = new Date(seconds * 1000);
      const minutes = date.getMinutes();
      const second = date.getSeconds();
      let formattedMinutes = "";
      let formattedSeconds = "";
      if (minutes < 10) {
        formattedMinutes = "0" + minutes;
      } else {
        formattedMinutes = minutes;
      }
      if (second < 10) {
        formattedSeconds = "0" + second;
      } else {
        formattedSeconds = second;
      }
      return `${formattedMinutes}:${formattedSeconds}`;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header {
  display: none;
}
.home {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    margin: 18px 0;
    font-size: 12px;
    color: #122545;
    line-height: 16.8px;
  }
  .progressBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 25px;
    margin-bottom: 12px;
    height: 40px;
    /deep/ .el-progress {
      width: 178px;
    }
    /deep/.vueAudioBetter {
      width: 243px;
      background: transparent;
      box-shadow: none;
      margin: 0;
      padding: 0;
      .slider {
        width: 178px;
      }
      .operate {
        .icon-playcircle-fill {
          display: inline-block;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background: #f7f9fc;
          font-size: 20px;
          &::before {
            content: "\e617";
            position: relative;
            top: 1px;
            left: 1px;
          }
        }
        .icon-pausecircle-fill {
          display: inline-block;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background: #f7f9fc;
          font-size: 20px;
          &::before {
            content: "\e605";
            position: relative;
            top: 1px;
            left: 1px;
          }
        }
        span {
          &:active {
            top: 0;
            left: 0;
          }
          &:nth-child(2) {
            display: none;
          }
          &:nth-child(3) {
            display: none;
          }
        }
      }
      .icon-notificationfill {
        display: none;
      }
    }
    .sendMessage {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #004c97;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      i {
        font-size: 20px;
        color: #ffffff;
        position: relative;
        top: 1px;
      }
    }
  }
  .feature {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  }
  .feature-box {
    width: 80px;
    height: 80px;
    position: relative;
    z-index: 2;
  }
  .pulse {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    i {
      color: #ffffff;
      font-size: 50px;
    }
    span {
      color: #ffffff;
      font-size: 16px;
      font-weight: 600;
      user-select: none;
    }
    .el-icon-refresh-left {
      color: #757d8a;
    }
    &.noRecord {
      color: #ffffff;
      background: linear-gradient(
        317deg,
        rgb(7, 111, 73) 0%,
        rgb(154.33, 223.12, 197.99) 100%
      );
    }
    &.recorded {
      color: #ffffff;
      background: linear-gradient(317deg, #dc3545 14.4%, #f09da5 84.82%);
    }
    &.endRecord {
      color: #757d8a;
      background: linear-gradient(
        317deg,
        rgb(180.18, 204.96, 233.75) 6.11%,
        rgba(164.69, 189.98, 255, 0) 100%
      );
    }
  }
  .pulse1,
  .pulse2 {
    position: absolute;
    width: 120px;
    height: 120px;
    top: -20px;
    margin-left: -20px;
    background: rgba(220, 53, 69, 0.60);
    border-radius: 50%;
    opacity: 0;
    z-index: -1;
    box-shadow: 1px 1px 15px rgba(219, 74, 88, 0.5);
  }
  .pulse1 {
    animation: warn1 1.5s linear;
    animation-iteration-count: infinite;
  }
  .pulse2 {
    animation: warn2 1.5s linear;
    animation-iteration-count: infinite;
  }
  @keyframes warn1 {
    0% {
      transform: scale(1);
      opacity: 0.01;
    }

    25% {
      transform: scale(1.1);
      opacity: 0.1;
    }

    50% {
      transform: scale(1.2);
      opacity: 0.07;
    }

    75% {
      transform: scale(1.4);
      opacity: 0.03;
    }

    100% {
      transform: scale(1.6);
      opacity: 0.01;
    }
  }

  @keyframes warn2 {
    0% {
      transform: scale(.6);
      opacity: 0.01;
    }

    25% {
      transform: scale(.8);
      opacity: 0.13;
    }

    50% {
      transform: scale(.9);
      opacity: 0.1;
    }

    75% {
      transform: scale(1);
      opacity: 0.07;
    }

    100% {
      transform: scale(1.2);
      opacity: 0.01;
    }
  }
}
.closeDialog {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  i {
    font-size: 24px;
    color: #122545;
  }
}
</style>
