module.exports = {
  weekfn: (week) => {
    return `第${week.values.n}周`;
  },
  el: {
    colorpicker: {
      confirm: '确定',
      clear: '清空',
    },
    datepicker: {
      now: '此刻',
      today: '今天',
      cancel: '取消',
      clear: '清空',
      confirm: '确定',
      selectDate: '选择日期',
      selectTime: '选择时间',
      startDate: '开始日期',
      startTime: '开始时间',
      endDate: '结束日期',
      endTime: '结束时间',
      prevYear: '前一年',
      nextYear: '后一年',
      prevMonth: '上个月',
      nextMonth: '下个月',
      year: '年',
      weekfn: (week) => {
        return `第${week.values.n}周`;
      },
      month1: '1 月',
      month2: '2 月',
      month3: '3 月',
      month4: '4 月',
      month5: '5 月',
      month6: '6 月',
      month7: '7 月',
      month8: '8 月',
      month9: '9 月',
      month10: '10 月',
      month11: '11 月',
      month12: '12 月',
      January: '1 月',
      February: '2 月',
      March: '3 月',
      April: '4 月',
      May: '5 月',
      June: '6 月',
      July: '7 月',
      August: '8 月',
      September: '9 月',
      October: '10 月',
      November: '11 月',
      December: '12 月',
      // week: '周次',
      weeks: {
        sun: '日',
        mon: '一',
        tue: '二',
        wed: '三',
        thu: '四',
        fri: '五',
        sat: '六',
      },
      months: {
        jan: '一月',
        feb: '二月',
        mar: '三月',
        apr: '四月',
        may: '五月',
        jun: '六月',
        jul: '七月',
        aug: '八月',
        sep: '九月',
        oct: '十月',
        nov: '十一月',
        dec: '十二月',
      },
    },
    xiaoxie: {
      customer: '客户',
      country: '国家',
      currency: '货币',
    },
    home: {
      at: '在',
      PURCHASER: '买家',
      VENDOR: '卖家',
      FORWARDER: '货代',
      People: '人员',
      'Read / Unread': '全部 / 未读',
      PIorCINo: 'PI或CI号',
      'Assign/Notify': '分配 / 通知',
      Pleasetype: '请选择类型',
      'Change the state': '更改管理员账号权限',
      'Please select member': '请选择成员',
      comment: '新评论',
      event: '新事件',
      toDo: '新待办',
      'New member apply': '新成员申请',
      'New customer apply': '新客户申请',
      zeroM: 'https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/schedule/1_1.png',
      zeroW: 'https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/schedule/0_0.png',
      'Illegal Token, Please log out and log in again':
        '非法令牌，请退出重新登录',
      'Token expired, Please log out and log in again':
        '令牌已过期，请退出重新登录',
      'Your account has been logged in other places, please log out and log in again':
        '您的账号已在其他地方登录，请退出重新登录',
      'Confirm logout': '确认退出',
      'Re-Login': '重新登录',
      'Last week': '最近一周',
      'Last month': '最近一月',
      'Last season': '最近一季',
      'Last year': '最近一年',
      'Chat Top': '聊天置顶',
      'Cancel Topping': '取消置顶',
      NOTICE: '通知',
      OPERATION: '分配',
      'Please enter': '请输入',
      'Please select': '请选择',
      CREATE: '创建',
      Create: '创建',
      CIAdd: '添加',
      Loading: '加载中...',
      Read: '已读',
      Unread: '未读',
      Home: '主页',
      dashboardCapacityMonit: '账号容量监控',
      Parther: '公司',
      Order: '订单',
      Files: '文件',
      Tracking: '跟踪',
      Dashboard: '数据看板',
      customerTitle: '客户',
      customerTitle1: '供应商',
      new: '新建',
      connectionTitle: '创建关系',
      companyLogo: '公司Logo',
      companyName: '公司名',
      cantfind: '找不到？',
      inviteCustomers: '邀请客户创建公司',
      inviteSupplier: '邀请供应商创建公司',
      exhibition: '将客户展示给',
      showTo: '展示给',
      email: '邮箱',
      VATNo: '增值税号',
      contact: '联系方式',
      country: '国家',
      choosethecountry: '选择国家',
      timezone: '时区',
      choosethetimezone: '选择时区',
      createConnectionNow: '确认创建',
      Pleasecompletetheinformation: '请完善信息',
      createCustomerSuccesfully: '创建关系成功',
      Nofollow: '无收藏',
      Unfollow: '取消收藏',
      Follow: '收藏',
      inviteEmployees: '邀请员工',
      Hide: '隐藏',
      Remove: '移除',
      Invite: '邀请员工加入',
      InviteConfirm: '确认邀请名单',
      InviteSuccessful: '邀请客户成功',
      ConfirmRemoveConnection: '确认删除关系',
      Remove: '删除',
      DeleteSuccessful: '客户已删除',
      Edit: '编辑',
      Delete: '删除',
      Dashboard: '数据看板',
      Message: '信息',
      Event: '事件',
      Todo: '待办',
      order: '订单',
      CInumber: 'CI号',
      PInumber: 'PI号',
      Pleaseselect: '选择',
      Customer: '客户',
      Currency: '货币',
      OrderAmount: '订单金额',
      Groups: '群组',
      ProducingStatus: '生产中',
      producing: '生产中',
      AddForwarder: '添加货代',
      Document: '文件',
      Createdsucceeded: '文件上传成功',
      SelectDocument: '选择文件',
      uploaded: '已上传',
      Clicktoselectforwarder: '点击选择货代',
      eopleSelected: '人已选中',
      ClicktocreateGroups: '点击创建群组',
      Invitecolleaguesandcustomerstothegroup: '邀请员工和客户进群组',
      createOrder: '创建订单',
      updateOrder: '编辑订单',
      'Order import': '订单导入',
      'Download template': '下载模板',
      CreateOrderNow: '立即创建订单',
      UpdateOrderNow: '立即编辑订单',
      groupConfirm: '群组确认',
      'Quickly create groups': '快速创建群组',
      'Update group': '更新群组',
      'set group': '群组设置',
      'Select group': '选择群组',
      Cancel: '取消',
      Confirm: '确认',
      'Todo status update': '待办项状态更新',
      'Finshed this todo?': '完成此待办项？',
      'Back to UNFINISHED?': '回退至未完成？',
      Finished: '已完成',
      Unfinished: '进行中',
      Unstart: '未开始',
      ConfirmAgree: '确认',
      ConfirmReject: '确认',
      Whetherusercompany: '拒绝用户加入公司',
      youinvitation: '你确定要拒绝邀请吗',
      Whethercompany: '同意人员加入公司',
      Areinvitation: '你确定要同意邀请吗？',
      'Assign colleagues to check': '邀请员工加入',
      'Confirm to modify the partner？': '确认员工名单？',
      Invitethesuccessful: '邀请成功',
      Copysucceeded: '复制成功',
      UploadSucceeded: '上传成功',
      Deletionsucceeded: '人员已移除',
      OrdersTotal: '订单总数',
      Otherlogins: '其他登录方式',
      ContactAdministrator: '联系管理员',
      language: 'English',
      MessageBoard: '公告',
      MyInvitation: '我的邀请',
      MyDashboard: '数据看板',
      Group: '公司',
      SelectForwarder: '选择货代',
      dow: 'https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/file/dow_zh.png',
      full: 'https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/file/full_zh.png',
      share:
        'https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/file/share_zh.png',
      LOGOUT: '退出登录',
      Confirmtheexit: '确认退出登录',
      warn: '提醒',
      Noinformation: '暂无数据',
      Pleaseselectcountry: '请选择国家',
      PleaseinputCInumber: '请输入CI号',
      PleaseinputPInumber: '请输入PI号',
      PleaseselectCustomer: '请选择客户',
      'Please upload the history file': '请上传历史文件',
      Pleaseselectcurrency: '请选择货币',
      Pleaseinputamount: '请输入金额',
      Pleaseselectmember: '请选择群组成员',
      PleaseselectStatus: '请选择状态',
      OR: '或',
      SendInvitationlink: '发送邀请链接',
      copylink: '复制链接',
      Email: '邮箱',
      CustomerName: '客户名称',
      Copysucceeded: '复制成功',
      Createdsuccessfully: '创建成功',
      Invitationdeclined: '邀请已被拒绝',
      Applythrough: '申请通过',
      PleaseinputVAT: '请输入增值税号',
      PleaseinputcorrectVAT: '请输入正确的增值税号',
      Pleaseinputaliasname: '请输入公司别名',
      Pleaseuploadlogo: '请上传公司logo',
      Pleaseselectcompanytype: '请选择公司类型',
      'Please input contact': '请输入公司联系方式',
      'lease select country': '请选择国家',
      'Please input email': '请输公司入邮箱',
      'Please input name': '请输入公司名称',
      'Please select timezone': '请选择时区',
      'Please enter the detailed address': '请输入详细地址',
      phone: '电话',
      VerificationCode: '验证码',
      Approveconfirm: '确认同意',
      explain_img:
        'https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/zh_explain_img.png',
      'Please fulfill the personal information and bind the company firstly':
        '您需要完成个人信息填写并绑定公司，才能进行下一步操作。',
    },
    InformationCenter: {
      ping: '消息',
      Todo: '待办',
      Comment: '评论',
      Event: '事件',
      Apply: '申请',
      Nomoremessages: '无更多消息',
      PleaseWritesomething: '写...',
      Send: '发送',
      Personaldetails: '个人详情',
      update: '更新',
      created: '创建',
      notified: '通知',
      you: '你',
      assignedto: '分配给',
      in: '在',
      Whendonenotify: '完成后，通知',
      Dueon: '截止日期',
      Notes: '备注',
      Status: '状态',
      UNSTARTED: '未开始',
      FINISHED: '已完成',
      UNFINISHED: '进行中',
      Createby: '创建人',
      Record: '打卡记录',
      Unfinished: '进行中',
      Finished: '已完成',
      Sortby: '排序',
      replied: '回复',
      Comments: '评论',
      posted: '发布',
      Company: '公司',
      Personal: '个人',
      Name: '姓名',
      Title: '职务',
      Agree: '同意',
      Refuse: '拒绝',
      wantstoconnectwithyourcompany: '想要与你的公司建立关系',
      wantstojoinyourcompany: '想要加入你的公司',
      Notification: '提示',
      AddressBook: '通讯录'
    },
    Dashboard: {
      'Download as PNG': '下载为PNG',
      'Please select item no': '请选择 item no.',
      'Please select size': '请选择 size',
      'Please select type': '请选择 type',
      bar: '柱状图',
      pie: '饼图',
      quantity: '数量',
      Proportion: '比例',
      BasicStatistics: '基本统计',
      Member: '成员',
      Supplier: '供应商',
      Customer: '客户',
      Orders: '订单',
      Shipping: '运输中',
      Producing: '生产中',
      Completed: '已完成',
      Total: '总额(USD)',
      Currency: '货币',
      Amount: '金额',
      MonthlyPurchaseAmount: '销售金额',
      MonthlySalesAmount: '销售额',
      ShipmentQuantity: '采购金额',
      Monthly: '月',
      Quarterly: '季',
      Yearly: '年',
      Startfrom: '起',
      Endoftime: '止',
      CommodityPrice: '原材料价格',
      PurchaseComparison: '销售对比',
      CustomerComparison: '采购对比',
      SupplierComparison: '供应商对比',
      Exchangerate: '汇率',
      CNY: '人民币',
      USD: '美元',
      JPY: '日元',
      HKD: '港币',
      EUR: '欧元',
      GBP: '英镑',
      Exportdata: '出口数据',
      ExportProportion: '出口占比',
      'Supplier(Amount)': '供应商(金额)',
      'customer(Amount)': '客户(金额)',
      'SKU(unit price)': 'SKU(单价)',
      'SKU(Amount)': 'SKU(金额)',
      whole: '全部',
      Statisticalcharts: '统计图表'
    },
    pagination: {
      goto: '前往',
      pagesize: '条/页',
      total: '共 {total} 条',
      pageClassifier: '页',
    },
    messagebox: {
      title: '提示',
      confirm: '确定',
      cancel: '取消',
      error: '输入的数据不合法!',
    },
    upload: {
      deleteTip: '按 delete 键可删除',
      delete: '删除',
      preview: '查看图片',
      continue: '继续上传',
    },
    table: {
      emptyText: '暂无数据',
      confirmFilter: '筛选',
      resetFilter: '重置',
      clearFilter: '全部',
      sumText: '合计',
    },
    tree: {
      emptyText: '暂无数据',
    },
    transfer: {
      noMatch: '无匹配数据',
      noData: '无数据',
      titles: ['列表 1', '列表 2'],
      filterPlaceholder: '请输入搜索内容',
      noCheckedFormat: '共 {total} 项',
      hasCheckedFormat: '已选 {checked}/{total} 项',
    },
    image: {
      error: '加载失败',
    },
    pageHeader: {
      title: '返回',
    },
    popconfirm: {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
    },
    empty: {
      description: '暂无数据',
    },
    select: {
      noMatch: '暂无数据',
      noData: '暂无数据',
    },
  },
  overallSituation: {
    noData: '暂无数据',
  },
  xiaoxie: {
    customer: '客户',
    country: '国家',
    currency: '货币',
    'Successfully established relationship': '已成功建立关系',
    'Please activate your account via email': '请通过邮箱激活账号',
    'register successfully': '注册成功',
    'Please verfify your email': '请验证您的电子邮件',
    'Confirmation email has been sent to': '确认电子邮件已发送至',
    'Please click on the link in that email to complete your signup':
      '请点击该电子邮件中的链接以完成注册',
    'check your spam folder':
      '如果您没有看到它，您可能需要检查您的垃圾邮件文件夹',
    'Still cant find this email': `仍然找不到此电子邮件`,
    'Resend email': '重新发送电子邮件',
    'Need help': '需要帮助',
    'Contact us': '联系我们',
    'Order return': '订单回退',
    CIFallbacktip: '回退至PI状态，将删除以下CI及相关事件和文件，确认操作？',
    amount: '金额',
    State: '状态',
    'Order Amount Confirmation': '订单金额确认',
    'Original PI order currency': '原PI货币',
    'Original PI order amount': '原PI金额',
    Filter: '筛选',
    'Split reminder': '拆单提醒',
    'Successfully split the order': '拆单成功',
    'The current PI order has been split, return to the order list page':
      '当前PI已转成CI，点击回到订单列表页',
    'The current CI order has been rolled back, return to the order list page':
      '当前CI已回退至PI状态，点击回到订单列表页',
    Notification: '提示',
    confirm: '确定',
    cancel: '取消',
    'Agreed to your cooperation invitation': '同意了您的合作邀请',
    'Confirm unfollow this order': '确认取消收藏该订单？',
    'Delete this todo': '确认删除该待办？',
    'Company name': '公司名',
    Title: '标题',
    Currency: '货币',
    CompanyName: '公司名 / 公司别名',
  },
  home: {
    'Quarter on quarter': '季同比',
    'Quarter to quarter ratio': '季环比',
    'Year on year': '年同比',
    at: '在',
    edit: '修改',
    allSelect: '全选',
    Asc: '升序',
    Desc: '降序',
    updateTime: '更新时间',
    waybill: '运单',
    Follow: '已收藏',
    AlreadyFollow: '已收藏',
    Nofollow: '未收藏',
    detailText: '详情',
    aliasName: '简称',
    createFolder: '新建文件夹',
    defaultFolder: "默认文件夹",
    'Please enter folder name': '请输入文件夹名称',
    'Please enter file name': '请输入文件名称',
    'Are you sure to delete the folder': '确定删除该文件吗',
    'The folder cannot be restored after deletion': '删除后文件不可恢复',
    'Share with me': "共享给我",
    'Account transfer': '账号转移',
    'Account transfer to': '账号转移至',
    'clock in': '打卡',
    'Custom Customers': '自定义客户',
    selectedPerson(row) {
      return `已选${row.values.n}人`
    },
    Unnamed: '未命名',
    'Sent successfully': '发送成功',
    'Join/Create Company': '加入/创建公司',
    'Only by joining/creating a company can you view it': '加入/创建公司才可以查看',
    'Successfully invited employees': '邀请员工成功',
    'Please enter a notice content': '请输入公告内容',
    'Please enter a group name': '请输入群组名称',
    'The group name must not be empty': '群组名称不能为空',
    allMember: "全部成员",
    'Invitation sent successfully': '邀请发送成功',
    createSuccess: "创建成功",
    editSuccess: "编辑成功",
    createCompany: '创建公司',
    'Confirma join': '确认加入',
    'Confirmation Information': '确认信息',
    'Modify information': '修改信息',
    'Personal Information': '个人信息',
    informationCenter: '信息中心',
    OrderRelatedPersonnel: ({values}) => {
      return `${values.n}相关人员`
    },
    'Return personal information': '返回个人信息页',
    moreAction: "更多操作",
    seeMore: "查看更多",
    'Select company you want to join': '请选择要加入的公司',
    PURCHASER: '买家',
    VENDOR: '卖家',
    FORWARDER: '货代',
    Download: '下载文件',
    'List of company personnel': '公司人员名单',
    People: '人员',
    'Read / Unread': '全部 / 未读',
    'incomplete / Completed': '未完成 / 已完成',
    incomplete: '未完成',
    Completed: '已完成',
    PIorCINo: 'PI或CI号',
    'Assign/Notify': '分配 / 通知',
    Pleasetype: '请选择类型',
    'Change the state': '更改管理员账号权限',
    'Please select member': '请选择成员',
    'Improve personal information': '完善个人信息',
    'Create/Join Company': '创建/加入公司',
    comment: '新评论',
    event: '新事件',
    toDo: '新待办',
    'New member apply': '新成员申请',
    'New customer apply': '新客户申请',
    zeroM: 'https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/schedule/1_1.png',
    zeroW: 'https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/schedule/0_0.png',
    'Illegal Token, Please log out and log in again':
      '非法令牌，请退出重新登录',
    'Token expired, Please log out and log in again':
      '令牌已过期，请退出重新登录',
    'Your account has been logged in other places, please log out and log in again':
      '您的账号已在其他地方登录，请退出重新登录',
    'Confirm logout': '确认退出',
    'Re-Login': '重新登录',
    'Last week': '最近一周',
    'Last month': '最近一月',
    'Last season': '最近一季',
    'Last year': '最近一年',
    'Chat Top': '聊天置顶',
    'Cancel Topping': '取消置顶',
    NOTICE: '通知',
    OPERATION: '分配',
    'Please enter': '请输入',
    'Please select': '请选择',
    CREATE: '创建',
    Create: '创建',
    CIAdd: '添加',
    Loading: '加载中...',
    Read: '已读',
    Unread: '未读',
    Home: '主页',
    dashboardCapacityMonit: '账号容量监控',
    Parther: '公司',
    Order: '订单',
    Files: '文件',
    Tracking: '跟踪',
    Dashboard: '数据看板',
    customerTitle: '客户',
    customerTitle1: '供应商',
    new: '新建',
    connectionTitle: '创建关系',
    companyLogo: '公司Logo',
    companyName: '公司名',
    cantfind: '找不到？',
    inviteCustomers: '邀请客户创建公司',
    inviteSupplier: '邀请供应商创建公司',
    exhibition: '将客户展示给',
    showTo: '展示给',
    email: '邮箱',
    VATNo: '增值税号',
    contact: '联系方式',
    country: '国家',
    choosethecountry: '选择国家',
    timezone: '时区',
    choosethetimezone: '选择时区',
    createConnectionNow: '确认创建',
    Pleasecompletetheinformation: '请完善信息',
    createCustomerSuccesfully: '创建关系成功',
    Unfollow: '取消收藏',
    Follow: '收藏',
    Translate: '翻译',
    inviteEmployees: '邀请员工',
    Hide: '隐藏',
    Remove: '移除',
    Invite: '邀请员工加入',
    InviteConfirm: '确认邀请名单',
    InviteSuccessful: '邀请客户成功',
    ConfirmRemoveConnection: '确认删除关系',
    Remove: '删除',
    DeleteSuccessful: '客户已删除',
    Edit: '编辑',
    Delete: '删除',
    Dashboard: '数据看板',
    Message: '信息',
    Event: '事件',
    List: '列表',
    Todo: '待办',
    order: '订单',
    CInumber: 'CI号',
    PInumber: 'PI号',
    Pleaseselect: '选择',
    Customer: '客户',
    Currency: '货币',
    OrderAmount: (val) => {
      return `${val.values.n}金额`
    },
    OrderNumber: (val) => {
      return `${val.values.n}数量`
    },
    Groups: '群组',
    ProducingStatus: '生产中',
    producing: '生产中',
    AddForwarder: '添加货代',
    Document: '文件',
    Createdsucceeded: '文件上传成功',
    SelectDocument: '选择文件',
    uploaded: '已上传',
    Clicktoselectforwarder: '点击选择货代',
    eopleSelected: '人已选中',
    ClicktocreateGroups: '点击创建群组',
    Invitecolleaguesandcustomerstothegroup: '邀请员工和客户进群组',
    createOrder: (val) => {
      return `创建${val.values.n}`
    },
    updateOrder: (val) => {
      return `编辑${val.values.n}`
    },
    generateOrder: (val) => {
      return `生成${val.values.n}`
    },
    'Order import': (val) => {
      return `${val.values?.n}导入`
    },
    'Download template': '下载模板',
    CreateOrderNow: '创建订单',
    UpdateOrderNow: '更新订单',
    groupConfirm: '群组确认',
    'Quickly create groups': '快速创建群组',
    'Update group': '更新群组',
    'set group': '群组设置',
    'Select group': '选择群组',
    Cancel: '取消',
    Confirm: '确认',
    'Todo status update': '待办项状态更新',
    'Finshed this todo?': '完成此待办项？',
    'Back to UNFINISHED?': '回退至未完成？',
    Finished: '已完成',
    Unfinished: '进行中',
    Unstart: '未开始',
    ConfirmAgree: '确认',
    ConfirmReject: '确认',
    Whetherusercompany: '拒绝用户加入公司',
    youinvitation: '你确定要拒绝邀请吗',
    Whethercompany: '是否确认用户加入公司',
    'Member successfully joined': '成员加入成功',
    'Refuse members to join': '拒绝成员加入',
    'Relationship established successfully': '关系建立成功',
    'Refuse to establish a relationship': '拒绝建立关系',
    Areinvitation: '你确定要同意邀请吗？',
    'Assign colleagues to check': '邀请员工加入',
    'Confirm to modify the partner？': '确认员工名单？',
    Invitethesuccessful: '人员添加成功',
    Copysucceeded: '复制成功',
    UploadSucceeded: '上传成功',
    Deletionsucceeded: '人员已移除',
    OrdersTotal: (val) => {
      return `${val.values.count}总数`
    },
    Otherlogins: '其他登录方式',
    ContactAdministrator: '联系管理员',
    language: 'English',
    MessageBoard: '公告',
    MyDocuments: "我的文档",
    'Invitation code': '邀请码',
    'Invitation Code Usage Record': '邀请码使用记录',
    'Invitation Code Management': '邀请码管理',
    'Generate invitation code': '生成邀请码',
    'My History Invitation': '我的历史邀请',
    'Employee History Invitation': '员工历史邀请',
    'This invitation code can only be used once': '该邀请码仅能使用一次, 有效期7天',
    'Copy Invite code': '复制邀请码',
    'Send via email': '通过邮件发送',
    'Please enter the company invitation code': '请输入公司邀请码',
    MyInvitation: '我的邀请',
    MyDashboard: '数据看板',
    Group: '公司',
    SelectForwarder: '选择货代',
    dow: 'https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/file/dow_zh.png',
    full: 'https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/file/full_zh.png',
    share:
      'https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/file/share_zh.png',
    LOGOUT: '退出登录',
    Confirmtheexit: '确认退出登录',
    warn: '提醒',
    Noinformation: '暂无数据',
    Pleaseselectcountry: '请选择国家',
    PleaseinputCInumber: '请输入CI号',
    PleaseinputPInumber: ({values}) => {
      return `请输入${values.n}名称`
    },
    PleaseselectCustomer: '请选择客户',
    PleaseInputProduct: '产品物料号、类型、尺寸',
    PleaseselectSupplier: '请选择供应商',
    'Please upload the history file': '请上传历史文件',
    Pleaseselectcurrency: '请选择货币',
    Pleaseinputamount: '请输入金额',
    Pleaseselectmember: '请选择群组成员',
    PleaseselectStatus: '请选择状态',
    OR: '或',
    SendInvitationlink: '发送邀请链接',
    copylink: '复制链接',
    Email: '邮箱',
    CustomerName: '客户名称',
    Copysucceeded: '复制成功',
    Createdsuccessfully: '创建成功',
    Invitationdeclined: '邀请已被拒绝',
    Applythrough: '申请通过',
    PleaseinputVAT: '请输入增值税号',
    PleaseinputcorrectVAT: '请输入正确的增值税号',
    Pleaseinputaliasname: '请输入公司别名',
    PleaseuploadCompanylogo: '请上传公司logo',
    PleaseuploadCustomerlogo: '请上传用户头像',
    Pleaseselectcompanytype: '请选择公司类型',
    'Please input contact': '请输入公司联系方式',
    'lease select country': '请选择国家',
    'Please input email': '请输公司入邮箱',
    'Please input name': '请输入公司名称',
    'Please select timezone': '请选择时区',
    'Please enter the detailed address': '请输入详细地址',
    phone: '电话',
    VerificationCode: '验证码',
    Approveconfirm: '确认同意',
    explain_img:
      'https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/zh_explain_img.png',
    'Please fulfill the personal information and bind the company firstly':
      '您需要完成个人信息填写并绑定公司，才能进行下一步操作。',
    'Good morning': '早上好',
    'Good noon': '中午好',
    'Good afternoon': '下午好',
    'Good evening': '晚上好'
  },
  InformationCenter: {
    ping: '消息',
    Todo: '待办',
    Comment: '评论',
    Event: '事件',
    Apply: '申请',
    Nomoremessages: '无更多消息',
    PleaseWritesomething: '写...',
    Send: '发送',
    Personaldetails: '个人详情',
    update: '更新',
    created: '创建',
    notified: '通知',
    you: '你',
    assignedto: '分配给',
    in: '在',
    Whendonenotify: '事后通知',
    Dueon: '截止日期',
    Notes: '备注',
    Status: '状态',
    UNSTARTED: '未开始',
    FINISHED: '已完成',
    UNFINISHED: '进行中',
    Createby: '创建人',
    Record: '打卡记录',
    Unfinished: '进行中',
    Finished: '已完成',
    Sortby: '排序',
    replied: '回复',
    Comments: '评论',
    posted: '发布',
    Company: '公司',
    Personal: '个人',
    Name: '姓名',
    Title: '职务',
    Agree: '同意',
    Refuse: '拒绝',
    wantstoconnectwithyourcompany: '想要与你的公司建立关系',
    wantstojoinyourcompany: '想要加入你的公司',
    Notification: '提示',
    AddressBook: '通讯录'
  },
  Dashboard: {
    'Download as PNG': '下载为PNG',
    'Please select item no': '请选择 item no.',
    'Please select size': '请选择 size',
    'Please select type': '请选择 type',
    confirmMessage: "是否前往数据看板？",
    confirmSeeMessage: "是否查看该数据？",
    confirmSeeMessageFn: (val) => {
      return `是否查看${val.values.count}的数据？`
    },
    'CorrespondingXCoordinate': '对应X坐标值',
    'numberValue': '数值',
    'Date Range': '日期范围',
    vip: '极简',
    ordinary: '普通',
    Matter: "原材料",
    skuItemNo: '物料号',
    skuSize: '尺寸',
    skuType: '类型',
    bar: '柱状图',
    pie: '饼图',
    quantity: '数量',
    Proportion: '比例',
    allContrastData: '全量数据',
    BasicStatistics: '基本统计',
    Company: "公司",
    ExtraData: "更多数据",
    MainSupplier: '供应商',
    MainCustomers: '客户',
    MainProducts: '产品',
    MainCountry: '国家',
    Conast: '对比',
    Product: '产品',
    Member: '成员数',
    Supplier: '供应商数',
    Customer: '客户数',
    customer: '客户',
    supplier: '供应商',
    Orders: (val) => {
      return `${val.values.n}数`
    },
    Shipping: '运输中',
    Producing: '生产中',
    Completed: '已完成',
    Total: '总额(USD)',
    TotalNumber: '总数',
    TotalAveage: '总平均值(天)',
    SupplierNumber: (val) => {
      let value = "";
      if(val.values.n == '1') value = "客户";
      else value = "供应商";
      return `数量(${value})`
    },
    ProductNumber: "数量(Sku)",
    CountryNumber: "数量(国家)",
    Proportion: "占比",
    Currency: '货币',
    Amount: '金额',
    statisticsCountryMarket: '国家信息',
    statisticsCustomerMarket: '客户信息',
    statisticsProductMarket: '产品信息',
    BasicStatisticsCountryMarket: '市场信息',
    MonthlySalesAmount: '销售额',
    MonthlyPurchaseAmount: '销售金额',
    MonthlyPurchaseNumber: '销售数量',
    ShipmentAmount: '采购金额',
    ShipmentQuantity: '采购数量',
    Monthly: '月',
    week: '周',
    Quarterly: '季',
    Yearly: '年',
    Startfrom: '起',
    Endoftime: '止',
    CommodityPrice: '原材料价格',
    PurchaseComparison: '销售对比',
    CustomerComparison: '采购对比',
    SupplierComparison: '供应商对比',
    Exchangerate: '汇率',
    CNY: '人民币',
    USD: '美元',
    JPY: '日元',
    HKD: '港币',
    EUR: '欧元',
    GBP: '英镑',
    BDIIndexNumber: "BDI指数",
    PMIIndexNumber: "PMI指数",
    ProductionLogisticsAnalysis: "生产物流分析",
    Exportdata: '出口数据',
    ExportProportion: '出口占比',
    'Supplier': '供应商',
    'customer': '客户',
    'SKU(unit price)': 'SKU(单价)',
    'SKU(Amount)': 'SKU(金额)',
    'SKU(Amount)Pie': 'SKU(金额)',
    'SKU(Amount)Bar': 'SKU(金额)',
    whole: '全部',
    Statisticalcharts: '统计图表',
    orderTotal: '总额(USD)',
    country: '国家',
    countryEn: '国家英文',
    Capital: '首都',
    Area: '面积(km²)',
    Population: '人口',
    Currency: '货币',
    Politics: '政体',
    GdpTotal: 'GDP总计(USD)',
    'GDP per capita': '人均GDP(USD)',
    'Calling code': '国际区号',
    'Official languages': '官方语言',
    'Province': '行政区划',
    'Organization' :'国际组织',
    'Religion': '主要宗教',
    CompanyName: '公司名',
    CompanyEmail: '公司邮箱',
    CompanyContact: '联系方式',
    VATNO: '增值税号',
    Country: '国家',
    Address: '详细地址',
    Website: '官方网站',
    CountryName: '国家名',
    maxValue: "最大值",
    minValue: "最小值",
    avgValue: "平均值"
  },
  dashboardCapacityMonit: {
    'Storage Usage': '存储空间使用(G)',
    Free: '剩余',
    Occupied: '已使用',
    'Total Space': '总容量',
    'File occupancy distribution': '空间使用分布',
    File: '文件',
    Account: '账号',
    'Partner Account': '合作公司数量',
    'Employee Account': '员工账号数量',
    'Employee Name': '员工姓名',
    Search: '搜索',
    'Clear All Filters': '清除所有条件',
    'File Quantity': '文件数量',
    Storage: '文件占用(MB)',
    'Involved Order': '参与订单数',
    Distribution: '空间使用分布',
  },
  Files: {
    'shared the file': '分享了一个文件',
    'File Name': '文件名称',
    'File type': '文件类型',
    'File Link': '链接',
    'Copy all information': '复制所有信息',
    'Copy Link': '复制链接',
    'Copy succeeded': '复制成功',
    'Select people': '选择人员',
    'Select company': '选择公司',
    'Input Name': '人员名字',
    Send: '分享文件',
    Company: '公司',
    Name: '名称',
    Selectrange: '搜索范围',
    Filename: '文件名',
    'Type File name': '文件名',
    'Type CI number': 'CI号',
    'Type PI number': 'PI号',
    'Type Uploader name': '上传人',
    CI: ' CI号',
    PI: ' PI号',
    Uploader: '上传人',
    inputcontent: '请输入内容',
    Search: '搜索',
    ClearAllFilters: '清除所有条件',
    Export: '导出',
    ExportPng: '导出PNG',
    ExportExcel: '导出Excel',
    ExportReportForms: '导出报表',
    ExportLoading: '导出进行中.....',
    Type: '类型',
    'Size(KB)': '大小(KB)',
    Format: '格式',
    Creator: '上传人',
    CreateTime: '创建时间',
    Action: '操作',
    'Send File Success': '发送文件成功',
    'File preview': '文件预览',
    'List only': '仅列表',
    'Choose Download Content': '选择下载方式'
  },
  registerLoging: {
    CreateAnAccount: '创建账户',
    Username: '账号名',
    Email: '邮箱',
    Password: '密码',
    ConfirmPassword: '确认密码',
    VerificationCode: '验证码',
    CreateYourAccount: '确认创建',
    CellphoneLogin: '手机登录',
    GetVerificationCode: '获取验证码',
    weChatLogin: "微信登录",
    PleaseinputaEmail: '请输入邮箱',
    PleaseinputaUserName: '请输入账号名',
    'Account name with a minimum of 2 digits': '账号名最小2位'
  },
  login: {
    'The password cannot be less than 5 digits': '密码不可小于5位',
    loginWechatError: "登录失败,请确认是否绑定微信",
    Username: 'Echola账号或邮箱',
    Password: '密码',
    'Password strength': '密码强度',
    authCode: '验证码',
    phoneNo: "手机号",
    AccountButtonLogin: '账号登录',
    GetStart: '开始',
    AccountLogin: '登录Echola',
    HaveNoAccountRegister: '还没账号？注册一个',
    Alreadyhaveone: '已有账号？',
    Login: '登录',
    enteroneusername: '请输入Echola账号或邮箱',
    PasswordInconsistency: '密码不一致',
    PleaseinputaSuccessEmail: '请输入正确的邮箱',
    Pleaseinputapassword: '请输入密码',
    Pleaseinputaauthcode: '请输入验证码',
    Pleaseenteryourmobilenumber: '请输入手机号码',
    Pleaseentertheverificationcode: '请输入验证码',
    'The current mailbox is bound or used': '当前邮箱已绑定或使用',
    'Please confirm that it is a new email that has not been registered':
      '请确定是未被注册的新邮箱',
    'Back to Login': '返回登录',
    'Re register': '重新注册',
    Getstarted: '开始',
    'The current account name does not exist': '当前账号名不存在',
    'Go to register': '是否前往注册',
    Gotoregister: '前往注册',
    Nonempty: '空',
    Tooweak: '较弱',
    secondary: '普通',
    senior: '强',
    Passwordshouldbe: '密码需满足',
    Atleast: '至少8个字符、1个字母和1个数字',
    Mininumoneuppercase: '最少一个大写字母',
    'Mininum one special character': '最少一个特殊字符',
    necessary: '必须项',
    Pleaseenteravalidmobilenumber: '请输入有效的手机号码',
    'Forgot password': "忘记密码",
    'Retrieve password': '找回密码'
  },
  people: {
    'Please input name': '请输入姓名',
    'Please input email': '请输入邮箱',
    InvitePeople: '邀请人员',
    NewMessage: '添加人员',
    EditMessage: '编辑人员',
    createAccount: '邀请人员创建账号',
    customerName: '姓名',
    Email: '邮箱',
    send: '发送',
    copyLink: '发送邀请链接，复制链接',
    updateSuccess: '更新成功',
    description: '无数据',
    SavaOrNext: "保存并下一步",
    'Choose to join/create': '选择加入/创建',
    'Join an existing company': '加入已有公司',
    'Create Company': '创建公司',
    'Your note information': '您的备注信息',
    'Please enter the correct official website address': '请输入正确的官网地址'
  },
  CompanyEdit: {
    Edit: '编辑',
    CompanyName: '公司名',
    CompanyAlias: '公司别名',
    Type: '公司类型',
    CompanyEmil: '公司邮箱',
    VATNO: '增值税号',
    Contact: '联系方式',
    Country: '国家',
    Address: '详细地址',
    Timezone: '时区',
    Website: '官网',
    UpdatingCompanyAccount: '更新公司信息',
    phone: '联系方式',
    Location: '国家',
  },
  deleteEmployee: {
    Tips: '移除人员',
    deleteEmployee: '确认移除该员工?',
    Cancel: '取消',
    Confirmz: '确认',
    'update picture': '更新图片',
  },
  memberList: {
    Member: '成员',
    Email: '邮箱',
    phone: '电话',
    Title: '职务',
    manage: '管理员',
    Actions: '操作',
    FindMember: '查找',
    Search: '查找',
    more: '更多',
    'Operation succeeded': '修改成功',
    'Please select a person': '请选择人员',
    'Permission transfer to': '权限转移给',
    'Manage transfer to': '管理员转移给',
    'Confirm transfer': '确定转移',
    'Manage Transfer': '管理员转移'
  },
  bind: {
    Bind: '绑定',
    unBind: '取消绑定',
    Unbound: '未绑定',
    BindPhone: '绑定你的手机',
    unBindPhone: '取消绑定你的手机',
    getCode: '获取验证码',
    confirmBinding: '确认',
    bindSuccess: '绑定成功',
    unBindSuccess: '解绑成功'
  },
  EditMine: {
    Name: '姓名',
    Title: '职务',
    Phone: '电话',
    WeChat: '微信',
    Email: '邮箱',
    Country: '国家',
    Timezone: '时区',
    MyCompany: '我的公司',
  },
  mineInfo: {
    Account: '账户名',
    Title: '职务',
    Email: '邮箱',
    PhoneWechat: '电话/微信',
    Location: '国家',
    TimeZone: '时区',
    Memo: '个性签名',
  },
  order: {
    to: '转',
    form: '由',
    systemEvent: '系统事件',
    eventNode: '事件节点',
    share: "分享",
    'Views switching': '视图切换',
    Member: "成员",
    'Click to add components': '点击添加组件',
    'Are you sure to delete this component': '确定删除该组件吗',
    'Set as Template': '设为模版',
    'Set Template Name': '设置模版名称',
    'Select Template': '选择模版',
    'Are you sure to switch to the new template': '确定切换为新模版吗',
    'The data will be replaced after use': '使用后数据将会替换',
    'Title Color': '标题颜色',
    batchDeleteOrderTip: ({values}) => {
      return `确定删除${values.n}个订单吗？`
    },
    'Custom Project Information': ({values}) => {
      return `自定义${values.n}信息`
    },
    'See order': '查看订单',
    'Enter login password to confirm': '输入登录密码确认',
    'Unrecoverable after deletion': '删除后不可恢复',
    'Are you sure to remove the personnel': '确定移除人员吗',
    'After removal, you will no longer be able to view orders': '移除后将不能再查看订单',
    'Are you sure to add personnel': '确定添加人员吗',
    'After adding, you can view order related information': '添加后可查看订单相关信息',
    'Multiple': '多选',
    'Cancel multiple': '取消多选',
    'Share orders': ({values}) => {
      return `分享${values.n}`
    },
    'Order node change': ({values}) => {
      return `${values.n}节点变更`
    },
    'Order node': ({values}) => {
      return `${values.n}节点`
    },
    'Share waybill': '分享运单',
    'Transportate detail': '运输详情',
    'Select link validity period': '选择链接有效期',
    'Invitation Map': '邀请地图',
    'Do you want to delete this note': '是否删除此备注',
    'Do you want to cancel this label': '是否取消此标签',
    'Related to me': '与我相关',
    'Event tag': '标签',
    'Tag Type': '标签类型',
    'Tag type name': '标签类型名称',
    'Only 5 label groups can be created': '标签组只能创建5个',
    'Tag cannot repeat': '标签不能重复',
    'My Tag Group': '我的标签组',
    'Add Tag Group': '新建标签组',
    'Label Group Name': '标签组名称',
    'Delete the forwarder': '删除该货代？',
    'You have a new event message': '您有一条新的事件消息',
    'Created an event': '创建了一条事件',
    'Created an comment': '创建了一条评论',
    'You have a new event tag message': '您有一条新的事件标签消息',
    'have event deleted': '有事件被删除',
    'The order has been modified': '订单被修改',
    'Waybill information has been updated': '运单信息已更新',
    'You have a new comment message': '您有一条新的事件评论消息',
    'You have a new todo message': '您有一条新的待办项需要处理',
    'todo updated': (week) => {
      return `待办 ${week.values.n} 已更新`;
    },
    'You have a new order': '您有一条新订单创建',
    uploadSkuFile: '上传SKU文件',
    totalPrice: "总金额",
    'to previous step': "返回上一步",
    AfterSplittingOrder: "CI号(拆单后)",
    orderDetail: (val) => {
      return `${val.values?.n}详情`
    },
    shipDetail: '运单详情',
    orderHistory: (val) => {
      return `${val.values?.n}进度`
    },
    pending: '进行中',
    numberOrder: '个订单',
    Created: '创建了', 
    Change: '变更',
    Changed: '变更了',
    'change to': '变更为',
    orderStatusForm: '订单状态由',
    Clocked: '打卡了',
    OrderInfo: (val) => {
      return `${val.values?.n}信息`
    },
    shipInfo: '运单信息',
    createTimeString: (week) => {
      return `由${week.values.n}创建于`;
    },
    members: (week) => {
      return `${week.values.n}位成员`;
    },
    cny: '总价',
    unit: '每箱单价',
    ctns: '箱数',
    ctn: '每箱千只数量',
    coil: '每卷总数量',
    type: '产品类型',
    'Type of point': '小类',
    Sizes: '产品规格',
    'Item No': '产品物料号',
    'Order No': (val) => {
      return `${val.values?.n}编号`
    },
    No: '编号',
    'Add the forwarder': '添加该货代及人员？',
    'File is too large to upload': ({values}) => {
      return `上传文件最大为${values.n}M`
    },
    'Select Forwarder': '选择货代',
    'Upload succeeded': '上传成功',
    'External sharing': '站外分享',
    'Internal sharing': '站内分享',
    'Successfully added': '货代添加成功',
    update_status: '状态修改成功',
    orderloding: '稍等...',
    Tracking: ({values}) => {
      return `跟踪${values.n}`
    },
    'not data': '暂无数据',
    'The current user has no operation permission for this order':
      '当前用户没有操作权限',
    'Confirm unfollow this order': '确认取消收藏此订单?',
    Unfollow: '取消收藏',
    'skuPreview': 'SKU预览',
    'sku File preview': 'sku文件预览',
    upload_icon:
      'https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/upload_2_icon.png',
    'Please enter the CI number and upload the file': '请输入CI号并上传文件',
    'Please CI': '请输入CI',
    'Please CI perhaps PI': '请输入CI或者PI',
    'Current PI': '当前PI',
    'Please enter the CI number after splitting the order and upload the sku file':
      '请输入拆单后CI号，并上传sku文件',
    'It s the last step': '已经是最后一步了',
    'Order progress has been changed to DESTINATION': '订单状态改为到目的地',
    'State torsion succeeded': '待办项状态已修改',
    FlightNo: '航班号',
    'Flight Date': '航班日期',
    'Please input content': '请输入内容',
    'Please input title': '请输入标题',
    'Shipping info updated': '运单信息更新',
    'Back to previous checkpoint': '退回上一个打卡点？',
    'Cross border transportation return verification': '跨国运输回退会导致航运等信息重置，是否返回上一步？',
    'createEvent succesfully': '	事件创建成功',
    'Go to next checkpoint': '	下一个打卡点？',
    Action: '操作',
    'Cancel update': '取消修改',
    'createBusinessShipping succesfully': '运单已创建',
    'Confirm add this ship info': '确认创建该运单',
    Add: '	创建',
    'completed to-dos': '个完成的待办事项',
    And: '还有',
    Cancels: '取消修改状态',
    'Add extra details': '添加详细信息...',
    'Select a date': '选择一个日期...',
    'Type names to notify': '提醒给...',
    'Type names to assign': '分配给...',
    'Describe this': '描述此待办项...',
    'Cancel input': '取消编辑',
    'New subject name is': '主题名修改为',
    'Your subject name is': '你的主题名是：',
    'Please create': '请输入创建内容',
    'Please input': '请输入',
    'New Subject': '新建主题',
    'updateBusinessShipping succesfully': '运单已修改',
    'Confirm update this shipment info': '更新运单？',
    Update: '更新',
    'Update shipping info': '修改运单信息',
    Rename: '重命名',
    renameSubject: '修改主题名',
    'Delete action': '删除',
    'Confirm delete this subject': '确认删除该主题?',
    FinshedTodo: '确认完成',
    TodoStatusUpdate: '修改待办项状态',
    Buyer: '客户',
    Supplier: '供应商',
    Status: '状态',
    Value: '金额',
    CreateTiem: '创建日期',
    OrderNo: (val) => {
      return `${val.values?.n}号`
    },
    Name: '姓名',
    Company: '公司',
    Title: '职务',
    manager: '经理',
    inviteNow: '找不到账号？邀请创建',
    Confirms: '确认',
    Previous: '上一步',
    Next: '下一步',
    'Files Only': '仅文件',
    Delete: '删除',
    Reply: '回复',
    'Write away': '写…',
    Confirm: '发表',
    Cancel: '取消',
    'Delete order': '删除订单',
    Export: '导出 PDF',
    export: '导出',
    'New Order': (val) => {
      return `新建${val.values.n}`
    },
    'Please select': '公司',
    'Please select country': '国家',
    CI: 'CI',
    'Input CI': 'CI号',
    'Input PI': 'PI号',
    Company: '公司',
    'Input Company Name ': '公司名',
    Search: '搜索',
    'Clear All Filter': '清除所有条件',
    'Order List': (val) => {
      return `${val.values.n}列表`
    },
    Hide: '隐藏',
    'Input CI': 'CI号',
    'Select status': '订单状态',
    'Start from—End to': '开始—结束',
    Producing: '生产中',
    'Local transportation': '本国运输',
    'Loading port': '发运港停留',
    Shipping: '跨境运输',
    'Discharge port': '目的港停留',
    'Destination delivery': '目的国运输',
    Destination: '目的地',
    Amount: '金额',
    'Create Time': '创建日期',
    Status: '状态',
    Action: '操作',
    'Order No.+': '订单 No.+',
    Supplier: '供应商',
    Purchaser: '买家',
    Forwarder: '货代',
    'Add Forwarder': '添加货代',
    'Ship info': '运输信息',
    'Air info': '空运信息',
    'Weight/Dimension': '重量/体积',
    'Port of Loading': '装货港',
    Quantity: '数量',
    'Port of discharge': '卸货港',
    'Update succeeded': '订单已更新',
    'Are you sure to delete this order': ({values}) => {
      return `确认删除该${values.n}？`
    },
    Customer: '客户',
    'Historical orders': ({values}) => {
      return `历史${values.n}`
    },
    'CI No': 'CI号',
    'PI No': 'PI号',
    Amount: '金额',
    'delete this order': '是的，确认删除',
    PleaseDoubleCheck: '请再次确认CI号',
    TypeCINumber: '输入CI号',
    TypePINumber: ({values}) => {
      return `输入${values.n}`
    },
    Delete: '删除',
    PleaseCheckTheCINumber: '请重新输入CI号',
    PleaseCheckThePINumber: '请重新输入PI号',
    'No message': '暂无数据',
    'Checkpoint updated': '物流状态更新',
    'Shipping info updated': '运单信息更新',
    'Shipping info created': '运单信息创建',
    orderEvent: '订单事件'
  },
  scheduleNew: {
    tm: '月',
    wk: '周',
    mon: '周一',
    tue: '周二',
    wed: '周三',
    thu: '周四',
    fri: '周五',
    sat: '周六',
    sun: '周天',
    Sunday: '周天',
    Monday: '周一',
    Tuesday: '周二',
    Wednesday: '周三',
    Thursday: '周四',
    Friday: '周五',
    Saturday: '周六',
    todoDetail: "待办详情",
  },
  createShipping: {
    'select date': '选择时间',
    'Ocean Shipping': '海运',
    'Air Transport': '空运',
    No: '提单号',
    Edit: '编辑',
    weight: '重量',
    'Place of Loading': '装货港',
    Quantity: '数量',
    'Place of Discharge': '卸货港',
    Dimension: '体积',
    'Final Destination': '目的地',
    'Freight Type': '运输方式',
    Sea: '海运',
    Air: '空运',
    Vessel: '船名',
    Voyage: '航次',
    'Container No': '集装箱号',
    'Add to shippment': '添加运单信息',
    ConfirmExit: '是否确认关闭？',
  },
  Forwarder: {
    'Add Forwarder': '添加货代',
    Name: '姓名',
    Manager: '经理',
    Title: '职务',
    Confirm: '确认',
    Cancel: '取消',
  },
  todo: {
    'addto-do': '新增待办',
    'to-do': '待办',
    Subject: '主题',
    Title: '标题',
    'Assign to': '分配给',
    'When done, notify': '提醒',
    'Due on': '截至到',
    Notes: '备注',
    'Add this to-do': '添加此待办事项',
    'Please input the subject name': '请输入主题名',
    'complete information': '请填写完整信息',
    'Please select the freight': '请选择需要添加的货代公司以及人员',
    'Cancel delete': '取消删除',
    'Delete Successful': '删除成功',
    'Modify Cancel': '取消修改状态',
    'Success': '已完成',
    'On going': '进行中',
    'Create': '创建',
    'You have completed': '你已完成',
    'You have not completed': '你未完成'
  },
  event: {
    'number new events': ({values}) => {
      return `${values.n}条新事件`
    },
    'Create event': '创建事件',
    'Upload succeeded': '上传成功',
    'Only one file can be uploaded': '只能上传一个文件',
    'Cancel input': '取消输入',
    'Delete successfully': '删除成功',
    'Delete cancel': '删除失败',
    'This event will be deleted': '删除该事件？',
    Notification: '删除',
    'select people to show': '展示给',
    'Please select the file type': '请选择文件类型',
    'Confirm upload': '确认上传',
    'Drag & Drop files here OR Browse Files': '点击或拖拽文件上传',
    Activities: '活动',
    Title: '标题',
    'Write away': '写...',
    'Event detail': '事件详情',
    Content: '内容',
    'Upload your file': '上传你的文件',
    'Who can see ': '谁可以看',
    'Select File Type': '选择文件类型',
    'Who can see': '谁可以看',
    'Everyone': '所有人可见',
    'Everyone in order can see': '所有人可见',
    'Only me': '仅自己可见',
    'Some people': '部分人',
    'Some people can see': '部分人可见',
    'Only selected people can see': '选择的人',
    'Can see': '可见',
    'Send out': '发送',
    'Event Title/Content': '事件标题/内容'
  },
  'Under review': '审核中',
  'Please enter a group name': '请输入群组名称',
  'Please Select a group member': '请选择群组成员',
  'Group name cannot exceed 20 characters': '群组名称不能超过20个字符',
  'Please select a customer company': '请选择客户公司',
  'New Group': '新增群组',
  'Group name': '群组名称',
  'Group members': '群组人员',
  totals: '共',
  peoples: '人',
  Tips: '提示',
  'are you sure to delete this group?': '确定删除该群组吗？',
  Confirm: '确定',
  Cancel: '取消',
  Select: '选择',
  image: "图片",
  Text: "文本",
  'Navigate to Chat': '定位到聊天',
  'Import in progress': '导入进行中',
  'You have new application information': '您有新的申请信息',
  'Your application has been approved': '您的申请已通过',
  'Your application has been rejected': '您的申请已被拒绝',
  'You have a new customer invitation notification': '您有新的客户邀请情况通知',
  Refresh: '刷新',
  ExportFile: '导出为文件',
  ExportWord: '导出为Word',
  expand: '展开',
  retract: '收起',
  success: '成功',
  color: '颜色',
  'Use current label group': '使用当前标签组',
  addContrast: '加入对比',
  custom: '自定义',
  'avatar': '头像',
  'save': '保存',
  'Email setting': '邮件设置',
  'Email Customization': '邮件定制',
  emailConfig: {
    'Event flow email reminder': '事件流邮件提醒',
    'System notification email settings': '系统通知邮件设置',
    'Opened': '已开启',
    'Open Notes': '开启后，当有更新时会有邮箱提醒',
    'allEmailRemind': '全部事件',
    'createOrder': '创建订单',
    'updateOrder': '修改订单',
    'createWaybill': '创建运单',
    'updateWaybill': '修改运单',
    'logisticsPunchCard': '物流打卡',
    'invitationInformation': '邀请信息',
    'toDoInformation': '待办信息',
    'announcementNotice': '公告通知',
    'historicalOrderImportNotification': '历史订单导入通知',
    'purchaseOrder': '采购单新建/更新',
  },
  'View permission settings': '查看权限设置',
  "or": '或者',
  "complete": "完成",
  "Topping": "置顶",
  "Take over administrator information": "接管管理员信息",
  "Modify company information": "修改公司信息",
  'The current label is duplicated and cannot be added': '当前添加标签重复，无法添加',
  'You still have unsaved tags': '你还有未保存的标签',
  'After closing, the label will not be saved': '关闭后该标签不会被保存',
  'Are you sure to delete the label': '确定删除标签吗',
  'Public label area': '公共标签区',
  'Operation records': '操作记录',
  'Tags of the same type that have been used after deletion will also be deleted': '删除后被用过的同类型标签也会被删除',
  'Order label is full and cannot be used': '订单标签已满，无法使用',
  'The order label has a duplicate tag with the current label group and cannot be used': '订单标签与当前标签组有重复tag，无法使用',
  'Please enter the note content': '请输入备注内容',
  eventExport: {
    eventType: "事件类型",
    eventName: "事件名称",
    eventContent: "事件内容",
    eventCreatePerson: "事件创建人",
    eventCreateTime: "事件创建时间",
    eventTags: "事件标签",
    eventFile: "事件文件",
    'Ordinary event': '普通事件',
    'System event': '系统事件'
  },
  'Online form': '在线表格',
  'validate': '验证',
  'edit validate': '修改验证',
  'Validate success': '验证成功',
  'Validate failed': '验证失败',
  'Please verify the email first': '请先验证邮箱',
  'Enter email verification code': '输入邮箱验证码',
  'Confirm takeover': '确认接管',
  'Successfully taken over, please log in again': '接管成功，请重新登录',
  'Send verification code': '发送验证码',
  'Generate': '生成',
  'Please': '请',
  "Not used": '未使用',
  'There are no pictures left': '没有图片啦',
  'Do you want to modify the current member role': '是否修改当前成员角色',
  'Theme switch': '主题切换',
  'Send 1 new message': '发来1条消息',
  'Create': '新增',
  'do': '为',
  'Read completed': '已读完成',
  'Affiliated company': '所属公司',
  'Current position': '当前职务',
  'Time zone': '所在时区',
  'nickname': '姓名',
  'One click read': '一键已读',
  'I have read and agree with': '我已阅读并同意',
  'Service agreement': '服务协议',
  'Privacy policy': '隐私政策',
  'And log in': '并登录',
  'Email assistance': '智能辅助',
  'Email template': '邮件模板',
  'Getting started': '新手入门',
  'Email banner': '邮件banner',
  'Are you sure to cancel the collection': '确定取消收藏吗',
  'and': '和',
  'filter': '过滤器',
  'Download image': '下载图片',
  'Send mail': '发送邮件',
  'Preview': '预览',
  'Select background': '选择背景',
  'Edit title': '编辑标题',
  'day': '天',
  'Long term': '长期',
  'Generate Link': '生成链接',
  'Shared by': '分享人',
  'Valid to': '有效期至',
  'Lifespan': '有效期',
  'Please enter the invitation code': '请输入邀请码',
  GroupChat: {
    'Add group chat': '创建群聊',
    'index': '群聊',
    'Initiate group chat': '发起群聊',
    'Insufficient number of people to group, add at least 2 people': '人数不足，无法成群，最少添加2人',
    'Enter group chat': '进入群聊',
    'hello': '你好',
    'Group chat settings': '群聊设置',
    'Group chat name': '群聊名称',
    'Specify Chat': '置顶聊天',
    'Do you want to delete this member': '是否删除该成员',
    'Exit group chat': '退出群聊',
    'Dissolve group chat': '解散群聊',
    'Are you sure to exit the group chat': '确定退出群聊吗',
    'Are you sure to dissolve the group chat': '确定解散群聊吗',
    'After exiting, the group chat content will be cleared': '退出后将清空群聊内容',
    'After dissolve, the group chat content will be cleared': '解散后将清空群聊内容',
    totalNumber: (week) => {
      return `共${week.values.n}人`;
    },
  },
  'You withdrew a message': '你撤回了一条消息',
  'withdrew a message': '撤回了一条消息',
  'withdraw': '撤回',
  'Please select the data to operate on': '请选择要操作的数据!',
  'Continue browsing this page': '继续浏览该页面',
  'Log in now': '立即登录',
  'Welcome to Echola': '欢迎来到Echola！',
  'Come on in Order Info': '快来登录查看和编辑更多订单信息吧！',
  'Move to': '移动至',
  'number people cannot be added': ({values}) => {
    return `有 ${values.n.number} 人不能${values.n.type ? '添加' : '移除'}`
  },
  'Chat records': '聊天记录',
  'One click translation': '一键翻译',
  'Are you sure to delete the label group': '确定删除标签组吗',
  'After deletion, the order related labels will also be deleted': '删除后订单相关标签也会被删除',
  'Creating a large file event requires waiting for the upload to complete before creating the event': '创建文件事件，需要等待上传完成才能创建事件',
  'EcholaAi': {
    'New dialogue': '新对话',
    'Recent conversations': '近期对话',
    'Enter your question here': '在这里输入你的问题',
    'Open more': '展开更多',
    'Do you want to delete the conversation?': '要删除对话吗？',
    'This conversation will no longer appear here This operation will also delete relevant activity records from your Bard activity records, such as questions, answers, and feedback': '这个对话将不再显示在此处。此操作还会从你的活动记录中删除相关活动记录，例如问题、回答和反馈',
    'Questioner': '提问者',
    'One message': '嗨！我是 Echola AI，我可以为你提供创意启发和实用信息，助你一臂之力。我能力有限，有时可能会出错，欢迎你提供反馈帮我变得更好！'
  },
  'File upload in progress': '文件上传中',
  'Copy': '复制',
  'View the remaining comments': ({values}) => {
    return `查看剩余${values.n}条评论`
  },
  'File format not supported': '文件格式不支持',
  'Project name': ({values}) => {
    return `${values.n}名称`
  },
  'Address Selection': '地址选择',
  'Change address': '地址变更',
  'Original address': '原地址',
  'New address': '新地址',
  'Logistics map': '物流地图',
  'Supplier Map': '供应商地图',
  'Customer Map': '客户地图',
  Purchase: {
    title: "采购单",
    'Purchase manage': '采购单管理',
    'Create Purchase': '创建采购单',
    'Edit Purchase': '编辑采购单',
    'Preview Purchase': '预览采购单',
    'Purchase Number': '采购单号',
    'Details (optional)': '明细（选填）',
    'Details': "明细",
    ProductIndex: ({values}) => {
      return `产品 ${values.n}`
    },
    ProductCode: "产品代码",
    ProductName: "产品名称",
    'Continue adding products': '继续添加产品',
    'Preview and send': '预览并发送',
    'Delete product': ({values}) => {
      return `删除${values.n}`
    },
    'Delivery (optional)': '交货（选填）',
    'Delivery address': '交货地址',
    'Delivery date': '交货日期',
    'Mode of transport': '运输方式',
    'Payment (optional)': '付款（选填）',
    'Delivery terms': '交易条款',
    'Payment method': '付款方式',
    'Payment date': '付款日期',
    'Create purchase': '创建采购单',
    'Updated On': '更新于',
    'Generated on': '生成于',
    'Status describe0': '未发送给供应商的采购单',
    'Status describe1': '已发送给供应商的采购单',
    'Status describe2': '与供应商商讨中的采购单',
    'Status describe3': '已生成订单的采购单',
    'Status vendor describe1': '没有任何操作的采购单',
    'Status vendor describe2': '与买家商讨中的采购单',
    'Status vendor describe3': '已生成订单的采购单',
    'Are you sure to withdraw the purchase order?': '确定撤回采购单吗？',
    'The purchase order will be saved in the purchase order list': '采购单将被保存在采购单列表中',
    'Purchase order deleted': '采购单已删除',
    'Update and send': '更新并发送'
  },
  'Generate Order': '生成订单',
  'Please input': ({values}) => {
    return `请输入${values.n}`;
  },
  'Are you sure to delete it?': '确定删除吗？',
  'After deletion, it cannot be restored': '删除后要不可恢复',
  unitPrice: "单价",
  'Upload attachments': '上传附件',
  'Save Only': '仅保存',
  'Do not save': '不保存',
  'Successfully saved': '保存成功',
  'Click to preview': '点击预览',
  'Please select an address on the map': '请在地图上选择地址',
  'see': '查看',
  'Have you edited it': '是否保存已编辑的内容？',
  'If the edited content is not saved, it will be cleared': '若不保存已编辑内容将清除',
  'Please manually enable recording permission': '请手动开启录音权限',
  'voice': '语音',
  'Hold to talk': '点击录音',
  'Release the audition': '点击试听',
  'Click to redo': '点击重录',
  'Making voice calls': '拨打语音电话',
  'Waiting to answer': '等待接听中',
  'I made a voice call to you': '向你发起了语音通话',
  'Positioning failed, please manually select': '定位失败，请手动选择',
  'Successfully sent 1 message to who': ({values}) => {
    return `成功发送给${values.n}1条消息`
  },
  'Usage time': '使用时间',
  'Expired': '已过期',
  'Company Profile': '公司简介',
  'Confirm modification': '确认修改',
  'Verification information sent': '验证信息已发送',
  'in total': ({values}) => {
    return `共 ${values.n} 条`
  },
  Placeholder: "框内提示词",
  'Add component': '添加组件',
  'Configuration': '配置',
  'Select Components': '选择组件',
  'Set Content': '设置内容',
  'Use': '使用',
  customeTips: {
    'Text input box, suitable for configuring themes, etc': '文字输入框，适用于配置主题等',
    'Dropdown radio box for configuration options': '下拉单选框，适用于配置选项',
    'Dropdown multiple selection box, suitable for configuring multiple selection options': '下拉多选框，适用于配置多选项️',
    'Time selector, can select a time point or time period': '时间选择器，可选择时间点或时间段',
    'Date selector, can select 1 or more days': '日期选择器，可选择1天或多天',
    'Counter, used for unit price, quantity of goods, etc': '计数器，用于单价商品数量等',
    'Attachment upload, can upload various types of files': '附件上传，可上传各种类型文件',
    'Image upload, display images can be uploaded': '图片上传，可上传展示图片',
    'Text field, can be used as notes, etc': '文本域，可用作备注等',
    'Switch, can be used for permission configuration, etc': '开关，可用于权限配置等'
  },
  customName: {
    Input: "输入框",
    RadioSelect: "单选框",
    CheckSelect: "多选框",
    TimeSelect: "时间选择器",
    DateSelect: "日期选择器",
    InputNumber: "计数器",
    FileUpload: "附件上传",
    ImageUpload: "图片",
    TextArea: "文本域",
    Switch: "开关",
    Score: "评分"
  },
  'Duplicate component form title name': '组件表单标题名称有重复',
  'The current component limit is 3 uploads': ({values}) => {
    return `当前组件限制使用${values.n}个`
  },
  'Open Content': '开启内容',
  'Close Content': '关闭内容',
  'Close': '关闭',
  'Open': '开启',
  Option: "选项",
  'Time type': '时间类型',
  'Point of time': '时间点',
  'Time slot': '时间段',
  'Time interval': '时间间隔',
  'Every 30 minutes': '每30分钟',
  'Every 1 hour': '每1个小时',
  'Continue adding options': "继续添加选项",
  'Add Picture': '添加图片',
  'Propaganda slogan': '宣传标语',
  'QR code 1 name': '二维码1名称',
  'Upload QR code': '上传二维码',
  'QR code 2 name': '二维码2名称',
  'Email Content': '邮件内容',
  'Only applicable to waybill/order sharing emails': '仅适用于运单/订单分享邮件',
  'Commodity management': '商品管理',
  'Service Management': '服务管理',
  noticeServer: {
    Server: "服务",
    Shop: "商品",
    Case: "案例",
    File: "文件",
    'From new to old': '由新到旧',
    'From old to new': '由旧到新',
    'Carrier': '承运人',
    'Place of departure': '出发地',
    'Destination': '目的地',
    'Transfer': '中转',
    'Direct': '直达',
    'Consult': '咨询'
  },
  'Please select event': ({values}) => {
    return `请选择${values.n}`
  },
  'Please add components first': '请先添加组件',
  'Duplicate template name': '模版名称重复',
  'Do you want to overwrite the previous template': '是否覆盖掉之前的模版',
  'After covering, the original template will be replaced': '覆盖后原模版会被替换掉',
  'Are you sure to delete this product/service': '确定删除该项吗',
  'Cannot reply after deletion': '删除后不可恢复',
  'No image': '暂无图片',
  'Case name': '案例名称',
  'Shop name': '商品名称',
  'Description': '描述',
  'Limit input to 50 words': '限制输入50字',
  'Server detail': '服务详情',
  'Case detail': '案例详情',
  'File detail': '文件详情',
  'Attachment': '附件',
  'Data has been deleted': '数据已被删除',
  'Do not upload empty files': '请勿上传空文件',
  'Filter custom information': '筛选自定义信息',
  'Price (for reference only)': '价格（仅作参考）',
  'Custom administrator name': '自定义管理员名称',
  'Custom administrator email': '自定义管理员邮箱',
  tracking: require('./tracking/zh'),
  customerInfo: require('./customerInfo/zh'),
  messageBoard: require('./messageBoard/zh'),
};
