import request from '@/utils/request';

//调用创建订单接口-以前创建ci订单
export function createBusinessOrder(data) {
  return request({
    url: '/businessOrder/create',
    method: 'post',
    data: data,
  });
}
//创建pi订单
export function createCreatePIOrder(data) {
  return request({
    url: '/order/add',
    method: 'post',
    data: data,
  });
}
//更新订单接口  pi-ci
export function updateBusinessOrder(data) {
  return request({
    url: '/order/update',
    method: 'post',
    data,
  });
}
//根据上传file,获取SKU统计金额
export function getMessage(data) {
  return request({
    url: `/order/sku/getSkuAmountSum`,
    method: 'post',
    data,
  });
}
//根据PI订单创建CI订单
export function ciOrderList(data) {
  return request({
    url: `/businessOrder/ciOrderList?orderPI=${data?.pi}`,
    method: 'get',
    data: data,
  });
}
//CI订单回退到PI订单
export function fallbackPIOrder(orderId) {
  return request({
    url: `/order/backByOrderId`,
    method: 'post',
    params: {
      orderId
    },
  });
}
//订单金额确认,分拆订单
export function createCIOrder(data) {
  return request({
    url: `/order/createCiOrder`,
    method: 'post',
    data,
  });
}
//获取sku表中数据
export function getSKUMessage(data) {
  return request({
    url: `/businessOrder/getSKUMessage`,
    method: 'post',
    data: data,
    timeout: 1000 * 60 * 10,
  });
}
//历史订单导入
export function importHistoryOrder(data) {
  return request({
    url: `/order/importHistory`,
    method: 'post',
    data: data,
    timeout: 600000000000000,
  });
}
//历史订单导入
export function businessFileDownload(data) {
  const { code } = data
  return request({
    url: `/businessFile/download?code=${code}`,
    method: 'get',
  });
}
//更新订单接口//新的
export function updateForWordCompany(data) {
  return request({
    url: '/order/addForwarder',
    method: 'post',
    data,
  });
}

//获取订单接口
export function getOrderList(data) {
  let item = {};
  if (data) {
    for (let i in data) {
      if (data[i] !== '' && data[i] !== null) {
        item[i] = data[i];
      }
    }
  }
  return request({
    url: '/order/page',
    method: 'get',
    params: item,
  });
}

//获取订单详情
export function getOrderDetails(unid) {
  return request({
    url: '/order/detail',
    method: 'get',
    params: { unid },
  });
}

//删除订单
export function deleteOrder(unid) {
  return request({
    url: '/order/delete',
    method: 'post',
    params: {
      unid
    },
  });
}

//获取可邀请成员列表
export function getInviteUserlist(unid) {
  return request({
    url: '/order/member/list',
    method: 'get',
    params: { unid },
  });
}

//邀请用户加入订单小组
export function inviteUser(data) {
  return request({
    url: '/order/member/add',
    method: 'post',
    data,
  });
}

//从订单小组中移除用户
export function removeUser(params) {
  return request({
    url: `/order/member/delete`,
    method: 'post',
    params,
  });
}

//获取订单历史状态
export function getOrderHistory(orderId) {
  return request({
    url: '/businessOrder/history',
    method: 'get',
    params: { orderId },
  });
}

//收藏订单
export function follow(params) {
  return request({
    url: '/manage/users/personalization/collectOrder',
    method: 'post',
    params,
  });
}

//取消收藏订单
export function unfollow(params) {
  return request({
    url: '/manage/users/personalization/cancelCollectOrder',
    method: 'post',
    params,
  });
}

//获取用户收藏订单列表
export function getFollowOrderList(data) {
  return request({
    url: '/businessOrder/followOrderList',
    method: 'post',
    data: data,
  });
}

//获取用户公司角色
export function getOrderCompany(orderId) {
  return request({
    url: '/businessCompany/officeCompany',
    method: 'get',
    params: { orderId },
  });
}

//获取用户公司角色
export function getRelation(data) {
  return request({
    url: '/businessCompany/getRelation',
    method: 'post',
    data: data,
  });
}

//获取用户订单货币列表
export function orderCurrencyList(data) {
  return request({
    url: '/businessOrder/orderCurrencyList',
    method: 'post',
    data,
  });
}

//order翻译对话信息
export function translation(data) {
  return request({
    url: '/businessOrder/translation',
    method: 'post',
    data,
  });
}
 
// 订单状态下一步
export function orderStatusNext(orderId) {
  return request({
    url: '/order/next',
    method: 'post',
    params: {
      orderId
    }
  })
}

// 订单状态上一步
export function orderStatusPrev(orderId) {
  return request({
    url: '/order/back',
    method: 'post',
    params: {
      orderId
    }
  })
}

// 获取可邀请公司列表
export function getInvitableOrderCompanyList(unid) {
  return request({
    url: '/order/orderCompany',
    method: 'get',
    params: { unid },
  });
}

// 获取可邀请订单成员列表
export function getInvitableOrderMemberList(params) {
  return request({
    url: '/order/listInviteOrderUser',
    method: 'get',
    params,
  });
}

// 删除订单货代
export function deleteForwarderApi(data) {
  return request({
    url: '/order/deleteForwarder',
    method: 'post',
    data,
  });
}

// 订单导入进度条
export function getImportHistoryProgress(importId) {
  return request({
    url: '/order/importHistoryProgress',
    method: 'get',
    params: {
      importId
    },
  });
}

// 订单添加tag
export function addTags(data) {
  return request({
    url: '/order/addOrderCompanyTag',
    method: 'post',
    data,
  });
}

// 订单邀请地图
export function getInviteMap(unid) {
  return request({
    url: '/order/member/inviteMap',
    method: 'get',
    params: {
      unid
    },
  });
}

// 分享订单发送邮件
export function sendShareOrderEmail(data) {
  return request({
    url: '/share/sendShareOrder',
    method: 'post',
    data,
  });
}

// 根据token查询分享详情
export function getShareInfo(token) {
  return request({
    url: "/share/token",
    method: "get",
    params: {
      token,
    }
  });
}

// 根据token查询订单详情
export function getShareOrderInfo(token) {
  return request({
    url: "/share/order",
    method: "get",
    params: {
      token,
    }
  });
}

// 根据token查询运单详情
export function getShareWaybillInfo(token) {
  return request({
    url: "/share/waybill",
    method: "get",
    params: {
      token,
    }
  });
}

// 根据token查询事件
export function getShareEventList(data) {
  return request({
    url: "/share/eventPage",
    method: "post",
    data
  });
}

// 根据token查询船舶信息
export function getShareShipInfo(token) {
  return request({
    url: "/share/getShipTrack",
    method: "get",
    params: {
      token
    }
  });
}

// 生成分享链接
export function createShareLink(data) {
  return request({
    url: "/share/add",
    method: "post",
    data
  });
}

// 批量删除订单
export function deleteBatchOrder(orderIds) {
  return request({
    url: "/order/deleteBatchOrder",
    method: "post",
    data: {
      orderIds
    }
  });
}

// 批量新增订单成员
export function addBatchOrderMember(data) {
  return request({
    url: "/order/addOrderMemberBatch",
    method: "post",
    data
  });
}

// 批量删除订单成员
export function deleteBatchOrderMember(data) {
  return request({
    url: "/order/deleteOrderMemberBatch",
    method: "post",
    data
  });
}

// 批量操作订单成员
export function batchActionOrderMember(data) {
  return request({
    url: "/order/orderMemberBatch",
    method: "post",
    data
  });
}

//查询全部节点
export function getAllNodeList() {
  return request({
    url: "/node/list",
    method: "get"
  });
}

// 查询自己公司和其他公司是否都是默认节点
export function detectionIsDefaultNode() {
  return request({
    url: "/node/isDefaultNode",
    method: "get"
  });
}

// 创建采购单
export function createPurchaseApi(data) {
  return request({
    url: "/businessPurchase/add",
    method: "post",
    data
  });
}

// 编辑采购单
export function editPurchaseApi(data) {
  return request({
    url: "/businessPurchase/update",
    method: "post",
    data
  });
}

// 采购单详情
export function getPurchaseDetailApi(id) {
  return request({
    url: "/businessPurchase/detail",
    method: "get",
    params: {
      id
    }
  });
}

// 采购单列表
export function getPurchaseListApi(params) {
  return request({
    url: "/businessPurchase/page",
    method: "get",
    params
  });
}

// 采购单状态列表
export function getPurchaseStatusListApi() {
  return request({
    url: "/businessPurchase/status",
    method: "get"
  });
}

// 采购单撤回
export function purchaseWithdrawApi(id) {
  return request({
    url: "/businessPurchase/withdraw",
    method: "get",
    params: {
      id
    }
  });
}

// 采购单删除
export function deletePurchaseApi(id) {
  return request({
    url: "/businessPurchase/delete",
    method: "get",
    params: {
      id
    }
  });
}

// 查看采购单操作记录
export function getPurchaseRecords(params) {
  return request({
    url: "/businessPurchaseLog/page",
    method: "get",
    params
  });
}

// 采购单导出word
export function exportPurchaseWord(id) {
  return request({
    url: "/businessPurchase/exportWord",
    method: "get",
    params: {
      id
    },
    responseType: 'blob',
  });
}

// 采购单导出PDF
export function exportPurchasePdf(id) {
  return request({
    url: "/businessPurchase/exportPdf",
    method: "get",
    params: {
      id
    },
    responseType: 'blob',
  });
}

// 查询自定义表单信息
export function getCustomFormInfomation(orderId) {
  return request({
    url: "/custom/detail",
    method: "get",
    params: {
      orderId
    }
  });
}

// 查询自定义模板列表
export function getCustomTemplateFormPage(params) {
  return request({
    url: "/custom/template/page",
    method: "get",
    params
  });
}

// 保存自定义信息
export function updateCustomFormInfo(data) {
  return request({
    url: "/custom/update",
    method: "post",
    data
  });
}

// 自定义信息设置为模版
export function setCustomFormToTemplate(data) {
  return request({
    url: "/custom/template/update",
    method: "post",
    data
  });
}

// 根据模版ID查询模版组件
export function getCustomTemplateDetail(templateId) {
  return request({
    url: "/custom/template/detail",
    method: "get",
    params: {
      templateId
    }
  });
}

// 自定义表单操作记录
export function getCustomFormInfoOperation(params) {
  return request({
    url: "/custom/log/page",
    method: "get",
    params
  });
}

// 查询分享订单和运单的banner配置
export function getShareConfigData(unid) {
  return request({
    url: "/share/background/detail",
    method: "get",
    params: {
      unid
    }
  });
}

// 判断模板名称是否重复了
export function isTemplateNameRepeat(templateName) {
  return request({
    url: "/custom/template/count",
    method: "get",
    params: {
      templateName
    }
  }); 
}

// 获取自定义标题列表
export function getCustomTitlePage(params) {
  return request({
    url: "/custom/page",
    method: "get",
    params
  });
}