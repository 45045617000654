<template>
  <el-dialog
    :title="$t('order.sku File preview')"
    :visible="orderId ? true : false"
    @close="close()"
    width="80%"
    :destroy-on-close="true"
    custom-class="sku-view"
    v-move-outside
  >
    <div class="searchBox">
      <el-input v-model="itemNo" size="small" :placeholder="$t('order.Item No')" />
      <el-input v-model="size" size="small" :placeholder="$t('order.Sizes')" />
      <el-input v-model="type" size="small" :placeholder="$t('order.type')" />
    </div>
    <el-table :data="dataList" height="calc(100vh - 400px)" v-loading="loading">
      <el-table-column prop="index" :label="$t('order.No')" width="60px">
        <template #default="{ $index }">
          <span>{{ $index + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="ciSkuId" :label="$tc('order.Order No', this.$isOrderTitle())" width="130px">
      </el-table-column>
      <el-table-column prop="itemNo" :label="$t('order.Item No')" width="100px">
      </el-table-column>
      <el-table-column prop="size" :label="$t('order.Sizes')" width="80px">
      </el-table-column>
      <el-table-column prop="type" :label="$t('order.type')" width="120px"></el-table-column>
      <el-table-column
        prop="typeOfPoint"
        :label="$t('order.Type of point')"
        width="100px"
      ></el-table-column>
      <el-table-column prop="noOfCoil" :label="$t('order.coil')" width="120px">
      </el-table-column>
      <el-table-column prop="noOfCtn" :label="$t('order.ctn')" width="120px"></el-table-column>
      <!-- <el-table-column prop="qty" label="Qty"> </el-table-column> -->
      <el-table-column prop="qty" :label="$t('order.ctns')"> </el-table-column>
      <el-table-column prop="unitPrice" :label="$t('order.unit')">
      </el-table-column>
      <el-table-column prop="amount" :label="$t('order.cny')">
      </el-table-column>
      <!-- <el-table-column prop="currency" label="Currency"> </el-table-column> -->
      <template #empty>
        <el-empty 
          :description="$t('overallSituation.noData')" 
          :image="$emptyImage"
          :image-size="90"
        />
      </template>
    </el-table>
  </el-dialog>
</template>
<script>
import { ref, watch, computed } from '@vue/composition-api';
import { previewSkuFile } from '@/api/files';
export default {
  name: 'skuView',
  props: {
    orderId: {
      type: [String, Number],
      default: '',
    },
    skuShow: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const skuArray = ref([]);
    const loading = ref(true);
    let itemNo = ref("");
    let size = ref("");
    let type = ref("");

    async function getSkuData() {
      try {
        loading.value = true;
        previewSkuFile({
          orderId: props.orderId,
          currentPage: 1,
          pageSize: 10000000
        }).then((data) => {
          skuArray.value = data.list;
          loading.value = false;
        })
      } catch (error) {}
    }

    function objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 1) {
        if (rowIndex % 2 === 0) {
          return {
            rowspan: 2,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    }

    watch(
      () => props.orderId,
      (newVal) => {
        if (newVal) {
          getSkuData();
        }
      },
      { immediate: true }
    );
    let dataList = computed(() => {
      let itemNos = itemNo.value.trim().toLowerCase()
      let sizes = size.value.trim().toLowerCase()
      let types = type.value.trim().toLowerCase()
      return skuArray.value.filter(row => {
        return row.itemNo.toString().toLowerCase().indexOf(itemNos) > -1 && row.size.toString().toLowerCase().indexOf(sizes) > -1 && row.type.toString().toLowerCase().indexOf(types) > -1
      })
    })

    return {
      loading,
      skuArray,
      dataList,
      itemNo,
      size,
      type,
      objectSpanMethod,
      close() {
        emit('close');
      },
    };
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-dialog__body {
  padding: 0 1px 0 1px;
}
.searchBox {
  display: flex;
  gap: 10px;
  padding: 0 10px 10px 10px;
  /deep/ .el-input {
    width: 150px;
  }
}
</style>
