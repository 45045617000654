module.exports = {
    'undefined': 'undefined',
  VENDOR: 'Supplier',
  FORWARDER: 'Forwarder',
  PURCHASER: 'Customer',
  'New Customer': 'New Customer',
  'Input Company': 'Input Company',
  'Input People': 'Input People',
  Search: 'Search',
  'Clear All Filter': 'Clear All Filter',
  'New Order': 'New Order',
  Export: 'Export',
  No: 'No.',
  Logo: 'Logo',
  Company: 'Company',
  Contact: 'Contact',
  Email: 'Email',
  Country: 'Country',
  Admin: 'Admin',
  Member: 'Member',
  'Order Total': 'Order Total',
  Action: 'Action',
  'Unhide Successful': 'Unhide Successful',
  'Hide Successful': 'Hide Successful',
  Name: 'Name',
  VAT: 'VAT',
  Address: 'Address',
  more: 'more',
  'Find Member': 'Find Member',
  Phone: 'Phone',
  Title: 'Title',
  'Please write ...': 'Please write ...',
  Send: 'Send',
  'Company Name ': 'Company Name ',
  'Account type': 'Account type',
  'VAT No.': 'VAT No.',
  Location: 'Location',
  'Company address': 'Company address',
  'Time zone': 'Time zone',
  Website: 'Website',
  Schedule: 'Schedule',
  Message: 'Message',
  'Message Board': 'Message Board',
  MessageBoards: 'Message Board',
  Connection: 'Connection',
  'Input CI': 'Input CI',
  'Input PI': 'Input PI',
  'Start from': 'Start from',
  'End to': 'End to',
  CI: 'CI',
  'Create Time': 'Create Time',
  Currency: 'Currency',
  Amount: 'Amount',
  Status: 'Status',
  details: 'details',
  'Change Password': 'Change Password',
  'Original password': 'Original password',
  'New password': 'New password',
  'Confirm password': 'Confirm password',
  'Please input password': 'Please input password',
  'Password must be at least 8 characters':
    'Password must be at least 8 characters',
  'Password must be at least 8 characters and contain at least one number and one letter':
    'Password must be at least 8 characters and contain at least one number and one letter',
  'Two passwords are inconsistent!': 'Two passwords are inconsistent!',
  cm: 'Confirm Modify',
  'Update successful': 'Update successful',
  'Save Changes': 'Save Changes',
  'Please input name': 'Please input name',
  'Length should be 2 to 64': 'Length should be 2 to 64',
  'Please input email': 'Please input email',
  'Please input correct email': 'Please input correct email',
  'Please input title': 'Please input title',
  'Please select country': 'Please select country',
  'Please select timezone': 'Please select timezone',
  'Please input phone': 'Please enter your phone number',
  'Please input correct phone': 'Please enter valid phone number',
    'Please input code': 'Please enter received verification Code',
    'Your phone number': 'Your phone number',
  'Verification code': 'Verification code',
  OR: 'OR',
  'Are you sure to delete this company?':
    'Are you sure to delete this company?',
  'delete company Name': 'Name',
  'delete company Type': 'Type',
  'delete company Country': 'Country',
  Cancel: 'Cancel',
  'Yes, delete this company': 'Yes, delete this company',
  'Creating Company Account': 'Creating Company Account',
  'Choose the timezone': 'Choose the timezone',
  'Choose the country': 'Choose the country',
  'Company Please input VAT': 'Please input VAT',
  'Company Please input correct VAT': 'Please input correct VAT',
  'Company Please input alias name': 'Please input alias name',
  'Company Please upload logo': 'Please upload logo',
  'Company Please select company type': 'Please select company type',
  'Company Please input contact': 'Please input contact',
  'Company Please select country': 'Please select country',
  'Company Please input address': 'Please input address',
  'Company Please input website': 'Please input website',
  'Company Please input email': 'Please input email',
  'Company Please input correct email': 'Please input correct email',
  'Company Please input name': 'Please input name',
  'Company Please select timezone': 'Please select timezone',
  Profile: 'Profile',
  Ping: 'Ping',
  at: 'at',
  'Choose the company': 'Choose the company',
  'Create Company after you complete and save your changes':
    'Create Company after you complete and save your changes',
  ctf: "Can't find?",
  'create account now': 'create account now',
  Delete: 'Delete',
  weak: 'weak',
  medium: 'medium',
  strong: 'strong',
  'Password Update Successful': 'Update Successful',
  'Update Successful': 'Update Successful',
  'Delete Successful': 'Delete Successful',
  'Create Successful': 'Create Successful',
  'Upload Successful': 'Upload Successful',
  'Copy Successful': 'Copy Successful',
  'Picture must be JPG or PNG or GIF!': 'Picture must be JPG or PNG or GIF!',
  'Picture size can not exceed 2MB!': 'Picture size can not exceed 2MB!',
  'Please complete the information first':
    'Please complete the information first',
  'Upload success': 'Upload success',
  'Create success': 'Create success',
  "Thali detail": 'Thali detail',
  customerNumber: "Customer number",
  storageSize: "Storage size(GB)",
  memberNumber: "Member number",
  orderNumber: "Order number",
  'become due': 'Become due',
  Used: 'Used',
  ExpiredDay: (week) => {
    return `Expired ${week.values.n} day`;
  },
};
