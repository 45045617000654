<template>
  <div class="file-view">
    <el-dialog
      :visible="embedShow"
      :fullscreen="full"
      :show-close="false"
      @close="close()"
      custom-class="dlg-embed"
      width="800px"
      :destroy-on-close="true"
      v-move-outside
    >
      <template v-if="embedUrlFile">
        <template v-if="!isVideo">
          <iframe
            :src="embedUrlFile"
            type="application/pdf"
            v-if="!isPicture"
          />
          <el-image :src="embedUrlFile" fit="scale-down" v-else></el-image>
        </template>
        <video ref="fileVideo" v-else controls>
          <source :src="embedUrlFile">
          您的浏览器不支持 HTML5 video 标签。
        </video>
      </template>
      <div class="tool-container">
        <div class="cirBtn" @click="close()">
          <i class="el-icon-close"></i>
        </div>
        <div class="bottom">
          <div class="cirBtn" @click="openWindow()">
            <i class="iconfont icon-zuidahua"></i>
          </div>
          <div class="cirBtn" @click="operation('下载')">
            <i class="iconfont icon-log-out" style="transform: rotate(270deg);"></i>
          </div>
          <el-popover placement="bottom" trigger="click" popper-class="share_box">
            <ul class="share">
              <li @click="operation('站内分享')">
                {{ $t('order.Internal sharing') }}
              </li>
              <li @click="operation('站外分享')">
                {{ $t('order.External sharing') }}
              </li>
            </ul>
            <div class="cirBtn" slot="reference">
              <i class="iconfont icon-fenxiang2"></i>
            </div>
          </el-popover>
        </div>
      </div>
      
    </el-dialog>
    <offStation
      :isOffStation="isOffStation"
      :offStationData="row"
      @onClose="isOffStation = false"
    />
    <inside
      :isInside="isInside"
      @onClose="isInside = false"
      :file="row"
    />
  </div>
</template>
<script>
let Base64 = require('js-base64').Base64;
import { ref, watch, onMounted } from '@vue/composition-api';
import { Message } from 'element-ui';

import offStation from '@/components/offStation/offStation.vue';
import inside from '@/components/fileInside/inside.vue';
import { getTemporaryFile } from "@/api/files";

export default {
  name: 'fileView',
  components: {
    offStation,
    inside
  },
  props: {
    row: {
      type: Object,
      default: null,
    },
    embedUrl: {
      type: String,
      default: '',
    },
    embedShow: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['close'],
  setup(props, { emit }) {
    const embedUrlFile = ref('');
    const full = ref(false);
    const isPicture = ref(false);
    const isVideo = ref(false);
    const isOffStation = ref(false);
    const isInside = ref(false);
    const insideData = ref(null);
    const type = ref("");
    const types = ref({
      pdf: ['pdf', 'PDF'],
      picture: ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg'],
      office: ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'csv'],
      video: [
        'mp4',
        'avi',
        'rmvb',
        'rm',
        'flv',
        '3gp',
        'mkv',
        'mov',
        'wmv',
        'mpg',
        'mpeg',
        'm4v',
        'webm',
      ],
      audio: [
        'mp3',
        'wav',
        'ogg',
        'wma',
        'aac',
        'flac',
        'ape',
        'm4a',
        'mid',
        'midi',
        'mka',
        'mp2',
        'mpc',
        'ra',
        'tta',
        'vqf',
        'ac3',
        'dts',
      ],
      zip: ["zip", "rar", "7z", "tar", "xz", "etc", "dwg"]
    });

    onMounted(() => {});

    function openWindow() {
      if (types.value.pdf.includes(type.value.toLowerCase())) {
        window.open(embedUrlFile.value);
      } else if (types.value.picture.includes(type.value.toLowerCase())) {
        const image = new Image();
        image.src = embedUrlFile.value;
        image.style.width = "100%";
        const imgWindow = window.open(embedUrlFile.value);
        imgWindow.document.write(image.outerHTML);
      } else if (types.value.office.includes(type.value.toLowerCase())) {
        window.open(embedUrlFile.value);
      } else if (types.value.video.includes(type.value.toLowerCase())) {
        let currElement = this.$refs.fileVideo;
        if (currElement.requestFullscreen) {
          currElement.requestFullscreen()
        } else if (currElement.mozRequestFullScreen) {
          currElement.mozRequestFullScreen()
        } else if (currElement.msRequestFullscreen) {
          currElement.msRequestFullscreen()
        } else if (currElement.oRequestFullscreen) {
          currElement.oRequestFullscreen()
        } else if (currElement.webkitRequestFullscreen) {
          currElement.webkitRequestFullScreen()
        }
      } else if (types.value.audio.includes(type.value.toLowerCase())) {
        window.open(embedUrlFile.value);
      } else {
        Message.warning('暂不支持该文件类型');
      }
    }

    function fileViewFun(url) {
      type.value = url.split('.').pop().split("?")[0];
      if (types.value.pdf.includes(type.value.toLowerCase())) {
        embedUrlFile.value = url;
      } else if (types.value.picture.includes(type.value.toLowerCase())) {
        isPicture.value = true;
        embedUrlFile.value = url;
      } else if (types.value.office.includes(type.value.toLowerCase())) {
        embedUrlFile.value = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(url)}`;
        // if(type.value.toLowerCase() == 'xlsx' || type.value.toLowerCase() == 'xls') {
        //   embedUrlFile.value = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(url)}`;
        // } else {
        //   embedUrlFile.value = `https://manage.echola.cn/kkfileview/onlinePreview?url=${encodeURIComponent(Base64.encode(url))}`;
        // }
      } else if (types.value.video.includes(type.value.toLowerCase())) {
        isVideo.value = true;
        embedUrlFile.value = url;
      } else if (types.value.audio.includes(type.value.toLowerCase())) {
        embedUrlFile.value = url;
      } else {
        Message.warning('暂不支持该文件类型');
      }
    }

    function operation(type) {
      switch (type) {
        case '下载':
          getTemporaryFile(props.embedUrl).then((data) => {
            // 使用a标签下载，禁止跳转
            const a = document.createElement('a');
            a.href = data;
            a.download = data;
            a.click();
          })
          break;
        case '站内分享':
          this.isInside = true;
          break;
        case '站外分享':
          this.isOffStation = true;
          break;
      }
    }

    watch(
      () => props.embedShow,
      (val) => {
        if (val) {
          setTimeout(() => {
            let url = new URL(props.embedUrl)
            if(url.searchParams.has("Expires")) {
              fileViewFun(props.embedUrl);
              return;
            }
            getTemporaryFile(props.embedUrl).then((data) => {
              fileViewFun(data);
            });
          }, 300);
        }
      }
    );

    function close() {
      embedUrlFile.value = '';
      isVideo.value = false;
      emit('close');
    }

    return {
      embedUrlFile,
      full,
      isPicture,
      isOffStation,
      isVideo,
      isInside,
      insideData,
      operation,
      openWindow,
      fileViewFun,
      close,
    };
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-dialog {
  border-radius: 4px;
}

/deep/ .el-dialog__body {
  padding: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
  border: none;
  height: calc(100vh - 300px);
  border-radius: 4px;
  overflow: visible;
}

/deep/ .el-dialog__header {
  display: none;
}

.dlg-embed iframe {
  border: none;
  width: 100%;
  height: 100%;
}

.dlg-embed video {
  border: none;
  width: 100%;
  height: 100%;
  background: #000000;
}

.tool-container {
  position: absolute;
  // width: 73px;
  height: calc(100vh - 310px);
  top: 0;
  right: -60px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 28px;
  .cirBtn {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    i {
      font-size: 16px;
      color: #757D8A;
      &::before {
        color: #757D8A;
      }
    }
  }
  .bottom {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
</style>
