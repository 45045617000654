import Cookies from "js-cookie";

const TokenKey = "Admin-Token";

export function getToken() {
  return Cookies.get(TokenKey) || window.localStorage.getItem(TokenKey);
}

export function setToken(token) {
  window.localStorage.setItem(TokenKey, token);
  return Cookies.set(TokenKey, token);
}

export function removeToken() {
  window.localStorage.removeItem(TokenKey);
  return Cookies.remove(TokenKey);
}
