<template>
  <el-popover
    ref="popover"
    placement="bottom"
    trigger="click"
    popper-class="ec-popper"
    :disabled="!isPopover"
    @hide="popoverShow = false"
  >
    <slot name="popperMenuItem" />
    <el-tooltip
      slot="reference"
      popper-class="ec-tooltip"
      effect="light"
      :content="tooltipText"
      placement="top"
      :open-delay="400"
    >
      <div 
        class="box" 
        :class="popoverShow || isActive ? 'active' : ''" 
        @click="clickEvent"
        :style="{
          '--hover-background': background
        }">
        <i
          :class="icon"
        ></i>
      </div>
    </el-tooltip>
  </el-popover>
  
</template>

<script>
export default {
  name: "tooltipIcon",
  props: {
    icon: {
      type: String,
      default: ""
    },
    background: {
      type: String,
      default: "#E0E4EA"
    },
    tooltipText: {
      type: String,
      default: "提示"
    },
    isPopover: {
      type: Boolean,
      default: false
    },
    popoverOption: {
      type: Array,
      default: () => []
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      popoverShow: false,
    }
  },
  methods: {
    clickEvent(e) {
      if(this.isPopover) {
        this.popoverShow = !this.popoverShow;
        return;
      }
      this.$emit("click", e)
    }
  },
}
</script>

<style lang="less" scoped>
.box {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    font-size: 20px;
    color: #757D8A;
    &.icon-download {
      font-size: 17px;
    }
  }
  &:hover {
    background: var(--hover-background);
  }
  &.active {
    background: #122545;
    i {
      color: #ffffff !important;
    }
  }
}
</style>