import request from '@/utils/request';

// 获取商品列表
export function getShopList(params) {
  return request({
    url: '/businessGoods/page',
    method: 'get',
    params,
  });
}

// 获取商品详情
export function getShopDetail(id) {
  return request({
    url: '/businessGoods/detail',
    method: 'get',
    params: {
      id
    },
  });
}

// 创建商品
export function createShop(data) {
  return request({
    url: '/businessGoods/add',
    method: 'post',
    data,
  });
}

// 编辑商品
export function editShop(data) {
  return request({
    url: '/businessGoods/update',
    method: 'post',
    data,
  });
}

// 置顶货代服务
export function topShop(id) {
  return request({
    url: '/manage/users/personalization/goodsTop',
    method: 'get',
    params: {
      id
    },
  });
}

// 删除商品
export function deleteShop(id) {
  return request({
    url: '/businessGoods/delete',
    method: 'get',
    params: {
      id
    },
  });
}

// 站外获取商品列表
export function getOffSiteShopList(params) {
  return request({
    url: '/share/businessGoodsShare/page',
    method: 'get',
    params,
  });
}

// 站外获取商品详情
export function getOffSiteShopDetail(params) {
  return request({
    url: '/share/businessGoodsShare/detail',
    method: 'get',
    params,
  });
}


// 获取货代服务列表
export function getForwarderServerList(params) {
  return request({
    url: '/businessForwarderService/page',
    method: 'get',
    params,
  });
}

// 获取货代服务详情
export function getForwarderServerDetail(id) {
  return request({
    url: '/businessForwarderService/detail',
    method: 'get',
    params: {
      id
    },
  });
}

// 获取航运公司列表
export function getCarrierList(params) {
  return request({
    url: '/systemShippingCompany/page',
    method: 'get',
    params,
  });
}

// 获取港口列表
export function getHarborList(params) {
  return request({
    url: '/systemHarbor/page',
    method: 'get',
    params,
  });
}

// 创建货代服务
export function createForwarderServer(data) {
  return request({
    url: '/businessForwarderService/add',
    method: 'post',
    data,
  });
}

// 编辑货代服务
export function editForwarderServer(data) {
  return request({
    url: '/businessForwarderService/update',
    method: 'post',
    data,
  });
}

// 置顶货代服务
export function topForwarderServer(id) {
  return request({
    url: '/manage/users/personalization/forwarderServiceTop',
    method: 'get',
    params: {
      id
    },
  });
}

// 删除货代服务
export function deleteForwarderServer(id) {
  return request({
    url: '/businessForwarderService/delete',
    method: 'get',
    params: {
      id
    },
  });
}

// 生成货代服务分享链接
export function getForwarderServerShareUrl(data) {
  return request({
    url: '/businessGoodsShare/add',
    method: 'post',
    data,
  });
}

// 站外获取货代服务列表
export function getOffSiteServerList(params) {
  return request({
    url: '/share/businessForwarderService/page',
    method: 'get',
    params,
  });
}

// 站外获取货代服务详情
export function getOffSiteServerDetail(params) {
  return request({
    url: '/share/businessForwarderService/detail',
    method: 'get',
    params,
  });
}

// 获取货代服务日志
export function getForwarderServerRecords(goodsId) {
  return request({
    url: '/businessGoodsLog/page',
    method: 'get',
    params: {
      goodsId
    },
  });
}

// 获取案例列表
export function getCaseList(params) {
  return request({
    url: '/businessGoodsCase/page',
    method: 'get',
    params,
  });
}

// 获取货代服务详情
export function getCaseDetail(id) {
  return request({
    url: '/businessGoodsCase/detail',
    method: 'get',
    params: {
      id
    },
  });
}

// 创建案例
export function createCase(data) {
  return request({
    url: '/businessGoodsCase/add',
    method: 'post',
    data,
  });
}

// 编辑案例
export function editCase(data) {
  return request({
    url: '/businessGoodsCase/update',
    method: 'post',
    data,
  });
}

// 置顶案例
export function topCase(id) {
  return request({
    url: '/manage/users/personalization/goodsCaseTop',
    method: 'get',
    params: {
      id
    },
  });
}

// 获取站外案例列表
export function getOffSiteCaseList(params) {
  return request({
    url: '/share/businessGoodsCase/page',
    method: 'get',
    params,
  });
}

// 获取站外案例详情
export function getOffSiteCaseDetail(params) {
  return request({
    url: '/share/businessGoodsCase/detail',
    method: 'get',
    params,
  });
}

// 删除货代服务
export function deleteCase(id) {
  return request({
    url: '/businessGoodsCase/delete',
    method: 'get',
    params: {
      id
    },
  });
}

// 获取文件列表
export function getFileList(params) {
  return request({
    url: '/businessGoodsFile/page',
    method: 'get',
    params,
  });
}

// 创建文件
export function createFile(data) {
  return request({
    url: '/businessGoodsFile/add',
    method: 'post',
    data,
  });
}

// 编辑文件
export function editFile(data) {
  return request({
    url: '/businessGoodsFile/update',
    method: 'post',
    data,
  });
}

// 置顶文件
export function topFile(id) {
  return request({
    url: '/manage/users/personalization/goodsFileTop',
    method: 'get',
    params: {
      id
    },
  });
}

// 删除文件
export function deleteFile(id) {
  return request({
    url: '/businessGoodsFile/delete',
    method: 'get',
    params: {
      id
    },
  });
}

// 获取文件详情
export function getFileDetail(id) {
  return request({
    url: '/businessGoodsFile/detail',
    method: 'get',
    params: {
      id
    },
  });
}

// 获取站外案例详情
export function getOffSiteFileDetail(params) {
  return request({
    url: '/share/businessGoodsFile/detail',
    method: 'get',
    params,
  });
}

// 查询分享公司数据
export function getShareCompanyData(token) {
  return request({
    url: '/share/businessForwarderService/shareInfo',
    method: 'get',
    params: {
      token
    },
  });
}
