import { MessageBox } from "element-ui";
import store from "@/store";
import { options } from "less";
import { log } from "console";

export default class SocketV2 {
  /**
   * @description: 初始化实例属性，保存参数
   */
  constructor(options) {
    this.isLock = false;
    this.url = options.url;
    this.userId = options.userId;
    this.callback = options.received;
    this.onClose = options.onClose;
    this.onOnLine = options.onOnLine;
    this.name = options.name || "default";
    this.ws = null;
    this.token = options.token;
    this.status = null;
    this.pingInterval = null;
    // 心跳检测频率
    this._timeout = 3000;
    this.isHeart = options.isHeart;
    this.isReconnection = options.isReconnection;
  }
  connect(data) {
    this.ws = new WebSocket(`${this.url}?token=${this.token}`);
    this.isLock = false;
    // 建立连接
    this.ws.onopen = (e) => {
      this.status = "open";
      console.warn("🚀  连接成功");
      this.onOnLine && this.onOnLine();
      if (this.isHeart) {
        // 心跳
        this._heartCheck();
      }
      // 给后台发送数据
      if (data !== undefined) {
        return this.ws.send(JSON.stringify({ type: "init" }));
      }
    };
    // 接受服务器返回的信息
    this.ws.onmessage = (e) => {
      if (e.data == "success") return; //默认消息返回
      try {
        let recvData = JSON.parse(e.data);
        if (recvData && typeof recvData == "object") {
          // 异常处理
          if(recvData.message.connectionType === -1) {
            this._closeSocket();
          } else if(recvData.message.connectionType === -2) {
            let vm = window.vm;
            this.close();
            MessageBox.confirm(
              vm.$t("home.Your account has been logged in other places, please log out and log in again"),
              vm.$t("home.Confirm logout"),
              {
                confirmButtonText: vm.$t("home.Re-Login"),
                type: "warning",
                closeOnClickModal: false,
                closeOnPressEscape: false,
                showClose: false,
                showCancelButton: false,
              }
            ).then(() => {
              store.dispatch("user/resetToken").then(() => {
                location.reload();
              });
            });
            this.isReconnection = false;
          } else if(recvData.message.connectionType === -3) {
            let vm = window.vm;
            this.close();
            MessageBox.confirm(
              vm.$t("home.Token expired, Please log out and log in again"),
              vm.$t("home.Confirm logout"),
              {
                confirmButtonText: vm.$t("home.Re-Login"),
                type: "warning",
                closeOnClickModal: false,
                closeOnPressEscape: false,
                showClose: false,
                showCancelButton: false,
              }
            ).then(() => {
              store.dispatch("user/resetToken").then(() => {
                location.reload();
              });
            });
            this.isReconnection = false;
          } else {
            // 正常结果返回
            this.callback && this.callback(recvData);
          }
        }
      } catch (err) {}
    };
    // 关闭连接
    this.ws.onclose = (e) => {
      console.warn("🚀 onclose");
      // this.onClose && this.onClose();
      this._closeSocket(e);
    };
    // 报错
    this.ws.onerror = (e) => {
      console.warn("🚀 onerror");
      this._closeSocket(e);
    };
  }
  sendMsg(data) {
    let msg = JSON.stringify(data);
    return this.ws.send(msg);
  }
  _resetHeart() {
    clearInterval(this.pingInterval);
    return this;
  }
  _heartCheck() {
    this.pingInterval = setInterval(() => {
      if (this.ws.readyState === 1) {
        try {
          this.ws.send(JSON.stringify({ connectionType: 10002 }));
        } catch (error) {
          console.error("心跳检测报错", error);
          this._closeSocket();
        }
      }
    }, this._timeout);
  }
  _closeSocket(e) {
    console.warn("🚀 断开，重连");
    if(this.status == 'close') return;
    if(!localStorage.getItem("Admin-Token")) return;
    if(this.isLock) return;
    this.isLock = true;
    this.close();
    if (this.isReconnection && this.userId) {
      // 重连
      setTimeout(this.connect.bind(this), 3000);
    }
  }
  close() {
    this.status = "close";
    this._resetHeart();
    this.onClose && this.onClose();
    this.ws.close();
  }
}
