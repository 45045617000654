<template>
  <div class="caseItemBox" v-if="item">
    <el-image 
      fit="cover" 
      class="elImage" 
      :src="(item.picList && item.picList[0] && item.picList[0].fileUrl) || ''">
      <div slot="error" class="image-slot">
        <img :src="$emptyImage" />
        <p>{{ $t('No image') }}</p>
      </div>
    </el-image>
    <div class="fileTag" v-if="item.attachmentId">{{ $t('noticeServer.File') }}</div>
    <div class="title line1" v-showTootip>{{ item.goodsName }}</div>
    <div class="description line2">{{ item.description }}</div>
    <div class="bottom">
      <div class="left">
        <div class="priceBox line1" v-showTootip>
          $ <span>{{ (Number(item.price)).toFixed(3) }}</span>
        </div>
      </div>
      <div class="right">
        <div class="otherBox" v-if="companyId != item.companyId">
          <div class="btn primary" @click.stop="consultEvent">{{ $t('noticeServer.Consult') }}</div>
        </div>
        <el-popover
          v-else
          ref="popover"
          v-model="item.check"
          class="el-pop"
          placement="right-start"
          trigger="manual"
          @hide="item.check = false"
          popper-class="ec-popper"
        >
          <div class="popperMenuItem" @click="topItem">
            <i class="el-icon-download rotate180" />
            <span>{{ item.top ? $t("home.Cancel Topping") : $t("Topping") }}</span>
          </div>
          <div class="popperMenuItem" @click="editItem">
            <i class="iconfont icon-Vector" />
            <span>{{ $t("home.Edit") }}</span>
          </div>
          <el-popover
            ref="popover"
            placement="right-start"
            trigger="click"
            :append-to-body="false"
            popper-class="ec-popper"
            @hide="popoverShow = false">
            <div class="popperMenuItem" @click="operation(0)">
              <i class="iconfont icon-fenxiang2" />
              <span>{{ $t("order.Internal sharing") }}</span>
            </div>
            <div class="popperMenuItem" @click="operation(1)">
              <i class="iconfont icon-zhanwaifenxiang" />
              <span>{{ $t("order.External sharing") }}</span>
            </div>
            <li 
              class="popperMenuItem" 
              slot="reference"
              :class="popoverShow ? 'active' : ''"
              @click="popoverShow = true">
              <i class="iconfont icon-fenxiang"></i>
              <span>{{ $t('order.share') }}</span>
            </li>
          </el-popover>
          <div class="popperMenuItem" @click="deleteItem">
            <i class="iconfont icon-shanchu" />
            <span>{{ $t("home.Delete") }}</span>
          </div>
          <i
            class="iconfont icon-Ellipsis downEllipsis"
            :class="item.check ? 'active' : ''"
            slot="reference"
            @click.stop="activePopover(item, 'popover')"
          />
        </el-popover>
      </div>
    </div>
    <inside
      :isInside="isInside"
      @onClose="isInside = false"
      type="shopServerInternal"
      :shopServerId="item.goodsId"
      :messageType="15"
    />
    <externalShare ref="externalShare" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { topShop, deleteShop } from "@/api/shopServer.js";
import inside from "@/components/fileInside/inside.vue";
import externalShare from '../share/externalShare.vue';

export default {
  components: {
    externalShare,
    inside
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      popoverShow: false,
      isInside: false
    }
  },
  computed: {
    ...mapGetters(['companyId'])
  },
  mounted() {
    document.body.addEventListener("click", () => {
      this.item.check = false;
      this.$nextTick(() => {
        this.$emit("closePopover", this.item.goodsId);
      });
    })
  },
  destroyed() {
    document.body.removeEventListener("click", () => {
      this.item.check = false;
      this.$nextTick(() => {
        this.$emit("closePopover", this.item.goodsId);
      });
    })
  },
  methods: {
    consultEvent() {
      this.$emit("consult", this.item.goodsId);
    },
    activePopover(row, key) {
      row.check = !row.check;
      this.$nextTick(() => {
        this.$emit("closePopover", row.goodsId);
      });
    },
    editItem() {
      this.$emit("edit", this.item);
    },
    topItem() {
      topShop(this.item.goodsId).then(() => {
        this.$emit("update", true)
      })
    },
    operation(type) {
      switch (type) {
        case 0:
          this.isInside = true;
          break;
        case 1:
          this.$refs.externalShare.open(this.item.goodsId, this.item.companyId, 1);
          break;
      }
    },
    deleteItem() {
      this.$confirm(
        this.$t('Cannot reply after deletion'), 
        this.$t('Are you sure to delete this product/service'), 
        {
          confirmButtonText: this.$t('Confirm'),
          cancelButtonText: this.$t('Cancel'),
          type: 'warning'
        }
      ).then(() => {
        deleteShop(this.item.goodsId).then(() => {
          this.$message.success(this.$t('event.Delete successfully'));
          this.$emit("update", false)
        })
      }).catch(() => {});
    }
  }
}
</script>

<style lang="less" scoped>
.caseItemBox {
  width: 100%;
  height: 321px;
  background: #F7F9FC;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 20px;
  cursor: pointer;
  border: 2px solid #F7F9FC;
  transition: all .3s;
  position: relative;
  &:hover {
    border: 2px solid #004C97;
  }
  .elImage {
    width: 100%;
    height: 187px;
    object-fit: cover;
    border-radius: 4px;
    background: #ffffff;
    vertical-align: bottom;
  }
  .fileTag {
    background: #FF8311;
    border-radius: 4px;
    padding: 2px 12px;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
    position: absolute;
    top: 0;
    left: 0;
  }
  .title {
    font-size: 20px;
    line-height: 24px;
    color: #122545;
    font-weight: 500;
    margin: 10px 0;
    width: 100%;
  }
  .description {
    font-size: 12px;
    line-height: 14px;
    color: #757d8a;
    margin-bottom: 6px;
    height: 28px;
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      .priceBox {
        color: #757D8A;
        font-size: 20px;
        line-height: 20px;
        max-width: 150px;
        span {
          color: #DC3545;
          font-size: 24px;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }
    .otherBox {
      .btn {
        padding: 10px 20px;
        color: #637381;
        font-size: 12px;
        line-height: 14px;
        border: 1px solid #E0E4EA;
        border-radius: 50px;
        &.primary {
          border: 1px solid #004C97;
          background: #004C97;
          color: #ffffff;
        }
      }
    }
    .downEllipsis {
      width: 26px;
      height: 26px;
    }
  }
}
</style>