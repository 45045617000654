<template>
  <div class="ecEditor" :class="{
    border: isBorder,
    toolbar: !isToolBar
  }">
    <Toolbar
      class="toolbarBox"
      style="border-bottom: 1px solid #E0E4EA;"
      :editor="editor"
      :defaultConfig="toolbarConfig"
      :mode="mode"
    />
    <Editor
      v-model="htmlContent"
      :defaultConfig="{
        addClassNames: true,
        ...editorConfig,
        ...editorConfigConst
      }"
      class="editorBox"
      :style="{
        'height': height
      }"
      :mode="mode"
      @onCreated="onCreated"
      @onChange="onChange"
      @customPaste="customPaste"
    />
  </div>
</template>

<script>
import Vue from "vue";
import "@wangeditor/editor/dist/css/style.css";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";

import { uploadFileUrl } from "@/api/files";

export default {
  components: { Editor, Toolbar },
  props: {
    value: {
      type: Number | String | Object,
      default: ""
    },
    isToolBar: {
      type: Boolean,
      default: true
    },
    isBorder: {
      type: Boolean,
      default: true
    },
    fileUploadCode: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: '150px'
    },
    toolbarConfig: {
      type: Object,
      default: () => ({
        excludeKeys: [
          'headerSelect',
          'blockquote',
          'group-more-style',
          'todo',
          'emotion',
          'group-video',
          'insertTable',
          'codeBlock',
          'undo',
          'redo',
          'divider',
          'group-indent',
          'fullScreen',
          'group-image',
          '|'
        ]
      })
    },
    editorConfig: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      editor: null,
      htmlContent: "",
      mode: "default", // or 'simple'
      editorConfigConst: {
        placeholder: "请输入内容..."
      }
    }
  },
  watch: {
    editorConfig: {
      handler(val) {
        if(!val.readOnly) {
          this.editor.enable()
        } else {
          this.editor.disable()
        }
      },
      deep: true
    },
    value(val) {
      this.htmlContent = val;
    }
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor);
      this.htmlContent = this.value;
    },
    onChange(editor) {
      this.$emit("input", this.htmlContent);
    },
    customPaste(editor, evt, callback) {
      if (
        evt.clipboardData &&
        evt.clipboardData.files &&
        evt.clipboardData.files.length
      ) {
        [].forEach.call(evt.clipboardData.files, (file) => {
          if (!file.type.match(/^image\/(gif|jpe?g|a?png|bmp)/i)) {
            return;
          }
          const data_up = new FormData();
          data_up.append("files", file);
          data_up.append("configCode", this.fileUploadCode);
          uploadFileUrl(data_up)
            .then((response) => {
              if (!response) return;
              editor.dangerouslyInsertHtml(`<img src="${response[0].savePath}" />`)
            })
            .catch((error) => {});
        });
        evt.preventDefault();
        callback(false);
      }
    }
  },
  mounted() {
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
};
</script>

<style lang="less" scoped>
  .ecEditor {
    border-radius: 4px;
    overflow: hidden;
    &.border {
      border: 1px solid #dcdfe6;
    }
    &.toolbar {
      .toolbarBox {
        display: none;
      }
    }
    .editorBox {
      padding-top: 10px;
      /deep/ img {
        cursor: zoom-in;
      }
    }
    /deep/ p {
      margin: 0;
    }
    /deep/ .w-e-text-placeholder {
      top: 0;
      line-height: 21px;
      font-style: inherit;
    }
  }
</style>
