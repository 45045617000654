import request from "@/utils/request";
import { log } from "console";
import exp from "constants";

//调用新建公司接口
export function createCompany(data) {
  return request({
    url: "/company/add",
    method: "post",
    data,
  });
}

//调用更新公司接口
export function updateCompany(data) {
  return request({
    url: "/company/update",
    method: "post",
    data,
  });
}

//调用获取公司列表对应的列表接口
export function getCompanyList(params) {
  return request({
    url: "/company/page",
    method: "get",
    params,
  });
}

//获取用户就职的公司信息对应的列表接口
export function getOfficeCompany(unid, token) {
  let headers = token
    ? {
        Authorization: "Bearer " + token,
      }
    : {};
  return request({
    url: "/company/detail",
    method: "get",
    params: {
      unid,
    },
    headers,
  });
}

// 申请加入公司
export function addJoinCompany(unid, inviteCode, remark) {
  return request({
    url: "/company/user/applyJoinCompany",
    method: "post",
    data: {
      unid,
      inviteCode,
      remark,
    },
  });
}

// 获取公司所有用户
export function getCompanyEmployeeList(unid) {
  return request({
    url: "/company/user/getCompanyUsers",
    method: "get",
    params: { unid },
  });
}

// 获取货代公司成员列表
export function getForwarderMemberList(customerId) {
  return request({
    url: "/group/listMemberByCustomerId",
    method: "get",
    params: { customerId },
  });
}

// 获取群组两个公司成员列表
export function getGounpCompanyList(companyCustomerId) {
  return request({
    url: "/group/listMemberByCompanyCustomerId",
    method: "get",
    params: { companyCustomerId },
  });
}

// 删除公司
export function deleteCompany(data) {
  return request({
    url: "/company/delete",
    method: "post",
    data: data,
  });
}

// 获取公司详情
export function getCompanyDetail(companyId) {
  return request({
    url: "/company/detail",
    method: "get",
    params: companyId,
  });
}

//创建用户客户公司关系
export function createCustomerConnection(data) {
  return request({
    url: "/customer/add",
    method: "post",
    data: data,
  });
}

//删除用户客户公司关系
export function deleteCustomerConnection(unid) {
  return request({
    url: "/customer/deleteCustomer",
    method: "post",
    params: {
      unid,
    },
  });
}

//获取待审核用户客户公司关系
export function getApproveList() {
  return request({
    url: "/businessCustomer/approveList",
    method: "get",
  });
}
//获取用户加入公司审核列表
export function memberList(data) {
  for (let index in data) {
    !data[index] && delete data[index];
  }
  return request({
    url: `businessMember/memberList`,
    method: "post",
    data,
  });
}
//审核用户是否加入该公司//审核用户公司关系
export function onApprove(params) {
  return request({
    url: "/company/user/approveApplyJoinCompany",
    method: "POST",
    params,
  });
}
//审核用户客户公司关系
export function approveCustomer(params) {
  return request({
    url: "/customer/approveApplyJoinCompany",
    method: "POST",
    params,
  });
}

//获取用户客户公司关系
export function getUserCustomList(params) {
  return request({
    url: "/customer/page",
    method: "get",
    params,
  });
}

// 根据用户名称查询自己公司成员信息
export function listCompanyUsers(params) {
  return request({
    url: "/company/user/listCompanyUsers",
    method: "get",
    params,
  });
}

//获取用户客户公司关系
export function getCustomerUsers(params) {
  return request({
    url: "/customer/listCompanyCustomerUser",
    method: "get",
    params,
  });
}

//置顶用户客户公司关系
export function topCustomer(data) {
  return request({
    url: "/businessCustomer/top",
    method: "POST",
    data: data,
  });
}
//收藏，取消收藏-用户客户公司关系
export function followedCustomer(data) {
  return request({
    url: "/businessCustomer/followed",
    method: "POST",
    data: data,
  });
}

//取消隐藏用户客户公司关系
export function showCustomer(params, hidden) {
  if (hidden) {
    return request({
      url: "/manage/users/personalization/cancelHideCustomer",
      method: "POST",
      params,
    });
  } else {
    return request({
      url: "/manage/users/personalization/hideCustomer",
      method: "POST",
      params,
    });
  }
}

// 收藏用户客户公司关系
export function collectCustomer(params) {
  return request({
    url: "/manage/users/personalization/collectCustomer",
    method: "POST",
    params,
  });
}

// 取消收藏客户
export function unCollectCustomer(params) {
  return request({
    url: "/manage/users/personalization/cancelCollectCustomer",
    method: "POST",
    params,
  });
}

//调用获取公司类型对应的列表接口
export function getCustomerList(companyType) {
  return request({
    url: "/businessCustomer/customerCompanyList",
    method: "get",
    params: { companyType },
  });
}

//获取用户客户公司列表//////在用 芴删！
export function postCustomerList(data) {
  return request({
    url: "/businessCustomer/customerCompanyList",
    method: "POST",
    data: data,
  });
}

//邀请用户创建客户公司关系
export function inviteCustomer(data) {
  return request({
    url: "/businessCustomer/invite",
    method: "POST",
    data: data,
  });
}

//更新用户客户公司关系
export function updateCustomerUser(params) {
  return request({
    url: "/customer/inviteMember",
    method: "POST",
    params,
  });
}

// 关联公司
export function getCompanyConnection(data) {
  return request({
    url: "/company/connection",
    method: "post",
    data: data,
  });
}
//删除公司员工
export function deleteEmployee(data) {
  return request({
    url: "/company/deleteEmployee",
    method: "post",
    data: data,
  });
}
//根据名称搜索船舶
export function ship(data) {
  return request({
    url: "/ship/getShip",
    method: "get",
    params: data,
  });
}

// 查询合作货代
export function getCooperateCustomer(params) {
  return request({
    url: "/customer/listCooperativeFreightForwarder",
    method: "get",
    params,
  });
}

// 获取他人个人信息
export function getOtherInfoData(openId) {
  return request({
    url: "/manage/users/getUserByOpenId",
    method: "get",
    params: {
      openId,
    },
  });
}

// 可分配客户成员
export function getCompanyMemberData(companyCustomerId) {
  return request({
    url: "/customer/listCompanyUser",
    method: "get",
    params: {
      companyCustomerId,
    },
  });
}

// 分享文件公司下拉
export function getFileShareList(params) {
  return request({
    url: "/customer/list",
    method: "get",
    params,
  });
}

// 添加临时用户
export function addVirtualCustomer(data) {
  return request({
    url: "/customer/addVirtualCustomer",
    method: "post",
    data,
  });
}

// 编辑临时用户
export function editVirtualCustomer(data) {
  return request({
    url: "/customer/updateVirtualCustomer",
    method: "post",
    data,
  });
}

// 发送临时客户邮件
export function sendTransferAccountEmail(data) {
  return request({
    url: "/customer/sendTransferEmail",
    method: "post",
    data,
  });
}

// 用户自己修改临时用户信息
export function updateOauthTempUserData(data, token) {
  let headers = token ? {
    Authorization: 'Bearer '+token,
  } : {};
  return request({
    url: "/oauth/updateTemp",
    method: "post",
    data,
    headers
  });
}

// 转移临时客户复制链接
export function getGenerateTransferUrl(companyCustomerId) {
  return request({
    url: "/customer/generateTransferUrl",
    method: "get",
    params: {
      companyCustomerId
    }
  });
}

// 获取个人邀请码
export function getGenerateInviteCode(params) {
  return request({
    url: "/invite/generateInviteCode",
    method: "get",
    params
  });
}

// 邮件发送公司邀请码
export function sendEmailInviteCode(params) {
  return request({
    url: "/invite/sendCustomerInviteCodeEmail",
    method: "get",
    params
  })
}

// 根据邀请码查询公司信息
export function getCompanyByInviteCode(inviteCode) {
  return request({
    url: "/invite/getCompanyByInviteCode",
    method: "get",
    params: {
      inviteCode
    }
  })
}

// 获取个人邀请码使用记录
export function getInviteCodeUseRecord(params) {
  return request({
    url: "/invite/page",
    method: "get",
    params
  })
}

// 账户通讯录数据
export function getAddressBookData(params) {
  return request({
    url: "/customer/pageAddressBook",
    method: "get",
    params
  })
}

// 查看当前公司套餐详情
export function getCompanyThaliDetail() {
  return request({
    url: "/systemCompanyCombo/current",
    method: "get"
  })
}

// 查询企信查模糊数据
export function getTianyanChaSearchData(companyName) {
  return request({
    url: "/company/getCompanyListByTYC",
    method: "get",
    params: {
      companyName
    }
  })
}

// 查询企信查数据
export function getTianyanChaData(companyName) {
  return request({
    url: "/company/getCompanyByNameAndTYC",
    method: "get",
    params: {
      companyName
    }
  })
}

// 判断公司是否为我的客户
export function getIsMyCustomer(companyId) {
  return request({
    url: "/company/user/isCustomer",
    method: "get",
    params: {
      companyId
    }
  })
}