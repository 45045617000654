<template>
  <div>
    <el-dialog 
      :visible="visible" 
      width="650px" 
      append-to-body
      :before-close="closeFun"
      v-move-outside>
      <div class="box">
        <div class="left">
          <div class="searchBox">
            <el-input 
              v-model="addressBookForm.username" 
              :placeholder="$t('order.Search')"
              size="small"
              @input="getAddressBookList(true)"
            >
              <i slot="suffix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </div>
          <div class="addressBookBox">
            <div 
              class="companyItem" 
              v-for="(item, index) in addressBookData" 
              :key="index">
              <div class="companyTitle" @click="item.open = !item.open">
                <div class="title">
                  <i :class="`el-icon-arrow-up ${!item.open ? 'hide' : ''}`"></i>
                  {{ item.aliasName }}
                </div>
                <div class="num">{{ item.users.length }}</div>
              </div>
              <div class="childrenList" v-show="item.open">
                <div 
                  class="item" 
                  v-for="(row, idx) in item.users" 
                  :key="idx"
                  :style="{
                    opacity: row.disabled || row.isOld ? '.5' : '1'
                  }">
                  <el-checkbox  
                    v-model="row.check" 
                    :disabled="row.disabled || row.isOld"
                    @change="addCreateGroupUser(row, index)"
                  />
                  <member 
                    @close="closeFunMessage()" 
                    :info="row" 
                    :size="48" 
                    :isPing="false" 
                  />
                  <div class="userBox" @click="checkChange(row, index)">
                    <div class="name line1">{{ row.nickName }}</div>
                    <div class="position">{{ row.position }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="createGroupChatBox">
            <div class="header">
              <div class="title">{{ $t('GroupChat.Initiate group chat') }}</div>
              <div class="checkedBox">
                {{ $tc('home.selectedPerson', createGroupList.filter(item => !item.isManage).length) }}
              </div>
            </div>
            <div class="createGroupBox">
              <div class="createGroupList">
                <div 
                  class="item" 
                  v-for="(item, index) in createGroupList.filter(item => !item.isManage)" 
                  :key="index">
                  {{ item.index }}
                  <member :info="item" :size="60" :isPing="false" @close="closeFun()" />
                  <div class="name line1">{{ item.nickName }}</div>
                  <i v-if="!item.isOld" class="el-icon-error" @click="createGroupUserDelete(item, index)"></i>
                </div>
              </div>
              <div class="btns">
                <el-button @click="closeFun">{{ $t('Cancel') }}</el-button>
                <el-button 
                  type="primary" 
                  @click="createGroupSubmit"
                >{{ group ? $t('Confirm') : $t('home.Create') }}</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getAddressBookData } from "@/api/customer";
import { 
  createGroupChat,
  addGroupChatMember,
  sendChatData
} from '@/api/news';
export default {
  name: "createGroupChatDialog",
  data() {
    return {
      visible: false,
      addressBookForm: {
        username: ""
      },
      group: null,
      addressBookData: [],
      createGroupList: [],
      selectedData: []
    }
  },
  computed: {
    ...mapGetters(['businessUserId'])
  },
  methods: {
    open(row = null) {
      this.group = row;
      this.getAddressBookList();
      this.visible = true;
    },
    getAddressBookList(isSearch = null) {
      getAddressBookData(this.addressBookForm).then(data => {
        let checkedMembers = null;
        if(this.group) {
          checkedMembers = this.group?.memberList.map(x => x.openId);
        }
        console.log(checkedMembers, this.createGroupList);
        this.addressBookData = data.map(item => ({
          ...item,
          users: item.users.map(row => ({
            ...row,
            check: checkedMembers || this.createGroupList.length ? ((checkedMembers && checkedMembers.concat(this.createGroupList.map(item => item.openId)) || this.createGroupList.map(xx => xx.openId)).includes(row.openId)) : row.openId == this.businessUserId,
            disabled: row.openId == this.businessUserId,
            isOld: checkedMembers ? checkedMembers.includes(row.openId) : row.openId == this.businessUserId,
            isManage: checkedMembers ? this.group.admin.openId == row.openId : row.openId == this.businessUserId
          })),
          open: true
        }));
        if(!isSearch) {
          if(this.group) {
            this.addCreateGroupUser();
          } else {
            this.addressBookData.forEach(item => {
              item.users.forEach(row => {
                if(row.openId == this.businessUserId) {
                  this.addCreateGroupUser(row);
                }
              })
            })
          }
        }
      })
    },
    checkChange(row, index) {
      if(row.disabled || row.isOld) return;
      row.check = !row.check;
      this.addCreateGroupUser(row, index);
    },
    addCreateGroupUser(valueRow) {
      if(!valueRow) {
        let checkedMembers = null;
        if(this.group) {
          checkedMembers = this.group?.memberList.map(x => x.openId);
        }
        this.group?.memberList.forEach(row => {
          this.createGroupList.push({
            ...row,
            check: checkedMembers || this.createGroupList.length ? ((checkedMembers && checkedMembers.concat(this.createGroupList.map(item => item.openId)) || this.createGroupList.map(xx => xx.openId)).includes(row.openId)) : row.openId == this.businessUserId,
            disabled: row.openId == this.businessUserId,
            isOld: checkedMembers ? checkedMembers.includes(row.openId) : row.openId == this.businessUserId,
            isManage: checkedMembers ? this.group.admin.openId == row.openId : row.openId == this.businessUserId
          });
        })
      } else {
        if(this.createGroupList.map(item => item.openId).includes(valueRow.openId)) {
          let indexs = this.createGroupList.findIndex((asd => asd.openId == valueRow.openId));
          this.createGroupList.splice(indexs, 1);
        } else {
          this.createGroupList.push(valueRow);
        }
      }
      this.createGroupList.forEach((item, index) => {
        if(item.openId == this.businessUserId) {
          this.createGroupList.splice(index, 1);
          this.createGroupList.unshift(item);
        }
      })
    },
    createGroupUserDelete(row, index) {
      this.addressBookData.forEach(item => {
        item.users.forEach(value => {
          if(value.openId == row.openId) {
            value.check = false;
          }
        })
      })
      this.$nextTick(() => {
        this.createGroupList = this.createGroupList.filter((item, i) => row.openId != item.openId);
      })
    },
    createGroupSubmit() {
      let arr = this.createGroupList.filter(item => !item.isOld);
      if(this.group) {
        if(!arr.length) {
          this.$message.warning(this.$t('home.Please select member'));
          return;
        }
      } else {
        if(this.createGroupList.length < 3) {
          this.$message.warning(this.$t('GroupChat.Insufficient number of people to group, add at least 2 people'));
          return;
        }
      }
      if(this.group) {
        console.log(this.createGroupList);
        addGroupChatMember({
          groupId: this.group.groupId,
          openIds: this.createGroupList.map(item => item.openId).join(",")
        }).then(() => {
          this.$message.success(this.$t('home.Createdsuccessfully'));
          sendChatData({
            toUserId: this.group.groupId,
            content: this.createGroupList.filter(item => !item.isOld).map(item => item.nickName).join("、"),
            messageType: 7,
            chatType: 1
          }).then(() => {
            this.$emit("update");
            this.closeFun();
          });
        })
        return;
      }
      createGroupChat({
        members: this.createGroupList.map(item => item.openId).join(",")
      }).then((data) => {
        sendChatData({
          toUserId: data,
          content: this.createGroupList.filter(item => item.openId != this.businessUserId).map(item => item.nickName).join("、"),
          messageType: 5,
          chatType: 1
        }).then(() => {
          this.$message.success(this.$t('home.Createdsuccessfully'));
          this.$emit("update");
          this.$emit("createUpdate");
          this.closeFun();
        });
      });
    },
    closeFunMessage() {
      this.closeFun();
      this.$emit("closeMessage");
    },
    closeFun() {
      this.visible = false;
      this.createGroupList = [];
      this.addressBookForm.username = "";
    }
  },
}
</script>

<style lang="less" scoped>
/deep/ .el-dialog {
  height: calc(100vh - 500px);
  min-height: 500px;
  overflow: hidden;
}
/deep/ .el-dialog__header {
  display: none;
}
/deep/ .el-dialog__body {
  padding: 0;
  background: #ffffff;
  height: 100%;
  max-height: none;
}
.box {
  display: flex;
  height: 100%;
  .left {
    width: 250px;
    height: 100%;
    border-right: 1px solid #E0E4EA;
    .searchBox {
      padding: 24px 20px 20px 20px;
    }
    .addressBookBox {
      height: calc(100% - 76px);
      overflow: overlay;
      .companyItem {
        .companyTitle {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 15px 10px 20px;
          cursor: pointer;
          .title {
            font-size: 14px;
            font-weight: 600;
            max-width: 160px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            i {
              font-weight: 600;
              &.hide {
                transform: rotate(180deg);
              }
            }
          }
          .num {
            font-size: 14px;
            font-weight: 600;
            color: #A3B0C6;
          }
        }
        .childrenList {
          .item {
            width: 100%;
            height: 64px;
            border-bottom: 1px solid #E0E4EA;
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 9px;
            padding: 0 15px 0 20px;
            position: relative;
            .userBox {
              display: flex;
              flex-direction: column;
              justify-content: center;
              flex: 1;
              min-width: 0;
              .name {
                line-height: 19.6px;
                font-size: 14px;
                font-weight: 500;
                color: #122545;
                margin-bottom: 4px;
                white-space: nowrap;
              }
              .position {
                line-height: 16.8px;
                font-size: 12px;
                color: #757D8A;
              }
            }
            &:hover {
              background: #F7F9FC;
            }
          }
        }
      }
    }
  }
  .right {
    flex: 1;
    .createGroupChatBox {
      width: 100%;
      height: 100%;
      padding: 14px 20px;
      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 28px;
        .title {
          font-size: 16px;
          font-weight: 600;
          color: #122545;
        }
        .checkedBox {
          font-size: 16px;
          font-weight: 600;
          color: #757D8A;
        }
      }
      .createGroupBox {
        padding: 20px 20px 0 20px;
        height: calc(100% - 32px);
        .createGroupList {
          margin-top: 20px;
          display: flex;
          align-content: flex-start;
          flex-wrap: wrap;
          gap: 40px;
          text-align: center;
          position: relative;
          height: calc(100% - 80px);
          overflow: overlay;
          .item {
            position: relative;
            width: 78px;
            .name {
              font-size: 14px;
              font-weight: 600;
              line-height: 20px;
              margin-top: 10px;
            }
            .el-icon-error {
              position: absolute;
              top: 0;
              right: -4px;
              font-size: 18px;
              color: #A3B0C6;
              cursor: pointer;
            }
          }
        }
        .btns {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 20px;
          margin-top: 10px;
          .el-button {
            width: 120px;
            margin: 0;
          }
        }
      }
    }
  }
}
</style>