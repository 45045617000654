var events = require('events');
import Vue from 'vue';
import dayjs from 'dayjs';
import App from './App.vue';
import router from './router';
import store from './store';
import quillEditor from 'vue-quill-editor'; //调用编辑器
import vueToPdf from 'vue-to-pdf';
import VueCompositionAPI from '@vue/composition-api';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import draggable from 'vuedraggable';
import InfiniteLoading from 'vue-infinite-loading';
import VueDraggableResizable from 'vue-draggable-resizable';
import infiniteScroll from "vue-infinite-scroll";
import VueAudio from "vue-audio-better";
import fullscreen from 'vue-fullscreen';
import PortalVue from 'portal-vue'
import i18n from "./utils/element";
import directive from "./utils/directives";
import { isFindData, toPercent, formatFileSize, replayImgShow, replaceLinks, uniqueArray, getFileMd5, isFileTypeIcon } from "@/utils/utils.js";
import { export_json_to_excel } from "@/vendor/Export2Excel";
import 'echarts-liquidfill';
import "./utils/echartsTheme";
import "./utils/iconfont";

import '@/assets/style/index.css';
import "@/assets/style/common.less";
import '@/assets/style/iconfont/index.css';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import 'swiper/dist/css/swiper.css';
import 'vue-draggable-resizable/dist/VueDraggableResizable.css';

import member from './components/member/member.vue';
import messageDialog from './components/messageDialog/messageDialog.vue';
import mapTrack from './components/mapTrack/mapTrack.vue';
import orderHistory from './components/orderHistory/orderHistory.vue';
import MemberList from './components/MemberList/MemberList.vue';
import Teleport from './components/teleport/teleport.vue';
import fileView from './components/fileView/fileView.vue';
import fileBlocks from './components/fileBlocks/fileBlocks.vue';
import testWrapper from './components/test/test.vue';
import skuView from './components/skuView/skuView.vue';
import ConversationBubble from './components/conversation-bubble/conversation-bubble.vue';
import groupManagement from './components/groupManagement/groupManagement.vue';
import pageTable from "@/components/pageTable";
import EcIcon from '@/components/ec-icon';
import EcEditor from "./components/ecEditor";
import toolTipIcon from "./components/tooltipIcon";
import Vue2OrgTree from 'vue2-org-tree';
import mapSelect from "@/components/mapSelect";
import 'vue2-org-tree/dist/style.css'

dayjs.extend(utc);
dayjs.extend(tz);
Vue.component('vue-draggable-resizable', VueDraggableResizable);
Vue.component('draggable', draggable);
Vue.component('pageTable', pageTable);
Vue.component('EcIcon', EcIcon);
Vue.component('toolTipIcon', toolTipIcon);
Vue.component('EcEditor', EcEditor);
Vue.component('mapSelect', mapSelect)
Vue.use(vueToPdf);
Vue.use(quillEditor);
Vue.use(infiniteScroll);
Vue.use(Vue2OrgTree);
Vue.use(VueAudio);

Vue.prototype.$dayjs = dayjs;

Vue.config.productionTip = false;

const GMT_TIME_ZONES = {
  'GMT-12': 'Etc/GMT+12',
  'GMT-11': 'Pacific/Midway',
  'GMT-10': 'Pacific/Honolulu',
  'GMT-9': 'America/Juneau',
  'GMT-8': 'America/Los_Angeles',
  'GMT-7': 'America/Denver',
  'GMT-6': 'America/Mexico_City',
  'GMT-5': 'America/New_York',
  'GMT-4': 'America/Caracas',
  'GMT-3': 'America/Argentina/Buenos_Aires',
  'GMT-2': 'Atlantic/South_Georgia',
  'GMT-1': 'Atlantic/Azores',
  'GMT+0': 'Europe/London',
  'GMT+1': 'Europe/Paris',
  'GMT+2': 'Europe/Helsinki',
  'GMT+3': 'Europe/Moscow',
  'GMT+4': 'Asia/Baku',
  'GMT+5': 'Asia/Karachi',
  'GMT+6': 'Asia/Dhaka',
  'GMT+7': 'Asia/Bangkok',
  'GMT+8': 'Asia/Shanghai',
  'GMT+9': 'Asia/Tokyo',
  'GMT+10': 'Australia/Sydney',
  'GMT+11': 'Pacific/Guadalcanal',
  'GMT+12': 'Pacific/Auckland',
};

Vue.use(VueCompositionAPI);
Vue.use(VueAwesomeSwiper);
Vue.component('member', member);
Vue.component('messageDialog', messageDialog);
Vue.component('mapTrack', mapTrack);
Vue.component('orderHistory', orderHistory);
Vue.component('MemberList', MemberList);
Vue.component('fileView', fileView);
Vue.component('Teleport', Teleport);
Vue.component('fileBlocks', fileBlocks);
Vue.component('testWrapper', testWrapper);
Vue.component('conversation-bubble', ConversationBubble);
Vue.component('skuView', skuView);
Vue.component('groupManagement', groupManagement);
Vue.use(fullscreen);
Vue.use(PortalVue);
Vue.use(directive);

function getTimeZooe(data){
	var time = new Date(); //获取时间
	// 获取时间偏移量 getTimezoneOffset 获取格林威治时间   *60000是到毫秒
	var dataOffset = new Date().getTimezoneOffset() * 60000
	// 获取格林威治时间 
	var utc = time.getTime() + dataOffset;  // 两个时间戳
	// 拿格林威治时间去反推指定地区时间    
	var newTime = utc + 3600000 * data;  // 
	var times = new Date(newTime);
	return times
}	

Vue.filter('ftz', function (value) {
  if (value) {
    let timeZone = Number(value.split('GMT')[1]);
    return dayjs(getTimeZooe(timeZone)).format('HH:mm');
  } else {
    return 'Not set timezone';
  }
});

Vue.prototype.$companyTypes = {
  '0': 'PURCHASER',
  '1': 'VENDOR',
  '2': 'FORWARDER'
}

Vue.prototype.$isFindData = isFindData;
Vue.prototype.$getTimeZone = (value) => {
  if (value) {
    let timeZone = Number(value.split('GMT')[1]);
    return dayjs(getTimeZooe(timeZone)).format('YYYY-MM-DD HH:mm:ss');
  } else {
    return 'Not set timezone';
  }
}
Vue.prototype.$toPercent = toPercent;
Vue.prototype.$formatFileSize = formatFileSize;
Vue.prototype.$replayImgShow = replayImgShow;
Vue.prototype.$replaceLinks = replaceLinks;
Vue.prototype.$uniqueArray = uniqueArray;
Vue.prototype.$myEvent = new events.EventEmitter();
Vue.prototype.$getFileMd5 = getFileMd5;
Vue.prototype.$isFileTypeIcon = isFileTypeIcon;
Vue.prototype.$isOrderTitle = () => {
  return isFindData(store.state.dict.orderAliasOption, store.state.user.orderAlias, 'value', 'key')
}

Vue.prototype.$isOrderPITitle = () => {
  if(store.state.user.orderAlias) {
    return window.vm.$tc('Project name', isFindData(store.state.dict.orderAliasOption, 1, 'value', 'key'))
  } else {
    return 'PI'
  }
}

Vue.prototype.$formatJson = (filterVal, tableData, filterArray) => {
  console.log(tableData)
  return tableData.map((v) => {
    return filterVal.map((j) => {
      if(j == 'country') {
        return isFindData(filterArray, v[j], 'value', 'key')
      } else if(j == 'progress') {
        return filterArray[v[j]].key
      } else {
        return v[j];
      }
    });
  });
}

Vue.prototype.$getGradeData = (val) => {
  let classOption = [];
  let NowYears = new Date().getFullYear();// 获取当前年
  var Years = NowYears - val;// 获取当前年和指定年份的插值
  for (let index = 0; index <= Years; index++) {
    classOption.push(NowYears--);
  }
  return [classOption[0]+1, ...classOption];
}

Vue.prototype.$export_json_to_excel = export_json_to_excel;

Vue.prototype.$emptyImage = "https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/emptyImage.png";

window.vm = new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

Vue.use(InfiniteLoading, {
  slots: {
    noMore: window.vm.$t('InformationCenter.Nomoremessages'),
    noResults: window.vm.$t('InformationCenter.Nomoremessages'),
  },
});
