import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { i18nConfig } from '../language';
import locale from 'element-ui/lib/locale';
import { 
  eSelect,
  eOption,
  eOptionGroup
} from "../components/ecSelect";
import {
  Tabs,
  TabPane
} from "../components/ecTabs";
import {
  ColorPicker,
  Pagination,
  Dialog,
  Autocomplete,
  Skeleton,
  SkeletonItem,
  Rate,
  Input,
  InputNumber,
  Radio,
  RadioGroup,
  RadioButton,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Switch,
  Button,
  ButtonGroup,
  Select,
  Option,
  OptionGroup,
  Table,
  TableColumn,
  DatePicker,
  TimeSelect,
  TimePicker,
  Popover,
  Tooltip,
  Slider,
  Form,
  FormItem,
  // Tabs,
  // TabPane,
  Tag,
  Tree,
  Alert,
  Icon,
  Row,
  Col,
  Upload,
  Spinner,
  Badge,
  Card,
  Steps,
  Step,
  Carousel,
  CarouselItem,
  Cascader,
  Transfer,
  Timeline,
  TimelineItem,
  Divider,
  Image,
  Calendar,
  Backtop,
  Loading,
  MessageBox,
  Message,
  Notification,
  Avatar,
  Empty,
  Drawer,
  Descriptions,
  DescriptionsItem,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Progress,
  Popconfirm
} from 'element-ui';

const messages = ['success', 'warning', 'info', 'error'];

messages.forEach((type) => {
  Message[type] = (options) => {
    if (typeof options === 'string') {
      options = {
        message: options,
        type: type,
        offset: 50,
        duration: 2000,
      };
    }
    return Message(options);
  };
});

// 注册i18n实例并引入语言文件
Vue.use(VueI18n);
const i18n = new VueI18n(i18nConfig);
locale.i18n((key, value) => i18n.t(key, value))

Vue.component('ElSelect', eSelect);
Vue.component('ElOption', eOption);
Vue.component('ElOptionGroup', eOptionGroup);

Vue.component('chatSelect', Select);
Vue.component('chatOption', Option);
Vue.component('chatOptionGroup', OptionGroup);

Vue.use(Pagination);
Vue.use(Dialog);
Vue.use(ColorPicker);
Vue.use(Autocomplete);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Rate);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Checkbox);
Vue.use(CheckboxButton);
Vue.use(CheckboxGroup);
Vue.use(Switch);
Vue.use(Skeleton);
Vue.use(SkeletonItem);
Vue.use(Popconfirm);
Vue.use(Button);
Vue.use(ButtonGroup);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(DatePicker);
Vue.use(TimeSelect);
Vue.use(TimePicker);
Vue.use(Popover);
Vue.use(Tooltip);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Tag);
Vue.use(Tree);
Vue.use(Alert);
Vue.use(Icon);
Vue.use(Row);
Vue.use(Col);
Vue.use(Upload);
Vue.use(Spinner);
Vue.use(Slider);
Vue.use(Badge);
Vue.use(Card);
Vue.use(Steps);
Vue.use(Step);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Cascader);
Vue.use(Transfer);
Vue.use(Timeline);
Vue.use(TimelineItem);
Vue.use(Divider);
Vue.use(Image);
Vue.use(Calendar);
Vue.use(Backtop);
Vue.use(Avatar);
Vue.use(Empty);
Vue.use(Drawer);
Vue.use(Descriptions)
Vue.use(DescriptionsItem);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Progress);

Vue.use(Loading.directive);

Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;

export default i18n;