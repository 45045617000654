import Vue from "vue";
import { loginUser, logoutUser, getLoginUserDetail } from "@/api/index";
import { getToken, setToken, removeToken } from "@/utils/auth";
import { getOfficeCompany } from "@/api/customer";
import { loginByPhone, loginByWechat } from "@/api";
import { getChatDetail, getGroupChatDetail } from "@/api/news";
import { getEventDetail } from "@/api/event";
import { detectionIsDefaultNode } from "@/api/order";
import router from "@/router";
import SocketV2 from "../socketV2";
import { Notification } from "element-ui";
import store from '../index';
import { log } from "console";
const h = Vue.createElement;

const systemCode = [21001, 21002, 21003, 21004, 22001, 22002, 22003, 22004, 23001, 21011];

function iconComputed(row) {
  let content = row.contentJson;
  if(content) {
    if(content.type == 1) {
      return "icon-dingdan1";
    } else if(content.type == 2) {
      return "icon-yundan";
    } else if(content.type == 3) {
      return "icon-daka";
    } else {
      return "icon-shuaxin";
    }
  }
}

function systemName(row) {
  let content = row.contentJson;
  if(content) {
    if(content.type == 1) return `${window.vm.$isOrderTitle()}：${row.ci || row.pi}`
    if(content.type == 2) return `${window.vm.$t("home.waybill")}：${row.subject}`
    if(content.type == 3) return `${window.vm.$t("home.clock in")}：${row.ci || row.pi}`
    if(content.type == 4) return window.vm.$tc('order.Order node change', window.vm.$isOrderTitle())
  }
}

function behaviorComputed(row) {
  let content = row.contentJson;
  if(content) {
    if(content.type == 1) {
      if(content.status == 1) {
        return window.vm.$t("order.Created");
      } else {
        return window.vm.$t("order.Changed");
      }
    } else if(content.type == 2) {
      if(content.status == 1) {
        return window.vm.$t("order.Created");
      } else {
        return window.vm.$t("order.Changed");
      }
    } else if(content.type == 3) {
      return window.vm.$t("order.Clocked");
    } else  {
      return window.vm.$t("order.Changed");
    }
  }
}

function titleTypeComputed(row) {
  let content = row.contentJson;
  if(content) {
    if(content.type == 1) {
      return window.vm.$tc("order.OrderInfo", window.vm.$isOrderTitle());
    } else if(content.type == 2) {
      return window.vm.$t("order.shipInfo");
    } else if(content.type == 3) {
      return window.vm.$tc("order.orderHistory", window.vm.$isOrderTitle());
    } else {
      return window.vm.$tc("order.Order node", window.vm.$isOrderTitle());
    }
  }
}

const state = {
  importFileProgress: {
    show: false,
    value: "0"
  },
  businessUserId: "",
  manageCompany: "",
  companyId: "",
  companyCurrentType: "",
  personalization: null,
  language: "zh",
  userInfo: null,
  company: null,
  token: getToken(),
  ws: null,
  detail: null,
  chatMessage: null, // 来自他人的聊天消息通知
  chatRead: null, //消息已读状态
  callPhoneNotify: null,
  messgaeWithdrawUpdate: null,
  groupMemberDeleteUpdate: null,
  groupChatDeleteUpdate: null,
  createGroupChatUpdate: null,
  systemNoticeUpdate: null,
  eventUpdate: null,
  groupChatUpdate: null,
  commentUpdate: null,
  shipUpdate: null,
  todoUpdate: null,
  newOrderUpdate: null,
  applicationUpdate: null,
  orderTagUpdate: null,
  callPhoneUpdate: null,
  videoPhoneResponseUpdate: null,
  notifySurrenderOfferUpdate: null,
  getSurrenderOfferUpdate: null,
  eventTagUpdate: null,
  eventAllRead: null,
  commentAllRead: null,
  readComment: null,
  readEvent: null,
  readTodo: null,
  readApply: null,
  newsComment: null,
  localEventList: [],
  localCommentList: [],
  toDoList: null,
  applyMsg: null,
  currentMsg: "",
  chartOrders: {
    all: [],
    country: [],
    customer: [],
    product: []
  },
  applyType: null,
  PIorder: null,
  isUpdateUserCustomer: 0,
  socketOnline: false,
  messageDialog: {
    visible: false,
    isElastic: true,
    chatId: null,
    otherParty: null
  },
  routerViewExclude: ["Schedule", "Customer"],
  orderStatusOptions: [{
    id: 1,
    label: 'order.Producing',
  }, {
    id: 2,
    label: 'order.Local transportation'
  }, {
    id: 3,
    label: 'order.Loading port'
  }, {
    id: 4,
    label: 'order.Shipping'
  }, {
    id: 5,
    label: 'order.Discharge port'
  }, {
    id: 6,
    label: 'order.Destination delivery'
  }, {
    id: 7,
    label: 'order.Destination'
  }],
  menuList: [],
  buttonAuths: [],
  fileEventType: [],
  isDefaultNode: false,
  orderAlias: "",
  purchaseStatus: 1,
  platformLogo: "",
  shareDetailId: "",
  serverShopFlag: ""
};

const mutations = {
  SET_BUSINESSUSERID: (state, businessUserId) => {
    state.businessUserId = businessUserId;
  },
  SET_MANAGECOMPANY: (state, manageCompany) => {
    state.manageCompany = manageCompany;
  },
  SET_COMPANYID: (state, companyId) => {
    state.companyId = companyId;
  },
  SET_PERSONALIZATION: (state, data) => {
    state.personalization = data;
  },
  SET_LANGUAGE: (state, data) => {
    state.language = data;
  },
  SET_COMPANYCURRENTTYPE: (state, companyCurrentType) => {
    state.companyCurrentType = companyCurrentType;
  },
  SET_USERINFO(state, data) {
    state.userInfo = data;
  },
  SET_COMPANY(state, data) {
    state.company = data;
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_VIP_MENUlIST(state) {
    state.menuList = state.menuList.map(item => {
      if(item.menuCode != "Dashboard") {
        return item;
      }
      return {
        ...item,
        childTreeMenu: [
          ...item.childTreeMenu.filter(row => row.menuCode != 'Product' && row.menuCode != "extraData"),
          {
            menuCode: "extraData",
            menuPath: "/dashboard/5",
            menuName: window.vm.$t("Dashboard.ExtraData"),
            childTreeMenu: [],
          }
        ]
      }
    })
  },
  CLEAR_VIP_MENULSIT(state) {
    state.menuList = state.menuList.map(item => {
      if(item.menuCode != "Dashboard") {
        return item;
      }
      return {
        ...item,
        childTreeMenu: state.userInfo.authMenusTree.filter(row => row.menuCode == 'Dashboard')[0].childTreeMenu
      }
    })
  },
  START_SOCKET: (state, data) => {
    if (window?.ws && window.ws.status != "close") {
      return;
    }
    if(window.ws) {
      window.ws.close();
    }
    window.ws = new SocketV2({
      url: process.env.VUE_APP_WS_API,
      userId: data.openId,
      name: "", // name
      isHeart: true, // 是否心跳
      isReconnection: true, // 是否断开重连
      token: getToken(),
      received({message, time}) {
        // 监听服务器返回信息
        if(message.connectionType === 10006) {
          if(message.informType === 20003) {
            getChatDetail(message.content.chatId).then((data) => {
              state.chatMessage = {
                ...data,
                groupId: message.content.groupId,
                user: data.createUser
              };
            })
          }
          if(message.informType === 25001) {
            state.createGroupChatUpdate = {
              time: new Date().getTime(),
              groupId: message.content.groupId
            };
          }
          if(message.informType === 25002) {
            state.groupChatDeleteUpdate = new Date().getTime();
          }
          if(message.informType === 25004) {
            if(message.content.openId == state.businessUserId) {
              state.groupChatUpdate = {
                time: new Date().getTime(),
                groupId: message.content.groupId
              };
            }
          }
          if(message.informType === 25005) {
            if(message.content.openIds[0] == state.businessUserId) {
              state.groupMemberDeleteUpdate = new Date().getTime();
            }
          }
          if(message.informType === 20005) {
            state.messgaeWithdrawUpdate = message;
          }
          if(message.informType === 20006) {
            if(message.userId === state.businessUserId) {
              getChatDetail(message.content.chatId).then((data) => {
                state.chatMessage = {
                  ...data,
                  groupId: message.content.groupId,
                  user: message.content.user
                };
                if(state.messageDialog.visible) {
                  if(!message.content.groupId ? (state.messageDialog.otherParty.openId != message.content.user.openId) : (state.messageDialog.otherParty.openId != message.content.groupId)) {
                    Notification({
                      dangerouslyUseHTMLString: true,
                      message: `
                      <div class="chatNotificationBox">
                        <img src="${message.content.user.avatarFileUrl}" />
                        <div class="content">
                          <div class="title">
                            ${message.content.user.nickName} ${window.vm.$t('Send 1 new message')}
                          </div>
                          <div class="message">
                            ${data.messageType != 10 ? message.content.content : `[${window.vm.$t('Purchase.title')}]`}
                          </div>
                        </div>
                      </div>
                      `,
                      customClass: "ec-notification",
                      onClick: () => {
                        state.messageDialog = {
                          visible: false,
                          otherParty: null
                        };
                        setTimeout(() => {
                          if(message.content.groupId) {
                            getGroupChatDetail(message.content.groupId).then((data) => {
                              state.messageDialog = {
                                visible: true,
                                otherParty: {
                                  ...data,
                                  openId: data.groupId,
                                  nickName: data.groupName,
                                }
                              };
                            })
                          } else {
                            state.messageDialog = {
                              visible: true,
                              otherParty: message.content.user
                            };
                          }
                        })
                      }
                    });
                  }
                } else {
                  if(message.content.user.openId != state.businessUserId) {
                    Notification({
                      dangerouslyUseHTMLString: true,
                      message: `
                      <div class="chatNotificationBox">
                        <img src="${message.content.user.avatarFileUrl}" />
                        <div class="content">
                          <div class="title">
                            ${message.content.user.nickName} ${window.vm.$t('Send 1 new message')}
                          </div>
                          <div class="message">
                            ${data.messageType != 10 ? message.content.content : `[${window.vm.$t('Purchase.title')}]`}
                          </div>
                        </div>
                      </div>
                      `,
                      customClass: "ec-notification",
                      onClick: () => {
                        if(message.content.groupId) {
                          getGroupChatDetail(message.content.groupId).then((data) => {
                            state.messageDialog = {
                              visible: true,
                              otherParty: {
                                ...data,
                                openId: data.groupId,
                                nickName: data.groupName,
                              }
                            };
                          })
                        } else {
                          state.messageDialog = {
                            visible: true,
                            otherParty: message.content.user
                          };
                        }
                      }
                    });
                  }
                }
              });
            }
          }
          if(
            message.informType === 21004 || 
            message.informType === 21008 || 
            message.informType === 21007 || 
            message.informType === 21006
          ) {
            if(message.informType === 21007 || message.informType === 21006) {
              state.newOrderUpdate = message.content.orderId+new Date().getTime();
            }
            if(message.informType === 21008) {
              state.shipUpdate = message.content;
            }
            getEventDetail(message.content.eventId).then((data) => {
              state.eventUpdate = data;
              if(message.content.createPerson != state.businessUserId) {
                Notification({
                  dangerouslyUseHTMLString: true,
                  message: !data.keyEvent ? `
                  <div class="chatNotificationBox">
                    <img src="${data.createPerson.avatarFileUrl}" />
                    <div class="content">
                      <div class="title">
                        ${data.createPerson.nickName} ${window.vm.$t('order.Created an event')}
                      </div>
                      <div class="message">
                        ${data.subject}
                      </div>
                    </div>
                  </div>
                  ` : `
                  <div class="chatNotificationBox">
                    <i class="iconfont avatar ${iconComputed(data)}"></i>
                    <div class="content">
                      <div class="title">
                        ${systemName(data)}
                      </div>
                      <div class="message">
                        ${data.contentJson.type != 4 ? data.createPerson.nickName : this.$t('memberList.manage')} ${behaviorComputed(data)}${titleTypeComputed(data)}
                      </div>
                    </div>
                  </div>
                  `,
                  customClass: "ec-notification",
                  onClick: () => {
                    router.push({
                      path: `/order/orderDetails`,
                      query: {
                        orderId: message.content.orderId,
                        eventId: message.content.eventId
                      }
                    });
                  }
                });
              }
            })
          }
          if(message.informType === 21012) {
            state.eventUpdate = null;
            if(message.content.createPerson != state.businessUserId) {
              window.vm.$notify.info({
                title: window.vm.$t('Tips'),
                message: window.vm.$t('order.have event deleted'),
                customClass: "ec-notification",
                onClick: () => {
                  router.push({
                    path: `/order/orderDetails`,
                    query: {
                      orderId: message.content.orderId
                    }
                  });
                }
              });
            }
          }
          if(message.informType === 21005) {
            getEventDetail(message.content.eventId).then((data) => {
              state.commentUpdate = data;
              if(message.content.createPerson != state.businessUserId) {
                let commentItem = data.commentList.find(item => item.commentId == message.content.commentId);
                Notification({
                  dangerouslyUseHTMLString: true,
                  message: `
                  <div class="chatNotificationBox">
                    <img src="${commentItem.createPerson.avatarFileUrl}" />
                    <div class="content">
                      <div class="title">
                        ${commentItem.createPerson.nickName} ${window.vm.$t('order.Created an comment')}
                      </div>
                      <div class="message">
                        ${commentItem.createTime}
                      </div>
                    </div>
                  </div>
                  `,
                  customClass: "ec-notification",
                  onClick: () => {
                    router.push({
                      path: `/order/orderDetails`,
                      query: {
                        orderId: message.content.orderId,
                        commentId: message.content.commentId
                      }
                    });
                  }
                });
              }
            });
          }
          if(message.informType === 21011) {
            state.newOrderUpdate = new Date().getTime();
            if(message.content.createPerson != state.businessUserId) {
              window.vm.$notify.info({
                title: window.vm.$t('Tips'),
                message: `${message.content.userName}${window.vm.$t('order.Created')}${message.content.importNum}${window.vm.$t('order.numberOrder')}`,
                customClass: "ec-notification",
              });
            }
          }
          if(message.informType === 21009) {
            state.todoUpdate = message.content;
            if(message.content.createPerson != state.businessUserId) {
              window.vm.$notify.info({
                title: window.vm.$t('Tips'),
                message: window.vm.$t('order.You have a new todo message')+message.content.title,
                customClass: "ec-notification",
              });
            }
          }
          if(message.informType === 21010) {
            state.todoUpdate = message.content;
            if(message.content.createPerson != state.businessUserId) {
              window.vm.$notify.info({
                title: window.vm.$t('Tips'),
                message: window.vm.$tc('order.todo updated', message.content.title),
                customClass: "ec-notification",
              });
            }
          }
          if(message.informType === 20004) {
            state.chatRead = new Date().getTime();
          }
          // 成员加入公司
          if(message.informType === 21001) {
            state.applicationUpdate = new Date().getTime();
            window.vm.$notify.info({
              title: window.vm.$t('Tips'),
              message: window.vm.$t('You have new application information'),
              customClass: "ec-notification",
            });
          }
          // 成员加入公司审批
          if(message.informType === 21002) {
            state.applicationUpdate = new Date().getTime();
            window.vm.$notify.info({
              title: window.vm.$t('Tips'),
              message: window.vm.$t('Your application has been approved'),
              customClass: "ec-notification",
            });
          }
          // 成员加入公司拒绝
          if(message.informType === 21003) {
            state.applicationUpdate = new Date().getTime();
            window.vm.$notify.info({
              title: window.vm.$t('Tips'),
              message: window.vm.$t('Your application has been rejected'),
              customClass: "ec-notification",
            });
          }
          // 公司客户邀请
          if(message.informType === 22001) {
            state.applicationUpdate = new Date().getTime();
            window.vm.$notify.info({
              title: window.vm.$t('Tips'),
              message: window.vm.$t('You have a new customer invitation notification'),
              customClass: "ec-notification",
            });
          }
          // 公司客户同意建立关系
          if(message.informType === 22002) {
            state.readApply = new Date().getTime();
            window.vm.$notify.info({
              title: window.vm.$t('Tips'),
              message: window.vm.$t('Your application has been approved'),
              customClass: "ec-notification",
            });
          }
          // 公司客户拒绝建立关系
          if(message.informType === 22003) {
            state.applicationUpdate = new Date().getTime();
            window.vm.$notify.info({
              title: window.vm.$t('Tips'),
              message: window.vm.$t('Your application has been rejected'),
              customClass: "ec-notification",
            });
          }
          // 修改订单Tag
          if(message.informType === 24001) {
            state.orderTagUpdate = new Date().getTime();
          }
          // 修改订单Tag
          if(message.informType === 24002) {
            getEventDetail(message.content.eventId).then((data) => {
              state.eventUpdate = data;
            });
            if(message.content.createPerson != state.businessUserId) {
              window.vm.$notify.info({
                title: window.vm.$t('Tips'),
                message: window.vm.$t('order.You have a new event tag message'),
                customClass: "ec-notification",
                onClick: () => {
                  router.push({
                    path: `/order/orderDetails`,
                    query: {
                      orderId: message.content.orderId,
                      eventId: message.content.eventId
                    }
                  });
                }
              });
            }
          }
          if(systemCode.includes(message.informType)) {
            state.systemNoticeUpdate = new Date().getTime();
          }
          if(message.informType == 30001) {
            state.callPhoneUpdate = {
              openId: message.content.formUserId,
              time: new Date().getTime()
            };
            state.callPhoneNotify = window.vm.$notify({
              dangerouslyUseHTMLString: true,
              duration: 0,
              message: `
              <div class="chatNotificationBox">
                <img src="https://echola-test.oss-cn-hangzhou.aliyuncs.com/file/companyProfile/cropper_0cf4f29e.jpg" />
                <div class="content">
                  <div class="title">
                    acaaas
                  </div>
                  <div class="message">
                    ${window.vm.$t('I made a voice call to you')}
                  </div>
                </div>
                <div class="agreeBtns">
                  <div class="refuse" id="videoPhoneRefuse">
                    <i class="iconfont icon-guaduan"></i>
                  </div>
                  <div class="agree" id="videoPhoneAgree">
                    <i class="iconfont icon-jieting"></i>
                  </div>
                </div>
              </div>
              `,
              customClass: "callPhoneNotification",
            });
          }
          if(message.informType == 30002) {
            state.videoPhoneResponseUpdate = {
              openId: message.content.formUserId,
              time: new Date().getTime(),
              agree: message.content.agree
            };
          }
          if(message.informType == 30003) {
            state.notifySurrenderOfferUpdate = {
              openId: message.content.formUserId,
              time: new Date().getTime()
            };
          }
          if(message.informType == 30004) {
            state.getSurrenderOfferUpdate = {
              openId: message.content.formUserId,
              messageSdp: message.content.message, 
              time: new Date().getTime()
            };
          }
        }
      },
      onClose: () => {
        state.socketOnline = false;
      },
      onOnLine: () => {
        state.socketOnline = true;
        window?.ws?.sendMsg({
          connectionType: 10001,
        })
      },
    });
    window.ws.connect();
  },
  SET_CURRENT_MSG: (state, data) => {
    state.currentMsg = data;
  },
  SET_CHATREAD(state, data) {
    state.chatRead = data;
  },
  SET_AllREADCOMMENT(state, data) {
    state.commentAllRead = data;
  },
  SET_ALLREADEVENT(state, data) {
    state.eventAllRead = data;
  }, 
  SET_READCOMMENT(state, data) {
    state.readComment = data;
  },
  SET_READEVENT(state, data) {
    state.readEvent = data;
  }, 
  SET_READTODO(state, data) {
    state.readTodo = data;
  },
  SET_READAPPLY(state, data) {
    state.readApply = data;
  },
  SET_DASHBOARDORDER(state, data) {
    state.chartOrders = data;
  },
  SET_MESSAGEDIALOGOPEN(state, data) {
    state.messageDialog = data;
  },
  SET_ROUTEREXCLUDE(state, data) {
    state.routerViewExclude = data;
  },
  SET_IMPORTPROGRESS(state, data) {
    state.importFileProgress = data;
  },
  SET_ROUTERDATA(state, data) {
    data.forEach(item => {
      if(item.menuCode == 'index') {
        item.icon = "icon-zhuye";
      }
      if(item.menuCode == 'customerList') {
        item.icon = "icon-gongsi";
      }
      if(item.menuCode == 'order') {
        item.icon = "icon-dingdan";
      }
      if(item.menuCode == 'Tracking') {
        item.icon = "icon-genzong";
      }
      if(item.menuCode == 'files') {
        item.icon = "icon-wenjian";
      }
      if(item.menuCode == 'Dashboard') {
        item.icon = "icon-shujukanban";
      }
    })
    state.menuList = data.map(item => {
      if(item.childTreeMenu.length) {
        return {
          ...item,
          isOpen: router.currentRoute.name == item.menuCode
        }
      }
      return item;
    });
    setTimeout(() => {
      if(store.state.layout.isVipMode == "1") {
        this.dispatch("user/setVipMenuList")
      } else {
        this.dispatch("user/clearVipMenuList")
      }
    }, 10);
  },
  SET_BUTTONAUTHS(state, data) {
    state.buttonAuths = data.map(item => item.menuCode);
  },
  SET_FILETYPE: (state, fileEventType) => {
    state.fileEventType = fileEventType;
  },
  SET_ISDEFAULTNODE(state, data) {
    state.isDefaultNode = data;
  },
  SET_ORDERALIAS(state, data) {
    state.orderAlias = data;
  },
  SET_PURCHASESTATUS(state, data) {
    state.purchaseStatus = data;
  },
  SET_PLATFORMLOGO(state, data) {
    state.platformLogo = data || 'https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/logo.png';
  },
  SET_SHAREDETAILID(state, data) {
    state.shareDetailId = data;
  },
  SET_SERVERSHOPFLAG(state, data) {
    state.serverShopFlag = data;
  }
};

const actions = {
  setVipMenuList({commit}) {
    commit("SET_VIP_MENUlIST");
  },
  clearVipMenuList({commit}) {
    commit("CLEAR_VIP_MENULSIT");
  },
  setToken({commit}, data) {
    commit("SET_TOKEN", data);
  },
  login({ dispatch, commit }, userInfo) {
    return new Promise((resolve, reject) => {
      loginUser(userInfo)
        .then((response) => {
          commit("SET_TOKEN", response.accessToken);
          setToken(response.accessToken);
          dispatch("getInfo").then((response) => {
            if(response.nickName && response.position && response.country && response.timeZone && response.avatarUrl) {
              router.push("/index");
            }
          });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  codeLogin({ dispatch, commit }, userInfo) {
    return new Promise((resolve, reject) => {
      loginByPhone(userInfo)
        .then((response) => {
          commit("SET_TOKEN", response.accessToken);
          setToken(response.accessToken);
          dispatch("getInfo").then((response) => {
            if(response.nickName && response.position && response.country && response.timeZone && response.avatarUrl) {
              router.push("/index");
            }
          });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  wxLogin({ dispatch, commit }, userInfo) {
    return new Promise((resolve, reject) => {
      loginByWechat(userInfo)
        .then((response) => {
          if(response.status == 11011) {
            this.$message.error(this.$t('login.loginWechatError'))
            return;
          }
          commit("SET_TOKEN", response.accessToken);
          setToken(response.accessToken);
          dispatch("getInfo").then((response) => {
            if(response.nickName && response.position && response.country && response.timeZone && response.avatarUrl) {
              router.push("/index");
            }
          });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // user logout
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      if(window.ws) {
        window.ws.close();
      }
      logoutUser()
        .then(() => {
          commit("SET_TOKEN", "");
          removeToken();
          localStorage.clear();
          sessionStorage.clear();
          commit("SET_USERINFO", null);
          commit('SET_COMPANY', null);
          setTimeout(() => {
            router.replace("/login");
          }, 200);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      commit("SET_TOKEN", "");
      removeToken();
      resolve();
    });
  },

  setPersonalization({commit}, data) {
    commit("SET_PERSONALIZATION", data);
  },

  setLanguage({commit}, data) {
    commit("SET_LANGUAGE", data);
  },

  //获取用户信息。名字与头像
  getInfo({ dispatch, commit }, type) {
    return new Promise((resolve, reject) => {
      getLoginUserDetail()
        .then((response) => {
          if (!response) return;
          commit("SET_USERINFO", response);
          commit("START_SOCKET", response);
          commit("SET_MANAGECOMPANY", response.authCompany?.admin);
          commit("SET_BUSINESSUSERID", response.openId);
          commit("SET_COMPANYCURRENTTYPE", response.authCompany?.companyType);
          commit("SET_PERSONALIZATION", response.personalization);
          commit("SET_ROUTERDATA", response.authMenusTree);
          commit("SET_BUTTONAUTHS", response.buttonsTree);
          commit("SET_FILETYPE", response.fileConfigs);
          commit("SET_PURCHASESTATUS", response.authCompany?.purchaseStatus);
          commit("SET_SERVERSHOPFLAG", response.authCompany?.goodsFlag);
          store.dispatch("layout/setVipMode", String(response.personalization?.pageStyle || '0'));
          if(router.app._route.path != '/profile') {
            if(!(response.nickName && response.position && response.country && response.timeZone && response.avatarUrl)) {
              router.push(`/profile?name=yourself&businessUserId=${response.openId}`);
            }
          }
          if(response.personalization) {
            if(response.personalization.language) {
              commit("SET_LANGUAGE", response.personalization.language);
              console.log(response.personalization.language);
              localStorage.setItem("lang", response.personalization.language);
              window.vm.$i18n.locale = response.personalization.language;
            }
            if(response.personalization.dashboardOrder) {
              if(response.personalization.dashboardOrder.split("|").length) {
                commit("SET_DASHBOARDORDER", {
                  all: response.personalization.dashboardOrder.split("|")[0].split(","),
                  country: response.personalization.dashboardOrder.split("|")[1].split(","),
                  customer: response.personalization.dashboardOrder.split("|")[2].split(","),
                  product: response.personalization.dashboardOrder.split("|")[3].split(",")
                });
              }
            }
          }
          dispatch("getCompany", response.authCompany?.companyId);
          detectionIsDefaultNode().then((data) => {
            commit("SET_ISDEFAULTNODE", data);
          });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  //获取自己公司信息，名称与头像
  getCompany({ commit }, companyId) {
    return new Promise((resolve, reject) => {
      if(companyId) {
        getOfficeCompany(companyId)
          .then((response) => {
            if (!response) return;
            commit('SET_COMPANY', {
              ...response,
              companyType: String(response.companyType)
            });
            commit("SET_COMPANYID", response.companyId);
            commit("SET_ORDERALIAS", Number(response.orderAlias));
            commit("SET_PLATFORMLOGO", response.platformLogoUrl);
            commit("SET_SHAREDETAILID", response.shareDetailId);
            resolve("");
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  },
  setUpdateMsg({ commit }, data) {
    commit("SET_CURRENT_MSG", data);
  },
  setChatRead({commit}, data) {
    commit("SET_CHATREAD", data)
  },
  setReadStatus({commit}, type) {
    commit("SET_"+type, new Date().getTime());
  },
  setMessageDialogOpen({ commit }, data) {
    commit("SET_MESSAGEDIALOGOPEN", data);
  },
  setRouterExclude({ commit }, data) {
    commit("SET_ROUTEREXCLUDE", data);
  },
  setImportProgress({ commit }, data) {
    commit("SET_IMPORTPROGRESS", data);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
