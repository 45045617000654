<script>
export default {
    name: "teleport",
    props: {
        to: {
            type: String,
            default: "body",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    inject: ["parent"],
    watch: {
        disabled() {
            if (this.disabled) {
                document.querySelector(this.to).appendChild(this.$el);
            }
        },
    },
    mounted() {
        if (this.disabled) {
            document.querySelector(this.to).appendChild(this.$el);
        }
    },
    render(h) {
        return h("div", this.$slots.default);
    },
};
</script>
