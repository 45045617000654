module.exports = {
    "Please select": "Please select",
    "CI": "CI",
    "Input CI": "Input CI",
    "Company": "Company",
    "Input Company Name": "Input Company Name",
    "Search": "Search",
    "Clear All Filter": "Clear All Filter",
    "Order List": "Order List",
    "Hide": "Hide",
    "Stow": "Hide",
    "Open": "Open",
    "Input CI": "Input CI",
    "Select status": "Select status",
    "Start from": "Start from",
    "End to": "End to",
    "Producing": "PRODUCING",
    "Local transportation": "Local transportation",
    "Loading port": "Loading port",
    "Shipping": "Shipping",
    "Discharge port": "Discharge port",
    "Destination delivery": "Destination delivery",
    "Destination": "Destination",
    "Amount": "Amount",
    "Create Time": "Create Time",
    "Status": "Status",
    "Action": "Action",
    "Order No": "Order No",
    "Create Time": "Create Time",
    "Supplier": "Supplier",
    "Purchaser": "Purchaser",
    "Forwarder": "Forwarder",
    "Ship info": "Ship info",
    "Weight/Dimension": "Weight/Dimension",
    "Port of Loading": "Port of Loading",
    "Quantity": "Quantity",
    "Port of discharge": "Port of discharge",
    'PRODUCING': 'Producing',
    'DELIVERY_LOCAL': 'Local transportation',
    'SEND_PORT': 'Loading port',
    'DELIVERY_TRANS': 'Shipping',
    'RECEIVE_PORT': 'Discharge port',
    'DELIVERY_DEST': 'Destination delivery',
    'DESTINATION': 'Destination',
};