import request from "@/utils/request";
//获取所有未完成待办项集合
export function getUnfinishedTodoItemList(data) {
    return request({
        url: "/businessInit/unfinishedTodoItemList",
        method: "get",
        data: data,
    });
}

// 获取船舶历史轨迹
export function getShipHistoryTrack(data) {
    return request({
        url: "/ship/getShipTrack",
        method: "get",
        params: data,
    });
}