<template>
  <div>
    <el-dialog
      :title="$t('Address Selection')"
      :visible.sync="show"
      width="668px"
      append-to-body
      :before-close="close"
      @close="close"
      v-move-outside>
      <div class="mapBox">
        <div class="mapDom" :id="`l-map${newDate}`" v-loading="mapLoading"></div>
        <div class="r-result" :id="`r-result${newDate}`">
          <el-input type="text" suffix-icon="el-icon-search" :placeholder="$t('order.Search')" v-model="searchMap" :id="`suggestId${newDate}`" />
        </div>
        <div :id="`searchResultPanel${newDate}`" style="border:1px solid #C0C0C0;width:150px;height:auto; display:none;"></div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button 
          class="cancel_button" 
          size="small" 
          @click="show = false">
          {{ $t('home.Cancel') }}
        </el-button>
        <el-button type="primary" size="small" @click="confirm">
          {{ $t('home.Confirm') }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="$t('Change address')"
      :visible.sync="changeShow"
      width="358px"
      append-to-body
      :before-close="changeClose"
      @close="changeClose"
      v-move-outside>
      <el-form 
        ref="companyFormRef" 
        :rules="rules" 
        :model="formNew" 
        class="companyForm">
        <el-form-item>
          <div class="title">{{ $t('Original address') }}</div>
          <el-input v-model="formOld.address" disabled autocomplete="off" />
        </el-form-item>
        <el-form-item prop="address">
          <div class="title">{{ $t('New address') }}</div>
          <el-input v-model="formNew.address" :placeholder="$t('home.Please enter')+$t('CompanyEdit.Address')" autocomplete="off" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button 
          class="cancel_button" 
          size="small" 
          @click="changeClose">
          {{ $t('home.Cancel') }}
        </el-button>
        <el-button type="primary" size="small" @click="changeConfirm">
          {{ $t('home.Confirm') }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newDate: new Date().getTime(),
      show: false,
      changeShow: false,
      map: null,
      searchMap: "",
      myValue: null,
      mapLoading: false,
      formOld: {
        address: "",
        position: ""
      },
      formNew: {
        address: "",
        addressPosition: []
      },
    }
  },
  computed: {
    rules() {
      return {
        address: [
          {
            required: true,
            message: this.$t('home.Please enter the detailed address'),
          },
        ]
      }
    }
  },
  methods: {
    open(obj) {
      this.newDate = new Date().getTime();
      this.show = true;
      this.formOld = obj;
      this.formNew.address = obj.address;
      this.formNew.addressPosition = obj.position ? obj.position.split(",") : [];
      this.$nextTick(() => {
        this.map = new BMap.Map(`l-map${this.newDate}`);
        this.mapInit();
      });
    },
    confirm() {
      this.changeShow = true;
    },
    changeConfirm() {
      this.$refs.companyFormRef.validate(async (valid) => {
        if (valid) {
          this.$emit("updateAddress", this.formNew);
          this.changeClose();
          this.close();
        }
      });
    },
    mapInit() {
      if(!this.formNew.addressPosition.length) {
        this.mapLoading = true;
        var geolocation = new BMap.Geolocation();
        geolocation.getCurrentPosition((r) => {
          try {
            let point = new BMap.Point(this.formNew.addressPosition[0] || r.longitude, this.formNew.addressPosition[1] || r.latitude);
            this.map.centerAndZoom(point, 12);
            this.map.addOverlay(new BMap.Marker(point));
            let address = r.address;
            this.formNew.address = address.province+address.city+address.district+address.street+address.street_number;
            this.formNew.addressPosition = [r.longitude, r.latitude];
          } catch {
            this.$message.error(this.$t('Positioning failed, please manually select'));
            this.map.centerAndZoom("杭州", 12);
            this.formNew.address = "";
            this.formNew.addressPosition = [];
          }
          this.mapLoading = false;
        });
      } else {
        let point = new BMap.Point(this.formNew.addressPosition[0] || r.longitude, this.formNew.addressPosition[1] || r.latitude);
        this.map.centerAndZoom(point, 12);
        this.map.addOverlay(new BMap.Marker(point));
      }
      let ac = new BMap.Autocomplete({
        input: `suggestId${this.newDate}`,
        location: this.map
      });
      this.map.enableScrollWheelZoom(true);
      ac.addEventListener("onhighlight", (e) => {  //鼠标放在下拉列表上的事件
        let str = "";
        let _value = e.fromitem.value;
        let value = "";
        if (e.fromitem.index > -1) {
          value = _value.province +  _value.city +  _value.district +  _value.street +  _value.business;
        }    
        str = "FromItem<br />index = " + e.fromitem.index + "<br />value = " + value;
        value = "";
        if (e.toitem.index > -1) {
          _value = e.toitem.value;
          value = _value.province +  _value.city +  _value.district +  _value.street +  _value.business;
        }    
        str += "<br />ToItem<br />index = " + e.toitem.index + "<br />value = " + value;
        this.G(`searchResultPanel${this.newDate}`).innerHTML = str;
      });
      ac.addEventListener("onconfirm", (e) => {    //鼠标点击下拉列表后的事件
        let _value = e.item.value;
        this.myValue = _value.province +  _value.city +  _value.district +  _value.street +  _value.business;
        this.G(`searchResultPanel${this.newDate}`).innerHTML ="onconfirm<br />index = " + e.item.index + "<br />myValue = " + this.myValue;
        this.setPlace();
      });
      this.map.addEventListener("click", ({point}) => {
        this.map.clearOverlays();
        // 使用geocoder获取地址信息
        var geocoder = new BMap.Geocoder({});
        geocoder.getLocation(new BMap.Point(point.lng, point.lat), (result) => {
          if (result) {
            this.map.addOverlay(new BMap.Marker(point));
            this.formNew.address = result.content.address+result.content.poi_desc;
            this.formNew.addressPosition = [result.point.lng, result.point.lat]
          }
        });
      });
    },
    // 显示位置信息
    showPosition(position){
      var lat = position.coords.latitude;
      var lng = position.coords.longitude;
      this.map.setCenter([lat, lng]);
      var marker = new BMap.Marker({
        position: [lat, lng],
        title: "您在这里"
      });
      this.map.addOverlay(marker);
    },
    // 错误处理函数
    showError(error){
      switch(error.code){
        case error.PERMISSION_DENIED:
          alert("用户拒绝对获取地理位置的请求")
          break;
        case error.POSITION_UNAVAILABLE:
          alert("位置信息是不可用的")
          break;
        case error.TIMEOUT:
          alert("请求用户地理位置超时")
          break;
        case error.UNKNOWN_ERROR:
          alert("发生未知错误")
          break;
      }
    },
    G(id) {
      return document.getElementById(id);
    },
    setPlace(){
      this.map.clearOverlays();    //清除地图上所有覆盖物
      const myFun = () => {
        var pp = local.getResults().getPoi(0).point;    //获取第一个智能搜索的结果
        this.map.centerAndZoom(pp, 12);
        this.map.addOverlay(new BMap.Marker(pp));    //添加标注
        this.formNew.address = this.myValue;
        this.formNew.addressPosition = [pp.lng, pp.lat]
      }
      const local = new BMap.LocalSearch(this.map, { //智能搜索
        onSearchComplete: myFun
      });
      local.search(this.myValue);
    },
    close() {
      this.show = false;
    },
    changeClose() {
      this.changeShow = false;
    }
  }
}
</script>

<style lang="less" scoped>
.mapBox {
  width: 100%;
  height: 395px;
  position: relative;
  .mapDom {
    width: 100%;
    height: 100%;
  }
  .r-result {
    position: absolute;
    top: 12px;
    left: 12px;
    z-index: 6;
  }
}
</style>