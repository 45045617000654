<template>
  <svg class="icon" aria-hidden="true">
    <use :xlink:href="'#'+fontClass"></use>
  </svg>
</template>

<script>
// 创建一个映射表，能更方便地设置Icon样式，
// 以后如果需要更换图标，也只需要更换映射表里的属性值。
const classMap = {
  shujukanban1: "icon-shujukanban1"
};
export default {
  name: "Icon",
  props: {
    type: {
      type: String,
      require: true
    }
  },
  computed: {
    fontClass() {
      return classMap[this.type];
    }
  }
}
</script>

<style type="text/css">
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>