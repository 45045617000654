import { getCountryData, getDict } from "@/api/common";
import { getAllNodeList } from "@/api/order";
import { getFileAllConfigList } from "@/api/files";

const state = {
  timezone: [],
  country: [],
  minuteCountry: [],
  companyType: [],
  currencyType: [],
  orderProgress: [],
  currencys: [],
  fileType: [],
  mysteelRawMaterial: [],
  exchangeRate: [],
  shipType: [],
  memberType: [],
  allNodeList: [],
  orderAliasOption: [],
  transportMode: [],
  payMentData: [],
  payMentMethods: []
};

const mutations = {
  SET_TIMEZONE: (state, timezone) => {
    state.timezone = timezone;
  },
  SET_COUNTRY: (state, country) => {
    state.country = country;
  },
  SET_MINUTECOUNTRY: (state, country) => {
    state.minuteCountry = country;
  },
  SET_COMPANYTYPE: (state, companyType) => {
    state.companyType = companyType;
  },
  SET_CURRENCY: (state, currencys) => {
    state.currencys = currencys;
  },
  SET_CURRENCYTYPE: (state, currencyType) => {
    state.currencyType = currencyType;
  },
  SET_ORDERPROGRESS: (state, orderProgress) => {
    state.orderProgress = orderProgress;
  },
  SET_FILETYPE: (state, fileType) => {
    state.fileType = fileType;
  },
  SET_RAW_MATERIAL: (state, mysteelRawMaterial) => {
    state.mysteelRawMaterial = mysteelRawMaterial;
  },
  SET_EXCHANGERATE: (state, exchangeRate) => {
    state.exchangeRate = exchangeRate;
  },
  SET_SHIPTYPE: (state, shipType) => {
    state.shipType = shipType;
  },
  SET_MEMBERTYPE(state, data) {
    state.memberType = data;
  },
  SET_ALLNODELIST(state, data) {
    state.allNodeList = data;
  },
  SET_ORDERALIAS(state, data) {
    state.orderAliasOption = data;
  },
  SET_MODE_TRANSPORT(state, data) {
    state.transportMode = data;
  },
  SET_PAYMENT(state, data) {
    state.payMentData = data;
  },
  SET_PAYMENTMETHODS(state, data) {
    state.payMentMethods = data;
  }
};

const actions = {
  getDictData({commit}) {
    return new Promise((resolve, reject) => {
      getDict()
        .then((response) => {
          commit("SET_TIMEZONE", response['TIME_ZONE']);
          commit("SET_COMPANYTYPE", response['COMPANY_TYPE']);
          commit("SET_CURRENCYTYPE", response['CURRENCY_TYPE']);
          commit("SET_ORDERPROGRESS", response['ORDER_PROGRESS']);
          // commit("SET_FILETYPE", response['FILE_TYPE']);
          commit("SET_CURRENCY", response['CURRENCY']);
          commit("SET_RAW_MATERIAL", response['MYSTEEL_RAW_MATERIAL']);
          commit("SET_EXCHANGERATE", response['EXCHANGE_RATE']);
          commit("SET_SHIPTYPE", response['SHIP_TYPE']);
          commit("SET_COUNTRY", response['COUNTRY']);
          commit("SET_MEMBERTYPE", response['MEMBER_TYPE']);
          commit("SET_ORDERALIAS", response['ORDER_ALIAS']);
          commit("SET_MODE_TRANSPORT", response['TRANSPORT_MODE']);
          commit("SET_PAYMENT", response['TRANSACTION_TERMS']);
          commit("SET_PAYMENTMETHODS", response['PAYMENT_METHOD']);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getFileAllConfigList({commit}) {
    return new Promise((resolve, reject) => {
      getFileAllConfigList({
        currentPage: 1,
        pageSize: 10000
      }).then((data) => {
        commit("SET_FILETYPE", data.list);
        resolve(data);
      }).catch((error) => {
        reject(error);
      })
    })
  },
  getCountry({ commit }) {
    return new Promise((resolve, reject) => {
      getCountryData({
        currentPage: 1,
        pageSize: 20000
      })
        .then((response) => {
          const country = response.list.map(item => ({
            ...item,
            countryName: window.vm.$i18n.locale === 'zh' ? item.countryCn : item.countryEn
          }));
          commit("SET_MINUTECOUNTRY", country);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getAllNodeList({ commit }) {
    return new Promise((resolve, reject) => {
      getAllNodeList().then((response) => {
        commit("SET_ALLNODELIST", response);
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
