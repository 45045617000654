import { setPersonalization } from "@/api/common";
import store from '../index';
import { log } from "console";

const state = {
  isVipMode: '0',
  layoutModel: 'light',
  layoutLightColors: ['#076f49', '#122545', '#ffffff', '#F7F9FC', '#0E4347', '#ffffff', '#48AC42'],
  layoutDarkColors: ['#d7e1ee', '#F7F9FC', '#757D8A', '#F7F9FC', '#d7e1ee', '#218EF3', '#218EF3'],
  chartPieColors: ['#2A634B', '#527F6C', '#7A9C8E', '#A2B9AF', '#CAD6D1', '#D0E4CF', '#AED6AB', '#8CC889', '#6ABA65', '#48AC42'],
  chartLineBarColors: ['#2A634B', '#C84154', '#E1B541', '#2D6AB2', '#A83B89', '#BBC942', '#DD7437', '#FDF250', '#459BD7', '#D44189'],
  chartVipColors: ['#004C97', '#5B90F7', '#8DC793', '#F8DF74', '#ED777A', '#7CD6F4', '#57BD94', '#EF9154', '#844CDC', '#5B90F7'],
  orderStatusColors: ['#5B517D', '#076F49', '#076F49', '#076F49', '#076F49', '#076F49', '#1F4E9D']
};

const mutations = {
  SET_VIP_COLORS(state, data) {
    state.chartPieColors = data;
    state.chartLineBarColors = data;
  },
  CLEAR_CHAT_COLORS(state) {
    state.chartPieColors = ['#2A634B', '#527F6C', '#7A9C8E', '#A2B9AF', '#CAD6D1', '#D0E4CF', '#AED6AB', '#8CC889', '#6ABA65', '#48AC42'];
    state.chartLineBarColors = ['#2A634B', '#C84154', '#E1B541', '#2D6AB2', '#A83B89', '#BBC942', '#DD7437', '#FDF250', '#459BD7', '#D44189'];
  },
  SET_VIP_MODE(state, data) {
    state.isVipMode = data;
  }
};

const actions = {
  setVipColor({ commit }, data) {
    commit("SET_VIP_COLORS", data);
  },
  clearChatColors({ commit }) {
    commit("CLEAR_CHAT_COLORS");
  },
  setVipMode({ commit, dispatch }, data) {
    commit("SET_VIP_MODE", data);
    if(data == '0') {
      dispatch("clearChatColors");
    } else {
      dispatch("setVipColor", state.chartVipColors);
    }
    if(store.state.user.personalization) {
      setPersonalization({
        ...store.state.user.personalization,
        language: localStorage.getItem("lang"),
        pageStyle: Number(data)
      });
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
