<template>
  <portal to="select-group-portal">
    <el-drawer
      :title="$t('home.Select group')"
      :visible="customerCompanyId ? true : false"
      @close="close()"
      size="836px"
      v-move-outside
    >
      <div class="demo-drawer__content">
        <div class="containerLeft" style="width: 214px;">
          <div class="scheduleTitle">
            <el-button
              icon="el-icon-plus"
              size="small"
              @click="createGourpEvent"
            >{{ $t("New Group") }}</el-button>
          </div>
          <div class="groupList" v-if="groupArray.length">
            <div 
              class="item" 
              :class="groupActiveId == item.groupId ? 'active' : ''"
              v-for="(item, index) in groupArray" 
              :key="item.groupId"
              @click="groupActiveId = item.groupId">
              <div class="avatarGather">
                <el-image 
                  v-for="i in item.groupUsers" 
                  :key="i.openId" 
                  :src="i.avatarFileUrl"
                  fit="cover">
                </el-image>
              </div>
              <div class="content">
                <div class="name">{{ item.groupName }}</div>
                <div class="totalMember">{{ $tc('order.members', (item.groupUsers && item.groupUsers.length) || 0) }}</div>
              </div>
            </div>
          </div>
          <el-empty
            v-else
            :image="$emptyImage"
            :image-size="90"
            :description="$t('order.No message')"
          ></el-empty>
        </div>
        <div class="containerRight">
          <div class="parentBox" v-if="groupItemForm">
            <div class="name">
              <span v-if="!isEdit">{{ groupItemForm.groupName }}</span>
              <el-input 
                v-model="groupName" 
                v-else
                size="mini"
                :placeholder="$t('home.Please enter a group name')" 
                @keyup.enter.native="editSubmit"
              />
              <i 
                class="iconfont icon-Vector"  
                @click="editFun"
              ></i>
            </div>
            <i 
              class="iconfont icon-shanchu" 
              @click="deleteGroupFun"
            ></i>
          </div>
          <div class="selectUserBox">
            <div class="selectTable">
              <div class="company" v-if="companyDetail">
                <div class="left">
                  <img :src="companyDetail.companyLogoUrl" />
                  <div class="content">
                    <span>{{ companyDetail.aliasName }}</span> | {{ $t('Dashboard.Member').split("数")[0] }}
                  </div>
                </div>
                <el-checkbox v-model="companyAllSelect" @change="companyAllSelectChange" />
              </div>
              <el-input 
                v-model="companySearch" 
                size="small"
                :placeholder="$t('Files.Search')"
                suffix-icon="el-icon-search"
              ></el-input>
              <div class="subject_table" v-if="companyId">
                <div class="item" v-for="(item, index) in filterOptions_company" :key="index">
                  <member
                    :info="item"
                    :size="48"
                    :placement="'left'"
                  />
                  <div class="content">
                    <div class="nameBox">
                      <span class="name">{{ item.nickName }}</span>
                      <span class="manageTag" v-if="!item.type">{{ $t('memberList.manage') }}</span>
                    </div>
                    <div class="duties">
                      {{ item.position }}
                    </div>
                  </div>
                  <el-checkbox v-model="item.check" :disabled="businessUserId == item.openId || item.type == 0"></el-checkbox>
                </div>
              </div>
            </div>
            <div class="line"></div>
            <div class="selectTable">
              <div class="company" v-if="customerDetail">
                <div class="left">
                  <img :src="customerDetail.companyLogoUrl" />
                  <div class="content">
                    <span>{{ customerDetail.aliasName }}</span> | {{ $t('home.customerTitle') }}
                  </div>
                </div>
                <el-checkbox v-model="customerAllSelect" @change="customerAllSelectChange" />
              </div>
              <el-input 
                v-model="customerSearch" 
                size="small"
                :placeholder="$t('Files.Search')"
                suffix-icon="el-icon-search"
              ></el-input>
              <div class="subject_table" v-if="companyId">
                <div class="item" v-for="(item, index) in filterOptions_customer" :key="index">
                  <member
                    :info="item"
                    :size="48"
                    :placement="'left'"
                  />
                  <div class="content">
                    <div class="nameBox">
                      <span class="name">{{ item.nickName }}</span>
                      <span class="manageTag" v-if="!item.type">{{ $t('memberList.manage') }}</span>
                    </div>
                    <div class="duties">
                      {{ item.position }}
                    </div>
                  </div>
                  <el-checkbox v-model="item.check" :disabled="item.type == 0"></el-checkbox>
                </div>
              </div>
            </div>
          </div>
          <div class="btns">
            <el-button
              size="small"
              :loading="createGroupLoading"
              type="primary"
              @click="createGroupEvent()"
            >{{ $t('home.Confirm') }}</el-button>
          </div>
        </div>
      </div>
    </el-drawer>
    <!-- <createOrderGroup -->
      <!-- :createOrderGroupShow.sync="createOrderGroupShow" -->
      <!-- :customerCompanyId="companyId" -->
      <!-- :groupUserList.sync="currentArray" -->
      <!-- :isGroup="true" -->
      <!-- @handleClose="handleClose" -->
      <!-- :groupCurrentId="groupId" -->
      <!-- :type="typeClass" -->
      <!-- :groupName="groupName" -->
    <!-- /> -->
  </portal>
</template>
<script>
import { mapGetters } from "vuex";
import { getGroupList, deleteGroup, createGroup, updateGroup } from "@/api/common";
import { getGounpCompanyList } from '@/api/customer';

export default {
  name: "groupManagement",
  props: {
    customerCompanyId: {
      type: [String, Number],
      default: "",
    }
  },
  data() {
    return {
      type: "add",
      groupActiveId: null,
      groupArray: [],
      groupItemForm: null,
      isEdit: false,
      groupName: "",
      options_company: [],
      options_customer: [],
      customerDetail: null,
      companyDetail: null,
      customerAllSelect: false,
      companyAllSelect: false,
      companySearch: "",
      customerSearch: "",
      createGroupLoading: false,

      loading: true,
      createOrderGroupShow: false,
      groupId: "",
      currentArray: [],
      typeClass: "add"
    }
  },
  computed: {
    ...mapGetters(['manageCompany', 'companyId', 'businessUserId']),
    filterOptions_company() {
      return this.options_company.filter(item => {
        return item.nickName.toLowerCase().indexOf(this.companySearch.toLowerCase()) !== -1
      })
    },
    filterOptions_customer() {
      return this.options_customer.filter(item => {
        return item.nickName.toLowerCase().indexOf(this.customerSearch.toLowerCase()) !== -1
      })
    }
  },
  watch: {
    customerCompanyId: {
      handler(newVal) {
        if (newVal) {
          this.getGroupData();
        }
      },
      immediate: true
    },
    groupActiveId(val) {
      if(val === 'Unnamed') {
        this.type = 'add';
      } else {
        this.type = "edit";
      }
      this.groupItemForm = this.groupArray.filter(item => item.groupId == val)[0];
      this.groupName = this.groupItemForm.groupName;
      this.getMemberList();
    },
    options_company: {
      handler(val) {
        let isCheck = val.map(item => item.check);
        this.companyAllSelect = !isCheck.includes(false);
      },
      deep: true
    },
    options_customer: {
      handler(val) {
        let isCheck = val.map(item => item.check);
        this.customerAllSelect = !isCheck.includes(false);
      },
      deep: true
    }
  },
  methods: {
    getGroupData() {
      this.loading = true;
      getGroupList({
        customerId: this.customerCompanyId.split("|")[1],
      }).then((data) => {
        this.groupArray = data;
        if(data.length) {
          this.groupActiveId = data[0].groupId;
        }
      }).finally(() => this.loading = false);
    },
    async getMemberList() {
      let propsList = this.groupItemForm?.groupUsers.map(item => item.openId);
      getGounpCompanyList(this.customerCompanyId.split("|")[0]).then((data) => {
        if(data[this.customerCompanyId.split("|")[1]]) {
          this.options_customer = data[this.customerCompanyId.split("|")[1]].memberList.map(item => ({
            ...item,
            check: propsList.includes(item.openId) || item.type == 0
          }));
          this.customerDetail = data[this.customerCompanyId.split("|")[1]];
        }
        if(data[this.companyId]) {
          this.options_company = data[this.companyId].memberList.map(item => ({
            ...item,
            check: propsList.includes(item.openId) || item.type == 0
          }));
          this.options_company = this.options_company.map(item => ({
            ...item,
            check: item.openId == this.businessUserId ? true : item.check
          }));
          this.companyDetail = data[this.companyId];
        }
      });
    },
    companyAllSelectChange(val) {
      this.options_company.forEach(item => {
        if(item.type != 0 || (this.businessUserId != item.openId)) {
          item.check = val;
        }
      })
    },
    customerAllSelectChange(val) {
      this.options_customer.forEach(item => {
        if(item.type != 0) {
          item.check = val;
        }
      })
    },
    createGourpEvent() {
      if(this.groupArray.filter(item => item.groupId == 'Unnamed').length) return;
      this.groupArray.unshift({
        groupId: "Unnamed",
        groupName: this.$t("home.Unnamed"),
        groupUsers: []
      });
      this.groupActiveId = "Unnamed";
    },
    deleteGroupFun() {
      if(this.groupActiveId == 'Unnamed') {
        this.groupArray = this.groupArray.filter(item => item.groupId != 'Unnamed');
        this.isEdit = false;
        if(this.groupArray[0]) {
          this.groupActiveId = this.groupArray[0].groupId;
        } else {
          this.groupActiveId = null;
        }
        return;
      }
      this.$confirm(
        this.$t("are you sure to delete this group?"),
        this.$t("Tips"),
        {
          confirmButtonText: this.$t("Confirm"),
          cancelButtonText: this.$t("Cancel"),
          type: "warning",
        }
      ).then(() => {
        this.loading = true;
        deleteGroup({
          unid: this.groupActiveId,
        }).then(() => {
          this.$message.success(this.$t("todo.Delete Successful"));
          this.getGroupData();
        }).finally(() => this.loading = false);
      }).catch(() => {});
    },
    createGroupEvent() {
      this.createGroupLoading = true;
      let arr1 = this.options_company.filter(item => item.check);
      let arr2 = this.options_customer.filter(item => item.check);
      let arr = arr1.concat(arr2);
      const body = {
        groupName: this.groupName,
        groupUsers: arr.map(item => item.openId).join(","),
        customerId: this.customerDetail.companyId,
        companyId: this.companyDetail.companyId
      };
      if(this.type === 'add') {
        createGroup(body).then(() => {
          this.$message({
            message: this.$t("home.createSuccess"),
            type: 'success',
            duration: 1000,
          });
          this.getGroupData();
        }).finally(() => {
          this.isEdit = false;
          this.createGroupLoading = false;
        })
        return;
      }
      updateGroup({
        groupName: this.groupName,
        groupUsers: arr.map(item => item.openId).join(","),
        groupId: this.groupActiveId,
      }).then(() => {
        this.$message({
          message: this.$t("home.editSuccess"),
          type: 'success',
          duration: 1000,
        });
        this.groupItemForm.groupName = this.groupName;
        this.getGroupData();
      }).finally(() => {
        this.isEdit = false;
        this.createGroupLoading = false;
      })
    },
    editSubmit() {
      if(this.groupActiveId == 'Unnamed') {
        this.groupItemForm.groupName = this.groupName;
        this.isEdit = false;
        return;
      }
      let arr1 = this.options_company.filter(item => item.check);
      let arr2 = this.options_customer.filter(item => item.check);
      let arr = arr1.concat(arr2);
      updateGroup({
        groupName: this.groupName,
        groupUsers: arr.map(item => item.openId).join(","),
        groupId: this.groupActiveId,
      }).then(() => {
        this.$message({
          message: this.$t("home.editSuccess"),
          type: 'success',
          duration: 1000,
        });
        this.groupItemForm.groupName = this.groupName;
        this.getGroupData();
      }).finally(() => {
        this.isEdit = false;
        this.createGroupLoading = false;
      })
    },
    editFun() {
      this.isEdit = !this.isEdit;
    },
    close() {
      this.$emit("update:customerCompanyId", null)
    }
  }
};
</script>
<style lang="less" scoped>
.groupList {
  .item {
    padding: 16px;
    cursor: pointer;
    transition: all .3s;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 12px;
    .avatarGather {
      width: 48px;
      height: 48px;
      border-radius: 4px;
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      background: #E0E4EA;
      .el-image {
        width: 24px;
        height: 24px;
      }
    }
    .content {
      flex: 1;
      min-width: 0;
      .name {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        color: #122545;
        margin-bottom: 4px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .totalMember {
        font-size: 12px;
        line-height: 16px;
        color: #757D8A;
      }
    }
    &.active {
      background: #F7F9FC;
      border-right: 2px solid #076F49;
    }
    &:hover {
      background: #F7F9FC;
    }
  }
}
.selectUserBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .selectTable {
    flex: 1;
    .company {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 9px;
      padding-right: 12px;
      .left {
        display: flex;
        align-items: center;
        img {
          width: 28px;
          height: 21px;
          border-radius: 4px;
          object-fit: cover;
          margin-right: 6px;
        }
        .content {
          font-size: 12px;
          line-height: 17px;
          color: #122545;
          span {
            font-size: 16px;
            line-height: 28px;
            font-weight: 600;
          }
        }
      }
    }
  }
  .line {
    width: 1px;
    height: 350px;
    margin: 0 15px;
    background: #E0E4EA;
  }
}
.subject_table {
  margin-top: 10px;
  height: 280px;
  overflow: overlay;
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    cursor: pointer;
    &:hover {
      background: #F7F9FC;
      border-radius: 4px;
    }
    .content {
      margin-left: 10px;
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      .nameBox {
        font-size: 14px;
        line-height: 20px;
        color: #122545;
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        .name {
          display: inline-block;
          max-width: 140px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .manageTag {
          font-size: 12px;
          line-height: 17px;
          padding: 1px 5px;
          background: #F7F9FC;
          color: #076F49;
          border-radius: 4px;
          margin-left: 6px;
        }
      }
      .duties {
        font-size: 12px;
        line-height: 17px;
        color: #757D8A;
        width: 140px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
