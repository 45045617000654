import VueI18n from 'vue-i18n';
import store from "../store";
import { i18nConfig } from '../language';
const i18n = new VueI18n(i18nConfig);

let scrollNum = 0;

let isOneInput = 0;

function checkKey(key) {
  // 获取权限数组
  let permissionData = store.state.user.buttonAuths.length ? store.state.user.buttonAuths : [];
  //如果传入的元素key不在权限数组里，则不可显示
  let index = permissionData.indexOf(key)
  if(index > -1) {
    return true;
  }else{
    return false;
  }
}

export default {
  install(Vue, option) {
    Vue.directive('showTootip', {
      // inserted() {

      // },
      update(el) {
        setTimeout(() => {
          /* 第1步：先要创建一个容器`span`去获取文本的宽度 */
          // 获取当前元素的style
          const curStyle = window.getComputedStyle(el, '');
          // 创建一个容器来记录文字的width
          const textSpan = document.createElement('span');
          // 设置新容器的字体样式，确保与当前需要隐藏的样式相同
          textSpan.style.fontSize = curStyle.fontSize;
          textSpan.style.fontWeight = curStyle.fontWeight;
          textSpan.style.fontFamily = curStyle.fontFamily;
          // 将容器插入body，如果不插入，offsetWidth为0
          document.body.appendChild(textSpan);
          // 设置新容器的文字
          textSpan.innerHTML = el.innerText;
          // 如果字体元素大于当前元素，则需要隐藏
          /* 第2步：用获取到的宽跟`el`的宽进行对比，如果文本字体大于当前`el`元素的宽度，则需要title提示
          * 第3步：监听`el`的`onmouseenter`以及`onmouseleave`的鼠标移入移出事件
          */
          if (textSpan.offsetWidth > el.offsetWidth) {
            // 给当前元素设置超出隐藏
            el.style.position = 'relative';
            el.style.overflow = 'hidden';
            el.style.textOverflow = 'ellipsis';
            el.style.whiteSpace = 'nowrap';
            // 鼠标移入
            el.onmouseenter = (e) => {
              /* 第4步：鼠标移入`onmouseenter`事件里需要处理title提示的显示 */ 
              // 创建浮层元素并设置样式
              const kxmTooltipDom = document.createElement('div');
              if(window.innerHeight - el.getBoundingClientRect().bottom < 60) {
                kxmTooltipDom.style.cssText = `
                  bottom: ${(window.innerHeight - el.getBoundingClientRect().bottom) + (el.offsetHeight+10)}px;
                  left: ${el.getBoundingClientRect().left}px;
                `;
              } else {
                kxmTooltipDom.style.cssText = `
                  top: ${el.getBoundingClientRect().top+el.offsetHeight+10}px;
                  left: ${el.getBoundingClientRect().left}px;
                `;
              }
              kxmTooltipDom.setAttribute('class', 'kxmTooltip');
              // 设置id方便寻找
              kxmTooltipDom.setAttribute('id', 'kxm-tooltip');
              // 将浮层插入到body中
              document.body.appendChild(kxmTooltipDom);
              // 浮层中的文字
              document.getElementById('kxm-tooltip').innerHTML = el.innerText;
            }
            // 鼠标移出
            el.onmouseleave = () => {
              /* 第5步：鼠标移出`onmouseleave`需要移出title显示的元素 */ 
              // 找到浮层元素并移出
              const kxmTooltipDom = document.getElementById('kxm-tooltip');
              kxmTooltipDom && document.body.removeChild(kxmTooltipDom);
            }
          }
          // 需要注意：更新完之后需要移除容器，不然body里会多一个span元素内容
          document.body.removeChild(textSpan);
        }, 300);
      },
      inserted(el) {
        /* 第1步：先要创建一个容器`span`去获取文本的宽度 */
        // 获取当前元素的style
        const curStyle = window.getComputedStyle(el, '');
        // 创建一个容器来记录文字的width
        const textSpan = document.createElement('span');
        // 设置新容器的字体样式，确保与当前需要隐藏的样式相同
        textSpan.style.fontSize = curStyle.fontSize;
        textSpan.style.fontWeight = curStyle.fontWeight;
        textSpan.style.fontFamily = curStyle.fontFamily;
        // 将容器插入body，如果不插入，offsetWidth为0
        document.body.appendChild(textSpan);
        // 设置新容器的文字
        textSpan.innerHTML = el.innerText;
        // 如果字体元素大于当前元素，则需要隐藏
        /* 第2步：用获取到的宽跟`el`的宽进行对比，如果文本字体大于当前`el`元素的宽度，则需要title提示
        * 第3步：监听`el`的`onmouseenter`以及`onmouseleave`的鼠标移入移出事件
        */
        if (textSpan.offsetWidth > el.offsetWidth) {
          // 给当前元素设置超出隐藏
          el.style.position = 'relative';
          el.style.overflow = 'hidden';
          el.style.textOverflow = 'ellipsis';
          el.style.whiteSpace = 'nowrap';
          // 鼠标移入
          el.onmouseenter = (e) => {
            /* 第4步：鼠标移入`onmouseenter`事件里需要处理title提示的显示 */ 
            // 创建浮层元素并设置样式
            const kxmTooltipDom = document.createElement('div');
            if(window.innerHeight - el.getBoundingClientRect().bottom < 60) {
              kxmTooltipDom.style.cssText = `
                bottom: ${(window.innerHeight - el.getBoundingClientRect().bottom) + (el.offsetHeight+10)}px;
                left: ${el.getBoundingClientRect().left}px;
              `;
            } else {
              kxmTooltipDom.style.cssText = `
                top: ${el.getBoundingClientRect().top+el.offsetHeight+10}px;
                left: ${el.getBoundingClientRect().left}px;
              `;
            }
            kxmTooltipDom.setAttribute('class', 'kxmTooltip');
            // 设置id方便寻找
            kxmTooltipDom.setAttribute('id', 'kxm-tooltip');
            // 将浮层插入到body中
            document.body.appendChild(kxmTooltipDom);
            // 浮层中的文字
            document.getElementById('kxm-tooltip').innerHTML = el.innerText;
          }
          // 鼠标移出
          el.onmouseleave = () => {
            /* 第5步：鼠标移出`onmouseleave`需要移出title显示的元素 */ 
            // 找到浮层元素并移出
            const kxmTooltipDom = document.getElementById('kxm-tooltip');
            kxmTooltipDom && document.body.removeChild(kxmTooltipDom);
          }
        }
        // 需要注意：更新完之后需要移除容器，不然body里会多一个span元素内容
        document.body.removeChild(textSpan);
      },
      unbind() {
        const kxmTooltipDom = document.getElementById('kxm-tooltip');
	      kxmTooltipDom && document.body.removeChild(kxmTooltipDom);
      }
    })
    Vue.directive('permission',{
      /**
       * inserted：被绑定元素插入父节点时调用 
       * el：指令所绑定的元素，可以用来直接操作 DOM
       * binding.value：指令的绑定值，例如：v-directive="10" 中，绑定值为 10。
       */
      inserted(el, binding){
          let buttonKey = binding.value;
          // 代表某个元素需要通过权限验证
          if(buttonKey) {
            let key = checkKey(buttonKey)
            if(!key){//没有权限
              el.remove()  //删除按钮
            }
          } else {
            throw new Error('缺少唯一指令')
          }
      },
    })
    Vue.directive('selectLazyLoad', (el, binding) => {
      const SELECT_WRAP_DOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap')
      SELECT_WRAP_DOM && SELECT_WRAP_DOM.addEventListener('scroll', function() {
        // toFixed：把this.scrollTop转换为整数，兼容不同版本浏览器
        if(scrollNum == this.scrollHeight - this.scrollTop) return;
        scrollNum = this.scrollHeight - this.scrollTop;
        const condition = this.scrollHeight - this.scrollTop == this.clientHeight;
        if (condition) binding.value();
      })
    })
    Vue.directive('dialogDrag', {
      bind(el, binding, vnode, oldVnode) {
        //弹框可拉伸最小宽高
        let minWidth = 600;
        let minHeight = 300;
        //初始非全屏
        let isFullScreen = false;
        //当前宽高
        let nowWidth = 0;
        let nowHight = 0;
        //当前顶部高度
        let nowMarginTop = 0;
        //获取弹框头部（这部分可双击全屏）
        const dialogHeaderEl = el.querySelector('.el-dialog__header');
        //弹窗
        const dragDom = el.querySelector('.el-dialog');
        //给弹窗加上overflow auto；不然缩小时框内的标签可能超出dialog；
        dragDom.style.overflow = "auto";
        if(binding.value) {
          dragDom.style.height = "calc(100vh - 250px)";
        }
        //清除选择头部文字效果
        dialogHeaderEl.onselectstart = new Function("return false");  
        //头部加上可拖动cursor
        dialogHeaderEl.style.cursor = 'move';
    
        // 获取原有属性 ie dom元素.currentStyle 火狐谷歌 window.getComputedStyle(dom元素, null);
        const sty = dragDom.currentStyle || window.getComputedStyle(dragDom, null);
    
        let moveDown = (e) => {
          // 鼠标按下，计算当前元素距离可视区的距离
          const disX = e.clientX - dialogHeaderEl.offsetLeft;
          const disY = e.clientY - dialogHeaderEl.offsetTop;
    
          // 获取到的值带px 正则匹配替换
          let styL, styT;
    
          // 注意在ie中 第一次获取到的值为组件自带50% 移动之后赋值为px
          if (sty.left.includes('%')) {
            styL = +document.body.clientWidth * (+sty.left.replace(/\%/g, '') / 100);
            styT = +document.body.clientHeight * (+sty.top.replace(/\%/g, '') / 100);
          } else {
            styL = +sty.left.replace(/\px/g, '');
            styT = +sty.top.replace(/\px/g, '');
          };
          document.onmousemove = function (e) {
            // 通过事件委托，计算移动的距离 
            const l = e.clientX - disX;
            const t = e.clientY - disY;
            console.log(t + styT <= 5);
            // 移动当前元素  
            if(l + styL <= 5) return;
            dragDom.style.left = `${l + styL}px`;
            if(t + styT <= 5) return;
            dragDom.style.top = `${t + styT}px`;
          };
    
          document.onmouseup = function (e) {
            document.onmousemove = null;
            document.onmouseup = null;
          };
        }
        dialogHeaderEl.onmousedown = moveDown;
        // //双击头部效果
        // dialogHeaderEl.ondblclick = (e) => {
        //   if (isFullScreen == false) {
        //     nowHight = dragDom.clientHeight;
        //     nowWidth = dragDom.clientWidth;
        //     nowMarginTop = dragDom.style.marginTop;
        //     dragDom.style.left = 0;
        //     dragDom.style.top = 0;
        //     dragDom.style.height = "100%";
        //     dragDom.style.width = "100%";
        //     dragDom.style.marginTop = 0;
        //     isFullScreen = true;
        //     dialogHeaderEl.style.cursor = 'initial';
        //     dialogHeaderEl.onmousedown = null;
        //   } else {
        //     dragDom.style.height = "calc(100vh - 450px)";
        //     dragDom.style.width = nowWidth + 'px';
        //     dragDom.style.marginTop = nowMarginTop;
        //     isFullScreen = false;
        //     dialogHeaderEl.style.cursor = 'move';
        //     dialogHeaderEl.onmousedown = moveDown;
        //   }
        // }
      
        //拉伸
        let resizeEl=document.createElement("div");
        dragDom.appendChild(resizeEl); 
        //在弹窗右下角加上一个10-10px的控制块
        resizeEl.style.cursor = 'se-resize';
        resizeEl.style.position = 'absolute';
        resizeEl.style.height = '10px';
        resizeEl.style.width = '10px';
        resizeEl.style.right = '0px';
        resizeEl.style.bottom = '0px';
        //鼠标拉伸弹窗
        resizeEl.onmousedown = (e) => {
          // 记录初始x位置
          const clientX = e.clientX;
          // 鼠标按下，计算当前元素距离可视区的距离
          const disX = e.clientX - resizeEl.offsetLeft;
          const disY = e.clientY - resizeEl.offsetTop;
    
          document.onmousemove = function (e) {
            e.preventDefault(); // 移动时禁用默认事件
    
            // 通过事件委托，计算移动的距离 
            const x = e.clientX - disX + (e.clientX - clientX);//这里 由于elementUI的dialog控制居中的，所以水平拉伸效果是双倍
            const y = e.clientY - disY;
            //比较是否小于最小宽高
            dragDom.style.width = x > minWidth ? `${x}px` : minWidth + 'px';
            dragDom.style.height = y > minHeight ? `${y}px` : minHeight + 'px';
          };
          //拉伸结束
          document.onmouseup = function (e) {
            document.onmousemove = null;
            document.onmouseup = null;
          };
        }
      }
    })
    Vue.directive('move-draw', {
      bind(el, binding) {
        const minWidth = binding.value || 800
        const dragDom = el.querySelector('.el-drawer')
        const resizeElL = document.createElement('div')
        const img = new Image(24, 38)
        dragDom.appendChild(resizeElL)
        resizeElL.style.cursor = 'w-resize'
        resizeElL.style.position = 'absolute'
        resizeElL.style.height = '100%'
        resizeElL.style.width = '30px'
        resizeElL.style.left = '0px'
        resizeElL.style.top = '0px'
        img.style.position = 'absolute'
        img.style.left = '-12px'
        img.style.top = '50%'
    
        resizeElL.onmousedown = (e) => {
          const elW = dragDom.clientWidth
          const EloffsetLeft = dragDom.offsetLeft
          const clientX = e.clientX
          document.onmousemove = function (e) {
            // console.log(e.clientX,dragDom.offsetLeft,333333)
            e.preventDefault()
            // 左侧鼠标拖拽位置
            if (clientX > EloffsetLeft && clientX < EloffsetLeft + 10) {
              // 往左拖拽
              if (clientX > e.clientX) {
                dragDom.style.width = elW + (clientX - e.clientX) + 'px'
              }
              // 往右拖拽
              if (clientX < e.clientX) {
                if (dragDom.clientWidth >= minWidth) {
                  dragDom.style.width = elW - (e.clientX - clientX) + 'px'
                }
              }
            }
          }
          // 拉伸结束
          document.onmouseup = function (e) {
            document.onmousemove = null
            document.onmouseup = null
          }
        }
      }
    })
    Vue.directive('move-outside', {
      bind(el, binding, vnode) {
        // 通过事件委托绑定到共同父级元素上
        el.addEventListener('mousedown', handleMouseDown)
        /**
         * 点击事件
         * @param event
         */
        function handleMouseDown(event) {
          const target = event.target
          const drawerWrapper = target.closest('.el-drawer__wrapper')
          const dialogWrapper = target.closest('.el-dialog__wrapper')
          // 抽屉
          if (drawerWrapper) {
            handleMoveOutsideDrawer(drawerWrapper, target)
            // 弹框
          } else if (dialogWrapper) {
            handleMoveOutsideDialog(dialogWrapper, target)
          }
        }
        function handleMoveOutsideDrawer(wrapper, target) {
          // 获取el-drawer元素
          const drawer = wrapper.querySelector('.el-drawer')
          // 判断是否点击在元素之外
          if (drawer && !drawer.contains(target)) {
            // 执行原有逻辑
            if (!vnode.componentInstance.wrapperClosable) return
            vnode.componentInstance.closeDrawer()
          }
        }
        function handleMoveOutsideDialog(wrapper, target) {
          // 获取 el-dialog元素
          const dialog = wrapper.querySelector('.el-dialog')
          // 判断是否点击在元素之外
          if (dialog && !dialog.contains(target)) {
            // 执行原有逻辑
            if (!vnode.componentInstance.closeOnClickModal) return
            vnode.componentInstance.handleClose()
          }
        }
        // 清空原组件点击事件
        vnode.componentInstance.handleWrapperClick = () => {}
      }
    })
    Vue.directive('quick', function(el, binding) {
      const { dValue, nValue, container } = binding.value;
      if (dValue == nValue && container) {
          const elApp = document.getElementById(container);
          // elApp.scrollTo({
          //     top: 100,
          //     left: 0,
          // });
      }
    })
    Vue.directive('input', {
      bind(el, binding, vnode) {}, // 只调用一次，指令第一次绑定到元素时候调用，用这个钩子可以定义一个绑定时执行一次的初始化动作。
      inserted() {}, // 被绑定的元素插入父节点的时候调用(父节点存在即可调用，不必存在document中)
      update() {}, // 被绑定与元素所在模板更新时调用，而且无论绑定值是否有变化，通过比较更新前后的绑定值，忽略不必要的模板更新
      componentUpdated() {}, // 被绑定的元素所在模板完成一次更新更新周期的时候调用
      unbind() {} // 只调用一次，指令元素解绑的时候调用
    })
    Vue.directive('focus', {
      bind(el, binding, vnode) {}, // 只调用一次，指令第一次绑定到元素时候调用，用这个钩子可以定义一个绑定时执行一次的初始化动作。
      inserted(el) {
        el.addEventListener('focus', () => {
          el.parentElement.parentElement.classList.add("focus")
        })
        el.addEventListener('blur', () => {
          el.parentElement.parentElement.classList.remove("focus")
        })
      }, // 被绑定的元素插入父节点的时候调用(父节点存在即可调用，不必存在document中)
      update() {}, // 被绑定与元素所在模板更新时调用，而且无论绑定值是否有变化，通过比较更新前后的绑定值，忽略不必要的模板更新
      componentUpdated() {}, // 被绑定的元素所在模板完成一次更新更新周期的时候调用
      unbind() {} // 只调用一次，指令元素解绑的时候调用
    })
    Vue.directive('required', {
      bind(el, binding, vnode) {}, // 只调用一次，指令第一次绑定到元素时候调用，用这个钩子可以定义一个绑定时执行一次的初始化动作。
      inserted(el, { value }) {
        el.addEventListener("blur", () => {
          if (el.value == "" || el.value == null) {
            el.parentElement.parentElement.classList.add("invalid")
            el.parentElement.parentElement.classList.remove("valid")
            el.parentElement.parentElement.querySelector('span').innerText = window.vm.$t(value.errorMessage);
          } else {
            console.log(value.errorMessage);
            if(value.errorMessage == 'login.Pleaseinputapassword') {
              if(el.value.length < 5) {
                el.parentElement.parentElement.classList.add("invalid")
                el.parentElement.parentElement.classList.add("assurgent")
                el.parentElement.parentElement.classList.remove("valid")
                el.parentElement.parentElement.querySelector('span').innerText = window.vm.$t('login.The password cannot be less than 5 digits');
              } else {
                el.parentElement.parentElement.classList.remove("invalid")
                el.parentElement.parentElement.classList.remove("assurgent")
                el.parentElement.parentElement.classList.add("valid")
                el.parentElement.parentElement.querySelector('span').innerText = window.vm.$t(value.message);
              }
            } else {
              el.parentElement.parentElement.classList.remove("invalid")
              el.parentElement.parentElement.classList.add("valid")
              el.parentElement.parentElement.querySelector('span').innerText = window.vm.$t(value.message);
            }
          }
        });
      }, // 被绑定的元素插入父节点的时候调用(父节点存在即可调用，不必存在document中)
      update(el, { value }) {
        if(isOneInput) {
          if (el.value == "" || el.value == null) {
            el.parentElement.parentElement.classList.add("invalid")
            el.parentElement.parentElement.classList.remove("valid")
            el.parentElement.parentElement.querySelector('span').innerText = window.vm.$t(value.errorMessage);
          } else {
            el.parentElement.parentElement.classList.remove("invalid")
            el.parentElement.parentElement.classList.add("valid")
            el.parentElement.parentElement.querySelector('span').innerText = window.vm.$t(value.message);
          }
          isOneInput = 1;
        }
      }, // 被绑定与元素所在模板更新时调用，而且无论绑定值是否有变化，通过比较更新前后的绑定值，忽略不必要的模板更新
      componentUpdated() {}, // 被绑定的元素所在模板完成一次更新更新周期的时候调用
      unbind() {} // 只调用一次，指令元素解绑的时候调用
    })
    Vue.directive('checked', {
      bind(el, binding, vnode) {}, // 只调用一次，指令第一次绑定到元素时候调用，用这个钩子可以定义一个绑定时执行一次的初始化动作。
      inserted(el) {
        return el;
      }, // 被绑定的元素插入父节点的时候调用(父节点存在即可调用，不必存在document中)
      update() {}, // 被绑定与元素所在模板更新时调用，而且无论绑定值是否有变化，通过比较更新前后的绑定值，忽略不必要的模板更新
      componentUpdated() {}, // 被绑定的元素所在模板完成一次更新更新周期的时候调用
      unbind() {} // 只调用一次，指令元素解绑的时候调用
    })
    Vue.directive('validate', {
      bind(el, binding, vnode) {}, // 只调用一次，指令第一次绑定到元素时候调用，用这个钩子可以定义一个绑定时执行一次的初始化动作。
      inserted(el, { value }) {
        // 监听失去焦点的时候
        el.addEventListener("blur", function () {
          //失去焦点进行验证
          checkedfun();
        });

        // 循环进行验证
        function checkedfun() {
          for (var i = 0; i < value.length; ++i) {
            let type = value[i].type
            let emailRegex = /^email$/; // 判断设置了email
            let phoneRegex = /^phone$/; // 判断设置了 phone
            let passwordRegex = /^password$/; // 判断设置了 phone
            // 判断设置了email
            if (emailRegex.test(type)) {
              if (!email(window.vm.$t(value[i].errorMessage))) {
                break;
              } else {
                removeTips(window.vm.$t(value[i].message));
              }
            }
            // 判断设置了 phone
            if (phoneRegex.test(type)) {
              if (!phone(window.vm.$t(value[i].errorMessage))) {
                break;
              } else {
                removeTips(window.vm.$t(value[i].message));
              }
            }
            // 判断设置了 密码
            if (passwordRegex.test(type)) {
              if (!password(window.vm.$t(value[i].errorMessage))) {
                break;
              } else {
                removeTips(window.vm.$t(value[i].message));
              }
            }
          }
        }

        // 验证是否是邮箱
        function email(errorMessage) {
          let emailRule = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/;
          if (!emailRule.test(el.value)) {
            tipMsg(window.vm.$t("login.PleaseinputaSuccessEmail"));
            return false;
          }
          return true;
        }

        // 验证是否是手机号码
        function phone(errorMessage) {
          let phoneRule = /^[1][3,4,5,7,8][0-9]{9}$/;
          if (!phoneRule.test(el.value)) {
            tipMsg(errorMessage);
            return false;
          }
          return true;
        }

        // 验证是否是手机号码
        function password(errorMessage) {
          let span = document.querySelector("#safetyStrength_tips .passTips");
          if (el.value === "") {
            span.innerHTML = window.vm.$t("login.Nonempty");
            span.style.color = "#F56C6C";
            tipMsg(window.vm.$t(errorMessage));
            return false
          } else if (
            el.value.length < 8 ||
            !/[a-zA-Z]/.test(el.value) ||
            !/[0-9]/.test(el.value)
          ) {
            span.innerHTML = window.vm.$t("login.Tooweak");
            span.style.color = "#F56C6C";
            tipMsg(window.vm.$t(errorMessage));
            return false
          } else {
            return true
          }
        }

        // 添加提示信息
        function tipMsg(msg) {
          el.parentElement.parentElement.classList.add("invalid")
          el.parentElement.parentElement.classList.remove("valid")
          el.parentElement.parentElement.querySelector('span').innerText = msg;
        }

        // 移除提示信息
        function removeTips(msg) {
          el.parentElement.parentElement.classList.remove("invalid")
          el.parentElement.parentElement.classList.add("valid")
          el.parentElement.parentElement.querySelector('span').innerText = msg;
        }

        el.addEventListener("blur", () => {
          if (el.value == "" || el.value == null) {
            el.parentElement.parentElement.classList.add("invalid")
            el.parentElement.parentElement.classList.remove("valid")
          } else {
            el.parentElement.parentElement.classList.add("valid")
          }
        });
      }, // 被绑定的元素插入父节点的时候调用(父节点存在即可调用，不必存在document中)
      update() {}, // 被绑定与元素所在模板更新时调用，而且无论绑定值是否有变化，通过比较更新前后的绑定值，忽略不必要的模板更新
      componentUpdated() {}, // 被绑定的元素所在模板完成一次更新更新周期的时候调用
      unbind() {} // 只调用一次，指令元素解绑的时候调用
    })
  }
}