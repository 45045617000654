<template>
  <div class="test-wrapper">
    <div class="test-left" id="test-left">
      <slot name="left"></slot>
    </div>
    <div class="test-right" :style="`height: ${rightHeight}`">
      <slot name="right"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'test',
  data() {
    return {
      rightHeight: ""
    }
  },
  mounted() {
    const dom = document.getElementById("test-left");
    const resizeObserver = new ResizeObserver((entries) => {
      this.rightHeight = entries[0].target.clientHeight+'px';
    });
    resizeObserver.observe(dom);
  },
};
</script>
<style lang="css">
.test-wrapper {
  display: flex;
  grid-template-columns: 70% 30%;
  align-items: flex-start;
  gap: 14px;
}

.test-left {
  width: 74%;
  height: fit-content;
  height: 100%;
  min-height: 1580px;
  background: #ffffff;
}

.test-right {
  width: 26%;
  min-width: 280px;
}

.block {
  display: block;
  width: 100%;
  height: 100px;
}
</style>
