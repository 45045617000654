import request from '@/utils/request';

//消息会话列表
export function messageSessionList(params) {
  return request({
    url: '/chat/list',
    method: 'get',
    params,
  });
}

// 发聊天消息
export function sendChatData(data) {
  return request({
    url: `chat/chat`,
    method: 'post',
    data,
  });
}

//查询消息记录
export function queryMessageRecord(params) {
  return request({
    url: `/chat/page`,
    method: 'get',
    params,
  });
}

// 消息历史记录
export function getMessageHistory(params) {
  return request({
    url: `/chat/history`,
    method: 'get',
    params,
  });
}

// 定位聊天记录
export function locateMessageHistory(chatId) {
  return request({
    url: `/chat/locate`,
    method: 'get',
    params: {
      chatId
    },
  });
}

// 根据ID获取消息详情
export function getChatDetail(unid) {
  return request({
    url: `/chat/detail`,
    method: 'get',
    params: {
      unid
    },
  });
}

//查询消息记录
export function sendMessage(data) {
  return request({
    url: `businessInstantMessage/sendMessage`,
    method: 'get',
    params: data,
  });
}
//聊天置顶-取消置顶
export function ChatTop(openId) {
  return request({
    url: `manage/users/personalization/chatUp`,
    method: 'get',
    params: { 
      openId
    },
  });
}
// 标记消息已读

export function markMessageStored(data) {
  return request({
    url: `businessInstantMessage/markMessageReadAll/${data}`,
    method: 'get',
  });
}

//获取待审核用户客户公司关系
export function approveList(data) {
  for (let index in data) {
    !data[index] && delete data[index]
  }
  return request({
    url: `businessCustomer/inviteList`,
    method: 'get',
    params: data,
  });
}

//审核列表
export function companyApplyList(params) {
  return request({
    url: `/manage/users/pageUnapprovedInformation`,
    method: 'get',
    params,
  });
}

//取消评论回复已读
export function markRead(data) {
  return request({
    url: `businessComment/markRead`,
    method: 'post',
    data,
  });
}
//取消event事件已读
export function eventmarkRead(data) {
  return request({
    url: `businessEvent/markRead`,
    method: 'post',
    data,
  });
}
//去除同个订单评论回复和event事件的已读
export function markOrderEventAndCommentRead(data) {
  return request({
    url: `businessOrder/markOrderEventAndCommentRead`,
    method: 'post',
    data,
  });
}
//审核用户客户公司关系(现用) //审核用户客户公司关系
export function approve(data) {
  return request({
    url: `businessCustomer/approve`,
    method: 'post',
    data,
  });
}
//获取待审核用户客户公司关系(先用)
export function approveListNow(data) {
  return request({
    url: `businessCustomer/approveList`,
    method: 'get',
    params: data,
  });
}

//获取待办集列表(分页)
export function todoListPage(data) {
  return request({
    url: `businessTodo/todoListPage`,
    method: 'post',
    data,
  });
}
//todo所有列表
export function todoItemPage(params) {
  return request({
    url: `/todo/item/index`,
    method: 'get',
    params,
  });
}
//todo记录列表
export function itemLogList(todoItemId) {
  return request({
    url: '/todo/item/log/getListByTodoItemId',
    method: 'get',
    params: {
      todoItemId
    }
  });
}
//todo记录列表
export function receiveItem(data) {
  return request({
    url: `/todo/item/updateState`,
    method: 'post',
    data,
  });
}
//1v1已读未读统计
export function messageStat() {
  return request({
    url: `/chat/unReadNum`,
    method: 'get',
  });
}
//todo已读未读统计
export function todoStatistics() {
  return request({
    url: `/todo/item/index`,
    method: 'get',
    params: {
      excludeRead: true,
      currentPage: 1,
      pageSize: 1
    }
  });
}
//事件已读未读统计
export function eventStatistics() {
  return request({
    url: `/event/index`,
    method: 'get',
    params: {
      excludeRead: true,
      currentPage: 1,
      pageSize: 1
    }
  });
}
//评论已读未读统计
export function commentStatistics() {
  return request({
    url: `/event/comment/index`,
    method: 'get',
    params: {
      excludeRead: true,
      currentPage: 1,
      pageSize: 1
    }
  });
}
//个人申请已读未读统计
export function applyStatistics() {
  return request({
    url: `/manage/users/pageUnapprovedInformation`,
    method: 'get',
    params: {
      currentPage: 1,
      pageSize: 1,
      status: 2,
      type: 0
    }
  });
}

// 评论一键已读
export function commentOneClickRead() {
  return request({
    url: `/event/comment/readAll`,
    method: 'get'
  });
}

// 事件一键已读
export function eventOneClickRead() {
  return request({
    url: `/event/readAll`,
    method: 'get'
  });
}

// 获取系统通知列表
export function getSystemNoticeList(params) {
  return request({
    url: `/notify/page`,
    method: 'get',
    params
  });
}

// 获取系统通知未读数量
export function getSystemNoticeCount(params) {
  return request({
    url: `/notify/count`,
    method: 'get',
    params
  });
}

// 系统通知已读单条
export function getReadSystemNotice(unid) {
  return request({
    url: `/notify/read`,
    method: 'post',
    params: {
      unid
    }
  });
}

// 一键已读系统通知
export function getReadAllSystemNotice() {
  return request({
    url: `/notify/readAll`,
    method: 'post'
  });
}

// 新建群聊
export function createGroupChat(data) {
  return request({
    url: `/groups/add`,
    method: 'post',
    data
  });
}

// 查询群组列表
export function getGroupChatList(params) {
  return request({
    url: `/groups/page`,
    method: 'get',
    params
  });
}

// 查询群聊单条数据
export function getGroupChatDetail(unid) {
  return request({
    url: `/groups/detail`,
    method: 'get',
    params: {
      unid
    }
  });
}

// 添加群聊成员
export function addGroupChatMember(params) {
  return request({
    url: `/groups/addUser`,
    method: 'post',
    params
  });
}

// 删除群聊成员
export function deleteGroupChatMember(params) {
  return request({
    url: `/groups/deleteUser`,
    method: 'post',
    params
  });
}

// 修改群名
export function updateGroupChatName(data) {
  return request({
    url: `/groups/update`,
    method: "post",
    data
  })
}

// 退出群聊
export function exitGroupChatApi(groupId, openId) {
  return request({
    url: `/groups/exit`,
    method: "post",
    params: {
      groupId,
      openId
    }
  })
}

// 解散群聊
export function dissolveGroupChatApi(groupId) {
  return request({
    url: `/groups/dismiss`,
    method: "post",
    params: {
      groupId
    }
  })
}

// 撤回消息
export function withdrawMessage(chatId) {
  return request({
    url: `/chat/withdraw`,
    method: "post",
    params: {
      chatId
    }
  })
}

// 音视频呼叫
export function videoPhoneCall(toUserId) {
  return request({
    url: `/video/call`,
    method: "get",
    params: {
      toUserId
    }
  })
}

// 音视频回应
export function videoPhoneResponse(params) {
  return request({
    url: `/video/response`,
    method: "get",
    params
  })
}

// 音视频告诉对方已打开摄像头
export function videoPhoneOpen(toUserId) {
  return request({
    url: `/video/open`,
    method: "get",
    params: {
      toUserId
    }
  })
}

// 交出offer
export function setVideoPhoneStreamOffer(params) {
  return request({
    url: `/video/getOff`,
    method: "get",
    params
  })
}
