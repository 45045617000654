<template>
  <el-drawer
    :visible.sync="show"
    :title="$t('Server detail')"
    size="728px"
    :before-close="handleClose"
    :show-close="true"
    :append-to-body="true"
    v-move-outside
  >
    <div class="detailBox" :class="isShare ? 'isShare' : ''" v-if="detail">
      <serverItem v-if="show" :item="detail" />
    </div>
    <div class="drawerFooter" v-if="detail && !isShare">
      <el-button 
        class="cancel_button" 
        size="small" 
        @click="handleClose"
      >{{ $t('Cancel') }}</el-button>
      <el-button 
        size="small"
        @click="deleteItem"
        v-if="detail.companyId == companyId"
      >{{ $tc('home.Delete') }}</el-button>
      <el-popover
        placement="top"
        trigger="click"
        :append-to-body="false"
        popper-class="ec-popper">
        <div class="popperMenuItem" @click="operation(0)">
          <i class="iconfont icon-fenxiang2" />
          <span>{{ $t("order.Internal sharing") }}</span>
        </div>
        <div class="popperMenuItem" @click="operation(1)">
          <i class="iconfont icon-zhanwaifenxiang" />
          <span>{{ $t("order.External sharing") }}</span>
        </div>
        <el-button 
          slot="reference"
          size="small"
          style="margin: 0 10px"
        >{{ $t('order.share') }}</el-button>
      </el-popover>
      <el-button 
        size="small"
        v-if="detail.companyId == companyId"
        @click="editItem"
      >{{ $t('home.Edit') }}</el-button>
      <el-button 
        v-else
        type="primary"
        size="small"
        @click="consultEvent"
      >{{ $t('noticeServer.Consult') }}</el-button>
    </div>
    <inside
      :isInside="isInside"
      @onClose="isInside = false"
      type="shopServerInternal"
      :shopServerId="detail && detail.serviceId"
      :messageType="12"
    />
    <externalShare ref="externalShare" />
  </el-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import { deleteForwarderServer } from "@/api/shopServer.js";
import serverItem from '../itemComponents/server.vue';
import inside from "@/components/fileInside/inside.vue";
import externalShare from '../share/externalShare.vue';

export default {
  components: {
    serverItem,
    inside,
    externalShare
  },
  props: {
    isShare: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: false,
      detail: null,
      isInside: false,
    }
  },
  computed: {
    ...mapGetters(["companyId"])
  },
  methods: {
    open(row = null) {
      this.show = true;
      this.detail = row;
    },
    handleClose() {
      this.show = false;
    },
    consultEvent() {
      this.$emit("consult", this.detail.serviceId);
    },
    changeImage(row) {
      this.detail.picList.forEach((item) => {
        item.check = false;
      })
      row.check = !row.check;
    },
    deleteItem() {
      this.$confirm(
        this.$t('Cannot reply after deletion'), 
        this.$t('Are you sure to delete this product/service'), 
        {
          confirmButtonText: this.$t('Confirm'),
          cancelButtonText: this.$t('Cancel'),
          type: 'warning'
        }
      ).then(() => {
        deleteForwarderServer(this.detail.serviceId).then(() => {
          this.$message.success(this.$t('event.Delete successfully'));
          this.$emit("updateList", false);
          this.handleClose();
        })
      }).catch(() => {});
    },
    editItem() {
      this.$emit("edit", this.detail);
    },
    operation(type) {
      switch (type) {
        case 0:
          this.isInside = true;
          break;
        case 1:
          this.$refs.externalShare.open(this.detail.serviceId, this.detail.companyId, 3);
          break;
      }
    },
  }
}
</script>

<style lang="less" scoped>
@import "./index.less";
</style>