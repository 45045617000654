
import ElTabs from './src/tabs.vue';
import ELPane from './src/tab-pane.vue';

/* istanbul ignore next */
ElTabs.install = function(Vue) {
  Vue.component(ElTabs.name, ElTabs);
};

/* istanbul ignore next */
ELPane.install = function(Vue) {
  Vue.component(ELPane.name, ELPane);
};

export const Tabs = ElTabs;
export const TabPane = ELPane;
