<template>
  <div>
    <el-dialog
      :visible="visible"
      width="358px"
      :title="isGroup ? $t('memberList.Manage transfer to') : $t('memberList.Permission transfer to')"
      :modal-append-to-body="false"
      @close="close"
      custom-class="forwarderDialog"
      v-move-outside
    >
      <div class="searchBox">
        <el-input
          suffix-icon="el-icon-search"
          size="small"
          :placeholder="$t('memberList.FindMember')"
          v-model="search"
        />
      </div>
      <div class="inviteBox">
        <div 
          class="item" 
          v-for="(item, idx) in companyUsers.filter(row => row.nickName.toLowerCase().indexOf(search.toLowerCase()) !== -1)" 
          :key="idx"
          @click="checkChange(item)">
          <member :info="item" :size="48" />
          <div class="content">
            <div class="name">
              {{ item.nickName }}
              <div class="tag" v-if="!item.type && !isGroup">{{ $t('memberList.manage') }}</div>
            </div>
            <div class="job">{{ item.position }}</div>
          </div>
          <i v-if="item.check" class="el-icon-check"></i>
        </div>
      </div>
      <div class="dialog-footer" slot="footer">
        <el-button 
          class="cancel_button" 
          size="small" 
          @click="close">
          {{ $t('home.Cancel') }}
        </el-button>
        <el-button
          type="primary"
          size="small"
          @click="setRole"
          >{{ $t('memberList.Confirm transfer') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { getCompanyEmployeeList } from '@/api/customer';
import { updateMemberRole } from "@/api/common";

export default {
  props: {
    isGroup: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visible: false,
      buttonLoading: false,
      companyUsers: [],
      search: "",
      openId: "",
      memberList: []
    }
  },
  computed: {
    ...mapGetters(["companyId", "businessUserId"])
  },
  methods: {
    open(openId = null, memberList = []) {
      this.openId = openId;
      this.visible = true;
      this.memberList = memberList;
      this.getCompanyInfo(this.companyId);
    },
    checkChange(item) {
      this.companyUsers.forEach(row => {
        row.check = false;
      })
      this.$nextTick(() => {
        item.check = true;
      });
    },
    setRole() {
      let find = this.companyUsers.find(item => item.check);
      if(find) {
        if(this.isGroup) {
          this.$emit("exit", find.openId);
          return;
        }
        this.$confirm(
          this.$t("Do you want to modify the current member role"),
          this.$t("Tips"),
          {
            confirmButtonText: this.$t("Confirm"),
            cancelButtonText: this.$t("Cancel"),
            type: "warning",
          }
        ).then(() => {
          updateMemberRole({
            openId: find.openId,
            type: 0
          }).then(async () => {
            this.$message.success(this.$t("memberList.Operation succeeded"));
            this.$emit("update");
            await this.$store.dispatch("socket/logout");
            await this.$store.dispatch("user/logout");
          }).finally(() => {});
        }).catch(() => {});
      } else {
        this.$message.warning(this.$t("memberList.Please select a person"));
      }
    },
    getCompanyInfo(companyId) {
      if(this.isGroup) {
        this.companyUsers = this.memberList.map(item => ({
          ...item,
          check: false
        })).filter(item => item.openId != this.businessUserId);
        console.log(this.companyUsers);
        return;
      }
      getCompanyEmployeeList(companyId).then(data => {
        this.companyUsers = data.memberList.map(item => ({
          ...item,
          check: false
        })).filter(item => item.openId != this.businessUserId);
      });
    },
    close() {
      this.search = "";
      this.visible = false;
    },
  }
}
</script>
<style lang="less" scoped>
/deep/ .el-dialog__header {
  display: block !important;
}
/deep/ .el-dialog__footer {
  background: transparent !important;
  border-top: none !important;
}
/deep/ .forwarderDialog {
  .el-dialog__body {
    padding: 0;
  }
}
.searchBox {
  padding: 0 16px;
}
.inviteBox {
  max-height: 300px;
  overflow: overlay;
  padding-bottom: 16px;
  margin-top: 10px;
  .item {
    padding: 10px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: all .3s;
    &:hover {
      background: #F7F9FC;
    }
    .content {
      flex: 1;
      min-width: 0;
      overflow: hidden;
      margin-left: 6px;
      .name {
        font-size: 14px;
        line-height: 20px;
        color: #122545;
        font-weight: 500;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        gap: 5px;
        .tag {
          padding: 2px 6px;
          font-size: 12px;
          line-height: 17px;
          background: #F7F9FC;
          border-radius: 4px;
          color: #076F49;
        }
      }
      .job {
        font-size: 12px;
        line-height: 17px;
        color: #757D8A;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .el-icon-check {
      font-size: 14px;
      color: #076F49;
    }
  }
}
</style>
