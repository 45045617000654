// this L.CRS.Baidu from https://github.com/muyao1987/leaflet-tileLayer-baidugaode/blob/master/src/tileLayer.baidu.js

if (L.Proj) {
	L.CRS.Baidu = new L.Proj.CRS('EPSG:900913', '+proj=merc +a=6378206 +b=6356584.314245179 +lat_ts=0.0 +lon_0=0.0 +x_0=0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs', {
		resolutions: function () {
			level = 19
			var res = [];
			res[0] = Math.pow(2, 18);
			for (var i = 1; i < level; i++) {
				res[i] = Math.pow(2, (18 - i))
			}
			return res;
		}(),
		origin: [0, 0],
		bounds: L.bounds([20037508.342789244, 0], [0, 20037508.342789244])
	});
}

const quadkey = (zyx) => {
	var result = "";
	for (var i = zyx.z; i > 0; i--) {
		var digit = 0;
		var mask = 1 << (i - 1);
		if ((zyx.x & mask) != 0) {
			digit++;
		}
		if ((zyx.y & mask) != 0) {
			digit++;
			digit++;
		}
		result += digit;
	}
	return result;
};

L.TileLayer.ChinaProvider = L.TileLayer.extend({
	initialize: function (type, options) { // (type, Object)
		var providers = L.TileLayer.ChinaProvider.providers;

		options = options || {}

		var parts = type.split('.');

		var providerName = parts[0];
		var mapName = parts[1];
		var mapType = parts[2];

		var url = providers[providerName][mapName][mapType];
		options.subdomains = providers[providerName].Subdomains;
		options.key = options.key || providers[providerName].key;
		options.quadkey = quadkey;

		if ('tms' in providers[providerName]) {
			options.tms = providers[providerName]['tms']
		}

		L.TileLayer.prototype.initialize.call(this, url, options);
	}
});

L.TileLayer.ChinaProvider.providers = {
	TianDiTu: {
		Normal: {
			Map: "http://t{s}.tianditu.com/DataServer?T=vec_w&X={x}&Y={y}&L={z}&tk={key}",
			Annotion: "http://t0.tianditu.com/DataServer?T=eva_w&X={x}&Y={y}&L={z}&tk={key}"
		},
		Satellite: {
			Map: "http://t{s}.tianditu.com/DataServer?T=img_w&X={x}&Y={y}&L={z}&tk={key}",
			Annotion: "http://t{s}.tianditu.com/DataServer?T=cia_w&X={x}&Y={y}&L={z}&tk={key}"
		},
		Terrain: {
			Map: "http://t{s}.tianditu.com/DataServer?T=ter_w&X={x}&Y={y}&L={z}&tk={key}",
			Annotion: "http://t{s}.tianditu.com/DataServer?T=cta_w&X={x}&Y={y}&L={z}&tk={key}"
		},
		Subdomains: ['0', '1', '2', '3', '4', '5', '6', '7'],
		key: "0fc91d5e1801411226ecc679e5490846"
	},
	GaoDe: {
		Normal: {
			Map: 'https://webrd0{s}.is.autonavi.com/appmaptile?lang=zh_cn&size=1&scale=1&style=8&x={x}&y={y}&z={z}'
		},
		Satellite: {
			Map: 'https://webst0{s}.is.autonavi.com/appmaptile?style=6&x={x}&y={y}&z={z}',
			Annotion: 'https://webst0{s}.is.autonavi.com/appmaptile?style=8&x={x}&y={y}&z={z}'
		},
		Subdomains: ["1", "2", "3", "4"]
	},
	GoogleCN: {
		Normal: {
			Map: "https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
		},
		Satellite: {
			Map: "https://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}",
			Annotion: "https://www.google.cn/maps/vt?lyrs=y@189&gl=cn&x={x}&y={y}&z={z}"
		},
		Subdomains: []
	},
	GoogleEN: {
		Normal: {
			Map: "https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}"
		},
		Satellite: {
			Map: "https://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}",
			Annotion: "https://www.google.cn/maps/vt?lyrs=y@189&gl=cn&x={x}&y={y}&z={z}"
		},
		Subdomains: []
	},
	Geoq: {
		Normal: {
			Map: "http://map.geoq.cn/ArcGIS/rest/services/ChinaOnlineCommunity/MapServer/tile/{z}/{y}/{x}",
			PurplishBlue: "http://map.geoq.cn/ArcGIS/rest/services/ChinaOnlineStreetPurplishBlue/MapServer/tile/{z}/{y}/{x}",
			Gray: "http://map.geoq.cn/ArcGIS/rest/services/ChinaOnlineStreetGray/MapServer/tile/{z}/{y}/{x}",
			Warm: "http://map.geoq.cn/ArcGIS/rest/services/ChinaOnlineStreetWarm/MapServer/tile/{z}/{y}/{x}",
		},
		Theme: {
			Hydro: "http://thematic.geoq.cn/arcgis/rest/services/ThematicMaps/WorldHydroMap/MapServer/tile/{z}/{y}/{x}"
		},
		Subdomains: []
	},
	OSM: {
		Normal: {
			Map: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
			Cycle: "https://{s}.tile.thunderforest.com/cycle/{z}/{x}/{y}.png?apikey=a5dd6a2f1c934394bce6b0fb077203eb",
			Transport: "http://{s}.tile2.opencyclemap.org/transport/{z}/{x}/{y}.png"
		},
		Subdomains: ['a', 'b', 'c']
	},
	LeafLet: {
		Normal: {
			Map: "http://map.geoq.cn/arcgis/rest/services/ChinaOnlineCommunityENG/MapServer/tile/{z}/{y}/{x}",
		},
		Subdomains: []
	},
	Bing: {
		Normal: {
			commonMap: "https://t{s}.dynamic.tiles.ditu.live.com/comp/ch/{quadkey}?mkt=zh-CN&ur=CN&it=G,RL&n=z&og=804&cstl=vb",
			Map: "https://t{s}.ssl.ak.dynamic.tiles.virtualearth.net/comp/ch/{quadkey}?mkt=en-US&it=G,LC,BF,RL&shading=hill&shading=t&n=t&og=2162&cstl=s23&o=webp&ur=jp",
			topographyMap: "https://t{s}.ssl.ak.tiles.virtualearth.net/tiles/a{quadkey}.jpeg?g=13466&n=t&prx=1"
		},
		Annotion: {
			En: "http://t{s}.tianditu.gov.cn/DataServer?T=eva_w&X={x}&Y={y}&L={z}&tk={key}",
			Cn: "http://t{s}.tianditu.gov.cn/DataServer?T=cia_w&X={x}&Y={y}&L={z}&tk={key}"
		},
		key: "afb27f3a898cf7f4197e7313b9ccd154",
		Subdomains: ['0', '1']
	}
};

L.tileLayer.chinaProvider = function (type, options) {
	return new L.TileLayer.ChinaProvider(type, options);
};