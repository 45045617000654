var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('div',{style:({
    '--theme-color': _vm.layoutColors[0],
    '--theme-background': _vm.layoutColors[1],
    '--theme-text-color': _vm.layoutColors[2],
    '--theme-main-color': _vm.layoutColors[3],
    '--theme-hover-color': _vm.layoutColors[4],
    '--theme-active-color': _vm.layoutColors[5],
    '--theme-children-active-color': _vm.layoutColors[6],
  })},[_c('keep-alive',{attrs:{"include":['Login']}},[_c('router-view')],1),_c('portal-target',{attrs:{"name":"select-group-portal"}})],1),_c('messageDialog'),(_vm.importFileProgress.show)?_c('div',{staticClass:"importProgressBox"},[_c('div',{staticClass:"box"},[_c('el-progress',{attrs:{"type":"circle","percentage":_vm.importFileProgress.value}}),_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('Import in progress')))])],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }