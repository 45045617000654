<template>
  <el-dialog
    :visible.sync="show"
    width="500px"
    append-to-body
    :before-close="close"
    v-move-outside
  >
    <template slot="title">
      <div class="headerTabs">
        <div 
          class="item" 
          :class="item.id == messageType ? 'active' : ''"
          v-for="(item, index) in headerTabs" 
          :key="index"
          @click="messageType = item.id">
          {{ item.label }}
        </div>
      </div>
    </template>
    <div class="searchBox">
      <el-input 
        v-model="keyword" 
        size="small" 
        :placeholder="$t('dashboardCapacityMonit.Search')"
        @input="getMessageHistory(true)">
        <i 
          slot="suffix" 
          @click="getMessageHistory(true)"
          class="el-input__icon el-icon-search"
          style="cursor: pointer;"
        ></i>
      </el-input>
    </div>
    <div class="messageList" id="messageHistory">
      <div class="item" v-for="(item, index) in historyMessages" :key="index">
        <template v-if="messageGroupType.includes(item.messageType)">
          <member :info="item.createUser" :size="40" />
          <div class="content">
            <div class="left" v-html="item.content"></div>
            <div class="right">
              <div class="time">{{ isToday(item.createTime) }}</div>
              <div class="locate" @click="locateMessageHistory(item.chatId)">{{ $t("Navigate to Chat") }}</div>
            </div>
          </div>
        </template>
        <template v-else>
          <fileBlocks
            type="history"
            :background="''"
            :embedUrl="item.chatFileUrl"
            :embedId="item.chatFileId"
          />
          <div class="content">
            <div class="left"></div>
            <div class="right">
              <div class="time">{{ isToday(item.createTime) }}</div>
              <div class="locate" @click="locateMessageHistory(item.chatId)">{{ $t("Navigate to Chat") }}</div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { queryMessageRecord, locateMessageHistory } from "@/api/news";
import dayjs from "dayjs";
export default {
  name: 'messageHistory',
  props: {
    otherParty: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      show: false,
      messageType: 0,
      keyword: "",
      historyMessages: [],
      messageGroupType: [1,3,5,6,7,8,9],
      headerTabs: [{
        label: this.$t("Dashboard.whole"),
        id: 0
      }, {
        label: this.$t("image"),
        id: 3
      }, {
        label: this.$t("home.Files"),
        id: 4
      }]
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  watch: {
    messageType() {
      this.historyMessages = [];
      this.getMessageHistory();
    }
  },
  methods: {
    open() {
      this.show = true;
      this.historyMessages = [];
      this.getMessageHistory();
      this.$nextTick(() => {
        document.querySelector("#messageHistory").addEventListener("scroll", this.scrollEvent);
      });
    },
    close() {
      this.show = false;
      this.messageType = 0;
      this.historyMessages = [];
      document.querySelector("#messageHistory").removeEventListener("scroll", this.scrollEvent);
    },
    scrollEvent({ target }) {
      const clientHeight = target.clientHeight;
      const scrollTop = target.scrollTop;
      const scrollHeight = target.scrollHeight;
      if (clientHeight + scrollTop == scrollHeight) {
        this.getMessageHistory();
      }
    },
    locateMessageHistory(id) {
      this.$emit("locateMessage", id);
      this.close();
    },
    isToday(time) {
      let todayDate = new Date().setHours(0,0,0,0);
      let paramsDate = new Date(time).setHours(0,0,0,0);
      if(todayDate === paramsDate) {
        return time.split(" ")[1]
      } else {
        return time.split(" ")[0]
      }
    },
    getMessageHistory(isUpdate = false) {
      if(isUpdate) this.historyMessages = [];
      let localeId = this.historyMessages.length && this.historyMessages[this.historyMessages.length - 1].chatId;
      queryMessageRecord({
        size: 10,
        next: false,
        messageType: this.messageType,
        keyword: this.keyword,
        locateChatId: localeId,
        chatUser: this.otherParty?.openId
      }).then((data) => {
        this.historyMessages.push(
          ...data.map(item => ({
            ...item,
            userInfo: item.current ? this.userInfo : this.otherParty
          }))
        );
      })
    }
  },
}
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header {
  display: block !important;
}
/deep/ .el-dialog__body {
  padding: 0;
}
.headerTabs {
  display: flex;
  align-items: center;
  gap: 20px;
  .item {
    cursor: pointer;
    font-size: 16px;
    color: #A3B0C6;
    &.active {
      color: #122545;
      font-weight: 600;
    }
  }
}
.searchBox {
  padding: 0 12px 12px 12px;
}
.messageList {
  width: 100%;
  height: 400px;
  overflow: overlay;
  overflow-x: hidden;
  .item {
    display: flex;
    width: 100%;
    padding: 8px 12px;
    gap: 8px;
    border-bottom: 1px solid #E0E4EA;
    .content {
      flex: 1;
      display: flex;
      justify-content: space-between;
      .left {
        width: 50%;
        margin-top: 12px;
        font-size: 14px;
        color: #122545;
        /deep/ img {
          width: 120px;
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-top: 5px;
        .time {
          font-size: 12px;
          color: #757D8A;
        }
        .locate {
          font-size: 12px;
          color: #076F49;
          opacity: 0;
          transition: all .3s;
          cursor: pointer;
          &:hover {
            opacity: .8 !important;
          }
        }
      }
    }
    &:hover {
      background: #F7F9FC;
      .content {
        .right {
          .locate {
            opacity: 1;
          }
        }
      }
    }
  }
}
</style>