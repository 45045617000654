import Vue from "vue";
import dayjs from "dayjs";
// import SparkMD5 from 'spark-md5'
import md5 from 'js-md5';
import store from "../store";
import tz from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
dayjs.extend(tz);

const GMT_TIME_ZONES = {
  "GMT-12": "Etc/GMT+12",
  "GMT-11": "Pacific/Midway",
  "GMT-10": "Pacific/Honolulu",
  "GMT-9": "America/Juneau",
  "GMT-8": "America/Los_Angeles",
  "GMT-7": "America/Denver",
  "GMT-6": "America/Mexico_City",
  "GMT-5": "America/New_York",
  "GMT-4": "America/Caracas",
  "GMT-3": "America/Argentina/Buenos_Aires",
  "GMT-2": "Atlantic/South_Georgia",
  "GMT-1": "Atlantic/Azores",
  "GMT+0": "Europe/London",
  "GMT+1": "Europe/Paris",
  "GMT+2": "Europe/Helsinki",
  "GMT+3": "Europe/Moscow",
  "GMT+4": "Asia/Baku",
  "GMT+5": "Asia/Karachi",
  "GMT+6": "Asia/Dhaka",
  "GMT+7": "Asia/Bangkok",
  "GMT+8": "Asia/Shanghai",
  "GMT+9": "Asia/Tokyo",
  "GMT+10": "Australia/Sydney",
  "GMT+11": "Pacific/Guadalcanal",
  "GMT+12": "Pacific/Auckland",
};

export function ftzm(value, type) {
  console.log("🚀 ~ file: utils.js ~ line 37 ~ ftzm ~ value", value);
  if (value) {
    const reg = /GMT[+-]\d{1,2}/;
    const timeZoneEnum = store?.getters?.timezone?.find(
      (item) => item.code === value
    )?.message;
    const timeZone = timeZoneEnum?.match(reg);
    if (!type) {
      return dayjs()?.tz(GMT_TIME_ZONES?.[timeZone?.[0]])?.format("HH:mm:ss");
    } else {
      return dayjs()
        ?.tz(GMT_TIME_ZONES?.[timeZone?.[0]])
        ?.format("YYYY-MM-DD HH:mm:ss");
    }
  } else {
    return "Not set timezone";
  }
}

// 容量格式化
export function formatCapacity(capacity) {
  if (capacity <= 0) {
    return "0B";
  } else if (capacity < 1024) {
    return capacity + "B";
  } else if (capacity < 1024 * 1024) {
    return (capacity / 1024).toFixed(2) + "KB";
  } else if (capacity < 1024 * 1024 * 1024) {
    return (capacity / 1024 / 1024).toFixed(2) + "MB";
  } else if (capacity < 1024 * 1024 * 1024 * 1024) {
    return (capacity / 1024 / 1024 / 1024).toFixed(2) + "GB";
  } else if (capacity < 1024 * 1024 * 1024 * 1024 * 1024) {
    return (capacity / 1024 / 1024 / 1024 / 1024).toFixed(2) + "TB";
  } else if (capacity < 1024 * 1024 * 1024 * 1024 * 1024 * 1024) {
    return (capacity / 1024 / 1024 / 1024 / 1024 / 1024).toFixed(2) + "PB";
  }
}
//el-date-picker 近一周、近一月、近一个季度
export function pickerOptions() {
  const vm = window.vm;
  let data = {
    shortcuts: [
      { text: vm.$t("home.Last week"), time: 6 },
      { text: vm.$t("home.Last month"), time: 30 },
      { text: vm.$t("home.Last season"), time: 90 },
      { text: vm.$t("home.Last year"), time: 365 },
    ].map((v) => {
      const { text, time } = v;
      return {
        text,
        onClick(picker) {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * time);
          picker.$emit("pick", [start, end]);
        },
      };
    }),
    value1: [new Date(2000, 10, 10, 10, 10), new Date(2000, 10, 11, 10, 10)],
    value2: "",
  };
  return data;
}
export function formatNum(str) {
  var newStr = "";
  var count = 0;
  // 当数字是整数
  if (str.indexOf(".") == -1) {
    for (var i = str.length - 1; i >= 0; i--) {
      if (count % 3 == 0 && count != 0) {
        newStr = str.charAt(i) + "," + newStr;
      } else {
        newStr = str.charAt(i) + newStr;
      }
      count++;
    }
    str = newStr; //自动补小数点后两位
    return str;
  }
  // 当数字带有小数
  else {
    for (var i = str.indexOf(".") - 1; i >= 0; i--) {
      if (count % 3 == 0 && count != 0) {
        newStr = str.charAt(i) + "," + newStr;
      } else {
        newStr = str.charAt(i) + newStr; //逐个字符相接起来
      }
      count++;
    }
    str = newStr + (str + "000").substr((str + "000").indexOf("."), 4);
    return str;
  }
}

// 返回当前世界时间戳
export function new_date() {
  let myDate = new Date();
  let i = myDate.getTime();
  return i;
}

export function isFileTypeIcon(value) {
  let type = value.toLowerCase();
  if (type === "pdf") return "icon-Pdf";
  if (type === "zip") return "icon-zip";
  if (type === "jpg") return "icon-tupian";
  if (type === "jpeg") return "icon-tupian";
  if (type === "png") return "icon-tupian";
  if (type === "docx") return "icon-Word";
  if (type === "xlsx") return "icon-Excel";
  if (type === "xls") return "icon-Excel";
  if (type === "pptx") return "icon-PPT";
  if (type === "mp4" || type === "avi" || type === "mkv" || type === "mov" || type === "wmv" || type === "flv") return "icon-shipin";
  if (type === "audio") return "icon-music";
}

export function isFindData(arr, id, field = "value", msg = "key") {
  return (
    arr.filter((item) => item[field] == id)[0] &&
    arr.filter((item) => item[field] == id)[0][msg]
  );
}

export function toPercent(num, total) {
  return Number(Math.round((num / total) * 10000) / 100.0); // 小数点后两位百分比
}

export function formatFileSize(size) {
  let mYsize = size;
  if (mYsize == 0) return 0 + "B";
  if (mYsize < 0.1) {
    // 小于0.1KB转换为B
    mYsize = parseFloat((mYsize * 1024).toFixed(2)) + "B";
  } else if (mYsize > 0.1 * 1024 && mYsize < 0.1 * 1024 * 1024) {
    // 大于0.1MB转换为MB
    mYsize = parseFloat((mYsize / 1024).toFixed(2)) + "MB";
  } else if (mYsize > 0.1 * 1024 * 1024) {
    // 大于0.1GB转换为GB
    mYsize = parseFloat((mYsize / 1024 / 1024).toFixed(2)) + "GB";
  } else {
    mYsize = mYsize + "KB";
  }

  return mYsize;
}

let result = []
export function getParentIds(list, key) {
  for(let o of list || []) {
    if(o[key] == 2) {
      result.push(o)
    }
    if(o.childTreeMenu && o.childTreeMenu.length) {
      getParentIds(o.childTreeMenu)
    }
  }
  return result
}

export function replayImgShow(e) {
  if(e.target.tagName == 'IMG') {
    e.stopPropagation();
    
    this.imgPreview = e.target.src;

    let content = document.createElement('div');
    content.className = "imgDolg";
    content.id = "imgPreview";

    let imageContent = document.createElement('div');
    imageContent.className = "imageContent";

    let img = document.createElement('img');
    img.id = "previewImage";
    img.src = this.imgPreview;
    img.onclick = (e) => {
      e.stopPropagation();
    }
    imageContent.appendChild(img);

    let close = document.createElement('div');
    close.className = "close";
    let closeIcon = document.createElement('i');
    closeIcon.className = "el-icon-close";
    close.appendChild(closeIcon);
    close.onclick = function(e) {
      e.stopPropagation();
      document.getElementById("imgPreview").remove();
    }

    let imageControl = document.createElement('div');
    imageControl.className = "imageControl";
    
    let enlarge = document.createElement('div');
    enlarge.className = "controlItem";
    let enlargeIcon = document.createElement('i');
    enlargeIcon.className = "el-icon-zoom-in";
    let scaleValue = 1;
    enlarge.onclick = (e) => {
      e.stopPropagation();
      scaleValue+=0.1;
      document.getElementById("previewImage").style.transform = `scale(${scaleValue})`
    }
    enlarge.appendChild(enlargeIcon);

    let shrink = document.createElement('div');
    shrink.className = "controlItem";
    let shrinkIcon = document.createElement('i');
    shrinkIcon.className = "el-icon-zoom-out";
    shrink.onclick = (e) => {
      e.stopPropagation();
      scaleValue-=0.1;
      document.getElementById("previewImage").style.transform = `scale(${scaleValue})`
    }
    shrink.appendChild(shrinkIcon);

    let maximize = document.createElement('div');
    maximize.className = "controlItem";
    let maximizeIcon = document.createElement('i');
    maximizeIcon.className = "el-icon-full-screen";
    maximize.onclick = (e) => {
      e.stopPropagation();
      const image = new Image();
      image.src = this.imgPreview;
      image.style.width = "100%";
      const imgWindow = window.open(this.imgPreview);
      imgWindow.document.write(image.outerHTML);
    }
    maximize.appendChild(maximizeIcon);

    imageControl.appendChild(enlarge);
    imageControl.appendChild(shrink);
    imageControl.appendChild(maximize);
    
    content.appendChild(imageContent);
    content.appendChild(close);
    content.appendChild(imageControl);

    content.onclick = function() {
      document.getElementById("imgPreview").remove();
    }

    document.body.appendChild(content);
  }
}

export function replaceLinks(str) {
  var pattern = /((https?|ftp):\/\/[^\s/$.?#].[^\s]*)/gi;
  var replacedStr = str.replace(pattern, '<a href="$1" class="aLink" target="_blank">$1</a>');
  return replacedStr;
}

export function uniqueArray(arr, key) {
	let temp = [];
	arr.forEach(function (a) {
		var check = temp.every(function (b) {
			return a[key] !== b[key];
		})
		check ? temp.push(a) : ''
	})
	return temp;
}

export function getFileMd5(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsArrayBuffer(file)
    fileReader.onload = e => {
      let md5Data = md5(e.target.result);
      resolve(md5Data)
    }
  })
}
