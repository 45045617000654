import Vue from "vue";
import * as echarts from "echarts";
import store from "@/store";

const echartsInit = (dom) => {
  echarts.registerTheme("westeros", {
    color: store.getters.chartLineBarColors,
    pie: {
      color: store.getters.chartPieColors,
    },
    bar: {
      barMaxWidth: 32,
      itemStyle: {
        
      }
    },
    tooltip: {
      backgroundColor: "#122545",
      borderWidth: 0,
      padding: [8, 12],
      textStyle: {
        color: "#ffffff"
      }
    },
    dataZoom: {
      width: "90%",
      backgroundColor: "rgba(0,0,0,0)",
      borderColor: '#ffffff',
      moveHandleSize: 0,
      backgroundColor: '#F7F9FC',
      fillerColor: "rgba(47,63,80,0.1)",
      handleColor: store.getters.chartLineBarColors[0],
      handleSize: "100%",
      borderRadius: 0,
      dataBackground: {
        areaStyle: {
          color: "#076F49"
        }
      },
      selectedDataBackground: {
        lineStyle: {
          color: '#e0e0e0'
        },
        areaStyle: {
          color: store.getters.chartLineBarColors[0]
        }
      },
      textStyle: {
        color: "#999999",
      }
    },
  });
  return echarts.init(dom, "westeros", {
    devicePixelRatio: 2.5,
  });
}

Vue.prototype.$echartsInit = echartsInit;
