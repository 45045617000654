module.exports = {
  el: {
    colorpicker: {
      confirm: 'OK',
      clear: 'Clear',
    },
    datepicker: {
      now: 'Now',
      today: 'Today',
      cancel: 'Cancel',
      clear: 'Clear',
      confirm: 'OK',
      selectDate: 'Select date',
      selectTime: 'Select time',
      startDate: 'Start Date',
      startTime: 'Start Time',
      endDate: 'End Date',
      endTime: 'End Time',
      prevYear: 'Previous Year',
      nextYear: 'Next Year',
      prevMonth: 'Previous Month',
      nextMonth: 'Next Month',
      year: '',
      weekfn: (week) => {
        return `week ${week.values.n}`;
      },
      month1: 'January',
      month2: 'February',
      month3: 'March',
      month4: 'April',
      month5: 'May',
      month6: 'June',
      month7: 'July',
      month8: 'August',
      month9: 'September',
      month10: 'October',
      month11: 'November',
      month12: 'December',
      January: 'January',
      February: 'February',
      March: 'March',
      April: 'April',
      May: 'May',
      June: 'June',
      July: 'July',
      August: 'August',
      September: 'September',
      October: 'October',
      November: 'November',
      December: 'December',
      week: 'Week',
      weeks: {
        sun: 'Sun',
        mon: 'Mon',
        tue: 'Tue',
        wed: 'Wed',
        thu: 'Thu',
        fri: 'Fri',
        sat: 'Sat',
      },
      months: {
        jan: 'Jan',
        feb: 'Feb',
        mar: 'Mar',
        apr: 'Apr',
        may: 'May',
        jun: 'Jun',
        jul: 'Jul',
        aug: 'Aug',
        sep: 'Sep',
        oct: 'Oct',
        nov: 'Nov',
        dec: 'Dec',
      },
    },
    xiaoxie: {
      customer: 'customer',
      country: 'Country',
      currency: 'currency',
    },
    home: {
      'Select Forwarder': 'Select Forwarder',
      at: 'at',
      PURCHASER: 'Purchaser',
      VENDOR: 'Vendor',
      FORWARDER: 'Forwarder',
      People: 'People',
      'Read / Unread': 'All / Unread',
      PIorCINo: 'PI or CI No.',
      'Assign/Notify': 'Assign / Notify',
      Pleasetype: 'Please select the type',
      'Change the state': 'Change administrator account ?',
      'Please select member': 'Please select member',
      comment: 'New comment',
      event: 'New event',
      toDo: 'New to do',
      'New member apply': 'New member apply',
      'New customer apply': 'New customer apply',
      zeroM: 'https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/schedule/0.png',
      zeroW: 'https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/schedule/1.png',
      'Illegal Token, Please log out and log in again':
        'Illegal Token, Please log out and log in again',
      'Token expired, Please log out and log in again':
        'Token expired, Please log out and log in again',
      'Your account has been logged in other places, please log out and log in again':
        'Your account has been logged in other places, please log out and log in again',
      'Confirm logout': 'Confirm logout',
      'Re-Login': 'Re-Login',
      'Last week': 'Last week',
      'Last month': 'Last month',
      'Last season': 'Last season',
      'Last year': 'Last year',
      'Chat Top': 'Chat Top',
      'Cancel Topping': 'Cancel Topping',
      NOTICE: 'Notice',
      OPERATION: 'Operation',
      'Please enter': 'Please enter',
      'Please select': 'Please select',
      CREATE: 'Create',
      Create: 'Create',
      CIAdd: 'Add',
      Loading: 'Loading...',
      Read: 'Read',
      Unread: 'Unread',
      Home: 'Home',
      Parther: 'Partner',
      Order: 'Order',
      Files: 'File',
      Tracking: 'Tracking',
      Dashboard: 'Dashboard',
      dashboardCapacityMonit: 'Account & Storage',
      customerTitle: 'Customer',
      customerTitle1: 'Supplier',
      new: 'New',
      connectionTitle: 'Create Connection',
      companyLogo: 'Company Logo',
      companyName: 'Company Name',
      cantfind: `Can't find?`,
      inviteCustomers: `Invite customer to create account`,
      inviteSupplier: '邀请供应商创建公司',
      exhibition: 'Show customer to',
      showTo: 'Select people',
      email: 'Email',
      VATNo: 'VAT No.',
      contact: 'Contact',
      choosethecountry: 'Choose the country',
      timezone: 'Timezone',
      choosethetimezone: 'Choose the timezone',
      createConnectionNow: 'Create Connection Now',
      Pleasecompletetheinformation: 'Please complete the information',
      createCustomerSuccesfully: 'Create Successfully',
      Unfollow: 'Unfollow',
      Follow: 'Follow',
      inviteEmployees: 'Invite colleague',
      Hide: 'Hide',
      Remove: 'Remove',
      Invite: 'Invite colleagues to join',
      InviteConfirm: 'Confirm',
      InviteSuccessful: 'Invite Successful',
      ConfirmRemoveConnection: 'Confirm remove connection?',
      Remove: 'Remove',
      DeleteSuccessful: 'Delete Successful',
      Edit: 'Edit',
      Delete: 'Delete',
      Dashboard: 'Dashboard',
      Message: 'Message',
      Event: 'Event',
      Todo: 'To-do',
      order: 'Order',
      CInumber: 'CI number',
      PInumber: 'PI number',
      Pleaseselect: 'Please Select',
      Customer: 'Customer',
      Currency: 'Currency',
      OrderAmount: 'Order Amount',
      Groups: 'Groups',
      ProducingStatus: 'Status',
      producing: 'Producing',
      AddForwarder: 'Add Forwarder',
      Document: 'Document',
      Createdsucceeded: 'Upload Successful',
      SelectDocument: 'Select Document',
      uploaded: 'Uploaded',
      Clicktoselectforwarder: 'Click to select forwarder',
      eopleSelected: 'people selected',
      ClicktocreateGroups: 'Click to create group',
      Invitecolleaguesandcustomerstothegroup:
        'Invite colleagues and customers to the group',
      createOrder: 'Create Order',
      updateOrder: 'Update Order',
      'Order import': 'Order import',
      'Download template': 'Download template',
      CreateOrderNow: 'Create Order Now',
      UpdateOrderNow: 'Update Order Now',
      groupConfirm: 'Group confirm',
      'Quickly create groups': 'Quickly create groups',
      'Update group': 'Update group',
      'set group': 'Set group',
      'Select group': 'Select group',
      Cancel: 'Cancel',
      Confirm: 'Confirm',
      'Todo status update': 'To-do status update',
      'Finshed this todo?': 'Finish this to-do?',
      'Back to UNFINISHED?': 'Back to UNFINISHED?',
      Finished: 'Finished',
      Unfinished: 'Unfinished',
      Unstart: 'Unstart',
      ConfirmAgree: 'Confirm',
      ConfirmReject: 'Confirm',
      Whetherusercompany: 'Refuse user to join company?',
      youinvitation: 'Are you sure to refusethe invitation？',
      Whethercompany: 'Approve user to join company?',
      Areinvitation: 'Confirm namelist and accept invitation？',
      'Assign colleagues to check': 'Invite colleagues to join',
      'Confirm to modify the partner？': 'Confirm colleague list？',
      Invitethesuccessful: 'Connection created successfully',
      Copysucceeded: 'Copy succeeded',
      UploadSucceeded: 'Upload succeeded',
      Deletionsucceeded: 'Deletion succeeded',
      OrdersTotal: 'Orders Total',
      Otherlogins: 'log in with',
      ContactAdministrator: 'Contact Administrator',
      language: '中文',
      MessageBoard: 'Message Board',
      MyDocuments: "My documents",
      MyInvitation: 'My Invitation',
      MyDashboard: 'My Dashboard',
      Group: 'Group',
      SelectForwarder: 'Select Forwarder',
      dow: 'https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/file/dow.png',
      full: 'https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/file/full.png',
      share:
        'https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/file/share.png',
      LOGOUT: 'LOG OUT',
      Confirmtheexit: 'Confirm log out',
      warn: 'Warn',
      Noinformation: 'No data now',
      country: 'Country',
      Pleaseselectcountry: 'Please select country',
      PleaseinputCInumber: 'Please input CI',
      PleaseinputPInumber: 'Please input PI',
      PleaseselectCustomer: 'Please select customer',
      PleaseselectSupplier: 'Please select supplier',
      'Please upload the history file': 'Please upload the history file',
      Pleaseselectcurrency: 'Please select currency',
      Pleaseinputamount: 'Please input amount',
      Pleaseselectmember: 'Please select member',
      PleaseselectStatus: 'Please select Status',
      OR: 'OR',
      SendInvitationlink: 'Send Invitation link',
      copylink: 'copy link',
      Email: 'Email',
      CustomerName: 'Customer Name',
      Copysucceeded: 'Copy succeeded',
      Createdsuccessfully: 'Created successfully',
      Invitationdeclined: 'Invitation declined',
      Applythrough: 'Apply through',
      PleaseinputVAT: 'Please input VAT No.',
      PleaseinputcorrectVAT: 'Please input correct VAT No.',
      Pleaseinputaliasname: 'Please input alias name',
      Pleaseuploadlogo: 'Please upload logo',
      Pleaseselectcompanytype: 'Please select company type',
      'Please input contact': 'Please input contact',
      'lease select country': 'Please select country',
      'Please input email': 'Please input email',
      'Please input name': 'Please input name',
      'Please select timezone': 'Please select timezone',
      'Please enter the detailed address': 'Please enter the detailed address',
      phone: 'Phone',
      VerificationCode: 'Verification Code',
      Approveconfirm: 'Approve confirm',
      explain_img:
        'https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/explain_img.png',
      'Please fulfill the personal information and bind the company firstly':
        'Please fulfill the personal information and bind the company firstly.',
    },
    cascader: {
      noMatch: 'No matching data',
      loading: 'Loading',
      placeholder: 'Select',
      noData: 'No data',
    },
    pagination: {
      goto: 'Go to',
      pagesize: '/page',
      total: 'Total {total}',
      pageClassifier: '',
    },
    messagebox: {
      title: 'Message',
      confirm: 'OK',
      cancel: 'Cancel',
      error: 'Illegal input',
    },
    upload: {
      deleteTip: 'press delete to remove',
      delete: 'Delete',
      preview: 'Preview',
      continue: 'Continue',
    },
    table: {
      emptyText: 'No Data',
      confirmFilter: 'Confirm',
      resetFilter: 'Reset',
      clearFilter: 'All',
      sumText: 'Sum',
    },
    tree: {
      emptyText: 'No Data',
    },
    transfer: {
      noMatch: 'No matching data',
      noData: 'No data',
      titles: ['List 1', 'List 2'], // to be translated
      filterPlaceholder: 'Enter keyword', // to be translated
      noCheckedFormat: '{total} items', // to be translated
      hasCheckedFormat: '{checked}/{total} checked', // to be translated
    },
    image: {
      error: 'FAILED',
    },
    pageHeader: {
      title: 'Back', // to be translated
    },
    popconfirm: {
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    },
    empty: {
      description: 'No Data',
    },
    select: {
      noMatch: 'No Data',
      noData: 'No Data',
    },
  },
  overallSituation: {
    noData: 'No data now',
  },
  xiaoxie: {
    customer: 'customer',
    country: 'country',
    currency: 'currency',
    'Successfully established relationship': 'Successfully established relationship',
    'Please activate your account via email':
      'Please activate your account via email',
    'register successfully': 'Register successfully',
    'Please verfify your email': 'Please verfify your email',
    'Confirmation email has been sent to':
      'Confirmation email has been sent to',
    'Please click on the link in that email to complete your signup':
      'Please click on the link in that email to complete your signup',
    'check your spam folder': `if you don't see it, you may need to check your spam folder`,
    'Still cant find this email': `Still can't find this email`,
    'Resend email': 'Resend email',
    'Need help': 'Need help',
    'Contact us': 'Contact us',
    'Order return': 'Order return',
    CIFallbacktip:
      'Return to PI, below CI and related events & documents will be deleted, confirm to process ?',
    amount: 'Amount',
    State: 'State',
    'Order Amount Confirmation': 'Order Amount Confirmation',
    'Original PI order currency': 'Currency of original PI',
    'Original PI order amount': 'Amount of original PI',
    Filter: 'Filter',
    'Split reminder': 'Split reminder',
    'Successfully split the order': 'Successfully split the order',
    'The current PI order has been split, return to the order list page':
      'Current PI already changed to CI, confirm back to order list',
    'The current CI order has been rolled back, return to the order list page':
      'Current CI already deleted and returned to PI, confirm back to order list',
    Notification: 'Notification',
    confirm: 'Confirm',
    cancel: 'Cancel',
    'Agreed to your cooperation invitation':
      'Agreed to your cooperation invitation',
    'Confirm unfollow this order': 'Confirm unfollow this order？',
    'Delete this todo': 'Delete this todo？',
    'Company name': 'Company Name',
    Title: 'Title',
    Currency: 'Currency',
    CompanyName: 'Company Name / Company Alias',
  },
  home: {
    'Quarter on quarter': 'Quarter on quarter',
    'Quarter to quarter ratio': 'Quarter to quarter ratio',
    'Year on year': 'Year on year',
    at: 'at',
    edit: 'edit',
    allSelect: 'All select',
    Asc: 'Asc',
    detailText: 'detail',
    updateTime: 'Update time',
    Desc: 'Desc',
    waybill: 'waybill',
    'clock in': 'clock in',
    aliasName: 'Alias name',
    Follow: 'Follow',
    AlreadyFollow: 'Already follow',
    Nofollow: 'Nofollow',
    createFolder: 'Create folder',
    'Please enter folder name': 'Please enter folder name',
    'Please enter file name': 'Please enter file name',
    'Are you sure to delete the folder': 'Are you sure to delete the folder',
    'The folder cannot be restored after deletion': 'The folder cannot be restored after deletion',
    defaultFolder: "Default folder",
    'Share with me': "Share with me",
    'Custom Customers': 'Self defined',
    'Account transfer': 'Account transfer',
    'Account transfer to': 'Account transfer to',
    'Sent successfully': 'Sent successfully',
    selectedPerson(row) {
      return `${row.values.n} person selected`
    },
    Unnamed: 'Unnamed',
    'Join/Create Company': 'Join/Create Company',
    'Only by joining/creating a company can you view it': 'Only by joining/creating a company can you view it',
    'Successfully invited employees': 'Successfully invited employees',
    'Please enter a notice content': 'Please enter a notice content',
    'Please enter a group name': 'Please enter a group name',
    'The group name must not be empty': 'The group name must not be empty',
    allMember: "All member",
    'Invitation sent successfully': 'Invitation sent successfully',
    createSuccess: "Create success",
    editSuccess: "Edit success",
    createCompany: 'Create',
    'Confirma join': 'Confirma join',
    'Return personal information': 'Return personal information',
    'Select company you want to join': 'Select company you want to join',
    'Confirmation Information': 'Confirmation Information',
    'Modify information': 'Modify information',
    'Personal Information': 'Personal Information',
    informationCenter: 'Information center',
    OrderRelatedPersonnel: ({values}) => {
      return `${values.n} related personnel`
    },
    moreAction: "More action",
    seeMore: "See more",
    'Improve personal information': 'Improve personal information',
    'Create/Join Company': 'Create/Join Company',
    PURCHASER: 'Purchaser',
    'List of company personnel': 'List of company personnel',
    VENDOR: 'Vendor',
    FORWARDER: 'Forwarder',
    Download: 'Download File',
    List: 'List',
    People: 'People',
    'Read / Unread': 'All / Unread',
    'incomplete / Completed': 'Incomplete / Completed',
    incomplete: 'Incomplete',
    Completed: 'Completed',
    PIorCINo: 'PI or CI No.',
    'Assign/Notify': 'Assign / Notify',
    Pleasetype: 'Please select the type',
    'Change the state': 'Change administrator account',
    'Please select member': 'Please select member',
    comment: 'New comment',
    event: 'New event',
    toDo: 'New to do',
    'New member apply': 'New member apply',
    'New customer apply': 'New customer apply',
    zeroM: 'https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/schedule/0.png',
    zeroW: 'https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/schedule/1.png',
    'Illegal Token, Please log out and log in again':
      'Illegal Token, Please log out and log in again',
    'Token expired, Please log out and log in again':
      'Token expired, Please log out and log in again',
    'Your account has been logged in other places, please log out and log in again':
      'Your account has been logged in other places, please log out and log in again',
    'Confirm logout': 'Confirm logout',
    'Re-Login': 'Re-Login',
    'Last week': 'Last week',
    'Last month': 'Last month',
    'Last season': 'Last season',
    'Last year': 'Last year',
    'Chat Top': 'Chat Top',
    'Cancel Topping': 'Cancel Topping',
    NOTICE: 'Notice',
    OPERATION: 'Operation',
    'Please enter': 'Please enter',
    'Please select': 'Please select',
    CREATE: 'Create',
    Create: 'Create',
    CIAdd: 'Add',
    Loading: 'Loading...',
    Read: 'Read',
    Unread: 'Unread',
    Home: 'Home',
    Parther: 'Partner',
    Order: 'Order',
    Files: 'File',
    Tracking: 'Tracking',
    Dashboard: 'Dashboard',
    dashboardCapacityMonit: 'Account & Storage',
    customerTitle: 'Customer',
    customerTitle1: 'Supplier',
    new: 'New',
    connectionTitle: 'Create Connection',
    companyLogo: 'Company Logo',
    companyName: 'Company Name',
    cantfind: `Can't find?`,
    inviteCustomers: `Invite customer create account`,
    inviteSupplier: 'Invite supplier create account',
    exhibition: 'Show customer to',
    showTo: 'Select people',
    email: 'Email',
    VATNo: 'VAT No.',
    contact: 'Contact',
    choosethecountry: 'Choose the country',
    timezone: 'Timezone',
    choosethetimezone: 'Choose the timezone',
    createConnectionNow: 'Create Connection Now',
    Pleasecompletetheinformation: 'Please complete the information',
    createCustomerSuccesfully: 'Create Successfully',
    Unfollow: 'Unfollow',
    Follow: 'Follow',
    Translate: 'Translate',
    inviteEmployees: 'Invite colleague',
    Hide: 'Hide',
    Remove: 'Remove',
    Invite: 'Invite colleagues to join',
    InviteConfirm: 'Confirm',
    InviteSuccessful: 'Invite Successful',
    ConfirmRemoveConnection: 'Confirm remove connection?',
    Remove: 'Remove',
    DeleteSuccessful: 'Delete Successful',
    Edit: 'Edit',
    Delete: 'Delete',
    Dashboard: 'Dashboard',
    Message: 'Message',
    Event: 'Event',
    Todo: 'To-do',
    order: 'Order',
    CInumber: 'CI number',
    PInumber: 'PI number',
    Pleaseselect: 'Select',
    Customer: 'Customer',
    Currency: 'Currency',
    OrderAmount: (val) => {
      return `${val.values.n} amount`
    },
    OrderNumber: (val) => {
      return `${val.values.n} number`
    },
    Groups: 'Groups',
    ProducingStatus: 'Status',
    producing: 'Producing',
    AddForwarder: 'Add Forwarder',
    Document: 'Document',
    Createdsucceeded: 'Upload Successful',
    SelectDocument: 'Select Document',
    uploaded: 'Uploaded',
    Clicktoselectforwarder: 'Click to select forwarder',
    eopleSelected: 'people selected',
    ClicktocreateGroups: 'Click to create group',
    Invitecolleaguesandcustomerstothegroup: 'Invite colleagues and customers to the group',
    createOrder: (val) => {
      return `Create ${val.values.n}`
    },
    updateOrder: (val) => {
      return `Update ${val.values.n}`
    },
    generateOrder: (val) => {
      return `Generate ${val.values.n}`
    },
    'Order import': (val) => {
      return `${val.values?.n} import`
    },
    'Download template': 'Download template',
    CreateOrderNow: 'Create Order Now',
    UpdateOrderNow: 'Update Order',
    groupConfirm: 'Group confirm',
    'Quickly create groups': 'Quickly create groups',
    'Update group': 'Update group',
    'set group': 'Set group',
    'Select group': 'Select group',
    Cancel: 'Cancel',
    Confirm: 'Confirm',
    'Todo status update': 'To-do status update',
    'Finshed this todo?': 'Finish this to-do?',
    'Back to UNFINISHED?': 'Back to UNFINISHED?',
    Finished: 'Finished',
    Unfinished: 'Unfinished',
    Unstart: 'Unstart',
    ConfirmAgree: 'Confirm',
    ConfirmReject: 'Confirm',
    Whetherusercompany: 'Refuse user to join company？',
    youinvitation: 'Are you sure to refusethe invitation？',
    'Member successfully joined': 'Member successfully joined',
    'Refuse members to join': 'Refuse members to join',
    'Relationship established successfully': 'Relationship established successfully',
    'Refuse to establish a relationship': 'Refuse to establish a relationship',
    Whethercompany: 'Approve user to join company?',
    Areinvitation: 'Confirm namelist and accept invitation？',
    'Assign colleagues to check': 'Invite colleagues to join',
    'Confirm to modify the partner？': 'Confirm colleague list？',
    Invitethesuccessful: 'People added successfully',
    Copysucceeded: 'Copy succeeded',
    UploadSucceeded: 'Upload succeeded',
    Deletionsucceeded: 'Deletion succeeded',
    OrdersTotal: (val) => {
      return `${val.values.count} Total`
    },
    Otherlogins: 'log in with',
    ContactAdministrator: 'Contact Administrator',
    language: '中文',
    MessageBoard: 'Message Board',
    MyDocuments: "My documents",
    'Invitation code': 'Invitation code',
    'Invitation Code Usage Record': 'Invitation Code Usage Record',
    'Invitation Code Management': 'Invite code manage',
    'Generate invitation code': 'Generate invite code',
    'My History Invitation': 'My History Invite',
    'Employee History Invitation': 'Employee History Invite',
    'This invitation code can only be used once': 'This invitation code can only be used once, Validity period 7 days',
    'Copy Invite code': 'Copy Invite code',
    'Send via email': 'Send via email',
    'Please enter the company invitation code': 'Please enter the company invitation code',
    MyInvitation: 'My Invitation',
    MyDashboard: 'My Dashboard',
    Group: 'Group',
    SelectForwarder: 'Select Forwarder',
    dow: 'https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/file/dow.png',
    full: 'https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/file/full.png',
    share:
      'https://os.hnmincheng.com/yh1/uploads/iKuIJP/GlIKheQrbfuVeb4c714c.png',
    LOGOUT: 'LOG OUT',
    Confirmtheexit: 'Confirm log out',
    warn: 'Warn',
    Noinformation: 'No data now',
    country: 'Country',
    Pleaseselectcountry: 'Please select country',
    PleaseinputCInumber: 'Please input CI',
    PleaseinputPInumber: ({values}) => {
      return `Please input ${values.n} name`
    },
    PleaseselectCustomer: 'Please select customer',
    PleaseInputProduct: 'Product material number, type and size',
    PleaseselectSupplier: 'Please select supplier',
    'Please upload the history file': 'Please upload the history file',
    Pleaseselectcurrency: 'Please select currency',
    Pleaseinputamount: 'Please input amount',
    Pleaseselectmember: 'Please select member',
    PleaseselectStatus: 'Please select Status',
    OR: 'OR',
    SendInvitationlink: 'Send Invitation link',
    copylink: 'copy link',
    Email: 'Email',
    CustomerName: 'Customer Name',
    Copysucceeded: 'Copy succeeded',
    Createdsuccessfully: 'Created successful',
    Invitationdeclined: 'Invitation declined',
    Applythrough: 'Apply through',
    PleaseinputVAT: 'Please input VAT No.',
    PleaseinputcorrectVAT: 'Please input correct VAT No.',
    Pleaseinputaliasname: 'Please input alias name',
    PleaseuploadCompanylogo: 'Please upload company logo',
    PleaseuploadCustomerlogo: 'Please upload customer logo',
    Pleaseselectcompanytype: 'Please select company type',
    'Please input contact': 'Please input contact',
    'lease select country': 'Please select country',
    'Please input email': 'Please input email',
    'Please input name': 'Please input name',
    'Please select timezone': 'Please select timezone',
    'Please enter the detailed address': 'Please enter the detailed address',
    phone: 'Phone',
    VerificationCode: 'Verification Code',
    Approveconfirm: 'Approve confirm',
    explain_img:
      'https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/explain_img.png',
    'Please fulfill the personal information and bind the company firstly':
      'Please fulfill the personal information and bind the company firstly.',
    'Good morning': 'Good morning',
    'Good noon': 'Good noon',
    'Good afternoon': 'Good afternoon',
    'Good evening': 'Good evening'
  },
  InformationCenter: {
    ping: 'Ping',
    Todo: 'To-do',
    Comment: 'Comment',
    Event: 'Event',
    Apply: 'Apply',
    Nomoremessages: 'No more message',
    PleaseWritesomething: 'Please write ...',
    Send: 'Send',
    Personaldetails: 'Personal details',
    update: 'update',
    created: 'created',
    notified: 'notified',
    you: 'you',
    assignedto: 'Assign to',
    in: 'in',
    Whendonenotify: 'After notify',
    Dueon: 'Due on',
    Notes: 'Notes',
    Status: 'Status',
    UNSTARTED: 'UNSTARTED',
    FINISHED: 'FINISHED',
    UNFINISHED: 'UNFINISHED',
    Createby: 'Created by',
    Record: 'History',
    Unfinished: 'Unfinish',
    Finished: 'Finish',
    Sortby: 'Sort',
    replied: 'replied',
    Comments: 'Commented',
    posted: 'posted a new',
    Company: 'Company',
    Personal: 'Personal',
    Name: 'Name',
    Title: 'Title',
    Agree: 'Agree',
    Refuse: 'Refuse',
    wantstoconnectwithyourcompany: 'wants to connect with your company',
    wantstojoinyourcompany: 'wants to join your company',
    Notification: 'Notification',
    AddressBook: 'Address book'
  },
  Dashboard: {
    'Download as PNG': 'Download As PNG',
    'Please select item no': 'Please select item no.',
    'Please select size': 'Please select size',
    'Please select type': 'Please select type',
    confirmMessage: "Whether to go to the data board？",
    confirmSeeMessage: "Do you want to view this data?",
    confirmSeeMessageFn: (val) => {
      return `Do you want to view ${val.values.n}'s data?`
    },
    'CorrespondingXCoordinate': 'Corresponding X coordinate value',
    'numberValue': 'Number value',
    'Date Range': 'Date Range',
    vip: 'vip',
    ordinary: 'ordinary',
    Matter: "Raw material",
    skuItemNo: 'Material No',
    skuSize: 'size',
    skuType: 'type',
    ExtraData: "More",
    bar: 'bar',
    week: 'Week',
    pie: 'pie',
    BDIIndexNumber: "BDI",
    PMIIndexNumber: "PMI",
    ProductionLogisticsAnalysis: "Production logistics analysis",
    quantity: 'Quantity',
    Proportion: 'Proportion',
    BasicStatistics: 'Basic Statistics',
    allContrastData: 'All Contrast Data',
    Member: 'Member',
    MainSupplier: 'Main Supplier',
    MainCustomers: 'Main Customers',
    MainProducts: 'Main Products',
    MainCountry: 'Main Country',
    Company: "Company",
    Supplier: 'Supplier',
    Customer: 'Customer',
    customer: 'Customer',
    supplier: 'Supplier',
    Orders: (val) => {
      return `${val.values.n}`
    },
    Shipping: 'Shipping',
    Producing: 'Producing',
    Completed: 'Completed',
    Total: 'Total(USD)',
    TotalNumber: 'Total',
    TotalAveage: 'Total aveage(day)',
    SupplierNumber: (val) => {
      let value = "";
      if(val.values.n === '1') value = "Customer";
      else value = "Supplier";
      return `Number(${value})`
    },
    CountryNumber: "Number(Country)",
    ProductNumber: "Number(Sku)",
    Proportion: "Proportion",
    Currency: 'Currency',
    Amount: 'Amount',
    statisticsCountryMarket: 'National information',
    statisticsCustomerMarket: 'Customer information',
    statisticsProductMarket: 'Product infomation',
    BasicStatisticsCountryMarket: 'Market information',
    MonthlySalesAmount: 'Monthly Sales Amount',
    MonthlyPurchaseAmount: 'Monthly Purchase Amount',
    MonthlyPurchaseNumber: 'MonthlyPurchase number',
    ShipmentAmount: 'Shipment amount',
    ShipmentQuantity: 'Shipment quantity',
    Conast: 'Contrast',
    Product: 'Product',
    country: 'Country',
    Customer: 'Customer',
    Monthly: 'Month',
    Quarterly: 'Quarter',
    Yearly: 'Year',
    Startfrom: 'Start from',
    Endoftime: 'End to',
    CommodityPrice: 'Commodity Price',
    PurchaseComparison: 'Sales Comparison',
    CustomerComparison: 'Purchase Comparison',
    SupplierComparison: 'Supplier Comparison',
    Exchangerate: 'Exchange rate',
    CNY: 'CNY',
    USD: 'USD',
    JPY: 'JPY',
    HKD: 'HKD',
    EUR: 'EUR',
    GBP: 'GBP',
    Exportdata: 'Export data',
    orderTotal: 'total(USD)',
    ExportProportion: 'Export proportion',
    'Supplier': 'Supplier',
    'customer': 'Customer',
    'SKU(unit price)': 'SKU(unit price)',
    'SKU(Amount)': 'SKU(Amount)',
    'SKU(Amount)Pie': 'SKU(Amount)',
    'SKU(Amount)Bar': 'SKU(Amount)',
    whole: 'All',
    Statisticalcharts: 'Statistical chart',
    countryEn: 'countryEn',
    Capital: 'Capital',
    Area: 'Area(km²)',
    Population: 'Population',
    Currency: 'Currency',
    Politics: 'Politics',
    GdpTotal: 'GdpTotal(USD)',
    'GDP per capita': 'GDP Avg(USD)',
    'Calling code': 'Calling code',
    'Official languages': 'Language',
    'Province': 'Province',
    'Organization' :'Organization',
    'Religion': 'Religion',
    CompanyName: 'Company name',
    CompanyEmail: 'Company email',
    CompanyContact: 'Contact',
    VATNO: 'Vat.no',
    Country: 'Country',
    Address: 'Address',
    Website: 'Website',
    CountryName: 'Country',
    maxValue: "max value",
    minValue: "min value",
    avgValue: "avg value"
  },
  dashboardCapacityMonit: {
    'Storage Usage': 'Storage Usage(G)',
    Free: 'Free',
    Occupied: 'Occupied',
    'Total Space': 'Total Space',
    File: 'File',
    Account: 'Account',
    'File occupancy distribution': 'Distribution',
    'Partner Account': 'Partner Account',
    'Employee Account': 'Employee Account',
    'Employee Name': 'Employee Name',
    Search: 'Search',
    'Clear All Filters': 'Clear All Filters',
    'File Quantity': 'File Quantity',
    Storage: 'Storage(MB)',
    'Involved Order': 'Involved Order',
    Distribution: 'Distribution',
  },
  Files: {
    'shared the file': 'shared the file',
    'File Name': 'File Name',
    'File type': 'File Type',
    'File Link': 'Link',
    'Copy all information': 'Copy all information',
    'Copy Link': 'Copy Link',
    'Copy succeeded': 'Copy succeeded',
    'Select people': 'Select people',
    'Select company': 'Select company',
    'Input Name': 'Enter Name',
    Send: 'Share file',
    Company: 'Company',
    Name: 'Name',
    Selectrange: 'Select range',
    Filename: 'File',
    'Type File name': 'Type File name',
    'Type CI number': 'Type CI number',
    'Type PI number': 'Type PI number',
    'Type Uploader name': 'Type Uploader name',
    CI: ' CI',
    PI: ' PI',
    Uploader: 'Uploader',
    inputcontent: 'Input here',
    Search: 'Search',
    ClearAllFilters: 'Clear All Filters',
    Export: 'Export',
    ExportPng: 'Export png image',
    ExportExcel: 'Export excel',
    ExportReportForms: 'Export report forms',
    ExportLoading: 'Export in progress.....',
    Type: 'Type',
    'Size(KB)': 'Size(KB)',
    Format: 'Format',
    Creator: 'Uploader',
    CreateTime: 'Create Time',
    Action: 'Action',
    'Send File Success': 'Send File Success',
    'File preview': 'File preview',
    'List only': 'List only',
    'Choose Download Content': 'Choose Download Content'
  },
  registerLoging: {
    CreateAnAccount: 'Create an account',
    Username: 'Username',
    Email: 'Email',
    Password: 'Password',
    ConfirmPassword: 'Confirm password',
    VerificationCode: 'Verification code',
    CreateYourAccount: 'Create your account',
    CellphoneLogin: 'Phone Login',
    GetVerificationCode: 'Get verification code',
    weChatLogin: "Wechat login",
    PleaseinputaEmail: 'Please input email',
    PleaseinputaUserName: 'Please userName',
    'Account name with a minimum of 2 digits': 'Account name with a minimum of 2 digits'
  },
  login: {
    'The password cannot be less than 5 digits': 'The password cannot be less than 5 digits',
    loginWechatError: "Login wechat error",
    Username: 'Username or email',
    Password: 'Password',
    authCode: 'Verification code',
    phoneNo: "Phone number",
    'Password strength': 'Pass vol',
    GetStart: 'Log in',
    AccountLogin: 'Log in Echola',
    AccountButtonLogin: 'Account login',
    HaveNoAccountRegister: 'No account yet？Create an account',
    Alreadyhaveone: 'Already have one？',
    Login: 'Login',
    PasswordInconsistency: 'Password inconsistency',
    enteroneusername: 'please input User Name or Email',
    PleaseinputaSuccessEmail: 'Please enter the correct email',
    Pleaseinputapassword: 'Please input password',
    Pleaseinputaauthcode: 'Please enter received verification code',
    Pleaseenteryourmobilenumber: 'Please enter your phone number',
    Pleaseentertheverificationcode: 'Please enter received verification code',
    'The current mailbox is bound or used':
      'The current mailbox is bound or used',
    'Please confirm that it is a new email that has not been registered':
      'Please confirm that it is a new email that has not been registered',
    'Back to Login': 'Back to Login',
    'Re register': 'Re register',
    Getstarted: 'Log in',
    'The current account name does not exist':
      'The current account name does not exist',
    'Go to register': 'Whether to register',
    Gotoregister: '前往注册',
    Nonempty: 'Nonempty',
    Tooweak: 'weak',
    secondary: 'Fair',
    senior: 'Strong',
    Passwordshouldbe: 'Password must be',
    Atleast: 'At least 8 characters and 1 letter & 1 number',
    Mininumoneuppercase: 'Mininum one uppercase',
    'Mininum one special character': 'Mininum one special character',
    Pleaseenteravalidmobilenumber: 'Please enter a valid mobile number',
    'Forgot password': "Forgot password",
    'Retrieve password': 'Retrieve password'
  },
  people: {
    'Please input name': 'Please input name',
    'Please input email': 'Please input email',
    InvitePeople: 'Invite People',
    NewMessage: 'Invite People',
    EditMessage: 'Edit people',
    createAccount: 'Invite people to create account',
    customerName: 'Name',
    Email: 'Email',
    send: 'Send',
    copyLink: 'Send  invitation  link，copy  link',
    updateSuccess: 'Update Successful',
    description: 'Empty data',
    SavaOrNext: "Save and Next",
    'Choose to join/create': 'Choose to join/create',
    'Join an existing company': 'Join an company',
    'Create Company': 'Create Company',
    'Your note information': 'Your note information',
    'Please enter the correct official website address': 'Please enter the correct official website address'
  },
  CompanyEdit: {
    Edit: 'Edit',
    CompanyName: 'Company Name / Company Alias',
    CompanyAlias: 'Company Alias',
    Type: 'Account type',
    CompanyEmil: 'Email',
    VATNO: 'VAT.No',
    Contact: 'Contact',
    Country: 'Country',
    Address: 'Address',
    Timezone: 'Time zone',
    Website: 'Website',
    UpdatingCompanyAccount: 'Update company info',
    phone: 'Contact',
    Location: 'Country',
  },
  deleteEmployee: {
    Tips: 'Remove',
    deleteEmployee: '	Confirm to remove this colleague?',
    Cancel: 'Cancel',
    Confirmz: 'Confirm',
    'update picture': 'update picture',
  },
  memberList: {
    Member: 'Member',
    Email: 'Email',
    phone: 'phone',
    Title: 'Title',
    Actions: 'Action',
    FindMember: 'Find  Member',
    Search: 'Search',
    more: 'more',
    manage: 'Administrator',
    'Operation succeeded': 'Change successful',
    'Please select a person': 'Please select a person',
    'Permission transfer to': 'Permission transfer to',
    'Manage transfer to': 'Manage transfer to',
    'Confirm transfer': 'Confirm transfer',
    'Manage Transfer': 'Manage Transfer'
  },
  bind: {
    Bind: 'Bind',
    unBind: 'unBind',
    Unbound: 'Unbound',
    BindPhone: 'Bind your mobile number',
    unBindPhone: 'unBind your phone number',
    getCode: 'Get verification code',
    confirmBinding: 'Confirm',
    bindSuccess: 'Bind success',
    unBindSuccess: 'Un bind success'
  },
  EditMine: {
    Name: 'Name',
    Title: 'Title',
    Phone: 'Phone',
    WeChat: 'WeChat',
    Email: 'Email',
    Country: 'Country',
    Timezone: 'Time zone',
    MyCompany: 'My company',
  },
  mineInfo: {
    Account: 'Account',
    Title: 'Title',
    Email: 'Email',
    PhoneWechat: 'Phone/Wechat',
    Location: 'Location',
    TimeZone: 'Time zone',
    Memo: 'Memo',
  },
  order: {
    to: 'to',
    form: 'Tagged by',
    systemEvent: 'System event',
    eventNode: 'Event node',
    share: "Share",
    'Views switching': 'Views switching',
    'See order': 'See order',
    Member: "Member",
    'Click to add components': 'Click to add components',
    'Are you sure to delete this component': 'Are you sure to delete this component',
    'Set as Template': 'Set as Template',
    'Set Template Name': 'Set Template Name',
    'Select Template': 'Select Template',
    'Are you sure to switch to the new template': 'Are you sure to switch to the new template',
    'The data will be replaced after use': 'The data will be replaced after use',
    'Title Color': 'Title Color',
    batchDeleteOrderTip: ({values}) => {
      return `Are you sure to delete ${values.n} orders?`
    },
    'Custom Project Information': ({values}) => {
      return `Custom ${values.n} Information`
    },
    'Are you sure to remove the personnel': 'Are you sure to remove the personnel',
    'After removal, you will no longer be able to view orders': 'After removal, you will no longer be able to view orders',
    'Are you sure to add personnel': 'Are you sure to add personnel',
    'After adding, you can view order related information': 'After adding, you can view order related information',
    'Enter login password to confirm': 'Enter login password to confirm',
    'Unrecoverable after deletion': 'Unrecoverable after deletion',
    'Multiple': 'Multiple',
    'Cancel multiple': 'Cancel multiple',
    'Share orders': ({values}) => {
      return `Share ${values.n}`
    },
    'Order node change': ({values}) => {
      return `${values.n} node change`
    },
    'Order node': ({values}) => {
      return `${values.n} node`
    },
    'Share waybill': 'Share waybill',
    'Transportate detail': 'Transportate detail',
    'Select link validity period': 'Select link validity period',
    'Invitation Map': 'Invitation Map',
    'Do you want to cancel this label': 'Do you want to cancel this label',
    'Do you want to delete this note': 'Do you want to delete this note',
    'Event tag': 'Tag',
    'Tag Type': 'Tag Type',
    'Tag type name': 'Tag type name',
    'Tag cannot repeat': 'Tag cannot repeat',
    'Only 5 label groups can be created': 'Only 5 label groups can be created',
    'My Tag Group': 'My Tag Group',
    'Label Group Name': 'Label Group Name',
    'Add Tag Group': 'Add Tag Group',
    'Related to me': 'Related to me',
    'Delete the forwarder': 'Delete the forwarder？',
    'You have a new event message': 'You have a new event message',
    'Created an event': 'Created an event',
    'Created an comment': 'Created an comment',
    'You have a new event tag message': 'You have a new event tag message',
    'have event deleted': 'Have event deleted',
    'Waybill information has been updated': 'Waybill information has been updated',
    'The order has been modified': 'The order has been modified',
    'You have a new comment message': 'You have a new comment message',
    'You have a new todo message': 'You have a new todo message',
    'todo updated': (week) => {
      return `To-do ${week.values.n} updated`;
    },
    'You have a new order': 'You have a new order',
    uploadSkuFile: 'upload file',
    totalPrice: "total",
    'to previous step': "Back to previous step",
    AfterSplittingOrder: "CI(After splitting order)",
    orderDetail: (val) => {
      return `${val.values?.n} detail`
    },
    shipDetail: 'Shiping detail',
    orderHistory: (val) => {
      return `${val.values?.n} history`
    },
    pending: 'in progress',
    numberOrder: 'orders',
    Created: 'Created', 
    'Please CI perhaps PI': 'Please CI perhaps PI',
    Change: 'Change',
    Changed: 'Changed',
    'change to': 'change to',
    orderStatusForm: 'order status form',
    Clocked: 'Clocked',
    OrderInfo: (val) => {
      return `${val.values?.n} info`
    },
    shipInfo: 'Ship info',
    createTimeString: (week) => {
      return `Created by ${week.values.n} on`;
    },
    members: (week) => {
      return `${week.values.n} members`;
    },
    cny: 'Amount',
    unit: 'Unit Price(CNY/CTN)',
    ctns: 'No. of CTNS',
    ctn: 'No. of pcs/CTN',
    coil: 'No. of pcs/Coil',
    type: 'type',
    'Type of point': 'Type of Point',
    Sizes: 'Sizes(mm)&Shank',
    'Item No': 'Item No.',
    'Order No': (val) => {
      return `${val.values?.n}No.`
    },
    No: 'No.',
    'Add the forwarder': 'Add the forwarder？',
    'File is too large to upload': ({values}) => {
      return `Upload files up to ${values.n}M`
    },
    'Select Forwarder': 'Select Forwarder',
    'Upload succeeded': 'Upload succeeded',
    'External sharing': 'External sharing',
    'Internal sharing': 'Internal sharing',
    'Successfully added': 'Forwarder added successfully',
    update_status: 'Todo status updated',
    orderloding: 'loading...',
    Tracking: ({values}) => {
      return `Tracking ${values.n}`
    },
    'not data': 'not data',
    'The current user has no operation permission for this order':
      'The current user does not have permission to operate',
    'Confirm unfollow this order': 'Confirm unfollow this order？',
    Unfollow: 'Unfollow',
    'skuPreview': 'SKU preview',
    'sku File preview': 'SKU File Preview',
    upload_icon:
      'https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/upload_1_icon.png',
    'Please enter the CI number and upload the file':
      'Please enter the CI number and upload the file',
    'Please CI': 'Please CI',
    'Current PI': 'Current PI',
    'Please enter the CI number after splitting the order and upload the sku file':
      'Please enter the CI number after splitting the order and upload the sku file',
    'It s the last step': "It's the last step",
    'Order progress has been changed to DESTINATION':
      'Order status has been changed to DESTINATION',
    'State torsion succeeded': 'To-do status updated',
    FlightNo: 'Flight No.',
    'Flight Date': 'Flight Date',
    'Please input content': 'Please input content',
    'Please input title': 'Please input title',
    'Shipping info updated': 'Shipping info updated',
    'Back to previous checkpoint': 'Back to previous checkpoint',
    'createEvent succesfully': 'Create event succesfully',
    'Go to next checkpoint': 'Go to next checkpoint',
    Action: 'Action',
    'Cancel update': 'Cancel update',
    'createBusinessShipping succesfully': 'Shippment info created successfully',
    'Confirm add this ship info': 'Confirm create this shippment info?',
    Add: 'Create',
    'completed to-dos': 'completed to-dos',
    And: 'And',
    'Please select country': 'Country',
    Cancels: 'Cancel',
    'Add extra details': 'Add extra details...',
    'Select a date': 'Select a date...',
    'Type names to notify': 'Type names to notify...',
    'Type names to assign': 'Type names to assign...',
    'Describe this': 'Describe this to-do...',
    'Cancel input': 'input Cancel',
    'New subject name is': 'New subject name is：',
    'Your subject name is': 'Your subject name is：',
    'Please create': 'Please input',
    'Please input': 'Please input',
    'New Subject': 'New Subject',
    'Add Forwarder': 'Create',
    'updateBusinessShipping succesfully': 'updateBusinessShipping succesfully',
    'Confirm update this shipment info': 'Update shipment details ？',
    Update: 'Update',
    'Update shipping info': 'Update shipping info',
    Rename: 'Rename',
    renameSubject: 'Please rename this subject',
    'Delete action': 'Delete',
    'Confirm delete this subject': 'Delete this subject ?',
    'Cross border transportation return verification': 'Backtracking of cross-border transportation will cause resetting of shipping and other information. Do you want to return to the previous step?',
    FinshedTodo: 'Finshed this to-do?',
    TodoStatusUpdate: 'To-do status update',
    Buyer: 'Purchaser',
    Supplier: 'Supplier',
    Status: 'Status',
    Value: 'Amount',
    CreateTiem: 'Create Time ',
    OrderNo: (val) => {
      return `${val.values?.n} No.`
    },
    Name: 'Name',
    Company: 'Company',
    Title: 'Title',
    manager: 'Manager',
    inviteNow: "Can't find people？invite now",
    Confirms: 'Confirm',
    Previous: 'Previous',
    Next: 'Next',
    'Files Only': 'Files only',
    Delete: 'Delete',
    Reply: 'Reply',
    'Write away': 'Type ...',
    Confirm: 'Confirm',
    Cancel: 'Cancel',
    'Delete order': 'Delete order',
    Export: 'Export PDF',
    export: 'Export',
    'New Order': (val) => {
      return `New ${val.values.n}`
    },
    'Please select': 'Select company',
    CI: 'CI',
    'Input CI': 'Input CI',
    'Input PI': 'Input PI',
    Company: 'Company',
    'Input Company Name ': 'Input Company Name ',
    Search: 'Search',
    'Clear All Filter': 'Clear All Filter',
    'Order List': (val) => {
      return `${val.values.n} List`
    },
    Hide: 'Hide',
    'Input CI': 'Input CI',
    'Select status': 'Select status',
    'Start from—End to': 'Start from—End to',
    Producing: 'Producing',
    'Local transportation': 'Local transportation',
    'Loading port': 'Loading port',
    Shipping: 'Shipping',
    'Discharge port': 'Discharge port',
    'Destination delivery': 'Destination delivery',
    Destination: 'Destination',
    Amount: 'Amount',
    'Create Time': 'Create Time',
    Status: 'Status',
    Action: 'Action',
    'Order No.+': 'Order No.+',
    'Create Time': 'Create Time',
    Supplier: 'Supplier',
    Purchaser: 'Purchaser',
    Forwarder: 'Forwarder',
    'Ship info': 'Ship info',
    'Air info': 'Air info',
    'Weight/Dimension': 'W/D',
    'Port of Loading': 'Port of Loading',
    Quantity: 'Quantity',
    'Port of discharge': 'Port of discharge',
    'Update succeeded': 'Update Successful',
    'Are you sure to delete this order': ({values}) => {
      return `Are you sure to delete this ${values.n}？`
    },
    Customer: 'Customer',
    'Historical orders': ({values}) => {
      return `Historical ${values.n}`
    },
    'CI No': 'CI No',
    'PI No': 'PI No',
    Amount: 'Amount',
    Cancel: 'Cancel',
    'delete this order': 'Yes, delete',
    PleaseDoubleCheck: 'Please enter CI No. to double check',
    TypeCINumber: 'Type CI number...',
    TypePINumber: ({values}) => {
      return `Type ${values.n}...`
    },
    Delete: 'Delete',
    PleaseCheckTheCINumber: 'Please double check the CI number！',
    PleaseCheckThePINumber: 'Please double check the PI number！',
    'No message': 'No data now',
    'Checkpoint updated': 'Checkpoint updated',
    'Shipping info updated': 'Shipping info updated',
    'Shipping info created': 'Shipping info created',
    orderEvent: 'Order event'
  },
  scheduleNew: {
    tm: 'Moon',
    wk: 'Wk',
    mon: 'MON',
    tue: 'TUE',
    wed: 'WED',
    thu: 'THU',
    fri: 'FRI',
    sat: 'SAT',
    sun: 'SUN',
    Sunday: 'Sunday',
    Monday: 'Monday',
    Tuesday: 'Tuesday',
    Wednesday: 'Wednesday',
    Thursday: 'Thursday',
    Friday: 'Friday',
    Saturday: 'Saturday',
    todoDetail: "Todo detail",
  },
  createShipping: {
    'select date': 'select date',
    Edit: 'Edit',
    'Ocean Shipping': 'Sea',
    'Air Transport': 'Air',
    No: 'B/L No.',
    weight: 'weight',
    'Place of Loading': 'Place of Loading',
    Quantity: 'Quantity',
    'Place of Discharge': 'Place of Discharge',
    Dimension: 'Dimension',
    'Final Destination': 'Final Destination',
    'Freight Type': 'Freight Type',
    Sea: 'Sea',
    Air: 'Air',
    Vessel: 'Vessel',
    Voyage: 'Voyage',
    'Container No': 'Container No.',
    'Add to shippment': 'Add to shippment',
    ConfirmExit: 'Confirm Exit？',
  },
  Forwarder: {
    'Add Forwarder': 'Add Forwarder',
    Name: 'Name',
    Manager: 'Manager',
    Title: 'Title',
    Confirm: 'Confirm',
    Cancel: 'Cancel',
  },
  todo: {
    'addto-do': 'Add to-do',
    'to-do': 'To-do',
    Subject: 'Subject',
    Title: 'Title',
    'Assign to': 'Assign to',
    'When done, notify': 'notify',
    'Due on': 'Due on',
    Notes: 'Notes',
    'Add this to-do': 'Add this to-do',
    'Please input the subject name': 'Please input the subject name',
    'complete information': 'Please fill in the complete information',
    'Please select the freight':
      'Please select the freight forwarding company and personnel to be added',
    'Cancel delete': 'Cancel',
    'Modify Cancel': 'Modify Cancel',
    'Delete Successful': 'Delete Successful',
    'Success': 'Success',
    'On going': 'On going',
    'Create': 'Create',
    'You have completed': 'You have completed',
    'You have not completed': 'You have not completed'
  },
  event: {
    'number new events': ({values}) => {
      return `${values.n} new events`
    },
    'Create event': 'Create event',
    'Upload succeeded': 'Upload succeeded',
    'Only one file can be uploaded': 'Only one file can be uploaded',
    'Cancel input': 'Cancel input',
    'Delete successfully': 'Delete Successful',
    'Delete cancel': 'Delete failed',
    'This event will be deleted': 'Delete this event？',
    Notification: 'Delete',
    'select people to show': 'select people to show',
    'Please select the file type': 'Please select the file type',
    'Confirm upload': 'Confirm upload',
    'Drag & Drop files here OR Browse Files':
      'Drag & Drop files here OR Browse Files',
    Activities: 'Activities',
    Title: 'Title',
    'Event detail': 'Event detail',
    'Write away': 'Write ...',
    Content: 'Content',
    'Upload your file': 'Upload your file',
    'Who can see ': 'Who can see ',
    'Select File Type': 'Select File Type',
    'Everyone': 'Everyone',
    'Who can see': 'Who can see',
    'Everyone in order can see': 'Everyone in order can see',
    'Only me': 'Only me can see',
    'Some people can see': 'Some people',
    'Some people': 'Some people',
    'Can see': 'Can see',
    'Only selected people can see': 'Only selected people can see',
    'Send out': 'Send out',
    'Event Title/Content': 'Event Title/Content'
  },
  'Under review': 'Under review',
  'Please enter a group name': 'Please enter a group name',
  'Please Select a group member': 'Please Select a group member',
  'Group name cannot exceed 20 characters':
    'Group name cannot exceed 20 characters',
  'Please select a customer company': 'Please select a customer company',
  'New Group': 'New Group',
  'Group name': 'Group name',
  'Group members': 'Group members',
  totals: 'total',
  peoples: 'people',
  Tips: 'Tips',
  'are you sure to delete this group?': 'are you sure to delete this group?',
  Confirm: 'Confirm',
  Cancel: 'Cancel',
  Select: 'Select',
  image: "image",
  Text: "Text",
  'Navigate to Chat': 'Navigate to Chat',
  Refresh: 'Refresh',
  ExportFile: 'Export file',
  ExportWord: 'Export to word',
  expand: 'expand',
  retract: 'retract',
  success: 'success',
  addContrast: 'Add contrast',
  custom: 'Custom',
  color: 'Color',
  'save': 'Save',
  'Use current label group': 'Use current label group',
  'avatar': 'Avatar',
  'Email setting': 'Email setting',
  'Email Customization': 'Email Customization',
  emailConfig: {
    'Opened': 'Opened',
    'Open Notes': 'After opening, there will be an email reminder when there are updates',
    'Event flow email reminder': 'Event flow email reminder',
    'System notification email settings': 'System notification email settings',
    'allEmailRemind': 'All event',
    'createOrder': 'Create order',
    'updateOrder': 'Update order',
    'createWaybill': 'Create waybill',
    'updateWaybill': 'Update waybill',
    'logisticsPunchCard': 'Logistics clock in',
    'invitationInformation': 'Invite Info',
    'toDoInformation': 'Todo Info',
    'announcementNotice': 'notice',
    'historicalOrderImportNotification': 'History order Import',
    'purchaseOrder': 'Purchase order creation/update',
  },
  'View permission settings': 'View permission settings',
  "or": 'or',
  "complete": "complete",
  "Topping": "Topping",
  "Take over administrator information": "Take over administrator information",
  "Modify company information": "Modify company information",
  'The current label is duplicated and cannot be added': 'The current label is duplicated and cannot be added',
  'Are you sure to delete the label': 'Are you sure to delete the label',
  'Public label area': 'Public label area',
  'Operation records': 'Operation records',
  'Tags of the same type that have been used after deletion will also be deleted': 'Tags of the same type that have been used after deletion will also be deleted',
  'Order label is full and cannot be used': 'Order label is full and cannot be used',
  'You still have unsaved tags': 'You still have unsaved tags',
  'After closing, the label will not be saved': 'After closing, the label will not be saved',
  'Please enter the note content': 'Please enter the note content',
  'Import in progress': 'Import in progress',
  'The order label has a duplicate tag with the current label group and cannot be used': 'The order label has a duplicate tag with the current label group and cannot be used',
  'You have new application information': 'You have new application information',
  'Your application has been approved': 'Your application has been approved',
  'Your application has been rejected': 'Your application has been rejected',
  'You have a new customer invitation notification': 'You have a new customer invitation notification',
  eventExport: {
    eventType: "Event type",
    eventName: "Event name",
    eventContent: "Event content",
    eventCreatePerson: "Event create person",
    eventCreateTime: "Event create time",
    eventTags: "Event tags",
    eventFile: "Event file",
    'Ordinary event': 'Ordinary event',
    'System event': 'System event'
  },
  'validate': 'Validate',
  'Lifespan': 'Lifespan',
  'edit validate': 'Edit validate',
  'Validate success': 'Validate success',
  'Validate failed': 'Validate failed',
  'Please verify the email first': 'Please verify the email first',
  'Enter email verification code': 'Enter email verification code',
  'Confirm takeover': '确认接管',
  'Successfully taken over, please log in again': 'Successfully taken over, please log in again',
  'Send verification code': 'Send verification code',
  'Generate': 'Generate',
  'Please': 'Please',
  "Not used": 'Not used',
  'There are no pictures left': 'There are no pictures left',
  'Do you want to modify the current member role': 'Do you want to modify the current member role',
  'Theme switch': 'Theme switch',
  'Send 1 new message': 'Send 1 new message',
  'Create': 'Create',
  'do': 'do',
  'Read completed': 'Read completed',
  'Affiliated company': 'Affiliated company',
  'Current position': 'Current position',
  'Time zone': 'Time zone',
  'nickname': 'Nickname',
  'One click read': 'One click read',
  'I have read and agree with': 'I have read and agree with',
  'Service agreement': 'Service agreement',
  'Privacy policy': 'Privacy policy',
  'And log in': 'And log in',
  'Email assistance': 'assistance',
  'Email template': 'Email template',
  'Getting started': 'Getting started',
  'Email banner': 'Email banner',
  'Are you sure to cancel the collection': 'Are you sure to cancel the collection',
  'and': 'And',
  'filter': 'Filter',
  'Download image': 'Download image',
  'Send mail': 'Send mail',
  'Preview': 'Preview',
  'Select background': 'Select background',
  'Edit title': 'Edit title',
  'day': 'Day',
  'Long term': 'Long term',
  'Generate Link': 'Generate Link',
  'Shared by': 'Shared by',
  'Valid to': 'Valid to',
  'Please enter the invitation code': 'Please enter the invitation code',
  GroupChat: {
    'Add group chat': 'Add group chat',
    'index': 'Group chat',
    'Initiate group chat': 'Initiate group chat',
    'Insufficient number of people to group, add at least 2 people': 'Insufficient number of people to group, add at least 2 people',
    'Enter group chat': 'Enter group chat',
    'hello': 'hello',
    'Group chat settings': 'Group chat settings',
    'Group chat name': 'Group chat name',
    'Specify Chat': 'Specify chat',
    'Do you want to delete this member': 'Do you want to delete this member',
    'Exit group chat': 'Exit group chat',
    'Dissolve group chat': 'Dissolve group chat',
    'Are you sure to exit the group chat': 'Are you sure to exit the group chat',
    'Are you sure to dissolve the group chat': 'Are you sure to dissolve the group chat',
    'After exiting, the group chat content will be cleared': 'After exiting, the group chat content will be cleared',
    'After dissolve, the group chat content will be cleared': 'After dissolve, the group chat content will be cleared',
    totalNumber: (week) => {
      return `${week.values.n} person in total`;
    },
  },
  'withdraw': 'Withdraw',
  'You withdrew a message': 'You withdrew a message',
  'withdrew a message': 'withdrew a message',
  'Please select the data to operate on': 'Please select the data to operate on!',
  'Continue browsing this page': 'Continue page',
  'Log in now': 'Login',
  'Welcome to Echola': 'Welcome to Echola!',
  'Come on in Order Info': 'Come on in to learn more about your order!',
  'Move to': '移动至',
  'number people cannot be added': ({values}) => {
    return `${values.n.number} people cannot be${values.n.type ? 'added' : 'delete'}`
  },
  'Chat records': 'Chat records',
  'One click translation': 'One click translation',
  'Online form': 'Online form',
  'Are you sure to delete the label group': 'Are you sure to delete the label group',
  'After deletion, the order related labels will also be deleted': 'After deletion, the order related labels will also be deleted',
  'Creating a large file event requires waiting for the upload to complete before creating the event': 'Creating a large file event requires waiting for the upload to complete before creating the event',
  'EcholaAi': {
    'New dialogue': 'New',
    'Recent conversations': 'Recent conversations',
    'Enter your question here': 'Enter your question here',
    'Open more': 'Open more',
    'Do you want to delete the conversation?': 'Do you want to delete the conversation?',
    'This conversation will no longer appear here This operation will also delete relevant activity records from your Bard activity records, such as questions, answers, and feedback': 'This conversation will no longer appear here. This operation will also delete relevant activity records from your activity records, such as questions, answers, and feedback',
    'Questioner': 'Questioner',
    'One message': 'Hi! I am Echola AI, and I can provide you with creative inspiration and practical information to help you. I have limited abilities and may make mistakes sometimes. Please provide feedback to help me become better!'
  },
  'File upload in progress': 'File upload in progress',
  'Copy': 'Copy',
  'View the remaining comments': ({values}) => {
    return `View the remaining ${values.n} comments`
  },
  'File format not supported': 'File format not supported',
  'Project name': ({values}) => {
    return `${values.n} name`
  },
  'Address Selection': 'Address Selection',
  'Change address': 'Change address',
  'Original address': 'Original address',
  'New address': 'New address',
  'Logistics map': 'Logistics map',
  'Supplier Map': 'Supplier Map',
  'Customer Map': 'Customer Map',
  Purchase: {
    title: "Purchase",
    'Purchase manage': 'Purchase manage',
    'Create Purchase': 'Create purchase',
    'Edit Purchase': 'Edit purchase',
    'Preview Purchase': 'Preview purchase',
    'Purchase Number': 'Number',
    'Details (optional)': 'Details (optional)',
    'Details': "Details",
    ProductIndex: ({values}) => {
      return `Product${values.n}`
    },
    ProductCode: "Product code",
    ProductName: "Product name",
    'Continue adding products': 'Continue adding products',
    'Preview and send': 'Preview and send',
    'Delete product': ({values}) => {
      return `Delete ${values.n}`
    },
    'Delivery (optional)': 'Delivery (optional)',
    'Delivery address': 'Address',
    'Delivery date': 'Date',
    'Mode of transport': 'Transport',
    'Payment (optional)': 'Payment (optional)',
    'Delivery terms': 'Terms',
    'Payment method': 'Method',
    'Payment date': 'Date',
    'Create purchase': 'Create purchase',
    'Updated On': 'Updated On',
    'Generated on': 'Generated on',
    'Status describe0': 'Purchase orders not sent to suppliers',
    'Status describe1': 'Purchase orders sent to suppliers',
    'Status describe2': 'Purchase order under discussion with supplier',
    'Status describe3': 'Purchase order with generated order',
    'Status vendor describe1': 'Purchase orders without any operations',
    'Status vendor describe2': 'Purchase orders under discussion with buyers',
    'Status vendor describe3': 'Purchase order with generated order',
    'Are you sure to withdraw the purchase order?': 'Are you sure to withdraw the purchase order?',
    'The purchase order will be saved in the purchase order list': 'The purchase order will be saved in the purchase order list',
    'Purchase order deleted': 'Purchase order deleted',
    'Update and send': 'Update and send'
  },
  'Generate Order': 'Generate Order',
  'Are you sure to delete it?': 'Are you sure to delete it?',
  'After deletion, it cannot be restored': 'After deletion, it cannot be restored',
  unitPrice: "Univalent",
  'Please input': ({values}) => {
    return `Please input ${values.n}`;
  },
  'Upload attachments': 'Attachment',
  'Save Only': 'Save only',
  'Do not save': 'Do not save',
  'Successfully saved': 'Successfully saved',
  'Click to preview': 'Click to preview',
  'Please select an address on the map': 'Please select an address on the map',
  'see': 'See',
  'Have you edited it': 'Have you edited it？',
  'If the edited content is not saved, it will be cleared': 'If the edited content is not saved, it will be cleared',
  'Please manually enable recording permission': 'Please manually enable recording permission',
  'voice': 'Voice',
  'Hold to talk': 'Click to record',
  'Release the audition': 'Click to listen',
  'Click to redo': 'Click to redo',
  'Making voice calls': 'Making voice calls',
  'Waiting to answer': 'Waiting to answer',
  'I made a voice call to you': 'I made a voice call to you',
  'Positioning failed, please manually select': 'Positioning failed, please manually select',
  'Successfully sent 1 message to who': ({values}) => {
    return `Successfully sent 1 message to ${values.n}`
  },
  'Usage time': 'Usage time',
  'Expired': 'Expired',
  'Company Profile': 'Company Profile',
  'Confirm modification': 'Confirm modification',
  'Verification information sent': 'Verification information sent',
  'in total': ({values}) => {
    return `${values.n} in total`
  },
  Placeholder: "Placeholder",
  'Add component': 'Add component',
  'Configuration': 'Configuration',
  'Select Components': 'Select Components',
  'Set Content': 'Set Content',
  'Use': 'Use',
  customeTips: {
    'Text input box, suitable for configuring themes, etc': 'Text input box, suitable for configuring themes, etc',
    'Dropdown radio box for configuration options': 'Dropdown radio box for configuration options',
    'Dropdown multiple selection box, suitable for configuring multiple selection options': 'Dropdown multiple selection box, suitable for configuring multiple selection options',
    'Time selector, can select a time point or time period': 'Time selector, can select a time point or time period',
    'Date selector, can select 1 or more days': 'Date selector, can select 1 or more days',
    'Counter, used for unit price, quantity of goods, etc': 'Counter, used for unit price, quantity of goods, etc',
    'Attachment upload, can upload various types of files': 'Attachment upload, can upload various types of files',
    'Image upload, display images can be uploaded': 'Image upload, display images can be uploaded',
    'Text field, can be used as notes, etc': 'Text field, can be used as notes, etc',
    'Switch, can be used for permission configuration, etc': 'Switch, can be used for permission configuration, etc'
  },
  customName: {
    Input: "Input",
    RadioSelect: "Radio Select",
    CheckSelect: "Check Select",
    TimeSelect: "Time Select",
    DateSelect: "Date Select",
    InputNumber: "Input Number",
    FileUpload: "File Upload",
    ImageUpload: "Image Upload",
    TextArea: "Text Area",
    Switch: "Switch",
    Score: "Score"
  },
  'Duplicate component form title name': 'Duplicate component form title name',
  'The current component limit is 3 uploads': ({values}) => {
    return `The current component limit is ${values.n} uploads`
  },
  'Open Content': 'Open Content',
  'Close Content': 'Close Content',
  Option: "Option",
  'Time type': 'Time type',
  'Time interval': 'Time interval',
  'Every 30 minutes': 'Every 30 minutes',
  'Every 1 hour': 'Every 1 hour',
  'Continue adding options': "Continue adding options",
  'Add Picture': 'Add Picture',
  'Propaganda slogan': 'Propaganda slogan',
  'QR code 1 name': 'QR code 1 name',
  'Upload QR code': 'Upload QR code',
  'QR code 2 name': 'QR code 2 name',
  'Email Content': 'Email Content',
  'Only applicable to waybill/order sharing emails': 'Only applicable to waybill/order sharing emails',
  'Commodity management': 'Commodity management',
  'Service Management': 'Service management',
  noticeServer: {
    Server: "Server",
    Shop: "Shop",
    Case: "Case",
    File: "File",
    'From new to old': 'From new to old',
    'From old to new': 'From old to new',
    'Carrier': 'Carrier',
    'Place of departure': 'Place of departure',
    'Destination': 'Destination',
    'Transfer': 'Transfer',
    'Direct': 'Direct',
    'Consult': 'Consult'
  },
  'Please select event': ({values}) => {
    return `Please select ${values.n}`
  },
  'Please add components first': 'Please add components first',
  'Duplicate template name': 'Duplicate template name',
  'Do you want to overwrite the previous template': 'Do you want to overwrite the previous template',
  'After covering, the original template will be replaced': 'After covering, the original template will be replaced',
  'No image': 'No image',
  'Case name': 'Case name',
  'Shop name': 'Shop name',
  'Description': 'Description',
  'Limit input to 50 words': 'Limit input to 50 words',
  'Server detail': 'Server detail',
  'Case detail': 'Case detail',
  'File detail': 'File detail',
  'Attachment': 'Attachment',
  'Data has been deleted': 'Data has been deleted',
  'Do not upload empty files': 'Do not upload empty files',
  'Filter custom information': 'Filter custom information',
  'Price (for reference only)': 'Price (for reference only)',
  'Custom administrator name': 'Custom administrator name',
  'Custom administrator email': 'Custom administrator email',
  tracking: require('./tracking/en'),
  customerInfo: require('./customerInfo/en'),
  messageBoard: require('./messageBoard/en'),
};
