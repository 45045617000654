import request from '@/utils/request';

//创建事件
export function createEventIn(data) {
  return request({
    url: '/event/add',
    method: 'post',
    data: data,
  });
}

//获取事件接口
export function getEventPageList(data) {
  return request({
    url: '/event/page',
    method: 'post',
    data,
  });
}

//获取事件接口
export function getEventList(params) {
  return request({
    url: '/event/index',
    method: 'get',
    params,
  });
}
//获取评论列表
export function getCommentList(params) {
  return request({
    url: '/event/comment/index',
    method: 'get',
    params,
  });
}
//获取To-do列表
export function getTodoListPage(data) {
  return request({
    url: '/businessTodo/todoListPage',
    method: 'post',
    data: data,
  });
}
//删除事件
export function deleteEvent(params) {
  return request({
    url: '/event/delete',
    method: 'post',
    params,
  });
}

//删除评论
export function deleteCommen(params) {
  return request({
    url: '/event/comment/delete',
    method: 'post',
    params,
  });
}

// 事件已读
export function eventRead(eventId) {
  return request({
    url: '/event/read',
    method: 'post',
    params: {
      eventId
    },
  });
}

// 评论已读
export function commentRead(commentId) {
  return request({
    url: '/event/comment/read',
    method: 'post',
    params: {
      commentId
    },
  });
}

// 根据事件ID查询事件详情
export function getEventDetail(unid) {
  return request({
    url: '/event/detail',
    method: 'get',
    params: {
      unid
    },
  }); 
}

// 收藏事件
export function followEvent(eventId) {
  return request({
    url: '/event/collectEvent',
    method: 'get',
    params: {
      eventId
    },
  });
}

// 备注事件
export function notesEvent(data) {
  return request({
    url: '/event/saveOrUpdateRemark',
    method: 'post',
    data,
  });
}

// 删除事件备注
export function deleteNotesEvent(eventId) {
  return request({
    url: '/event/deleteRemark',
    method: 'post',
    params: {
      eventId
    },
  });
}

// 取消事件某个tag
export function deleteEventTag(data) {
  return request({
    url: '/event/deleteEventCompanyTag',
    method: 'post',
    data,
  });
}

// 增加事件某个tag
export function addEventTag(data) {
  return request({
    url: '/event/addEventCompanyTag',
    method: 'post',
    data,
  });
}

// 获取所有事件的图片
export function getEventImages(data) {
  return request({
    url: '/event/eventImages',
    method: 'post',
    data,
  });
}
