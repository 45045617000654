<template>
  <div>
    <el-dialog 
      :visible="visible" 
      width="60vw" 
      custom-class="live-msg"
      append-to-body
      @open="open"
      :before-close="closeFun"
      v-move-outside
      v-dialogDrag="true"
      id="chatDialog">
      <div class="headerFixed">
        <i class="el-icon-close" style="cursor: pointer;" @click="closeFun()"></i>
      </div>
      <div class="chatBox" @click="isContentMenu = false;">
        <div class="left" :class="isElastic ? 'open' : 'retract'" @click.prevent="isOpenGroupChatSetting = false">
          <div class="retractBox" v-if="pingList[0]">
            <member
              :info="userInfo"
              :size="35"
              :placement="'left'"
              :isPing="false"
              @close="closeFun()" 
            />
            <div class="retractIcon" @click="isElastic = true">
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
          <div class="headerLeft">
            <div class="tabs">
              <div 
                :class="`title ${chatTitleActive == 0 ? 'active' : ''}`"
                @click="chatTitleActive = 0"
              >{{ $t('InformationCenter.ping') }}<span v-if="pingTotal">({{ pingTotal }})</span></div>
              <div 
                :class="`title ${chatTitleActive == 1 ? 'active' : ''}`"
                @click="chatTitleActive = 1"
              >{{ $t('InformationCenter.AddressBook') }}</div>
            </div>
            <div class="retractIcon" @click="isElastic = !isElastic">
              <i class="el-icon-arrow-left" ></i>
            </div>
          </div>
          <div class="inputSearch">
            <el-autocomplete
              v-if="chatTitleActive == 0"
              v-model="data.keyword"
              :fetch-suggestions="querySearchAsync"
              @select="handleSelect"
              :placeholder="$t('order.Search')"
              clearable
              style="flex: 1;"
              suffix-icon="el-icon-search"
              size="small"
              @change="onGetList"
            >
              <template slot-scope="{ item }">
                <div class="customer_name">
                  <div 
                    class="avatarList" 
                    v-if="item.user.memberList && item.user.memberList.length">
                    <div class="imageBox">
                      <img 
                        v-for="(row, index) in item.user.memberList.filter((row, index) => index < 4)" 
                        :src="row.avatarFileUrl" 
                        :key="index"
                      />
                    </div>
                  </div>
                  <el-image v-else :src="item.user && item.user.avatarFileUrl" fit="cover" />
                  <div class="companyName">
                    <div class="name">{{ item.user.nickName || item.user.username }}</div>
                  </div>
                </div>
              </template>
            </el-autocomplete>
            <template v-else>
              <el-input 
                v-model="addressBookForm.username" 
                :placeholder="$t('order.Search')"
                size="small"
                style="flex: 1;"
                @input="getAddressBookAllData"
              >
                <i slot="suffix" class="el-input__icon el-icon-search"></i>
              </el-input>
              <el-button 
                type="primary" 
                size="small" 
                class="createGroupBtn"
                icon="el-icon-plus"
                @click="openCreateGroup(null)">
                {{ $t('GroupChat.Add group chat') }}
              </el-button>
            </template>
          </div>
          <div 
            class="pingList" 
            v-infinite-scroll="load" 
            :infinite-scroll-distance="20">
            <template v-if="chatTitleActive == 0">
              <div 
                class="item"
                v-for="(item, index) in pingList.filter(item => item.user)" 
                :key="index"
                :class="{
                  active: activeId == (item.user && item.user.openId),
                  topActive: item.top,
                  hoverPopoverShow: item.check
                }">
                <div 
                  class="avatarList" 
                  v-if="item.user && item.user.memberList && item.user.memberList.length">
                  <div class="imageBox">
                    <img 
                      v-for="(row, index) in item.user && item.user.memberList.filter((row, index) => index < 4)" 
                      :src="row && row.avatarFileUrl" 
                      :key="index"
                    />
                  </div>
                  <div class="badge" v-if="item.unReadNum">{{ item.unReadNum }}</div>
                </div>
                <member
                  v-else
                  :info="item.user"
                  :size="48"
                  :placement="'left'"
                  :isPing="false"
                  :isBadge="item.unReadNum"
                  @close="closeFun()" 
                />
                <div class="content" @click.prevent="setMessageActive(item)">
                  <div class="top">
                    <div class="name">
                      <p v-showTootip>{{ item.user && item.user.nickName }}</p>
                    </div>
                    <!-- <div class="count" v-if="item.unReadNum">{{ item.unReadNum > 99 ? '99+' : item.unReadNum }}</div> -->
                    <el-popover
                      ref="popover_message"
                      class="el-pop"
                      placement="right-start"
                      trigger="click"
                      :offset="-10"
                      @hide="item.check = false"
                      popper-class="ec-popper"
                      v-if="item.content"
                    >
                      <div class="popperMenuItem" @click="setTop(item.user.openId, index)">
                        <i class="el-icon-download rotate180" />
                        <span>{{ item.top ? $t('home.Cancel Topping') : $t('home.Chat Top') }}</span>
                      </div>
                      <i
                        class="iconfont icon-Ellipsis downEllipsis"
                        :class="item.check ? 'active' : ''"
                        slot="reference"
                        @click.stop="activePopover(item)"
                      />
                    </el-popover>
                  </div>
                  <div class="bottom">
                    <div class="message">
                      <p v-showTootip>{{ item.content }}</p>
                    </div>
                    <div class="time">{{ isToday(item.createTime) }}</div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="addressBookBox">
                <div class="companyItem">
                  <div class="companyTitle" @click="groupChatOpen = !groupChatOpen">
                    <div class="title">
                      <i :class="`el-icon-arrow-up ${!groupChatOpen ? 'hide' : ''}`"></i>
                      {{ $t('GroupChat.index') }}
                    </div>
                    <div class="num">{{ groupChatList.length }}</div>
                  </div>
                  <div class="childrenList" v-show="groupChatOpen">
                    <div 
                      class="item" 
                      :class="{
                        active: groupChatIndex == index
                      }"
                      v-for="(item, index) in groupChatList" 
                      :key="index"
                      @click="openGroupChatInfo(item, index)">
                      <div class="avatarList">
                        <img 
                          v-for="(row, index) in item.memberList.filter((row, index) => index < 4)" 
                          :src="row.avatarFileUrl" 
                          :key="index"
                        />
                      </div>
                      <div class="userBox">
                        <div class="name line1">{{ item.groupName || $t('GroupChat.index') }}</div>
                        <div class="position">{{ $tc('GroupChat.totalNumber', item.memberList.length) }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="companyItem" v-for="(item, index) in addressBookData" :key="index">
                  <div class="companyTitle" @click="item.open = !item.open">
                    <div class="title">
                      <i :class="`el-icon-arrow-up ${!item.open ? 'hide' : ''}`"></i>
                      {{ item.aliasName }}
                    </div>
                    <div class="num">{{ item.users.length }}</div>
                  </div>
                  <div class="childrenList" v-show="item.open">
                    <div 
                      class="item" 
                      :class="{
                        active: companyIndex == index && childrenUserIndex == idx
                      }"
                      v-for="(row, idx) in item.users" 
                      :key="idx">
                      <member 
                        @close="closeFun()" 
                        :info="row" 
                        :size="48" 
                        :isPing="false" 
                      />
                      <div class="userBox" @click="setRightUserInfo(row, index, idx)">
                        <div class="name line1">{{ row.nickName }}</div>
                        <div class="position">{{ row.position }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="right" v-if="!systemNoticeShowActive">
          <template v-if="chatTitleActive == 0">
            <div class="liveHeader" v-if="otherParty" @click.prevent="isOpenGroupChatSetting = false">
              <div class="from_user" v-if="otherParty">
                <div 
                  class="avatarList" 
                  v-if="otherParty.memberList && otherParty.memberList.length">
                  <img 
                    v-for="(row, index) in otherParty.memberList.filter((row, index) => index < 4)" 
                    :src="row.avatarFileUrl" 
                    :key="index"
                  />
                </div>
                <img v-else :src="otherParty.avatarFileUrl || otherParty.avatarUrl" />
                <div class="user_name">
                  {{ otherParty.nickName }}
                  <div class="user_company">
                    {{ otherParty.position }}
                  </div>
                </div>
              </div>
            </div>
            <div 
              class="liveContainer" 
              ref="reference" 
              @scroll="scroll($event)"
              @click="isOpenGroupChatSetting = false"
              v-if="historyMessages.length">
              <div 
                v-for="(item, index) in $uniqueArray(historyMessages, 'chatId')" 
                class="chatItem" 
                :key="index"
                :id="'chat'+item.chatId">
                <div v-if="onGetYears(item, index)" class="time">
                  {{ onGetYears(item, index) }}
                </div>
                <div class="groupMessageTypeBox" v-if="messageGroupType.includes(item.messageType) || item.withdraw == 1">{{ item.content }}</div>
                <div 
                  class="liveItem" 
                  v-else-if="item.createUser"
                  :class="item.current ? 'flexRight' : ''">
                  <member 
                    @close="closeFun()" 
                    :info="item.createUser" 
                    :size="40" 
                    :isPing="false" 
                  />
                  <div class="lmi-wrapper">
                    <conversation-bubble 
                      maxWidth="50%" 
                      background="#ffffff" 
                      :item="item" 
                      @close="closeFun"
                      :otherParty="otherParty"
                      @contextmenu.native="chatPopoverAction($event, item)"
                    ></conversation-bubble>
                    <div 
                      class="contextMenuPopover" 
                      v-if="item.current && isContentMenu && contentMenuActiveId == item.chatId"
                      :style="contextMenuStyle">
                      <div class="item" @click="withdrawMessage">
                        <i class="iconfont icon-chehui"></i>
                        {{ $t('withdraw') }}
                      </div>
                    </div>
                    <!-- <article>{{ item.msg }}</article> -->
                    <time>{{ dayjsFormat(item.createTime) }}</time>
                  </div>
                </div>
              </div>
            </div>
            <div class="liveContainer" @click.prevent="isOpenGroupChatSetting = false" v-else></div>
            <div 
              v-if="otherParty && otherParty.groupId"
              class="groupChatSetting" 
              :class="{
                isTransform: isOpenGroupChatSetting
              }">
              <el-input 
                v-model="searchGroupChatMemeber" 
                :placeholder="$t('order.Search')" 
                size="small" 
                prefix-icon="el-icon-search"
              />
              <div class="groupChatSettingBox">
                <div class="memberList">
                  <div 
                    class="item" 
                    v-for="(item, index) in otherParty.memberList.filter(item => item.nickName.toLowerCase().indexOf(searchGroupChatMemeber.toLowerCase()) != -1)" 
                    :key="index">
                    <member 
                      @close="closeFun()" 
                      :info="item" 
                      :size="40" 
                      :isPing="false" 
                    />
                    <div class="name line1">{{ item.nickName }}</div>
                    <i 
                      class="el-icon-error" 
                      v-if="otherParty.admin.openId == businessUserId && item.openId != businessUserId"
                      @click="deleteGroupChatMember(item)"
                    ></i>
                  </div>
                  <div class="item" @click="openCreateGroup(otherParty)">
                    <div class="addIcon">
                      <i class="el-icon-plus"></i>
                    </div>
                    <div class="name">{{ $t('home.CIAdd') }}</div>
                  </div>
                </div>
                <div class="groupChatNameBox">
                  <div class="label">{{ $t('GroupChat.Group chat name') }}</div>
                  <div class="groupNameInput">
                    <p class="name" v-if="!isGroupNameEdit" @click="setGroupNameEdit">{{ otherParty.groupName }}</p>
                    <el-input 
                      v-else 
                      ref="groupNameDom" 
                      v-model="otherParty.groupName" 
                      :maxlength="30" 
                      size="mini" 
                      @blur="groupNameBlur"
                    />
                    <i class="iconfont icon-bianji3" v-if="!isGroupNameEdit"></i>
                  </div>
                </div>
                <div class="specifyChat">
                  <div class="label">{{ $t('GroupChat.Specify Chat') }}</div>
                  <el-switch
                    v-model="chatTop"
                    size="small"
                    active-color="#48AC42"
                    inactive-color="#d8d8d8"
                    @change="setTop(otherParty.groupId)">
                  </el-switch>
                </div>
              </div>
              <div class="settingFooter">
                <p @click="exitGroupChat">{{ $t('GroupChat.Exit group chat') }}</p>
                <p @click="dissolveGroupChat" v-if="otherParty.admin.openId == businessUserId">{{ $t('GroupChat.Dissolve group chat') }}</p>
              </div>
            </div>
            <div class="liveFooter" @click="isOpenGroupChatSetting = false">
              <div class="liveAction">
                <div class="actionLeft">
                  <toolTipIcon 
                    icon="el-icon-picture-outline" 
                    :tooltipText="$t('image')"
                    @click="uploadImage"
                  />
                  <toolTipIcon 
                    icon="el-icon-folder" 
                    :tooltipText="$t('home.Files')"
                    @click="uploadFile"
                  />
                  <toolTipIcon 
                    icon="el-icon-chat-dot-round" 
                    :tooltipText="$t('Chat records')"
                    @click="openMessageHistory"
                  />
                  <el-divider
                    v-if="companyCurrentType == 0 && purchaseStatus == 1 && (otherParty && otherParty.company && otherParty.company.purchaseStatus == 1) && !(otherParty && otherParty.groupId) && (businessUserId != (otherParty && otherParty.openId)) && (otherParty && otherParty.company.companyType != 2) && (otherParty && otherParty.company.companyType != 0)"
                    direction="vertical"
                  />
                  <toolTipIcon 
                    v-if="companyCurrentType == 0 && purchaseStatus == 1 && (otherParty && otherParty.company && otherParty.company.purchaseStatus == 1) && !(otherParty && otherParty.groupId) && (businessUserId != (otherParty && otherParty.openId)) && (otherParty && otherParty.company.companyType != 2) && (otherParty && otherParty.company.companyType != 0)"
                    icon="iconfont icon-caigoudanguanli" 
                    :tooltipText="$t('Purchase.title')"
                    @click="openPurchaseDialog"
                  />
                  <input 
                    ref="uploadInputImage" 
                    type="file" 
                    accept="image/*" 
                    @change="uploadImageInput"
                  />
                  <input ref="uploadInputFile" type="file" accept="application/pdf, .csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"  @change="uploadFileInput" />
                </div>
                <div class="actionRight">
                  <toolTipIcon 
                    v-if="otherParty && otherParty.groupId"
                    icon="iconfont icon-shezhi" 
                    :tooltipText="$t('GroupChat.Group chat settings')"
                    @click.stop="openGroupChatSetting"
                  />
                  <!-- <toolTipIcon  -->
                    <!-- v-else -->
                    <!-- icon="iconfont icon-a-24gl-phoneBubble1"  -->
                    <!-- :tooltipText="$t('Making voice calls')" -->
                    <!-- @click.stop="openAudioVisualPhone" -->
                  <!-- /> -->
                </div>
              </div>
              <el-input 
                ref="sendInput"
                type="textarea" 
                resize="none"
                v-model="text"
                :disabled="isLoading" 
                @keyup.enter.native="sedMessage" 
              />
              <div class="messageBtns">
                <div class="soundRecords" @click="openSoundRecords">
                  <i class="el-icon-microphone"></i>
                </div>
                <div class="sendButton" :class="!text ? 'disabled' : ''" @click="sedMessage()">{{ $t('people.send') }}</div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="addressBookRightBox">
              <div v-if="rightUserInfo" class="addressBookInfo">
                <div class="userInfoBox">
                  <member 
                    @close="closeFun()" 
                    :info="rightUserInfo" 
                    :size="82" 
                    :isPing="false" 
                  />
                  <div class="content">
                    <div class="userName">{{ rightUserInfo.username }}</div>
                    <div class="specificInfo">
                      <div class="item">
                        <div class="label">{{ $t('nickname') }}：</div>
                        <div class="value">{{ rightUserInfo.nickName }}</div>
                      </div>
                      <div class="item">
                        <div class="label">{{ $t('EditMine.Phone') }}：</div>
                        <div class="value">{{ rightUserInfo.phone || '_ _' }}</div>
                      </div>
                      <div class="item">
                        <div class="label">{{ $t('EditMine.Email') }}：</div>
                        <div class="value">{{ rightUserInfo.email }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="userItem">
                  <div class="label">{{ $t('Affiliated company') }}</div>
                  <div class="content">{{ rightUserInfo.company.aliasName }}</div>
                </div>
                <div class="userItem">
                  <div class="label">{{ $t('Current position') }}</div>
                  <div class="content">{{ rightUserInfo.position }}</div>
                </div>
                <div class="userItem">
                  <div class="label">{{ $t('Time zone') }}</div>
                  <div class="content">{{ rightUserInfo.timeZone }}</div>
                </div>
                <div class="userBtns">
                  <div class="btnBox" @click="goUserChat">
                    <div class="btn">
                      <i class="iconfont icon-lianxiguanliyuan"></i>
                    </div>
                    <div class="text">{{ $t('customerInfo.Ping') }}</div>
                  </div>
                  <div class="btnBox" @click="goUserProfile">
                    <div class="btn">
                      <i class="iconfont icon-Home1"></i>
                    </div>
                    <div class="text">{{ $t('home.Home') }}</div>
                  </div>
                </div>
              </div>
              <div v-else-if="groupChatRow" class="groupChatInfo">
                <div class="avatarList">
                  <img 
                    v-for="(row, index) in groupChatRow.memberList.filter((row, index) => index < 4)" 
                    :src="row.avatarFileUrl" 
                    :key="index"
                  />
                </div>
                <div class="groupName">{{ groupChatRow.groupName || $t('GroupChat.index') }}</div>
                <div class="memberListBox">
                  <el-row :gutter="20">
                    <el-col 
                      :span="6"
                      v-for="(item, index) in groupChatRow.memberList" 
                      :key="index">
                      <div class="item">
                        <member :info="item" :size="60" />
                        <div class="nickName line1">{{item.nickName}}</div>
                      </div>
                    </el-col>
                  </el-row>
                </div>
                <el-button type="primary" size="small" @click="goGroupChat">{{ $t('GroupChat.Enter group chat') }}</el-button>
              </div>
              <el-empty 
                v-else 
                :description="' '" 
                :image="$emptyImage"
                :image-size="90"
              />
            </div>
          </template>
        </div>
      </div>
    </el-dialog>
    <messageHistory ref="messageHistory" :otherParty="otherParty" @locateMessage="locateMessage" />
    <createGroupChatDialog 
      ref="createGroupChatDialog" 
      @update="updateGroupChat" 
      @closeMessage="closeFun" 
      @sendGroupChatMsg="sendGroupChatMsg"
      @createUpdate="chatTitleActive = 0"
    />
    <manageTranfer ref="manageTranfer" isGroup @exit="exitGroupEvent" />
    <audioDialog ref="audioDialog" @sendPublicMessage="sendPublicMessage" />
  </div>
</template>
<script>
import dayjs from 'dayjs';
import store from '@/store';
import { mapGetters } from 'vuex';
import { uniqBy } from "lodash";
import { 
  queryMessageRecord, 
  sendChatData, 
  messageStat,
  messageSessionList,
  ChatTop,
  getSystemNoticeList,
  getGroupChatList,
  updateGroupChatName,
  deleteGroupChatMember,
  exitGroupChatApi,
  dissolveGroupChatApi,
  withdrawMessage
} from '@/api/news';
import { uploadFileUrl, uploadFile } from "@/api/files";
import { getAddressBookData } from "@/api/customer";
import { purchaseWithdrawApi } from "@/api/order";

import messageHistory from "@/components/messageHistory";
import createGroupChatDialog from './createGroupChatDialog';
import manageTranfer from '../MemberList/manageTranfer';
import audioDialog from "../audioDialog/index.vue";
import Member from '../member/member.vue';

export default {
  name: 'MessageDialog',
  components: {
    messageHistory,
    createGroupChatDialog,
    manageTranfer,
    audioDialog,
    Member,
  },
  provide() {
    return {
      parent: this,
      isOneClickTranslate: () => {
        return false
      }
    };
  },
  data() {
    return {
      chatTitleActive: 0,
      companyIndex: null,
      childrenUserIndex: null,
      rightUserInfo: null,
      localeConfig: false,
      isOpenGroupChatSetting: false,
      createGroupList: [],
      groupChatList: [],
      isGroupNameEdit: false,
      groupChatOpen: false,
      groupChatIndex: null,
      groupChatRow: null,
      text: "",
      searchGroupChatMemeber: "",
      isElastic: true,
      isHeaderMessage: false,
      activeId: null,
      pingTotal: 0,
      visible: false,
      bottomShow: true,
      pingList: [],
      historyMessages: [],
      addressBookData: [],
      isLoading: false,
      isInfinite: false,
      chatTop: false,
      isContentMenu: false,
      contentMenuActiveId: null,
      contentMenuActiveType: "",
      contentMenuActiveContent: "",
      activeCopyId: null,
      messageGroupType: [5,6,7,8,9],
      data: {
        keyword: "",
        currentPage: 1,
        pageSize: 10000
      },
      addressBookForm: {
        username: ""
      },
      totalPage: 0,
      msgsParams: {
        toUserId: "",
        currentPage: 1,
        pageSize: 10,
      },
      systemNoticeShowActive: false,
      systemNoticeParams: {
        page: 1,
        size: 20
      },
      contextMenuStyle: {
        left: "",
        top: ""
      }
    }
  },
  computed: {
    ...mapGetters([
      'businessUserId', 
      'userInfo', 
      'chatMessage', 
      "messageDialog", 
      "chatRead",
      "systemNoticeUpdate",
      "groupChatUpdate",
      "messgaeWithdrawUpdate",
      "groupMemberDeleteUpdate",
      "groupChatDeleteUpdate",
      "createGroupChatUpdate",
      "companyCurrentType",
      "orderAlias",
      "purchaseStatus"
    ]),
    otherParty() {
      let item = this.pingList.find((v) => {
        return v?.user?.openId == this.activeId;
      });
      this.groupNameCopy = item?.user && item.user?.nickName;
      this.chatTop = Boolean(item?.top);
      return item?.user || null;
    },
    isShowPurchase() {
      return (
        this.companyCurrentType == 0 && 
        this.orderAlias != '1' && 
        !(this.otherParty && this.otherParty.groupId) && 
        (this.businessUserId != (this.otherParty && this.otherParty.openId)) && 
        (this.otherParty && this.otherParty.company.companyType != 2)
      )
    }
  },
  watch: {
    chatTitleActive(val) {
      this.addressBookForm.username = "";
      if(val == 1) {
        this.getAddressBookList();
        this.getGroupChatList();
      }
    },
    messgaeWithdrawUpdate: {
      handler(val) {
        this.onGetList(true);
        this.historyMessages.forEach((item, index) => {
          if(item.chatId == val.content) {
            if(item.createUser) {
              item.withdraw = 1;
              if(item.createUser?.openId == this.businessUserId) {
                item.content = this.$t("You withdrew a message");
              } else {
                item.content = item.createUser.nickName+' '+this.$t("withdrew a message");
              }
            }
          }
        });
      },
      deep: true
    },
    chatMessage(val) {
      if(this.visible) {
        this.onGetList(true);
        this.onMessageStat();
        if(!val.groupId ? (val.user.openId == this.otherParty.openId || val.user.openId == this.businessUserId) : (val.groupId == this.otherParty.openId)) {
          if(!val.current) {
            window?.ws.sendMsg({
              chatId: val.chatId,
              userId: this.otherParty.openId,
              toUserId: this.businessUserId,
              connectionType: 10004,
              chatType: 1,
            });
          }
          let arr = JSON.parse(JSON.stringify(this.historyMessages));
          arr.push({
            ...val,
            createUser: val.user
          })
          this.historyMessages = arr;
          this.setTopChatRoom();
        }
      }
    },
    activeId(val) {
      if(!val) return;
      this.historyMessages = [];
      this.getHistoryMsgs();
    },
    messageDialog: {
      handler(val) {
        this.visible = val.visible;
        this.isElastic = val.isElastic;
        this.isHeaderMessage = val.isHeaderMessage || false;
      },
      deep: true
    },
    chatRead() {
      // this.onGetList(true);
      this.onMessageStat();
    },
    createGroupChatUpdate(val) {
      this.activeCopyId = val.groupId;
    },
    groupChatUpdate(val) {
      if(this.$route.name == 'MessageList') return;
      this.isOpenGroupChatSetting = false;
      this.historyMessages = [];
      this.$nextTick(() => {
        this.$store.dispatch("user/setMessageDialogOpen", {
          visible: true,
          isElastic: this.isElastic,
          isHeaderMessage: this.isHeaderMessage,
          top: null,
          otherParty: null
        });
        this.open();
      })
    },
    groupMemberDeleteUpdate() {
      if(this.$route.name == 'MessageList') return;
      this.isOpenGroupChatSetting = false;
      this.historyMessages = [];
      this.$nextTick(() => {
        this.$store.dispatch("user/setMessageDialogOpen", {
          visible: true,
          isElastic: this.isElastic,
          isHeaderMessage: this.isHeaderMessage,
          top: null,
          otherParty: null
        });
        this.open();
      })
    },
    groupChatDeleteUpdate() {
      if(this.$route.name == 'MessageList') return;
      this.isOpenGroupChatSetting = false;
      this.historyMessages = [];
      this.$nextTick(() => {
        this.$store.dispatch("user/setMessageDialogOpen", {
          visible: true,
          isElastic: this.isElastic,
          isHeaderMessage: this.isHeaderMessage,
          top: null,
          otherParty: null
        });
        this.open();
      })
    },
    systemNoticeShowActive(val) {
      if(val) {
        this.historyMessages = [];
        this.activeId = null;
        this.activeCopyId = null;
        document.removeEventListener("paste", this.pasteEvent);
        document.removeEventListener('keydown', this.onEsc);
        this.getSystemNoticeList();
      } else {
        this.open();
      }
    },
    systemNoticeUpdate() {

    }
  },
  methods: {
    open() {
      this.pingList = [];
      this.historyMessages = [];
      this.activeId = null;
      this.onMessageStat();
      this.onGetList(true);
      document.addEventListener('paste', this.pasteEvent);
      document.addEventListener('keydown', this.onEsc);
      this.setDrawerAction();
    },
    openAudioVisualPhone() {
      this.$myEvent.emit("openVideoPhone", this.otherParty);
    },
    openSoundRecords() {
      this.$refs.audioDialog.open();
    },
    sendPublicMessage(obj) {
      this.historyMessages.push(obj);
      this.setTopChatRoom();
      sendChatData({
        toUserId: this.otherParty.openId,
        content: obj.content,
        fileId: obj.chatFileId,
        messageType: obj.messageType,
        chatType: this.otherParty.memberList && this.otherParty.memberList.length ? 1 : 0
      }).then((data) => {
        let pingFilter = this.pingList.find(item => item.user.openId == this.otherParty.openId);
        pingFilter.content = obj.content;
        let msgFilter = this.historyMessages.find(item => item.chatId == obj.chatId);
        msgFilter.chatId = data.chatId;
        msgFilter.loading = false;
      });
    },
    goGroupChat() {
      this.chatTitleActive = 0;
      this.$store.dispatch("user/setMessageDialogOpen", {
        visible: true,
        isElastic: this.isElastic,
        isHeaderMessage: this.isHeaderMessage,
        top: null,
        otherParty: JSON.parse(JSON.stringify({
          ...this.groupChatRow,
          openId: this.groupChatRow.groupId,
          nickName: this.groupChatRow.groupName,
        }))
      });
      this.open();
      this.rightUserInfo = null;
      this.companyIndex = null;
      this.childrenUserIndex = null;
    },
    chatPopoverAction(e, row) {
      e.preventDefault();
      var now = new Date(this.$getTimeZone(this.userInfo.timeZone)).getTime();
      if(now > (new Date(row.createTime).getTime() + 5 * 60 * 1000)) return;
      this.isContentMenu = true;
      this.contentMenuActiveId = row.chatId;
      this.contentMenuActiveType = row.messageType;
      this.contentMenuActiveContent = row.content;
      this.contextMenuStyle = {
        left: e.clientX+'px',
        top: e.clientY+'px'
      }
    },
    withdrawMessage() {
      if(this.contentMenuActiveType == 10) {
        this.$confirm(
          this.$t("Purchase.The purchase order will be saved in the purchase order list"), 
          this.$t("Purchase.Are you sure to withdraw the purchase order?"), 
          {
            confirmButtonText: this.$t('Confirm'),
            cancelButtonText: this.$t('Cancel'),
            type: 'warning'
          }
        ).then(() => {
          withdrawMessage(this.contentMenuActiveId).then(() => {
            purchaseWithdrawApi(this.contentMenuActiveContent);
          });
        }).catch(() => {});
        return;
      }
      withdrawMessage(this.contentMenuActiveId);
    },
    exitGroupEvent(openId = null) {
      if(openId) {
        sendChatData({
          toUserId: this.otherParty.groupId,
          content: " ",
          messageType: 9,
          chatType: 1
        }).then(() => {
          exitGroupChatApi(this.otherParty.groupId, openId).then(() => {
            this.$refs.manageTranfer.close();
           })
        });
        return;
      }
      this.$confirm(
        this.$t('GroupChat.After exiting, the group chat content will be cleared'), 
        this.$t("GroupChat.Are you sure to exit the group chat"),
        {
          confirmButtonText: this.$t('Confirm'),
          cancelButtonText: this.$t('Cancel'),
          type: 'warning'
        }
      ).then(() => {
        sendChatData({
          toUserId: this.otherParty.groupId,
          content: " ",
          messageType: 9,
          chatType: 1
        }).then(() => {
          exitGroupChatApi(this.otherParty.groupId, openId);
        });
      }).catch(() => {});
    },
    exitGroupChat() {
      if(this.otherParty.admin.openId == this.businessUserId) {
        if(this.otherParty.memberList.length > 1) {
          this.$refs.manageTranfer.open(null, this.otherParty.memberList);
        } else {
          this.exitGroupEvent()
        }
        return;
      }
      this.exitGroupEvent();
    },
    dissolveGroupChat() {
      this.$confirm(
        this.$t('GroupChat.After dissolve, the group chat content will be cleared'), 
        this.$t("GroupChat.Are you sure to dissolve the group chat"),
        {
          confirmButtonText: this.$t('Confirm'),
          cancelButtonText: this.$t('Cancel'),
          type: 'warning'
        }
      ).then(() => {
        dissolveGroupChatApi(this.otherParty.groupId).then(() => {
          this.activeId = null;
          this.pingList = [];
        });
      }).catch(() => {});
    },
    setGroupNameEdit() {
      this.isGroupNameEdit = true;
      this.$nextTick(() => {
        this.$refs.groupNameDom.focus();
      });
    },
    deleteGroupChatMember(row) {
      this.$confirm(
        this.$t('GroupChat.Do you want to delete this member'), 
        this.$t("Tips"),
        {
          confirmButtonText: this.$t('Confirm'),
          cancelButtonText: this.$t('Cancel'),
          type: 'warning'
        }
      ).then(() => {
        deleteGroupChatMember({
          groupId: this.otherParty.groupId,
          openIds: row.openId
        }).then(() => {
          this.$message.success(this.$t('event.Delete successfully'));
          sendChatData({
            toUserId: this.otherParty.groupId,
            content: row.nickName,
            messageType: 8,
            chatType: 1
          });
        })
      }).catch(() => {});
    },
    groupNameBlur() {
      this.isGroupNameEdit = false;
      if(this.otherParty.groupName === this.groupNameCopy) return;
      updateGroupChatName({
        groupId: this.otherParty.groupId,
        groupName: this.otherParty.groupName
      }).then(() => {
        this.groupNameCopy = this.otherParty.groupName;
        sendChatData({
          toUserId: this.otherParty.groupId,
          content: this.otherParty.groupName,
          messageType: 6,
          chatType: 1
        });
      })
    },
    sendGroupChatMsg(data) {
      sendChatData({
        toUserId: data,
        content: this.$t('GroupChat.hello'),
        messageType: 5,
        chatType: 1
      }).then((data) => {
        let pingFilter = this.pingList.find(item => item.user.openId == this.otherParty.openId);
        pingFilter.content = textContent;
        let msgFilter = this.historyMessages.find(item => item.chatId == time);
        msgFilter.chatId = data.chatId;
        msgFilter.loading = false;
      });
    },
    updateGroupChat() {
      this.getAddressBookList();
      this.getGroupChatList();
    },
    getAddressBookAllData() {
      this.groupChatOpen = true;
      this.getAddressBookList();
      this.getGroupChatList();
    },
    getSystemNoticeList() {
      getSystemNoticeList(this.systemNoticeParams).then((data) => {
        console.log(data);
      })
    },
    openCreateGroup(row = null) {
      this.$refs.createGroupChatDialog.open(row);
    },
    openMessageHistory() {
      this.$refs.messageHistory.open();
    },
    openPurchaseDialog() {
      this.$myEvent.emit("openPurchase", {
        mode: 1,
        otherParty: this.otherParty
      });
    },
    openGroupChatInfo(row, index) {
      this.groupChatIndex = index;
      this.groupChatRow = row;
      this.rightUserInfo = null;
      this.companyIndex = null;
      this.childrenUserIndex = null;
    },
    openGroupChatSetting() {
      this.isOpenGroupChatSetting = true;
    },
    getGroupChatList() {
      getGroupChatList({
        page: 1,
        size: 10000,
        groupName: this.addressBookForm.username
      }).then((data) => {
        this.groupChatList = data.list;
      });
    },
    goUserProfile() {
      this.$router.push(`/profile?businessUserId=${this.rightUserInfo.openId}`);
      this.closeFun();
    },
    goUserChat() {
      this.chatTitleActive = 0;
      this.$store.dispatch("user/setMessageDialogOpen", {
        visible: true,
        isElastic: this.isElastic,
        isHeaderMessage: this.isHeaderMessage,
        top: null,
        otherParty: JSON.parse(JSON.stringify(this.rightUserInfo))
      });
      this.open();
      this.rightUserInfo = null;
      this.companyIndex = null;
      this.childrenUserIndex = null;
    },
    setMessageActive(row) {
      row.unReadNum = 0;
      this.activeId = row.user.openId;
      this.$store.dispatch("user/setMessageDialogOpen", {
        visible: true,
        isElastic: this.isElastic,
        isHeaderMessage: this.isHeaderMessage,
        top: row.top,
        otherParty: row.user
      });
    },
    setDrawerAction() {
      this.$nextTick(() => {
        let thisDilaog = document.querySelector("#chatDialog");
        const dragDom = thisDilaog.querySelector('.el-dialog');
        const dialogHeaderEl = thisDilaog.querySelector('.headerFixed');
        //清除选择头部文字效果
        dialogHeaderEl.onselectstart = new Function("return false");  
        //头部加上可拖动cursor
        dialogHeaderEl.style.cursor = 'move';
        const sty = dragDom.currentStyle || window.getComputedStyle(dragDom, null);
        let moveDown = (e) => {
          // 鼠标按下，计算当前元素距离可视区的距离
          const disX = e.clientX - dialogHeaderEl.offsetLeft;
          const disY = e.clientY - dialogHeaderEl.offsetTop;
    
          // 获取到的值带px 正则匹配替换
          let styL, styT;
    
          // 注意在ie中 第一次获取到的值为组件自带50% 移动之后赋值为px
          if (sty.left.includes('%')) {
            styL = +document.body.clientWidth * (+sty.left.replace(/\%/g, '') / 100);
            styT = +document.body.clientHeight * (+sty.top.replace(/\%/g, '') / 100);
          } else {
            styL = +sty.left.replace(/\px/g, '');
            styT = +sty.top.replace(/\px/g, '');
          };
    
          document.onmousemove = function (e) {
            arguments[0].preventDefault();
            // 通过事件委托，计算移动的距离 
            const l = e.clientX - disX;
            const t = e.clientY - disY;
    
            // 移动当前元素
            if(l + styL < 5 || (l + styL >= (window.innerWidth - dragDom.offsetWidth))) return;
            dragDom.style.left = `${l + styL}px`;
            if(t + styT < 5 || (t + styT >= (window.innerHeight - dragDom.offsetHeight))) return;
            dragDom.style.top = `${t + styT}px`;
    
            //将此时的位置传出去
            //binding.value({x:e.pageX,y:e.pageY})
          };
    
          document.onmouseup = function (e) {
            document.onmousemove = null;
            document.onmouseup = null;
          };
        }
        dialogHeaderEl.onmousedown = moveDown;
      })
    },
    onMessageStat() {
      messageStat().then((data) => {
        this.pingTotal = data.chat || 0;
      });
    },
    querySearchAsync(queryString, cb) {
      if(!queryString) {
        cb([]);
        return;
      }
      var data_name = {
        keyword: queryString,
        currentPage: 1,
        pageSize: 10000
      };
      messageSessionList(data_name).then((data) => {
        console.log(data);
        cb(data.list.map(item => ({
          ...item,
          user: item.user || {
            ...item.group,
            openId: item.group.groupId,
            nickName: item.group.groupName
          }
        })));
      }).catch((error) => {
        this.$message.error('querySearchAsync error');
      });
    },
    handleSelect(item) {
      this.data.keyword = item?.user?.nickName;
      this.onGetList(true);
    },
    //分页
    load() {
      if(this.chatTitleActive != 0) return;
      if (this.data.currentPage < this.totalPage) {
        // this.data.currentPage += 1;
      }
    },
    isToday(time) {
      let todayDate = new Date().setHours(0,0,0,0);
      let paramsDate = new Date(time).setHours(0,0,0,0);
      if(todayDate === paramsDate) {
        return time.split(" ")[1]
      } else {
        return time.split(" ")[0]
      }
    },
    setRightUserInfo(row, index, idx) {
      this.rightUserInfo = row;
      this.companyIndex = index;
      this.childrenUserIndex = idx;
      this.groupChatIndex = null;
      this.groupChatRow = null;
    },
    getAddressBookList() {
      getAddressBookData(this.addressBookForm).then(data => {
        this.addressBookData = data.map(item => ({
          ...item,
          users: item.users.map(row => ({
            ...row,
            check: row.openId == this.businessUserId,
            disabled: row.openId == this.businessUserId,
          })),
          open: true
        }));
      })
    },
    onGetList(clear = false) {
      this.loading_ping = true;
      messageSessionList(this.data)
        .then((data) => {
          if(clear) this.pingList = [];
          this.totalPage = data.total;
          this.pingList = data.list.map(item => ({
            ...item,
            user: item.user || {
              ...item.group,
              openId: item.group.groupId,
              nickName: item.group.groupName,
            },
            check: false,
            content: item.content.replace(/<?img[^>]*>/gi, `[${this.$t('image')}]`).replaceAll(/\<[\s\S]*?\>/g, ""),
          }));
          this.$nextTick(() => {
            if(this.messageDialog?.visible) {
              if(this.activeCopyId) {
                this.activeId = this.pingList.filter(item => item.user.openId == this.activeCopyId)[0].user?.openId;
              } else {
                let isOpenUser = this.pingList.map(item => item.user?.openId).includes(this.messageDialog?.otherParty?.openId);
                if(isOpenUser) {
                  this.activeId = this.messageDialog?.otherParty?.openId;
                } else {
                  if(this.messageDialog?.isHeaderMessage) {
                    if(this.activeId) return;
                    if(this.messageDialog.otherParty?.openId == this.pingList[0]?.user?.openId) {
                      this.activeId = this.pingList[0]?.user?.openId;
                    } else if(!isOpenUser) {
                      let topIndex = this.pingList.filter(item => item.top).length;
                      let activeId = this.messageDialog?.otherParty?.openId || this.pingList[0]?.user?.openId;
                      if(this.messageDialog?.otherParty) {
                        this.pingList.splice(topIndex, 0, {
                          check: false,
                          content: "",
                          createTime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
                          read: false,
                          top: null,
                          unReadNum: 0,
                          user: this.messageDialog?.otherParty
                        })
                      }
                      this.activeId = activeId;
                    }
                  } else {
                    this.pingList.unshift({
                      check: false,
                      content: "",
                      createTime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
                      read: false,
                      top: null,
                      unReadNum: 0,
                      user: this.messageDialog?.otherParty
                    });
                    this.activeId = this.messageDialog?.otherParty?.openId;
                  }
                }
              }
            } else {
              if(this.pingList.length) {
                this.activeId = this.pingList[0].user?.openId;
              }
            }
          })
        })
        .finally(() => (this.loading_ping = false));
    },
    activePopover(row) {
      row.check = !row.check;
      this.$nextTick(() => {
        this.pingList.forEach((item, index) => {
          if(item.user.openId != row.user.openId) {
            item.check = false;
            this.$refs.popover_message[index].showPopper = false;
          }
        });
      });
    },
    setTop(id, index = null) {
      ChatTop(id).then(() => {
        this.onGetList(false);
        if(index == null) return;
        this.$refs.popover_message[index].showPopper = false;
      });
    },
    uploadImage() {
      this.$refs.uploadInputImage.click();
    },
    uploadFile() {
      this.$refs.uploadInputFile.click();
    },
    uploadFileInput(event) {
      let files = event.target.files[0];
      if(!this.beforeFileUpload(files)) return;
      const data_up = new FormData();
      data_up.append("files", files);
      data_up.append("configCode", "chatFile");
      uploadFileUrl(data_up).then((response) => {
        if (!response) return;
        let time = new Date().getTime();
        let obj = {
          chatId: time,
          chatFileId: response[0].fileId,
          chatFileUrl: response[0].savePath,
          loading: true,
          content: this.$t('Files.shared the file'),
          messageType: 4,
          current: true,
          read: 0,
          createTime: this.$getTimeZone(this.userInfo.timeZone),
          createUser: this.userInfo
        }
        this.historyMessages.push(obj);
        this.setTopChatRoom();
        sendChatData({
          toUserId: this.otherParty.openId,
          content: this.$t('Files.shared the file'),
          fileId: response[0].fileId,
          messageType: 4,
          chatType: this.otherParty.memberList && this.otherParty.memberList.length ? 1 : 0
        }).then((data) => {
          let pingFilter = this.pingList.find(item => item.user.openId == this.otherParty.openId);
          pingFilter.content = this.$t('Files.shared the file');
          let msgFilter = this.historyMessages.find(item => item.chatId == time);
          msgFilter.chatId = data.chatId;
          msgFilter.loading = false;
        });
      })
    },
    uploadImageInput(event) {
      let files = event.target.files[0]
      if (!this.beforeAvatarUpload(files)) return;
      const data_up = new FormData();
      data_up.append("files", files);
      data_up.append("configCode", "file_chat_content_img");
      uploadFileUrl(data_up).then((response) => {
        if (!response) return;
        let imgDom = (<img style="width: 100%" src={response[0].savePath} />);
        let imgDomString = `<img src="${response[0].savePath}" />`;
        this.$confirm(imgDom, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(() => {
          let time = new Date().getTime();
          let obj = {
            chatId: time,
            chatFileId: null,
            chatFileUrl: null,
            loading: true,
            content: imgDomString,
            messageType: 3,
            current: true,
            read: 0,
            createTime: this.$getTimeZone(this.userInfo.timeZone),
            createUser: this.userInfo
          }
          this.historyMessages.push(obj);
          this.setTopChatRoom();
          sendChatData({
            toUserId: this.otherParty.openId,
            content: imgDomString,
            messageType: 3,
            chatType: this.otherParty.memberList && this.otherParty.memberList.length ? 1 : 0
          }).then((data) => {
            let pingFilter = this.pingList.find(item => item.user.openId == this.otherParty.openId);
            pingFilter.content = `[${this.$t('image')}]`;
            let msgFilter = this.historyMessages.find(item => item.chatId == time);
            msgFilter.chatId = data.chatId;
            msgFilter.loading = false;
          });
        }).catch(() => {});
      })
      event.target.value = "";
    },
    beforeFileUpload({ size, type }) {
      const isLt2M = size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error(window.vm.$t('customerInfo.Picture size can not exceed 2MB!'));
      }
      return isLt2M;
    },
    beforeAvatarUpload({ size, type }) {
      const isJPG =
          type === 'image/jpeg' ||
          type === 'image/png' ||
          type === 'image/jpg' ||
          type === 'image/gif';
      const isLt2M = size / 1024 / 1024 < 10;
      if (!isJPG) {
        this.$message.error(window.vm.$t('customerInfo.Picture must be JPG or PNG or GIF!'));
      }
      if (!isLt2M) {
        this.$message.error(window.vm.$t('customerInfo.Picture size can not exceed 2MB!'));
      }
      return isJPG && isLt2M;
    },
    foucsSendInput() {
      setTimeout(() => {
        if(this.$refs.sendInput) {
          this.$refs.sendInput.focus();
        }
      }, 1000);
    },
    onEsc(e) {
      if (e.keyCode === 27) {
        this.closeFun();
      }
    },
    pasteEvent(event) {
      let text = event.clipboardData || window.clipboardData;
      if(text.files && text.files.length) {
        if (!text.files[0].type.match(/^image\/(gif|jpe?g|a?png|bmp)/i)) return;
        const data_up = new FormData();
        data_up.append("files", text.files[0]);
        data_up.append("configCode", "file_chat_content_img");
        uploadFileUrl(data_up).then((response) => {
          if (!response) return;
          let imgDom = (<img style="width: 100%" src={response[0].savePath} />);
          let imgDomString = `<img src="${response[0].savePath}" />`;
          this.$confirm(imgDom, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消'
          }).then(() => {
            let time = new Date().getTime();
            let obj = {
              chatId: time,
              chatFileId: null,
              chatFileUrl: null,
              loading: true,
              content: imgDomString,
              messageType: 3,
              current: true,
              read: 0,
              createTime: this.$getTimeZone(this.userInfo.timeZone),
              createUser: this.userInfo
            }
            this.historyMessages.push(obj);
            this.setTopChatRoom();
            sendChatData({
              toUserId: this.otherParty.openId,
              content: imgDomString,
              messageType: 3,
              chatType: this.otherParty.memberList && this.otherParty.memberList.length ? 1 : 0
            }).then((data) => {
              let pingFilter = this.pingList.find(item => item.user.openId == this.otherParty.openId);
              pingFilter.content = `[${this.$t('image')}]`;
              let msgFilter = this.historyMessages.find(item => item.chatId == time);
              msgFilter.chatId = data.chatId;
              msgFilter.loading = false;
            });
          }).catch(() => {});
        }).catch((error) => {});
      }
    },
    locateMessage(id) {
      let chatDom = document.querySelector(`#chat${id}`);
      if(chatDom) {
        chatDom.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      } else {
        this.forEventApi(id);
      }
    },
    forEventApi(id) {
      this.localeConfig = true;
      this.isLoading = true;
      let localeId = this.historyMessages.length && this.historyMessages[0].chatId;
      queryMessageRecord({
        size: 20,
        next: false,
        locateChatId: localeId,
        chatUser: this.otherParty.openId
      }).then(data => {
        if(!data.length) return;
        this.historyMessages.unshift(
          ...data.reverse()
        );
        this.historyMessages.forEach((item, index) => {
          if(item.withdraw == 1) {
            if(item.createUser.openId == this.businessUserId) {
              item.content = this.$t("You withdrew a message");
            } else {
              item.content = item.createUser.nickName+' '+this.$t("withdrew a message");
            }
          }
        });
        if(data.map(item => item.chatId).includes(id)) {
          this.$nextTick(() => {
            let chatDom = document.querySelector(`#chat${id}`);
            chatDom.scrollIntoView({
              behavior: 'smooth',
              block: 'start'
            });
          })
          this.localeConfig = false;
          return;
        }
        this.forEventApi(id);
      }).finally(() => this.isLoading = false);
    },
    getHistoryMsgs(isUpdate = true) {
      this.isLoading = true;
      let localeId = this.historyMessages.length && this.historyMessages[0].chatId;
      queryMessageRecord({
        size: 20,
        next: this.bottomShow,
        locateChatId: localeId,
        chatUser: this.otherParty.openId
      }).then(data => {
        this.historyMessages.unshift(
          ...data.reverse()
        );
        this.historyMessages.forEach((item, index) => {
          if(item.withdraw == 1) {
            if(item.createUser.openId == this.businessUserId) {
              item.content = this.$t("You withdrew a message");
            } else {
              item.content = item.createUser.nickName+' '+this.$t("withdrew a message");
            }
          }
        });
        this.foucsSendInput();
        if(!this.activeCopyId) {
          this.$store.dispatch("user/setChatRead", new Date().getTime());
        } else {
          this.activeCopyId = null;
        }
        if(isUpdate) {
          this.setTopChatRoom();
        } else {
          this.$nextTick(() => {
            if(document.getElementById(localeId)) {
              document.getElementById(localeId).scrollIntoView({
                block: "center"
              });
            }
          })
        }
      }).finally(() => this.isLoading = false);
    },
    scroll (e) {
      if(this.localeConfig) return;
      const body = e.target;
      // 获取距离顶部的距离
      const scrollTop = body?.scrollTop;
      // 获取可视区的高度
      const windowHeight = body.clientHeight;
      if (windowHeight - scrollTop >= windowHeight) {
        // 把距离顶部的距离加上可视区域的高度 等于或者大于滚动条的总高度就是到达底部
        this.bottomShow = false;
        this.getHistoryMsgs(false);
      } else {
        this.bottomShow = true;
      }
    },
    sedMessage() {
      if (!this.text.trim().length) {
        this.text = "";
        return;
      }
      let textContent = this.$replaceLinks(this.text);
      let time = new Date().getTime();
      let obj = {
        chatId: time,
        chatFileId: null,
        chatFileUrl: null,
        loading: true,
        content: textContent,
        messageType: 1,
        current: true,
        read: 0,
        createTime: this.$getTimeZone(this.userInfo.timeZone),
        createUser: this.userInfo
      }
      this.historyMessages.push(obj);
      this.setTopChatRoom();
      this.text = '';
      sendChatData({
        toUserId: this.otherParty.openId,
        content: textContent,
        messageType: 1,
        chatType: this.otherParty.memberList && this.otherParty.memberList.length ? 1 : 0
      }).then((data) => {
        let pingFilter = this.pingList.find(item => item.user.openId == this.otherParty.openId);
        pingFilter.content = textContent;
        let msgFilter = this.historyMessages.find(item => item.chatId == time);
        msgFilter.chatId = data.chatId;
        msgFilter.loading = false;
      });
    },
    dayjsFormat(sendDateStr) {
      return dayjs(sendDateStr).format('HH:mm:ss');
    },
    setTopChatRoom() {
      setTimeout(() => {
        if(this.$refs.reference) {
          this.$refs.reference.scrollTo({
            top: this.$refs.reference?.scrollHeight,
            behavior: 'instant'
          })
        }
      }, 300);
    },
    onGetYears(data, i) {
      if (i == 0) {
        return data && dayjs(data.createTime).format('YYYY-MM-DD');
      }
      const { createTime } = data; //当前时间
      let time = this.historyMessages[i - 1].createTime; //下一条时间
      if (
        dayjs(createTime).format('YYYY-MM-DD') !=
        dayjs(time).format('YYYY-MM-DD')
      ) {
        return dayjs(createTime).format('YYYY-MM-DD');
      } else {
        return '';
      }
    },
    closeFun() {
      this.historyMessages = [];
      this.activeId = null;
      this.activeCopyId = null;
      this.chatTitleActive = 0;
      this.systemNoticeShowActive = false;
      this.groupChatIndex = null;
      this.groupChatRow = null;
      document.removeEventListener("paste", this.pasteEvent);
      document.removeEventListener('keydown', this.onEsc);
      store.dispatch("user/setMessageDialogOpen", {
        visible: false,
        isElastic: this.isElastic,
        isHeaderMessage: this.isHeaderMessage,
        top: null,
        otherParty: null
      })
    }
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
