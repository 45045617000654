<template>
  <div id="app">
    <div :style="{
      '--theme-color': layoutColors[0],
      '--theme-background': layoutColors[1],
      '--theme-text-color': layoutColors[2],
      '--theme-main-color': layoutColors[3],
      '--theme-hover-color': layoutColors[4],
      '--theme-active-color': layoutColors[5],
      '--theme-children-active-color': layoutColors[6],
    }">
      <keep-alive :include="['Login']">
        <router-view />
      </keep-alive>
      <portal-target name="select-group-portal"></portal-target>
    </div>
    <messageDialog />
    <div class="importProgressBox" v-if="importFileProgress.show">
      <div class="box">
        <el-progress type="circle" :percentage="importFileProgress.value"></el-progress>
        <div class="label">{{ $t('Import in progress') }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import { mapGetters } from 'vuex';
import router from './router/index';
import store from './store';
import { getToken } from '@/utils/auth';
import NProgress from 'nprogress';
export default {
  data() {
    return {
      layoutColors: []
    }
  },
  computed: {
    ...mapGetters(['msg', 'layoutModel', 'layoutLightColors', 'layoutDarkColors', 'importFileProgress', 'isVipMode', 'userInfo']),
    userStore() {
      return store.state.user;
    },
  },
  watch: {
    msg(val) {
      const { msg, from } = val;
      this.$notify({
        title: from.name,
        message: msg,
        position: 'top-right',
      });
    },
    isVipMode: {
      handler(val) {
        if(val == '0') {
          this.layoutColors = this.layoutLightColors;
        }
        if(val == '1') {
          this.layoutColors = this.layoutDarkColors;
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.$store.dispatch('dict/getDictData');
    this.$store.dispatch('dict/getAllNodeList');
    let emptyEl = document?.querySelector('body');
    emptyEl.addEventListener(
      'DOMSubtreeModified',
      () => {
        for (let i = 0; i < emptyEl?.children?.length; i++) {
          const element = emptyEl?.children[i];
          if (
            element?.className == 'el-tooltip__popper is-dark' &&
            element?.children?.length < 2
          ) {
            element.remove();
          }
        }
      },
      false
    );
    router.beforeEach((to, from, next) => {
      const hasToken = getToken();
      if (this.userStore.userInfo?.authCompany && hasToken) {
        next();
      } else if (
        to.path == '/profile' ||
        to.path == '/companyProfile' ||
        to.path == '/login' || 
        to.path == '/register' ||
        to.path == '/forgot' ||
        to.path == '/emailVerify' || 
        to.path == '/index' || 
        to.path == '/transferTakeover' || 
        to.path == '/lawDetail' || 
        to.path == '/shareOrderLink' || 
        to.path == '/goodsListUrl'
      ) {
        if(to.path == '/index') {
          if(this.userInfo.avatarUrl) {
            next();
          } else {
            NProgress.done()
            return this.$message.warning(
              window.vm.$t('customerInfo.Please complete the information first')
            );
          }
        } else {
          next();
        }
      } else {
        NProgress.done()
        return this.$message.warning(
          window.vm.$t('customerInfo.Please complete the information first')
        );
      }
    });
    document.addEventListener('visibilitychange', async () => {
      if(localStorage.getItem("Admin-Token")) {
        if(this.$store.state.user.token != localStorage.getItem("Admin-Token")) {
          this.$store.dispatch("user/setToken", localStorage.getItem("Admin-Token"));
          this.$nextTick(() => {
            location.reload();
          })
        }
      }
    });
    setTimeout(() => {
      if(document.getElementById('app-cockpit-loading')) {
        document.getElementById('app-cockpit-loading').remove();
      }
    }, 1000);
    this.$store.dispatch("user/setMessageDialogOpen", {
      visible: false,
      isElastic: this.isElastic,
      top: null,
      otherParty: null
    })
  }
};
</script>
<style lang="less">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.importProgressBox {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 111111111111111;
  display: flex;
  align-items: center;
  justify-content: center;
  .box {
    background: #ffffff;
    padding: 20px 30px;
    border-radius: 8px;
    text-align: center;
    .label {
      margin-top: 5px;
      font-size: 17px;
      color: #122545;
    }
  }
}

.grid-table {
  height: inherit;
  display: grid;
  grid-template-rows: 1fr auto;
}

.el-dialog__close.el-icon.el-icon-close::before {
  font-size: 24px;
}

.co_group_footer .el-button--text {
  color: #606266;
  padding: 8px 10px;
  border: none;
}

.share-copy .el-button--text {
  color: #2e3f4f;
}

.co_group_footer .el-button--text:focus,
.co_group_footer .el-button--text:hover,
.share-copy .el-button--text:focus,
.share-copy .el-button--text:hover {
  // color: #606266c4;
  // border-color: transparent;
  // background-color: transparent;
  background-color: #d8d9dac4;
  color: #4e5f6f;
  font-weight: 600;
  border: none;
}

.co_group_footer .el-button--text:focus,
.co_group_footer .el-button--text:hover {
  color: #4e5f6f;
  // border-color: transparent;
  // background-color: transparent;
  background-color: rgba(46, 63, 79, 0.1);
  padding: 8px 10px;
}

.ql-toolbar.ql-snow {
  display: none;
}
</style>
