import axios from "axios";
import { MessageBox, Message, Notification } from "element-ui";
import store from "@/store";
import router from "@/router/index";
import { getToken } from "@/utils/auth";
import config from "../store/Config";
import { log } from "console";

let errorShowMsg = false;

//cookies put in Modified by Wyn
axios.defaults.withCredentials = true;

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 85000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    let lang = localStorage.getItem("lang") || window.vm.$i18n.locale;
    config.headers["Accept-Language"] = lang == "zh" ? "zh_CN" : "en_US";
    if(localStorage.getItem("captcha")) {
      config.headers["captcha-session"] = localStorage.getItem("captcha");
    }
    if(localStorage.getItem("rsa")) {
      config.headers["rsa-session"] = localStorage.getItem("rsa");
    }
    if(!config.headers.Authorization) {
      if (store.getters.token) {
        config.headers["Authorization"] = 'Bearer '+getToken();
      }
      if (config.headers["Authorization"] == null) {
        delete config.headers["Authorization"];
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    const res = response; //根据后端标准修改，对应的res.code也得改为res.status. Modified by Wyn in 2022/3/21
    let vm = window.vm;
    if(res.headers && res.headers['captcha-session']) {
      localStorage.setItem("captcha", res.headers['captcha-session']);
    }
    if(res.headers && res.headers['rsa-session']) {
      localStorage.setItem("rsa", res.headers['rsa-session']);
    }
    if (res.status !== 200) {
      return Promise.reject(new Error(res.message || "Error"));
    } else {
      let routerPath = router.app._route.path;
      const { url } = response.config;
      if (
        store.getters.token ||
        routerPath == "/register" ||
        routerPath == "/emailVerify" ||
        url.indexOf("/oauth") != -1
      ) {
        if (res.data.status == 11012) {
          MessageBox.confirm(
            vm.$t("home.Token expired, Please log out and log in again"),
            vm.$t("home.Confirm logout"),
            {
              confirmButtonText: vm.$t("home.Re-Login"),
              type: "warning",
              closeOnClickModal: false,
              closeOnPressEscape: false,
              showClose: false,
              showCancelButton: false,
            }
          ).then(() => {
            store.dispatch("user/resetToken").then(() => {
              location.reload();
            });
          });
          return {};
        } else if (res.data.status == 11013) {
          MessageBox.confirm(
            vm.$t("home.Token expired, Please log out and log in again"),
            vm.$t("home.Confirm logout"),
            {
              confirmButtonText: vm.$t("home.Re-Login"),
              type: "warning",
              closeOnClickModal: false,
              closeOnPressEscape: false,
              showClose: false,
              showCancelButton: false,
            }
          ).then(() => {
            store.dispatch("user/resetToken").then(() => {
              location.reload();
            });
          });
          return {};
        } else if (res.data.status == 11014) {
          MessageBox.confirm(
            vm.$t(
              "home.Your account has been logged in other places, please log out and log in again"
            ),
            vm.$t("home.Confirm logout"),
            {
              confirmButtonText: vm.$t("home.Re-Login"),
              type: "warning",
              closeOnClickModal: false,
              closeOnPressEscape: false,
              showClose: false,
              showCancelButton: false,
            }
          ).then(() => {
            store.dispatch("user/resetToken").then(() => {
              location.reload();
            });
          });
          return {};
        }
      }
      return res.data;
    }
  },
  ({ response }) => {
    if(response && response.data && (response.data.message != 'The current user has not joined any company' && response.data.message != '当前用户未加入任何公司')) {
      if(!errorShowMsg) {
        errorShowMsg = true;
        setTimeout(() => {
          errorShowMsg = false;
        }, 2000);
        if(response.data.errors && response.data.errors.length) {
          response.data.errors.forEach(item => {
            Message({
              message: item.errorMessage,
              type: "error",
              duration: 3 * 1000,
            });
          })
        } else {
          if(response.data instanceof Blob) {
            response.data.text().then(text => {
              const json = JSON.parse(text)
              Message({
                message: json.message,
                type: 'error',
                duration: 3 * 1000
              })
            })
          } else {
            Message({
              message: response.data.message,
              type: "error",
              duration: 3 * 1000,
            });
          }
        }
      }
    }
    return Promise.reject(response && response.data);
  }
);

export default service;
