<template>
  <div class="map_box">
    <div :id="domId" class="map-track-container" v-loading="loading"></div>
    <div class="mapModeSelectBox" v-if="isSelectlayer" :class="showSelect ? 'active' : ''">
      <div class="top" @click="showSelect = !showSelect">
        {{ mapOptions.filter(item => item.value === mapId)[0].text }}<i class="iconfont icon-a-Polygon3"></i>
      </div>
      <div class="list">
        <div 
          class="item" 
          :class="item.value == mapId ? 'active' : ''"
          @click="handleCommand(item.value)" 
          v-for="(item, index) in mapOptions" 
          :key="index">
          <img v-if="item.imgUrl" :src="item.imgUrl" />
          <div class="text">{{ item.text }}</div>
          <div class="checkbox" v-if="item.value == mapId">
            <i data-v-308bbae6="" class="el-icon-check"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, onMounted, watch, nextTick, onActivated } from "@vue/composition-api";
import { getShipHistoryTrack } from "@/api/init";
import { getShareShipInfo } from "@/api/order";
import { getUserCustomList } from "@/api/customer";
import * as L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet.pm";
import "leaflet.pm/dist/leaflet.pm.css";
import "./leaflet.ChineseTmsProviders";
import "./leaflet-bing-layer";
import { Message, Row } from "element-ui";
import router from "@/router";
import store from "@/store";
import { log } from 'console';
/* leaflet icon */
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  name: "MapTrack",
  props: {
    type: {
      type: Number,
      default: 0
    },
    domId: {
      type: String,
      default: "map-track"
    },
    companyName: {
      type: String,
      default: ""
    },
    orders: {
      type: Array,
      default: "",
    },
    timeNow: {
      type: [String, Number],
      default: Date.now(),
    },
    isSelectlayer: {
      type: Boolean,
      default: false,
    }
  },
  emits: ["callback"],
  setup(props, { emit }) {
    const map = ref(null);
    const mapId = ref(0);
    let showSelect = ref(false);
    const mapOptions = [
      {
        value: 0,
        imgUrl: "https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/mapImage/BingCommonCn.png",
        text: "BingCommon(Cn)",
      },
      {
        value: 1,
        imgUrl: "https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/mapImage/BingCommonCn.png",
        text: "BingCommon(En)",
      },
      {
        value: 2,
        imgUrl: "https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/mapImage/BingCn.png",
        text: "Bing(Cn)",
      },
      {
        value: 3,
        imgUrl: "https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/mapImage/BingCn.png",
        text: "Bing(En)",
      },
      {
        value: 4,
        imgUrl: "https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/mapImage/Bingdixing.png",
        text: "Bing地形(Cn)",
      },
      {
        value: 5,
        imgUrl: "https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/mapImage/Bingdixing.png",
        text: "Bing地形(En)",
      },
      {
        value: 6,
        imgUrl: "https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/mapImage/openStreeMap.png",
        text: "OpenStreetMap",
      },
      {
        value: 7,
        imgUrl: "https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/mapImage/openStreeMapCycle.png",
        text: "OpenStreetMap-Cycle",
      },
      // {
      //   value: 8,
      //   text: "天地图(tiandiMap)",
      // },
      {
        value: 9,
        imgUrl: "https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/mapImage/gaode.png",
        text: "高德(GaoDe)",
      },
      {
        value: 10,
        text: "谷歌(Google-CN)",
      },
      {
        value: 11,
        text: "谷歌(Google-EN)",
      },
      {
        value: 12,
        imgUrl: "https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/mapImage/zhitu.png",
        text: "智图(geoq)",
      },
      {
        value: 13,
        imgUrl: "https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/mapImage/zhitu.png",
        text: "Leaflet",
      }
    ];
    const mapData = ref([]);
    const loading = ref(true);
    const mapConfig = ref({
      center: [36.901934, 98.424015], // 地图中心
      zoom: 4, //缩放比列
      zoomControl: Boolean(router.currentRoute.query.token), //禁用 + - 按钮
      doubleClickZoom: false, // 禁用双击放大
      attributionControl: false, // 移除右下角leaflet标识
      minZoom: 3, // 最小缩放比例
      maxZoom: 18, // 最大缩放比例
      crs: L.CRS.EPSG3857, // 坐标系
      maxBounds: L.latLngBounds(L.latLng(-90, -360), L.latLng(90, 720)), // 地图边界
    });
    const shipHistoryTrack = ref([]);
    const shipHistoryTrackList = ref([]);
    const polyline_group = ref([])

    onMounted(() => {
      window.routerCompanyPage = (id) => {
        router.push(`/customer?companyId=${id}`)
      }
      const map_track_id = Number(localStorage.getItem('map_track')) || 0;
      mapId.value = map_track_id;
      InitializingMap(mapId.value);
      if(props.type == 1 || props.type == 2) {
        getCompanyList();
      } else {
        if (props.orders.length) {
          renderTrack();
        }
      }
    })

    onActivated(() => {
      const map_track_id = Number(localStorage.getItem('map_track')) || 0;
      mapId.value = map_track_id;
      InitializingMap(mapId.value);
      console.log(props.type, props.orders);
      if(props.type == 1 || props.type == 2) {
        getCompanyList();
      } else {
        if (props.orders.length) {
          renderTrack();
        }
      }
    })

    const handleCommand = (value) => {
      mapId.value = value;
      handleMapChange(value);
    }

    function InitializingMap(id) {
      loading.value = true;
      if(map.value) {
        map.value.remove()
      }
      switch (id) {
        case 0:
          initBingCommonCnMap();
          break;
        case 1:
          initBingCommonEnMap();
          break;
        case 2:
          initBingCnMap();
          break;
        case 3:
          initBingEnMap();
          break;
        case 4:
          initBingTopographyCnMap();
          break;
        case 5:
          initBingTopographyEnMap();
          break;
        case 6:
          initOSMMap();
          break;
        case 7:
          initOSMCycleMap();
          break;
        case 8:
          initTianMap();
          break;
        case 9:
          initGaodeMap();
          break;
        case 10:
          initGoogleCNMap();
          break;
        case 11:
          initGoogleENMap();
          break;
        case 12:
          initGeoqMap();
          break;
        case 13:
          initLeafletMap();
          break;
      }
      loading.value = false;
      nextTick(() => {
        HTMLCanvasElement.prototype.getContext = (function (origFn) {
          return function (type, attributes) {
            if (type === "webgl") {
              attributes = Object.assign({}, attributes, {
                preserveDrawingBuffer: true,
              });
            }
            return origFn.call(this, type, attributes);
          };
        })(HTMLCanvasElement.prototype.getContext);
      });
    }

    const initTianMap = () => {
      map.value = L.map(props.domId, mapConfig.value);
      L.tileLayer.chinaProvider("TianDiTu.Normal.Map").addTo(map.value);
      L.tileLayer.chinaProvider("TianDiTu.Normal.Annotion").addTo(map.value);
    };

    const initGaodeMap = () => {
      map.value = L.map(props.domId, mapConfig.value);
      L.tileLayer.chinaProvider("GaoDe.Normal.Map").addTo(map.value);
    };

    const initGoogleCNMap = () => {
      map.value = L.map(props.domId, mapConfig.value);
      L.tileLayer.chinaProvider("GoogleCN.Normal.Map").addTo(map.value);
    };

    const initGoogleENMap = () => {
      map.value = L.map(props.domId, mapConfig.value);
      L.tileLayer.chinaProvider("GoogleEN.Normal.Map").addTo(map.value);
    };

    const initGeoqMap = () => {
      map.value = L.map(props.domId, mapConfig.value);
      L.tileLayer.chinaProvider("Geoq.Normal.Map").addTo(map.value);
    };

    const initOSMMap = () => {
      map.value = L.map(props.domId, mapConfig.value);
      L.tileLayer.chinaProvider("OSM.Normal.Map").addTo(map.value);
    };

    const initOSMCycleMap = () => {
      map.value = L.map(props.domId, mapConfig.value);
      L.tileLayer.chinaProvider("OSM.Normal.Cycle").addTo(map.value);
    };

    const initLeafletMap = () => {
      map.value = L.map(props.domId, mapConfig.value);
      L.tileLayer.chinaProvider("LeafLet.Normal.Map").addTo(map.value);
    };

    const initBingCnMap = () => {
      map.value = L.map(props.domId, mapConfig.value);
      L.tileLayer.chinaProvider("Bing.Normal.Map").addTo(map.value);
      L.tileLayer.chinaProvider("Bing.Annotion.Cn").addTo(map.value);
    };

    const initBingEnMap = () => {
      map.value = L.map(props.domId, mapConfig.value);
      L.tileLayer.chinaProvider("Bing.Normal.Map").addTo(map.value);
      L.tileLayer.chinaProvider("Bing.Annotion.En").addTo(map.value);
    };

    const initBingTopographyCnMap = () => {
      map.value = L.map(props.domId, mapConfig.value);
      L.tileLayer.chinaProvider("Bing.Normal.topographyMap").addTo(map.value);
      L.tileLayer.chinaProvider("Bing.Annotion.Cn").addTo(map.value);
    };

    const initBingTopographyEnMap = () => {
      map.value = L.map(props.domId, mapConfig.value);
      L.tileLayer.chinaProvider("Bing.Normal.topographyMap").addTo(map.value);
      L.tileLayer.chinaProvider("Bing.Annotion.En").addTo(map.value);
    };

    const initBingCommonCnMap = () => {
      map.value = L.map(props.domId, mapConfig.value);
      L.tileLayer.chinaProvider("Bing.Normal.commonMap").addTo(map.value);
      L.tileLayer.chinaProvider("Bing.Annotion.Cn").addTo(map.value);
    }

    const initBingCommonEnMap = () => {
      map.value = L.map(props.domId, mapConfig.value);
      L.tileLayer.chinaProvider("Bing.Normal.commonMap").addTo(map.value);
      L.tileLayer.chinaProvider("Bing.Annotion.En").addTo(map.value);
    }

    const handleMapChange = (id) => {
      localStorage.setItem('map_track', id)
      InitializingMap(id);
      if(props.type == 1 || props.type == 2) {
        getCompanyList();
      } else {
        if (props.orders.length) {
          initMapData(mapData.value, true);
        }
      }
    };

    // 渲染船舶历史轨迹
    async function renderTrack() {
      loading.value = true;
      if(map.value) {
        if(polyline_group.value.length) {
          polyline_group.value.forEach(item => {
            map.value.removeLayer(item);
          })
          map.value.eachLayer((layer) => {
            if (layer instanceof L.Marker) {
              map.value.removeLayer(layer);
            }
          });
        }
      }
      try {
        let api = !router.currentRoute.query.token ? getShipHistoryTrack : getShareShipInfo;
        api(!router.currentRoute.query.token ? {
          orderIds: props.orders.map(item => item.orderId).join(','),
        } : router.currentRoute.query.token).then((data) => {
          console.log(data);
          mapData.value = data.map((item, index) => ({
            ...item,
            index: props.orders.filter(row => item.orderId == row.orderId)[0].index
          }));
          initMapData(mapData.value);
        }).finally(() => (loading.value = false));
      } catch (error) {
        loading.value = false;
      }
    }

    const initMapData = (res) => {
      shipHistoryTrack.value = res;
      const lineColors = [
        "#FF9412",
        "#467ADA",
        "#D63D23",
        "#6D8194",
        "#2E3F4F",
        "#D53D24",
      ];
      res.forEach((element, index) => {
        const arr = [];
        if (element && element.shipTrack.length > 0) {
          element.shipTrack.forEach((item, idx) => {
            const lat = item.lat / 1000000;
            const lon = item.lon < 0 && (element.shipTrack[0].lon > 0 || element.shipTrack[0].lon < 0) ? item.lon / 1000000 + 360 : item.lon / 1000000;
            arr.push([lat, lon]);
          });
          let marker = null;
          if(index === res.length - 1) {
            polyline_group.value.push(L.polyline(arr, {
              color: '#757D8A',
              weight: 2,
              opacity: 1,
              smoothFactor: 1,
              dashArray: "4",
              obj: element.shipTrack[element.shipTrack.length - 1],
              CI: element.ci,
              orderId: element.orderId,
            }).addTo(map.value));

            let stateDropColor = "";
            if(element.progress === '0') {
              stateDropColor = 'producing'
            } else if(element.progress === '6') {
              stateDropColor = 'destination'
            } else {
              stateDropColor = 'inTransit'
            }
            const icon = L.divIcon({
              html: `
                <div class="content">
                  <div class="circle">${element.index || 1}</div>
                  <div class="box">
                    <div class="ciNo">CI：${element.ci}</div>
                    <div class="status">
                      <div class="dropState ${stateDropColor}"></div>
                      ${store.state.dict.orderProgress[element.progress].key}
                    </div>
                  </div>
                </div>
              `,
              iconAnchor: [125, 9],
              zIndexOffset: 300,
              className: 'shipCircle'
            })
            marker = L.marker(arr[0], { icon: icon });
            marker.addTo(map.value);
            // 始发地图标
            const iconStart = L.DivIcon.extend({
              options: {
                iconSize: [16, 16],
                className: "startIconCircle",
                html: ``,
                obj: element.shipTrack[element.shipTrack.length - 1],
                CI: element.ci,
                orderId: element.orderId,
                color: lineColors[index],
                isActive: false,
              },
            });
            const markerStart = L.marker(arr[arr.length - 1], {
              icon: new iconStart(),
            });
            markerStart.addTo(map.value);
          } else {
            let icon = L.divIcon({
              html: `<div class="circle">${element.index}</div>`,
              className: 'map-circle'
            })
            marker = L.marker([
              arr[0][0], 
              arr[0][1], 
            ], {
              icon: icon
            }).addTo(map.value);
          }
          // click marker
          marker.on("click", () => {
            emit("callback", {
              ...element,
              isActive: false,
            });
            iconFun(element.ci);
          });
          // set map center
          map.value.setView(arr[0], 1);
          shipHistoryTrackList.value.push({
            CI: element.ci,
            startLatLon: arr[arr.length - 1],
            endLatLon: arr[0],
            spotColor: lineColors[index],
            shipInfo: element.shipInfo,
            orderId: element.orderId,
          });
        } else {
          const path = router.currentRoute.path;
          if (path != "/order/orderDetails") {
            Message.error("暂无轨迹数据");
          }
        }
        loading.value = false;
      })
    };

    function iconFun(CI) {
      polyline_group.value.forEach(item => {
        map.value.removeLayer(item);
      })
      map.value.eachLayer((layer) => {
        if (layer instanceof L.Marker) {
          map.value.removeLayer(layer);
        }
      });
      setTimeout(() => {
        let index = mapData.value.findIndex(item => item.ci === CI);
        let find = mapData.value.find(item => item.ci === CI);
        mapData.value.splice(index, 1);
        mapData.value.push(find);
        initMapData(mapData.value);
      }, 2)
    }

    watch(
      () => props.timeNow,
      () => {
        if (props.orders.length) {
          renderTrack();
        }
      }
    );

    let companyList = ref([])

    const getCompanyList = () => {
      getUserCustomList({
        currentPage: 1,
        pageSize: 100000,
        companyName: props.companyName,
        companyType: store.state.user.companyCurrentType == 1 ? 0 : (store.state.user.companyCurrentType == 2 ? (props.type == 1 ? 1 : 0) : 1)
      }).then((data) => {
        companyList.value = data.list;
        companyList.value.forEach((item) => {
          if(!item.position) return;
          const icon = L.divIcon({
            html: `
            <div class="companyMarkerBox" id="companyMarkerBox_${item.companyId}">
              <img class="markerIcon" src="https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/Subtract.png" />
              <img class="avatar" src="${item.companyLogoUrl}" />
            </div>
            `,
            iconAnchor: [23, 45],
          })
          let position = [item.position.split(",")[1], item.position.split(",")[0]]
          const marker = L.marker(position, { icon: icon });
          marker.on(`click`, (e) => {
            handleMapMarerClick(item);
            companyList.value.forEach(row => {
              if(!row.position) return;
              let currentId = e.target._icon.firstElementChild.getAttribute("id").split("companyMarkerBox_")[1];
              if(currentId == row.companyId) return;
              document.querySelector(`#companyMarkerBox_${row.companyId}`).style.opacity = '.7'
            })
          });
          marker.addTo(map.value);
          map.value.setView(position, 3);
        })
      })
    }

    const handleMapMarerClick = (item) => {
      let content = `
        <div class="mapCompanyInfoBox">
          <div class="top">
            <img src="${item.companyLogoUrl}" />
            <div class="content">
              <div class="title">
                <div class="text line1">${item.aliasName || item.name}</div>
                <span onclick="routerCompanyPage('${item.companyId}')">${window.vm.$t('home.detailText')}</span>
              </div>
              <div class="concat">
                <div class="phone box">
                  <div class="iconBox">
                    <i class="iconfont icon-shouji"></i>
                  </div>
                  ${item.contact}
                </div>
                <div class="email box">
                  <div class="iconBox">
                    <i class="iconfont icon-Mail"></i>
                  </div>
                  ${item.email}
                </div>
              </div>
            </div>
          </div>
          <div class="bottom">
            ${item.address}<br/>
            ${window.vm.$t('CompanyEdit.VATNO')}: ${item.vat}
          </div>
        </div>
      `;
      L.popup({
        offset: [-210, 80],
        closeButton: false,
        className: "markerPopupBox"
      })
      .setLatLng([item.position.split(",")[1], item.position.split(",")[0]])
      .setContent(content)
      .openOn(map.value)
      map.value.on('popupclose', () => {
        companyList.value.forEach(row => {
          if(!row.position) return;
          if(document.querySelector(`#companyMarkerBox_${row.companyId}`)) {
            document.querySelector(`#companyMarkerBox_${row.companyId}`).style.opacity = '1'
          }
        })
      })
    }

    watch(
      () => props.type,
      (val) => {
        InitializingMap(mapId.value);
        if(val == 1 || val == 2) {
          getCompanyList();
        } else {
          if (props.orders.length) {
            renderTrack();
          }
        }
      }
    );
    watch(() => props.companyName, (val) => {
      InitializingMap(mapId.value);
      nextTick(() => {
        getCompanyList();
      });
    })

    return {
      map,
      mapId,
      showSelect,
      mapOptions,
      loading,
      shipHistoryTrack,
      handleMapChange,
      InitializingMap,
      renderTrack,
      iconFun,
      handleCommand
    };
  },
};
</script>
<style lang="less" scoped>
.map_box {
  position: relative;
  --h: calc(100vh - 58px);
  width: 100%;
  height: var(--h);
  z-index: 1;
  border-radius: 0 0 0 12px;
  overflow: hidden;
  /deep/ .el-dropdown {
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 11111111111111;
    // width: 240px;
    font-weight: 600;
    color: #122545;
  }
}
.mapModeSelectBox {
  position: absolute;
  bottom: -120px;
  z-index: 1000;
  width: 100%;
  background: #ffffff;
  transition: all .3s;
  &.active {
    bottom: 0;
    .top {
      i {
        transform: scale(.3) rotate(180deg);
      }
    }
  }
  .top {
    position: absolute;
    top: -32px;
    right: 20px;
    background: #ffffff;
    padding: 5px 20px;
    border-radius: 4px 4px 0 0;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    cursor: pointer;
    i {
      display: inline-block;
      transition: all .3s;
      transform: scale(.3) rotate(0deg);
    }
  }
  .list {
    display: flex;
    flex-wrap: nowrap;
    gap: 20px;
    white-space: nowrap;
    padding: 15px 11px;
    overflow-x: overlay;
    .item {
      width: 120px;
      height: 90px;
      background: #122545;
      position: relative;
      flex-shrink: 0;
      cursor: pointer;
      border-radius: 8px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .text {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding-left: 8px;
        padding-right: 8px;
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
        color: #ffffff;
        white-space: pre-wrap;
        word-break: break-all;
        background: linear-gradient(to top, rgba(0,0,0,.7), rgba(0,0,0,0));
      }
      .checkbox {
        display: none;
        position: absolute;
        bottom: 0;
        width: 24px;
        height: 24px;
        background: #48AC42;
        border-radius: 4px 0 0 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          font-size: 18px;
          color: #ffffff;
          font-weight: 600;
        }
      }
      &.active {
        border: 2px solid #48AC42;
        .checkbox {
          display: flex;
        }
      }
    }
  }
}
</style>
<style lang="less">
.map-track-container {
  width: 100%;
  height: 100%;
}

.leaflet-div-icon {
  background: transparent;
  border: none;
}

.leaflet-div-icon svg {
  width: 32px;
  height: 32px;
}
.map-circle {
  width: auto !important;
  height: auto !important;
  padding: 0 6px;
  border-radius: 50px;
  background: #122545;
  border: 3px solid #ffffff;
  color: #ffffff;
}
.startIconCircle {
  border: 3px solid #ffffff;
  border-radius: 50px;
  background: #122545;
}
.shipCircle {
  width: auto !important;
  height: auto !important;
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.12);
  border-radius: 50px;
  padding: 6px 30px 6px 10px;
  z-index: 1000 !important;
  opacity: .8;
  transition: all .3s;
  &:hover {
    opacity: 1;
  }
  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .circle {
      border: 2px solid #f0f0f0;
      border-radius: 50px;
      background: #2A3B58;
      color: #ffffff;
      text-align: center;
      line-height: 1;
      padding: 5px 8px;
    }
    .box {
      margin-left: 8px;
      .ciNo {
        white-space: nowrap;
        max-width: 145px;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #122545;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
      }
      .status {
        color: #122545;
        font-size: 12px;
        line-height: 17px;
        display: flex;
        align-items: center;
        white-space: nowrap;
        .dropState {
          width: 6px;
          height: 6px;
          border-radius: 50%;
          margin-right: 4px;
          &.producing {
            background: #DC3545;
          }
          &.destination {
            background: #2E4793;
          }
          &.inTransit {
            background: #48AC42;
          }
        }
      }
    }
  }
}
</style>
