import request from "@/utils/request";
import store from "../store";

//调用返回枚举列表接口(系统用户)
export function getDict(token) {
  let headers = token ? {
    Authorization: 'Bearer '+token
  } : {};
  return request({
    url: "/manage/dict/getDict",
    method: "get",
    headers
  });
}

//创建群组
export function createGroup(data) {
  return request({
    url: "/group/add",
    method: "post",
    data,
  });
}
//删除群组
export function deleteGroup(params) {
  return request({
    url: "/group/delete",
    method: "post",
    params,
  });
}
//获取对方公司关系成员
export function companyMember(customerId) {
  return request({
    url: `/group/listDetail`,
    method: "get",
    params: { customerId },
  });
}
//修改群组
export function updateGroup(data) {
  return request({
    url: "/group/update",
    method: "post",
    data,
  });
}
//获取小组列表
export function getGroupList(params) {
  return request({
    url: "/group/list",
    method: "get",
    params,
  });
}

// 获取国家数据
export function getCountryData(params) {
  return request({
    url: "/manage/country/page",
    method: "get",
    params,
  });
}

// 用户个性化设置
export function setPersonalization(data) {
  return request({
    url: "/manage/users/personalization/record",
    method: "post",
    data,
  });
}

// 根据文本生产图片
export function getTextImage(text) {
  return request({
    url: "/file/generateAvatar",
    method: "get",
    params: {
      text
    },
    responseType: 'blob',
  });
}

// 根据文本生产用户图片
export function getTextUserImage(text) {
  return request({
    url: "/file/generateAvatarSquare",
    method: "get",
    params: {
      text
    },
    responseType: 'blob',
  });
}

// 获取用户设置的Tag组
export function getConfigTagList() {
  return request({
    url: "/manage/users/personalization/listEventTag",
    method: "get"
  });
}

// 设置用户的Tag组
export function setUserConfigTags(data) {
  return request({
    url: "/manage/users/personalization/updateEventTag",
    method: "post",
    data,
  });
}

// 设置成员角色
export function updateMemberRole(data) {
  return request({
    url: "/company/user/updateMemberRole",
    method: "post",
    data
  })
}

// 查看标签操作记录
export function seeTagOperationRecords(params) {
  return request({
    url: "/order/tagLog/page",
    method: "get",
    params
  });
}

// 获取法律法规数据
export function getLawData(type) {
  return request({
    url: "/systemLaw/detail",
    method: "get",
    params: {
      type
    }
  });
}

// 获取邮件模板目录列表
export function getEmailTemplateDirectoryList(params) {
  return request({
    url: "/mail/template/tree",
    method: "get",
    params
  });
}

// 查询邮件模板数据
export function getEmailTemplateList(parentId) {
  return request({
    url: "/mail/template/findByParentIds",
    method: "get",
    params: {
      parentId
    }
  });
}

// 收藏模板
export function collectEmailTemplate(id) {
  return request({
    url: "/mail/template/collect",
    method: "get",
    params: {
      id
    }
  });
}

// 获取邮件模板搜索列表
export function getEmailTemplateSearchList(keyword) {
  return request({
    url: "/mail/template/list",
    method: "get",
    params: {
      keyword
    }
  });
}

// 获取新手入门目录列表
export function getGuideDirectoryList(params) {
  return request({
    url: "/systemNewbieGuide/page",
    method: "get",
    params
  });
}

// 收藏模板
export function collectGuide(id) {
  return request({
    url: "/systemNewbieGuide/collect",
    method: "get",
    params: {
      id
    }
  });
}

// 获取邮件BannerImage
export function getEmailBannerList(params) {
  return request({
    url: "/systemMailBanner/page",
    method: "get",
    params
  });
}

// 发送邮件banner到他人邮箱
export function sendEmailBanner(params) {
  return request({
    url: "/systemMailBanner/sendMailBanner",
    method: "get",
    params
  });
}

// 保存分享邮件配置数据
export function saveShareEmailConfig(data) {
  return request({
    url: "/share/mail/save",
    method: "post",
    data
  });
}

// 获取分享邮件配置数据
export function getShareEmailConfig(companyId) {
  return request({
    url: "/share/mail/detail",
    method: "get",
    params: {
      companyId
    }
  });
}
