<template>
  <el-drawer
    :visible.sync="show"
    :title="$t('Case detail')"
    size="728px"
    :before-close="handleClose"
    :show-close="true"
    :append-to-body="true"
    v-move-outside
  >
    <div class="detailBox" :class="isShare ? 'isShare' : ''" v-if="detail">
      <el-image 
        fit="cover" 
        class="elImage" 
        :preview-src-list="detail.picList.map(item => item.fileUrl)"
        :src="detail.picList.find(item => item.check) && detail.picList.find(item => item.check).fileUrl">
        <div slot="error" class="image-slot">
          <img :src="$emptyImage" />
          <p>{{ $t('No image') }}</p>
        </div>
      </el-image>
      <div class="checkImageBox">
        <div 
          class="imageItem" 
          v-for="(item, index) in detail.picList" 
          :key="index"
          :class="item.check ? 'active' : ''"
          @click="changeImage(item)">
          <img :src="item.fileUrl" />
        </div>
      </div>
      <div class="title">{{ detail.caseName }}</div>
      <div class="description">{{ detail.description }}</div>
      <div class="tagList">
        <div 
          class="item line1" 
          v-for="(row, index) in detail.tags"
          :key="index"
          :style="{
            background: row.color
          }"
        >{{ row.content }}</div>
      </div>
      <div class="pageTitle">
        {{ $t('Attachment') }}
      </div>
      <fileBlocks
        v-if="detail.attachmentId"
        type="message"
        background="#F7F9FC"
        :embedUrl="detail.attachmentUrl"
        :embedId="detail.attachmentId"
      />
    </div>
    <div class="drawerFooter" v-if="detail && !isShare">
      <el-button 
        class="cancel_button" 
        size="small" 
        @click="handleClose"
      >{{ $t('Cancel') }}</el-button>
      <el-button 
        size="small"
        @click="deleteItem"
        v-if="detail.companyId == companyId"
      >{{ $tc('home.Delete') }}</el-button>
      <el-popover
        ref="popover"
        placement="top"
        trigger="click"
        :append-to-body="false"
        popper-class="ec-popper">
        <div class="popperMenuItem" @click="operation(0)">
          <i class="iconfont icon-fenxiang2" />
          <span>{{ $t("order.Internal sharing") }}</span>
        </div>
        <div class="popperMenuItem" @click="operation(1)">
          <i class="iconfont icon-zhanwaifenxiang" />
          <span>{{ $t("order.External sharing") }}</span>
        </div>
        <el-button 
          slot="reference"
          size="small"
          style="margin: 0 10px"
        >{{ $t('order.share') }}</el-button>
      </el-popover>
      <el-button 
        size="small"
        v-if="detail.companyId == companyId"
        @click="editItem"
      >{{ $t('home.Edit') }}</el-button>
      <el-button 
        v-else
        type="primary"
        size="small"
        @click="consultEvent"
      >{{ $t('noticeServer.Consult') }}</el-button>
    </div>
    <inside
      :isInside="isInside"
      @onClose="isInside = false"
      type="shopServerInternal"
      :shopServerId="detail && detail.goodsCaseId"
      :messageType="13"
    />
    <externalShare ref="externalShare" />
  </el-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import { deleteCase } from "@/api/shopServer.js";
import inside from "@/components/fileInside/inside.vue";
import externalShare from '../share/externalShare.vue';

export default {
  components: {
    inside,
    externalShare
  },
  props: {
    isShare: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: false,
      detail: null,
      isInside: false,
    }
  },
  computed: {
    ...mapGetters(["companyId"])
  },
  methods: {
    open(row = null) {
      this.show = true;
      this.detail = row;
      this.detail.picList = this.detail.picList ? this.detail.picList.map((item, index) => ({
        ...item,
        check: index == 0
      })) : []
    },
    handleClose() {
      this.show = false;
    },
    consultEvent() {
      this.$emit("consult", this.detail.goodsCaseId);
    },
    changeImage(row) {
      this.detail.picList.forEach((item) => {
        item.check = false;
      })
      row.check = !row.check;
    },
    deleteItem() {
      this.$confirm(
        this.$t('Cannot reply after deletion'), 
        this.$t('Are you sure to delete this product/service'), 
        {
          confirmButtonText: this.$t('Confirm'),
          cancelButtonText: this.$t('Cancel'),
          type: 'warning'
        }
      ).then(() => {
        deleteCase(this.detail.goodsCaseId).then(() => {
          this.$message.success(this.$t('event.Delete successfully'));
          this.$emit("updateList", false);
          this.handleClose();
        })
      }).catch(() => {});
    },
    editItem() {
      this.$emit("edit", this.detail);
    },
    operation(type) {
      switch (type) {
        case 0:
          this.isInside = true;
          break;
        case 1:
          this.$refs.externalShare.open(this.detail.goodsCaseId, this.detail.companyId, 5);
          break;
      }
    },
  }
}
</script>

<style lang="less" scoped>
@import "./index.less";
</style>