<template>
  <div class="historyBox" v-if="history.length">
    <div
      v-for="(item, index) in history"
      :key="index"
      class="item"
      :class="{
        active: historyObject[item.progress] && historyObject[item.progress].active
      }"
    >
      <template v-if="historyObject[item.progress] && historyObject[item.progress].active">
        <div class="status active">
          <i :class="`iconfont ${computedNodeList(item.progress).icon}`"></i>
        </div>
        <div class="content">
          <div class="statusText">
            {{ computedNodeList(item.progress).name }}
          </div>
          <div class="time">
            {{ historyObject[item.progress].date || "_ _" }}
          </div>
        </div>
        <div class="user" v-if="historyObject[item.progress].user">
          <member
            :info="historyObject[item.progress].user"
            :size="28"
            :placement="'left'"
          ></member>
        </div>
      </template>
      <template v-else>
        <div class="status">
          <i :class="`iconfont ${computedNodeList(item.progress).icon}`"></i>
        </div>
        <div class="content">
          <div class="statusText">
            {{ computedNodeList(item.progress).name }}
          </div>
          <div class="time">_ _</div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "orderHistory",
  props: {
    history: {
      type: Array,
      default: [],
    },
    progress: {
      type: Number | String,
      default: "",
    },
  },
  data() {
    return {
      historyObject: {}
    };
  },
  computed: {
    ...mapGetters(["orderProgress", "allNodeList"]),
    computedNodeList() {
      return function (type) {
        return this.allNodeList.find(item => item.type == type);
      }
    }
  },
  mounted() {

  },
  watch: {
    history: {
      handler() {
        this.history.forEach((item) => {
          this.historyObject[item.progress] = item;
          if (Number(item.progress) <= Number(this.progress)) {
            this.historyObject[item.progress].active = true;
          } else {
            this.historyObject[item.progress].active = false;
          }
        });
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {

  },
};
</script>
<style lang="less" scoped>
.historyBox {
  margin-top: 20px;
  .item {
    width: 100%;
    height: 73px;
    padding: 13px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    transition: background 0.3s;
    cursor: pointer;
    .status {
      width: 40px;
      height: 40px;
      border-radius: 4px;
      background: #f7f9fc;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #e0e4ea;
      transition: background 0.3s;
      &.active {
        color: #0e559c;
      }
      i {
        font-size: 24px;
      }
    }
    .content {
      flex: 1;
      margin-left: 12px;
      .statusText {
        font-size: 12px;
        line-height: 17px;
        font-weight: 500;
        color: #122545;
        margin-bottom: 4px;
      }
      .time {
        font-size: 12px;
        line-height: 17px;
        color: #64748b;
      }
    }
    .user {
      text-align: center;
      position: relative;
      top: -2px;
      p {
        font-size: 12px;
        line-height: 1;
        color: #637381;
        font-weight: 500;
        width: 36px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    &:hover {
      background: #f9f9f9;
      .status {
        background: #ffffff;
      }
    }
    &::after {
      content: "";
      display: inline-block;
      width: 3px;
      height: 26px;
      background: #e0e4ea;
      position: absolute;
      left: 39px;
      top: 60px;
      z-index: 1;
    }
    &.active {
      &::after {
        background: #0e559c;
      }
    }
    &:last-child {
      &::after {
        display: none;
      }
    }
  }
}
</style>
