import request from '@/utils/request';
import trackingZH from '@/language/tracking/zh'

//创建公告
export function createMessage(data) {
  return request({
    url: '/notice/add',
    method: 'post',
    data,
  });
}

//删除公告
export function deleteMessage(params) {
  return request({
    url: '/notice/delete',
    method: 'post',
    params,
  });
}

//获取发送公告列表
export function getSendMessageList(params) {
  return request({
    url: '/notice/page',
    method: 'get',
    params,
  });
}

//获取接收公告列表
export function getReceiveMessageList(data) {
  return request({
    url: '/businessMessage/receiveMessageList',
    method: 'post',
    body: data,
  });
}

//从订单小组中移除用户
export function kickOutUser(data) {
  return request({
    url: '/businessGroup/kickOutUser',
    method: 'post',
    data,
  });
}

// 翻译对话信息
export function translateMessage(data) {
  return request({
    url: '/manage/translate/get',
    method: 'post',
    data,
  });
}

// 设置公司普通员工
export function setManager(data) {
  return request({
    url: '/businessCompany/setManager',
    method: 'post',
    data,
  });
}
