import request from "@/utils/request";

//创建评论
export function createComment(data) {
  return request({
    url: "/event/comment/add",
    method: "post",
    data,
  });
}

//获取评论
export function getCommentList(data) {
  return request({
    url: "/businessComment/commentList",
    method: "post",
    data: data,
  });
}
//获取公司下的所有员工和关系公司的所有员工
export function getCompanyEmployeeListAndRelation(params) {
  return request({
    url: `/customer/pageMember`,
    method: "get",
    params
  });
}
//批量分享附件
export function batchShareAttachment(data) {
  return request({
    url: "/businessInstantMessage/batchShareAttachment",
    method: "post",
    data: data,
  });
}