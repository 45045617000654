// 通过计数实现部分页面数据动态刷新，例如订单删除后事件等自动刷新

const state = {
  order: 100,
};

const mutations = {
  ORDER_UPDATE: (state) => {
    state.order = state.order + 1;
  },
};

const actions = {
  orderUpdate({ commit }, event) {
    commit("ORDER_UPDATE", event);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
