const state = {
  eventList: [],
  commentList: [],
};

const mutations = {
  SET_EVENTLIST: (state, eventList) => {
    state.eventList = eventList;
  },
  SET_COMMENTLIST: (state, commentList) => {
    state.commentList = commentList;
  },
};

const actions = {
  setEventList({ commit }, event) {
    commit('SET_EVENTLIST', event);
  },

  setCommentList({ commit }, comment) {
    commit('SET_COMMENTLIST', comment);
  },

  //注意在logout时候也得把vuex清掉
  logout({ commit }) {
    commit('SET_EVENTLIST', []);
    commit('SET_COMMENTLIST', []);
    window.ws && window.ws.close();
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
