var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"reference",staticClass:"file-block-wrapper",class:_vm.type == 'message' ? 'message' : _vm.type == 'history' ? 'history' : '',style:({
    background: _vm.background
  }),on:{"click":_vm.fileEventClick}},[(_vm.fileFontType == 'pdf')?_c('span',{staticClass:"iconfont_file icon-Pdf"}):_vm._e(),(_vm.fileFontType == 'zip')?_c('span',{staticClass:"iconfont_file icon-zip"}):_vm._e(),(_vm.fileFontType == 'picture')?_c('span',{staticClass:"iconfont_file icon-tupian"}):_vm._e(),(_vm.fileFontType == 'word')?_c('span',{staticClass:"iconfont_file icon-Word"}):_vm._e(),(_vm.fileFontType == 'excel' || _vm.fileFontType == 'csv')?_c('span',{staticClass:"iconfont_file icon-Excel"}):_vm._e(),(_vm.fileFontType == 'powerpoint')?_c('span',{staticClass:"iconfont_file icon-PPT"}):_vm._e(),(_vm.fileFontType == 'video')?_c('span',{staticClass:"iconfont_file icon-shipin"}):_vm._e(),(!_vm.audioPlayStatus && _vm.fileFontType == 'audio')?_c('span',{staticClass:"iconfont icon-weixinyuyin"}):(_vm.audioPlayStatus && _vm.fileFontType == 'audio')?_c('img',{staticClass:"playIcon",attrs:{"src":"https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/audioPlay.gif"}}):_vm._e(),_c('div',{staticClass:"file_block_name"},[_c('span',[_vm._v(_vm._s(_vm.fileFontType != 'audio' ? _vm.fileName : _vm.fileAudio.duration+'s'))])]),(!_vm.isShare)?_c('Teleport',{attrs:{"to":"body","disabled":_vm.embedShow}},[_c('fileView',{attrs:{"row":{
        id: _vm.embedId,
        fileName: _vm.fileName,
        fileSuffix: _vm.fileFontType,
        savePath: _vm.embedUrl
      },"embedShow":_vm.embedShow,"embedUrl":_vm.embedUrl},on:{"close":function($event){_vm.embedShow = false}}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }