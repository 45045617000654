import request from "@/utils/request";
import dayjs from "dayjs";

//调用新增文件配置接口(系统用户)
export function createFileConfig(data) {
  return request({
    url: "/file/config/create",
    method: "post",
    data: data,
  });
}

//调用返回文件配置列表接口(系统用户)
export function getFileConfigList() {
  return request({
    url: "/file/config/list",
    method: "get",
  });
}

//调用上传文件文件接口
export function uploadFile(data, token) {
  let headers = token ? {
    Authorization: "Bearer " + token,
  } : {};
  return request({
    url: "/file/upload",
    method: "post",
    data: data,
    headers
  });
}

//调用上传文件文件接口
export function uploadFileUrl(data) {
  return request({
    url: "/file/urlUpload",
    method: "post",
    data: data,
  });
}

export function previewFile(data) {
  return request({
    url: "/file/preview",
    method: "post",
    responseType: "blob",
    data: data,
  });
}

//获取头像的二进制流
export function downloadUserPic(fileId) {
  return request({
    url: "/file/download",
    method: "get",
    params: { fileId },
    responseType: "blob",
  });
}

//业务文件上传
export function uploadBusinessFile(data) {
  return request({
    url: "/businessFile/upload",
    method: "post",
    data: data,
  });
}

//获取普通业务文件
export function downloadFile(fileId) {
  return request({
    url: "/file/download",
    method: "get",
    params: { fileId },
    responseType: "blob",
  });
}

//获取用户分类文件列表
export function getClassifyList(params) {
  return request({
    url: "/file/listFile",
    method: "get",
    params,
  });
}

//删除用户文件
export function fileConfigDelete(data) {
  return request({
    url: "/businessFile/delete",
    method: "post",
    data: data,
  });
}

//sku文件预览
export function previewSkuFile(params) {
  return request({
    url: "/order/sku/page",
    method: "get",
    params,
  });
}

// 获取临时文件地址
export function getTemporaryFile(filePath) {
  return request({
    url: "/file/getTemporaryFile",
    method: "get",
    params: {
      filePath
    },
  });
}

// 下载Zip 
export function downloadZip(data) {
  return request({
    url: "/file/downloadZip",
    method: "post",
    data,
    responseType: "blob",
  });
}

// 分片上传检测
export function testChunkFileUpload(params) {
  return request({
    url: "/file/check",
    method: "get",
    params,
  });
}

// 分片上传接口
export function chunkFileUpload(data) {
  return request({
    url: "/file/upload/chunk",
    method: "post",
    data,
  });
}

export function getFileAllConfigList(params) {
  return request({
    url: '/fileConfig/page',
    method: 'get',
    params
  })
}

export function getListCompanyFileConfig() {
  return request({
    url: '/fileConfig/listCompanyFileConfig',
    method: 'get'
  })
}
