<template>
  <el-dialog 
    :title="this.$t('order.External sharing')" 
    width="500px" 
    :visible.sync="isOffStation" 
    :before-close="onClose"
    custom-class="share-copy"
    v-move-outside>
    <div class="off_station_box">
      <div class="duplicate">
        <span class="name" v-if="userInfo">
          <img :src="userInfo.avatarUrl" class="img" />
          {{ userInfo.nickName }} {{ $t('Files.shared the file') }}
        </span>
        <div class="files">
          <div>
            {{ $t('Files.File Name') }}：{{
              offStationData.fileName || offStationData.originalFileName
            }}
          </div>
          <div>
            {{ $t('Files.File type') }}：{{
              offStationData.format || offStationData.fileSuffix
            }}
          </div>
          <div>{{ $t('Files.File Link') }}：{{ savePath }}</div>
          <div>{{ $t('Lifespan') }}：7{{ $t('day') }}</div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button class="dialog-footer-text" type="text" @click="operation('复制全部信息')">{{ $t('Files.Copy all information')
      }}</el-button>
      <el-button type="primary" @click="operation('复制链接')">{{
        $t('Files.Copy Link')
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { getTemporaryFile } from "@/api/files";

export default {
  name: 'offStation',
  components: {},
  data() {
    return {
      savePath: ""
    };
  },
  props: ['isOffStation', 'offStationData'],
  emits: ['onClose'],
  computed: {
    ...mapGetters(["userInfo"]),
  },
  watch: {
    offStationData: {
      handler() {
        this.savePath = this.offStationData.savePath;
      },
      deep: true
    }
  },
  methods: {
    onClose() {
      this.$emit('onClose');
    },
    operation(type) {
      switch (type) {
        case '复制链接':
          getTemporaryFile(this.offStationData.savePath).then((data) => {
            this.savePath = data;
            const theClipboard = navigator.clipboard;
            theClipboard.writeText(data).then(() => {
              this.$message.success(this.$t('Files.Copy succeeded'));
            });
          });
          break;
        case '复制全部信息':
          getTemporaryFile(this.offStationData.savePath).then((data) => {
            this.savePath = data;
            setTimeout(() => {
              const contentDom = document.querySelector('.duplicate');
              var selection = window.getSelection();
              var range = document.createRange();
              range.selectNodeContents(contentDom);
              selection.removeAllRanges();
              selection.addRange(range);
              setTimeout(() => {
                // document.execCommand('Copy')
                const theClipboard = navigator.clipboard;
                theClipboard.writeText(selection.toString()).then(() => {
                  this.$message.success(this.$t('Files.Copy succeeded'));
                });
              }, 50);
            }, 1000)
          });
          break;
      }
    },
  },
};
</script>
<style>
.off_station_box *::selection {
  background: transparent;
}
</style>
<style lang="less" scoped>
/deep/ .el-dialog__header {
  display: block !important;
}
.off_station_box {
  position: relative;
  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }

  .duplicate {
    position: relative;
    .name {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .files {
      display: flex;
      flex-direction: column;
      gap: 12px 0;
      margin-top: 10px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(59, 69, 82, 1);
    }
  }
}
</style>
<style>
.share-copy .el-dialog__body {
  border-top: 1px solid #f2f2f2 !important;
  padding: 24px !important;
  height: auto !important;
}

.dialog-footer-text:hover {
  color: #2e3f4f !important;
  background-color: rgba(46, 63, 79, 0.1) !important;
}

.dialog-footer-text:focus {
  color: #2e3f4f !important;
}
</style>
