import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import getters from "./getters";
import user from "./modules/user";
import dict from "./modules/dict";
import socket from "./modules/socket";
import layout from "./modules/layout";
import updateCount from "./modules/updateCount";

Vue.use(Vuex);

// // https://webpack.js.org/guides/dependency-management/#requirecontext
// const modulesFiles = require.context('./modules', true, /\.js$/)

// // you do not need `import app from './modules/app'`
// // it will auto require all vuex module from modules file
// const modules = modulesFiles.keys().reduce((modules, modulePath) => {
//   // set './app.js' => 'app'
//   const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
//   const value = modulesFiles(modulePath)
//   modules[moduleName] = value.default
//   return modules
// }, {})

const store = new Vuex.Store({
  modules: { user, dict, socket, updateCount, layout },
  getters,
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      key: "store",
      paths: ['user','dict','layout']
    })
  ]
});

export default store;
