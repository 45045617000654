var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ecEditor",class:{
  border: _vm.isBorder,
  toolbar: !_vm.isToolBar
}},[_c('Toolbar',{staticClass:"toolbarBox",staticStyle:{"border-bottom":"1px solid #E0E4EA"},attrs:{"editor":_vm.editor,"defaultConfig":_vm.toolbarConfig,"mode":_vm.mode}}),_c('Editor',{staticClass:"editorBox",style:({
      'height': _vm.height
    }),attrs:{"defaultConfig":Object.assign({}, {addClassNames: true},
      _vm.editorConfig,
      _vm.editorConfigConst),"mode":_vm.mode},on:{"onCreated":_vm.onCreated,"onChange":_vm.onChange,"customPaste":_vm.customPaste},model:{value:(_vm.htmlContent),callback:function ($$v) {_vm.htmlContent=$$v},expression:"htmlContent"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }