import Vue from 'vue';
import VueRouter from 'vue-router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { getToken } from '@/utils/auth';
import store from "@/store";
import { Message } from "element-ui";

Vue.use(VueRouter);

/* 在创建router实例对象之前，手动覆盖原型链的push来吞掉报错catch */
// 先存储默认底层的push
const originPush = VueRouter.prototype.push;
// 覆盖原型链的push
VueRouter.prototype.push = function (location, resolve, reject) {
  // this:路由实例对象

  // 判断用户有没有传后面两个可选参数
  if (resolve || reject) {
    return originPush.call(this, location, resolve, reject);
  } else {
    // 用户只传了第一个参数
    /* 
      默认底层： catch()方法代码  throw err : 抛出异常
      吞掉报错原理： 重写catch()方法,把默认底层的 throw err给去掉，就不会抛出异常
      */
    return originPush.call(this, location).catch((err) => {
      //  throw err
    });
  }
};

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/login.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/login/register.vue'),
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: () => import('../views/login/forgot.vue'),
  },
  {
    path: '/demoEditor',
    name: 'demoEditor',
    component: () => import('../views/demoEditor'),
  },
  {
    path: '/lawDetail',
    name: 'lawDetail',
    component: () => import('../views/login/lawDetail.vue'),
  },
  {
    path: '/shareOrderLink',
    name: 'shareOrderLink',
    component: () => import('../views/shareOrderLink/index.vue'),
  },
  {
    path: '/goodsListUrl',
    name: 'goodsListUrl',
    component: () => import('../views/sharePage/shopServer.vue'),
  },
  {
    path: '/pdfConfig',
    name: 'PdfConfig',
    component: () => import('../views/pdfConfig'),
  },
  {
    path: '/emailVerify',
    name: 'emailVerify',
    component: () => import('../views/login/emailVerify.vue'),
  },
  {
    path: '/transferTakeover',
    name: 'transferTakeover',
    component: () => import('../views/transferTakeover/index.vue'),
  },
  {
    path: '/ImagePreview',
    name: 'ImagePreview',
    component: () => import('../views/ImagePreview.vue'),
  },
  {
    path: '/',
    name: 'Home',
    redirect: '/index',
    component: () => import('../layout/index.vue'),
    children: [
      {
        path: '/index',
        name: 'Index',
        component: () => import('../views/index/index.vue'),
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/messageList',
        name: 'MessageList',
        component: () => import('../views/messageList/messageList.vue'),
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/customerList',
        name: 'CustomerList',
        component: () => import('../views/customer/customerList.vue'),
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/customer',
        name: 'Customer',
        component: () => import('../views/customer/customer.vue'),
        meta: {
          keepAlive: false
        }
      },
      {
        path: '/order',
        name: 'Order',
        component: () => import('../views/order/order.vue'),
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/tracking',
        name: 'Tracking',
        component: () => import('../views/tracking/tracking.vue'),
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/member',
        name: 'Member',
        component: () => import('../views/member/member.vue'),
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/customer/schedule',
        name: 'Schedule',
        component: () => import('../views/customer/childrenView/scheduleNew.vue'),
        meta: {
          keepAlive: false
        }
      },
      {
        path: '/files',
        name: 'files',
        component: () => import('../views/files/index.vue'),
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/order/orderDetails',
        name: 'OrderDetails',
        component: () => import('../views/order/childrenView/orderDetails.vue'),
        meta: {
          keepAlive: false
        }
      },
      {
        path: '/profile',
        name: 'Profile',
        component: () => import('../views/profile/profile.vue'),
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/companyProfile',
        name: 'companyProfile',
        component: () => import('../views/companyProfile/companyProfile.vue'),
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/dashboard/:id',
        // path: '/dashboard',
        name: 'Dashboard',
        component: () => import('../views/dashboard/dashboard.vue'),
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/dashboardCapacityMonit',
        name: 'dashboardCapacityMonit',
        component: () => import('../views/dashboard/dashboardCapacityMonit.vue'),
        meta: {
          keepAlive: true
        }
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  NProgress.configure({ showSpinner: false });
  NProgress.start();
  const hasToken = getToken();
  if (hasToken) {
    //token存在，如果当前跳转的路由是登录界面
    if (to.path == '/login') {
      // if is logged in, redirect to the home page
      next({ path: '/' });
    } else {
      // 在这里，就拉去用户权限，判断用户是否有权限访问这个路由
      next();
    }
  } else {
    let isArray = ['/login', '/register', '/forgot', '/emailVerify', '/transferTakeover', '/lawDetail', '/shareOrderLink', '/goodsListUrl']
    //没有登录，去跳转登录页
    if (isArray.includes(to.path)) {
      next();
    } else {
      next({
        path: '/login',
      });
    }
  }
});

router.afterEach(() => {
  NProgress.done()
})

export default router;
