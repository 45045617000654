<template>
  <el-dialog 
    :title="this.$t('order.External sharing')" 
    width="500px" 
    :visible.sync="show" 
    :before-close="onClose"
    custom-class="share-copy"
    append-to-body
    v-move-outside>
    <div class="off_station_box">
      <div class="duplicate">
        <span class="name" v-if="userInfo">
          <img :src="userInfo.avatarUrl" class="img" />
          {{ userInfo.nickName }} {{ $t('Files.shared the file') }}
        </span>
        <div class="files" v-if="shareData">
          <div>
            {{ $t('event.Title') }}：{{ shareData.title }}
          </div>
          <div>{{ $t('Files.File Link') }}：{{ shareData.url }}</div>
          <div>{{ $t('Lifespan') }}：7{{ $t('day') }}</div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button 
        class="cancel_button" 
        size="small"
        type="text" 
        @click="operation('复制全部信息')"
      >{{ $t('Files.Copy all information')}}</el-button>
      <el-button type="primary" size="small" @click="operation('复制链接')">{{
        $t('Files.Copy Link')
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { getForwarderServerShareUrl } from "@/api/shopServer.js";

export default {
  name: 'externalShare',
  props: {
    offStationData: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      show: false,
      shareData: null
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  methods: {
    /**
     * type: //分享类型: 0:商品列表分享 1: 商品详情分享 2: 服务列表分享 3: 服务详情分享 4: 案例列表分享 5: 案例详情分享 6: 文件列表分享 7: 文件详情分享
     */
    open(id, companyId, type) {
      this.show = true;
      getForwarderServerShareUrl({
        companyId,
        shareType: type,
        effectiveDays: 7,
        id,
      }).then((data) => {
        console.log(data);
        this.shareData = data;
      })
    },
    onClose() {
      this.show = false;
    },
    operation(type) {
      switch (type) {
        case '复制链接':
          const theClipboard = navigator.clipboard;
          theClipboard.writeText(this.shareData.url).then(() => {
            this.$message.success(this.$t('Files.Copy succeeded'));
          });
          break;
        case '复制全部信息':
          setTimeout(() => {
            const contentDom = document.querySelector('.duplicate');
            var selection = window.getSelection();
            var range = document.createRange();
            range.selectNodeContents(contentDom);
            selection.removeAllRanges();
            selection.addRange(range);
            setTimeout(() => {
              // document.execCommand('Copy')
              const theClipboard = navigator.clipboard;
              theClipboard.writeText(selection.toString()).then(() => {
                this.$message.success(this.$t('Files.Copy succeeded'));
              });
            }, 50);
          }, 1000)
          break;
      }
    },
  },
};
</script>
<style>
.off_station_box *::selection {
  background: transparent;
}
</style>
<style lang="less" scoped>
/deep/ .el-dialog__header {
  display: block !important;
}
.off_station_box {
  position: relative;
  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }

  .duplicate {
    position: relative;
    .name {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .files {
      display: flex;
      flex-direction: column;
      gap: 12px 0;
      margin-top: 10px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(59, 69, 82, 1);
    }
  }
}
</style>
<style>
.share-copy .el-dialog__body {
  border-top: 1px solid #f2f2f2 !important;
  padding: 24px !important;
  height: auto !important;
}
</style>
