import request from "@/utils/request";
import axios from "axios";
import service from "../utils/request";
import { log } from "console";

//示例： 接口请求模板
export function indexTest(data) {
  return request({
    url: "/telematics/v3/weather",
    method: "post",
    data: data,
  });
}

export function searchUser(name) {
  return request({
    url: "/vue-element-admin/search/user",
    method: "get",
    params: { name },
  });
}

//调用注册接口
export function registerUser(data) {
  return request({
    url: "/oauth/register",
    method: "post",
    data,
  });
}

//调用登录接口
export function loginUser(data) {
  return request({
    url: "/oauth/login",
    method: "post",
    data: data,
  });
}

//调用登出接口
export function logoutUser() {
  return request({
    url: "/oauth/logout",
    method: "post",
  });
}

// 获取手机验证码
export function getPhoneCode(params) {
  return request({
    url: "/oauth/sendSms",
    method: "get",
    params,
  });
}

// 验证码登录
export function loginByPhone(data) {
  return request({
    url: "/oauth/loginPhone",
    method: "post",
    data: data,
  });
}

// 微信登录验证
export function wechatLogin(params) {
  return request({
    url: "/oauth/QRCode",
    method: "get",
    params,
  });
}

// 微信登录
export function loginByWechat(data) {
  return request({
    url: "/oauth/loginWeChat",
    method: "post",
    data: data,
  });
}

// 绑定微信
export function bindWechat(data) {
  return request({
    url: "/oauth/bindWeChat",
    method: "post",
    data: data,
  });
}

// 解绑微信
export function unbindWechat(data) {
  return request({
    url: "/oauth/unbindWeChat",
    method: "post",
    data: data,
  });
}

// 绑定手机号
export function bindPhone(data) {
  return request({
    url: "/oauth/bindPhone",
    method: "post",
    data,
  });
}

// 解绑手机号
export function unbindPhone(data) {
  return request({
    url: "/oauth/unbindPhone",
    method: "post",
    data: data,
  });
}

//调用激活接口
export function activateUser(data) {
  return request({
    url: "/oauth/active",
    method: "post",
    data,
  });
}

//调用绑定用户角色
export function bindRole(data) {
  return request({
    url: "/systemUser/bind",
    method: "post",
    data: data,
  });
}

//调用解绑用户角色
export function unbindRole(data) {
  return request({
    url: "/systemUser/unbind",
    method: "post",
    data: data,
  });
}

// 更新密码

export function updatePassword(data) {
  return request({
    url: "/oauth/updatePasswd",
    method: "post",
    data,
  });
}

// 发送邀请注册邮件
export function sendEmail(data) {
  return request({
    url: "/oauth/sendRegisterEmail",
    method: "post",
    data,
  });
}

// 获取邀请注册链接
export function getRegisterLink(data) {
  return request({
    url: `/oauth/getRegisterLink`,
    method: "get",
  });
}

//获取登录system账号对应的business账号信息
export function getLoginUserDetail(token) {
  let headers = token ? {
    Authorization: 'Bearer '+token,
  } : {};
  return request({
    url: "/oauth/userInfo",
    method: "get",
    headers
  });
}

//调用更新业务账号内容
export function updateProfile(data) {
  return request({
    url: "/oauth/update",
    method: "post",
    data: data,
  });
}

//获取验证码截图的二进制流
export function getCaptcha() {
  return request({
    withCredentials: true,
    url: "/oauth/getCaptcha",
    method: "get",
    params: {},
    responseType: "blob", //Modified by Wyn
  });
}

//获取加密公钥
export function getPublicKey() {
  return request({
    url: "/oauth/getPublicKey",
    method: "get",
    params: {},
  });
}

// 获取用户角色
export function getUserRole() {
  return request({
    url: "/systemRole/authMenu",
    method: "get",
  });
}

// 发送邮箱验证码
export function sendEmailAuth(email, token) {
  let headers = token ? {
    Authorization: 'Bearer '+token,
  } : {};
  return request({
    url: "/oauth/sendEmailAuth",
    method: "get",
    params: {
      email
    },
    headers
  });
}

// 验证用户邮箱验证码
export function validatorEmail(params) {
  return request({
    url: "/customer/verifyEmailCode",
    method: "get",
    params
  });
}

// 密码校验
export function confirmPassword(password) {
  return request({
    url: "/oauth/confirmPassword",
    method: "post",
    data: {
      password
    }
  })
}

// 发送找回密码邮箱获取code
export function sendEmailGetCode(email) {
  return request({
    url: "/oauth/sendForgetPasswdEmail",
    method: "post",
    data: {
      email
    }
  })
}

// 修改找回密码
export function editRecoverPassword(data) {
  return request({
    url: "/oauth/resetPasswd",
    method: "post",
    data
  })
}