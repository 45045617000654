<template>
  <div class="member-avatar">
    <el-tooltip 
      effect="light" 
      popper-class="ec-tooltip"
      :content="info && info.nickName" 
      placement="top"
      :disabled="!isShare">
      <el-popover
        :placement="placement"
        trigger="click"
        popper-class="memberPopover"
        @hide="hideFun()"
        ref="popover"
        :disabled="!isShare"
        :open-delay="400"
      >
        <div class="memberUserBox">
          <div class="top">
            <div class="left">
              <el-avatar
                :size="35"
                :src="info && (info.avatarFileUrl || info.avatarUrl)"
              />
              <div class="nickName">{{ info && info.nickName }}</div>
            </div>
            <div class="right">
              <el-button 
                circle 
                @click="hideFun('profile')"
                icon="iconfont icon-Home1"
              ></el-button>
              <el-button
                circle
                @click="openMessageDialog"
                v-show="isPing"
                icon="iconfont icon-lianxiguanliyuan"
              ></el-button>
            </div>
          </div>
          <div class="bottom">
            <p>
              {{ info && info.position }} {{ windows.vm.$t("customerInfo.at") }}
              {{ (info && info.company && info.company.name) || (info && info.authCompany && info.authCompany.name) }}
            </p>
            <span>{{ info && info.timeZone | ftz }}</span>
          </div>
        </div>
        <div
          :class="`                                                                                                                                                         } ${
            isDone ? 'done-avatar-border' : ''
          }`"
          slot="reference"
          @click="isShare ? isClick = !isClick : ''"
        >
          <div class="badgeBox" v-if="isBadge">
            {{ isBadge > 99 ? '99+' : isBadge }}
          </div>
          <img
            :src="info && (info.avatarFileUrl || info.avatarUrl)" 
            :style="typeof size === 'number' ? `width: ${size}px;height: ${size}px` : ''"
            :class="`ma-avatar ${typeof size === 'string' ? size : ''} ${isClick ? 'ma-click-avatar' : ''}`" 
          />
          <i class="el-icon-success" v-if="isDone"></i>
        </div>
      </el-popover>
    </el-tooltip>
  </div>
</template>
<script>
import { defineComponent, provide, computed, ref, watch } from "@vue/composition-api";
import router from "../../router";
import store from "../../store";

export default defineComponent({
  name: "Member",
  emits: ["close"],
  props: {
    info: {
      type: Object | Number | String,
      default: () => {
        return {};
      },
    },
    size: {
      type: String | Number,
      default: "small",
    },
    isPing: {
      type: Boolean,
      default: true,
    },
    isShare: {
      type: Boolean,
      default: true,
    },
    time: {
      type: String | Number,
      default: "",
    },
    isDone: {
      type: Boolean,
      default: false,
    },
    placement: {
      type: String,
      default: "left",
    },
    isBadge: {
      type: Number | String,
      default: 0
    }
  },
  setup(props, { emit }) {
    const isClick = ref(false);
    const user = ref(store.state.user);
    const popover = ref(null);
    const isShow = ref(false);
    provide("parent", {
      toSourceDom: (el) => {
        popover.value.$el.appendChild(el);
      },
    });

    function hideFun(type) {
      isClick.value = false;
      popover.value.showPopper = false;
      if (type == "profile") {
        emit("close");
        router.push({
          name: "Profile",
          query: {
            businessUserId: props.info && props.info.openId
          },
        });
      }
    }

    const openMessageDialog = () => {
      store.dispatch("user/setMessageDialogOpen", {
        visible: true,
        isElastic: false,
        top: null,
        otherParty: props.info
      })
    }

    watch(
      () => props.time,
      (val) => {
        if (val && isShow.value) {
          popover.value.showPopper = false;
        }
      }
    );

    watch(
      () => popover.value?.showPopper,
      (val) => {
        setTimeout(() => {
          isShow.value = val;
        }, 300);
      }
    );

    const windows = computed(() => {
      return window;
    })

    return {
      windows,
      isClick,
      user,
      popover,
      isShow,
      hideFun,
      openMessageDialog
    };
  },
});
</script>
<style lang="less" scoped>
.member-avatar {
  cursor: pointer;
  position: relative;
}

.memberUserBox {
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 22px;
    margin-bottom: 8px;
    .left {
      display: flex;
      align-items: center;
      gap: 8px;
      .nickName {
        font-size: 14px;
        line-height: 19.6px;
        font-weight: 500;
        color: #122545;
      }
    }
    .right {
      .el-button {
        background: #F7F9FC;
        /deep/ i {
          font-size: 16px;
          color: #076F49;
        }
        &:hover {
          opacity: .7;
        }
      }
    }
  }
  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    color: #637381;
  }
}

.badgeBox {
  position: absolute;
  top: -2px;
  left: -5px;
  border: 1px solid #ffffff;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #DC3545;
  font-size: 12px;
  line-height: normal;
  color: #ffffff;
  border-radius: 50%;
}

.ma-avatar {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: #E3E9F1;
  // border: 1px solid rgba(0, 0, 0, 0);
  margin: 1px;
  &.large {
    width: 40px;
    height: 40px;
  }
  &.medium {
    width: 36px;
    height: 36px;
  }
  &.small {
    width: 28px;
    height: 28px;
  }
}

.ma-avatar:hover {
  border: 1px solid #076F49;
  margin: 0;
}

.user-done {
  width: 14px;
  height: 14px;
  position: absolute;
  bottom: -7px;
  background-color: white;
  border-radius: 50%;
}

.ma-click-avatar {
  border: 1px solid #076F49;
  margin: 0;
}

.done-avatar-border {
  position: relative;
  .el-icon-success {
    color: #48AC42;
    font-size: 12px;
    position: absolute;
    right: -1px;
    bottom: -2px;
    background: #ffffff;
    border-radius: 50%;
  }
}
</style>

<style lang="less">
.memberPopover {
  border: none;
}
</style>
