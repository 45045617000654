const lang = window.localStorage.getItem("lang");
import elementEnLocale from 'element-ui/lib/locale/lang/en'
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'
export const i18nConfig = {
  locale: lang ? lang : "zh",
  messages: {
    zh: {
      ...require("./zh.js"),
      ...elementZhLocale
    },
    en: {
      ...require("./en.js"),
      ...elementEnLocale
    },
  },
  datetimeFormats: {
    en: {
      // MMMM D, YYYY
      short: {
        year: "numeric",
        month: "short",
        day: "numeric",
      },
    },
    zh: {
      // YYYY年M月D日
      short: {
        year: "numeric",
        month: "short",
        day: "numeric",
      },
    },
  },
};
