import Select from './src/select.vue';
import Option from "./src/option.vue";
import OptionGroup from "./src/option-group.vue";

/* istanbul ignore next */
Select.install = function(Vue) {
  Vue.component(Select.name, Select);
};

Option.install = function(Vue) {
  Vue.component(Option.name, Option);
}

OptionGroup.install = function(Vue) {
  Vue.component(OptionGroup.name, OptionGroup);
}

export const eSelect = Select;
export const eOption = Option;
export const eOptionGroup = OptionGroup;
