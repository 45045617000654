<template>
  <div>
    <div class="searchBox" id="searchBox">
      <div class="searchForm">
        <slot name="searchForm" />
        <div>
          <el-button
            type="primary"
            size="small"
            icon="el-icon-search"
            @click="$emit('searchEvent')"
          >
            {{ $t('customerInfo.Search') }}
          </el-button>
          <el-button size="small" v-if="!isForm" @click="$emit('clearEvent')">
            {{ $t('customerInfo.Clear All Filter') }}
          </el-button>
        </div>
      </div>
      <div class="action" v-if="!isForm">
        <slot name="searchAction" />
      </div>
    </div>
    <div class="echolaTable" v-if="!isForm">
      <el-table :height="`calc(100vh - ${searchHeight+198}px)`" v-bind="$attrs" v-on="$listeners">
        <slot name="tableColumn" />
        <template #empty>
          <el-empty 
            :description="$t('overallSituation.noData')" 
            :image="$emptyImage"
            :image-size="100"
          />
        </template>
      </el-table>
      <div class="pagination">
        <el-pagination
          @size-change="changePageSizeHandler"
          @current-change="changePageHandler"
          :current-page="searchParams.currentPage"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          :total="searchParams.totalElement"
          background
          layout="prev, pager, next, sizes, total, jumper"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pageTable",
  props: {
    searchParams: {
      type: Object,
      default: {}
    },
    isForm: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchHeight: 0
    }
  },
  inheritAttrs: false,
  mounted() {
    const dom = document.getElementById("searchBox");
    const resizeObserver = new ResizeObserver((entries) => {
      this.searchHeight = entries[0].target.clientHeight;
    });
    resizeObserver.observe(dom);
  },
  methods: {
    changePageHandler(page) {
      this.$emit("update:searchParams", {
        ...this.searchParams,
        currentPage: page
      });
      this.$emit("updateTable");
    },
    changePageSizeHandler(pageSize) {
      this.$emit("update:searchParams", {
        ...this.searchParams,
        pageSize: pageSize
      });
      this.$emit("updateTable");
    },
  },
}
</script>

<style lang="less" scoped>
.searchBox {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  .searchForm {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    flex: 1;
    .translateButton {
      height: 32px;
      /deep/ .special_tabs {
        border: 1px solid #dcdfe6;
        background: transparent;
        .el-tabs__active-bar {
          background: #dcdfe6;
        }
        .el-tabs__item {
          color: #637381;
        }
        .el-tabs__item.is-active {
          color: #122545 !important;
        }
      }
    }
  }
  .action {
    width: auto;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    // align-items: center;
    flex-wrap: wrap;
    i {
      font-size: 18px;
      color: #757D8A;
      cursor: pointer;
      transition: all .3s;
      &:hover {
        color: #076F49;
      }
    }
  }
}
.echolaTable {
  margin-top: 16px;
}
.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
</style>