const getters = {
  importFileProgress: (state) => state.user.importFileProgress,
  messageDialog: (state) => state.user.messageDialog,
  token: (state) => state.user.token,
  businessUserId: (state) => state.user.businessUserId,
  manageCompany: (state) => state.user.manageCompany,
  companyId: (state) => state.user.companyId,
  companyCurrentType: (state) => state.user.companyCurrentType,
  userInfo: (state) => state.user.userInfo,
  company: (state) => state.user.company,
  chartOrder: (state) => state.user.chartOrders,
  platformLogo: (state) => state.user.platformLogo,
  shareDetailId: (state) => state.user.shareDetailId,
  language: (state) => state.user.language,
  personalization: (state) => state.user.personalization,
  isUpdateUserCustomer: (state) => state.user.isUpdateUserCustomer,
  msg: (state) => state.user.msg,
  purchaseStatus: (state) => state.user.purchaseStatus,
  applyType: (state) => state.user.applyType,
  localEventList: (state) => state.user.localEventList,
  localCommentList: (state) => state.user.localCommentList,
  toDoList: (state) => state.user.toDoList,
  isDefaultNode: (state) => state.user.isDefaultNode,
  menuList: (state) => state.user.menuList,
  newsComment: (state) => state.user.newsComment,
  groupChatUpdate: (state) => state.user.groupChatUpdate,
  callPhoneUpdate: (state) => state.user.callPhoneUpdate,
  videoPhoneResponseUpdate: (state) => state.user.videoPhoneResponseUpdate,
  notifySurrenderOfferUpdate: (state) => state.user.notifySurrenderOfferUpdate,
  getSurrenderOfferUpdate: (state) => state.user.getSurrenderOfferUpdate,
  newOrderUpdate: (state) => state.user.newOrderUpdate,
  messgaeWithdrawUpdate: (state) => state.user.messgaeWithdrawUpdate,
  groupMemberDeleteUpdate: (state) => state.user.groupMemberDeleteUpdate,
  groupChatDeleteUpdate: (state) => state.user.groupChatDeleteUpdate,
  createGroupChatUpdate: (state) => state.user.createGroupChatUpdate,
  detail: (state) => state.user.detail,
  msglist: (state) => state.user.msglist,
  applyMsg: (state) => state.user.applyMsg,
  PIorder: (state) => state.user.PIorder,
  chatMessage: (state) => state.user.chatMessage,
  chatRead: (state) => state.user.chatRead,
  currentMsg: (state) => state.user.currentMsg,
  socketOnline: (state) => state.user.socketOnline,
  commentUpdate: (state) => state.user.commentUpdate,
  systemNoticeUpdate: (state) => state.user.systemNoticeUpdate,
  applicationUpdate: (state) => state.user.applicationUpdate,
  eventUpdate: (state) => state.user.eventUpdate,
  shipUpdate: (state) => state.user.shipUpdate,
  todoUpdate: (state) => state.user.todoUpdate,
  eventAllRead: (state) => state.user.eventAllRead,
  commentAllRead: (state) => state.user.commentAllRead,
  readComment: (state) => state.user.readComment,
  readEvent: (state) => state.user.readEvent,
  readTodo: (state) => state.user.readTodo,
  readApply: (state) => state.user.readApply,
  orderAlias: (state) => state.user.orderAlias,
  routerViewExclude: (state) => state.user.routerViewExclude,
  buttonAuths: (state) => state.user.buttonAuths,
  fileEventType: (state) => state.user.fileEventType,
  serverShopFlag: (state) => state.user.serverShopFlag,
  timezone: (state) => state.dict.timezone,
  transportMode: (state) => state.dict.transportMode,
  orderAliasOption: (state) => state.dict.orderAliasOption,
  allNodeList: (state) => state.dict.allNodeList,
  payMentData: (state) => state.dict.payMentData,
  payMentMethods: (state) => state.dict.payMentMethods,
  country: (state) => state.dict.country,
  minuteCountry: (state) => state.dict.minuteCountry,
  mysteelRawMaterial: (state) => state.dict.mysteelRawMaterial,
  exchangeRate: (state) => state.dict.exchangeRate,
  currencys: (state) => state.dict.currencys,
  companyType: (state) => state.dict.companyType,
  currencyType: (state) => state.dict.currencyType,
  orderProgress: (state) => state.dict.orderProgress,
  fileType: (state) => state.dict.fileType,
  shipType: (state) => state.dict.shipType,
  memberType: (state) => state.dict.memberType,
  eventList: (state) => state.socket.eventList,
  commentList: (state) => state.socket.commentList,
  orderStatusOptions: (state) => state.user.orderStatusOptions,
  // 页面刷新通知
  updateCountOrder: (state) => state.updateCount.order,
  //布局主题
  chartPieColors: (state) => state.layout.chartPieColors,
  chartLineBarColors: (state) => state.layout.chartLineBarColors,
  layoutModel: (state) => state.layout.layoutModel,
  layoutLightColors: (state) => state.layout.layoutLightColors,
  layoutDarkColors: (state) => state.layout.layoutDarkColors,
  orderStatusColors: (state) => state.layout.orderStatusColors,
  chartVipColors: (state) => state.layout.chartVipColors,
  isVipMode: (state) => state.layout.isVipMode
};
export default getters;
