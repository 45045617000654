module.exports = {
    "Please select": "请选择",
    "CI": "CI",
    "Input CI": "CI号",
    "Company": "公司",
    "Input Company Name": "公司名",
    "Search": "搜索",
    "Clear All Filter": "清除所有条件",
    "Order List": "订单列表",
    "Stow": "隐藏",
    "Open": "展开",
    "Input CI": "CI号",
    "Select status": "订单状态",
    "Start from": "开始",
    "End to": "结束",
    "Producing": "生产中",
    "Local transportation": "本国运输",
    "Loading port": "发运港停留",
    "Shipping": "跨境运输",
    "Discharge port": "目的港停留",
    "Destination delivery": "目的国运输",
    "Destination": "目的地",
    "Amount": "金额",
    "Create Time": "创建日期",
    "Status": "状态",
    "Action": "操作",
    "Order No": "订单 No",
    "Create Time": "创建日期",
    "Supplier": "供应商",
    "Purchaser": "客户",
    "Forwarder": "货代",
    "Ship info": "运输信息",
    "Weight/Dimension": "重量/体积",
    "Port of Loading": "装货港",
    "Quantity": "数量",
    "Port of discharge": "卸货港",
    'PRODUCING': '生产中',
    'DELIVERY_LOCAL': '本国运输',
    'SEND_PORT': '发运港停留',
    'DELIVERY_TRANS': '跨境运输',
    'RECEIVE_PORT': '目的港停留',
    'DELIVERY_DEST': '目的国运输',
    'DESTINATION': '到目的地',
};