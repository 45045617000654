<template>
  <el-drawer
    :title="$t('Files.Select people')"
    :append-to-body="true"
    :visible.sync="isInside"
    size="400px"
    :before-close="onClose"
    @open="open"
    v-move-outside
  >
    <div class="inside_box">
      <div class="inside_box_search">
        <el-select
          v-model="data.companyId"
          filterable
          :placeholder="$t('Files.Select company')"
          clearable
          size="small"
          v-if="type != 'shopServer'"
        >
          <el-option
            v-for="item in companyList"
            :key="item.value"
            :label="item.aliasName || item.name"
            :value="item.companyId"
          >
            <div class="option_list">
              <div>{{ item.aliasName || item.name }}</div>
              <div>{{ $t(`home.${$companyTypes[item.companyType]}`) }}</div>
            </div>
          </el-option>
        </el-select>
        <el-input
          v-model="data.userName"
          :placeholder="$t('Files.Input Name')"
          clearable
          size="small"
          v-if="type != 'shopServer'"
        />
        <el-input
          v-if="type == 'shopServer'"
          v-model="shopServerInput"
          :placeholder="$t('Files.Input Name')"
          clearable
          size="small"
        />
      </div>
      <div class="inviteBox" v-loading="loading_table">
        <template v-if="tableData.length">
          <div 
            class="item" 
            v-for="(item, idx) in tableData.filter(row => row.nickName.toLowerCase().indexOf(shopServerInput.toLowerCase()) !== -1)" 
            :key="idx"
            @click.prevent="goChecked(item)">
            <member :info="item" :size="48" />
            <div class="content">
              <div class="name">{{ item.nickName }}</div>
              <div class="job">{{ item.position }}</div>
            </div>
            <el-checkbox v-if="type != 'purchase' && type != 'shopServer' " v-model="item.check" />
            <div class="radioBox" v-else-if="item.check">
              <i class="el-icon-check"></i>
            </div>
          </div>
        </template>
        <el-empty
          :description="$t('overallSituation.noData')"
          :image="$emptyImage"
          :image-size="90"
          v-else
        />
      </div>
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page="data.currentPage"
        :page-size="data.pageSize"
        :total="total"
        style="display: flex;justify-content: flex-end;"
        @current-change="currentChange"
        v-if="type !== 'member' && type != 'shopServer'" 
      />
    </div>
    <div class="drawerFooter">
      <el-button
        type="primary"
        size="small"
        icon="el-icon-position"
        @click="send()"
        :loading="loading_button"
        v-if="type != 'member'"
      >
        {{ type != 'purchase' && type != 'shopServer' && type != 'shopServerInternal' ? $t('Files.Send') : $t('InformationCenter.Send') }}
      </el-button>
      <el-button
        type="primary"
        size="small"
        @click="send()"
        :loading="loading_button"
        v-else
      >
        {{ $t('home.Confirm') }}
      </el-button>
    </div>
  </el-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import {
  getCompanyEmployeeListAndRelation,
} from '@/api/comment';
import { getFileShareList, getCompanyEmployeeList } from "@/api/customer";
import {
  sendChatData
} from '@/api/news';

import { 
  inviteUser, 
  getInvitableOrderCompanyList, 
  getInvitableOrderMemberList 
} from '@/api/order';
import { createPurchaseApi, editPurchaseApi } from "@/api/order";

export default {
  name: 'inside',
  components: {},
  data() {
    return {
      loading_table: false,
      loading_button: false,
      shopServerInput: "",
      data: {
        userName: '',
        companyId: '',
        includeSelf: true,
        currentPage: 1,
        pageSize: 15
      },
      total: 0,
      NameInput: '',
      tableData: [],
      businessUserIdList: [],
      companyList: []
    };
  },
  props: {
    isInside: {
      type: Boolean,
      default: false
    },
    file: {
      type: Object,
      default: null
    },
    type: {
      type: Number | String,
      default: ""
    },
    purchaseRow: {
      type: Object,
      default: null
    },
    companyId: {
      type: Number | String,
      default: ""
    },
    shopServerId: {
      type: Number | String,
      default: ""
    },
    messageType: {
      type: Number | String,
      default: ""
    }
  },
  emits: ['onClose'],
  computed: {
    ...mapGetters(['businessUserId', 'userInfo', 'purchaseStatus'])
  },
  watch: {
    data: {
      handler(obj) {
        this.onGetCompanyEmployeeListAndRelation();
      },
      deep: true
    },
  },
  methods: {
    open() {
      this.onGetCompanyEmployeeListAndRelation();
      if(this.type === 'member') {
        getInvitableOrderCompanyList(this.$route.query.orderId).then(response => {
          this.companyList = response;
        });
        return;
      }
      if(this.type === 'shopServer') return;
      let obj = this.type == 'purchase' ? {
        companyType: 1,
        purchaseStatus: this.purchaseStatus
      } : {};
      getFileShareList({ 
        includeSelf: this.type != 'purchase',
        ...obj
      }).then((response) => {
        this.companyList = response;
      });
    },
    goChecked(row) {
      if(this.type == 'purchase' || this.type == 'shopServer') {
        this.tableData.forEach(item => {
          item.check = false;
        });
      }
      row.check = true;
    },
    onClose() {
      this.$emit('onClose');
    },
    currentChange(val) {
      this.data.currentPage = val;
    },
    onGetCompanyEmployeeListAndRelation() {
      this.loading_table = true;
      if(this.type === 'member') {
        getInvitableOrderMemberList({
          orderId: this.$route.query.orderId,
          companyId: this.data.companyId,
          username: this.data.userName
        }).then((data) => {
          this.tableData = data.map(item => ({
            ...item,
            check: false
          }));
        }).finally(() => (this.loading_table = false));
        return;
      }
      if(this.type === 'shopServer') {
        getCompanyEmployeeList(this.companyId).then((data) => {
          this.tableData = data.memberList.map(item => ({
            ...item,
            check: false
          }));
        }).finally(() => (this.loading_table = false));
        return;
      }
      if(this.type === 'purchase') {
        let dataValue = JSON.parse(JSON.stringify(this.data));
        dataValue.includeSelf = false;
        dataValue.companyType = 1;
        dataValue.purchaseStatus = this.purchaseStatus;
        getCompanyEmployeeListAndRelation(dataValue)
        .then((data) => {
          this.tableData = data.list.map(item => ({
            ...item.user,
            check: false
          }));
          this.total = data.total;
        })
        .finally(() => (this.loading_table = false));
        return;
      }
      getCompanyEmployeeListAndRelation(this.data)
        .then((data) => {
          this.tableData = data.list.map(item => ({
            ...item.user,
            check: false
          }));
          this.total = data.total;
        })
        .finally(() => (this.loading_table = false));
    },
    send() {
      let arr = this.tableData.filter(item => item.check);
      if(!arr.length) {
        this.$message.warning(this.$t('Please select the data to operate on'))
        return;
      }
      this.loading_button = true;
      if(this.type == 'member') {
        inviteUser({
          groupUsers: arr.map(item => item.openId).join(","),
          orderId: this.$route.query.orderId,
        }).then(() => {
          this.$emit("updateData", this.$route.query.orderId)
          this.$message.success(this.$t('home.Invitethesuccessful'));
          this.onClose();
        }).finally(() => this.loading_button = false);
        return;
      }
      new Promise((resolve, reject) => {
        let arrs = [];
        arr.forEach(item => {
          if(this.type == 'purchase') {
            if(!this.purchaseRow.purchaseId) {
              this.purchaseRow.purchaseStatus = 1;
              createPurchaseApi({
                ...this.purchaseRow,
                sellerUserId: item.openId,
                sellerCompanyId: item.company?.companyId
              }).then((data) => {
                this.$emit("CloseDrawer");
                this.$myEvent.emit("onPurchaseList", true);
                sendChatData({
                  toUserId: item.openId,
                  content: data.purchaseId,
                  messageType: 10,
                  chatType: 0
                }).then((data) => {
                  arrs.push(item.openId);
                  if(arrs.length == arr.length) {
                    resolve()
                  }
                  this.$notify({
                    dangerouslyUseHTMLString: true,
                    message: `
                    <div class="chatNotificationBox">
                      <img src="${this.userInfo.avatarUrl}" />
                      <div class="content">
                        <div class="title">
                          ${this.$tc('Successfully sent 1 message to who', item.nickName)}
                        </div>
                        <div class="message">
                          ${this.$t('Purchase.title')}
                        </div>
                      </div>
                    </div>
                    `,
                    customClass: "ec-notification",
                    onClick: () => {
                      this.$store.dispatch("user/setMessageDialogOpen", {
                        visible: true,
                        isElastic: true,
                        chatId: null,
                        otherParty: item
                      });
                    }
                  });
                });
              }).catch(() => {
                this.$emit("CloseDrawer");
                this.loading_button = false;
              })
            } else {
              this.purchaseRow.purchaseStatus = 1;
              editPurchaseApi({
                ...this.purchaseRow,
                sellerUserId: item.openId,
                sellerCompanyId: item.company.companyId
              }).then(() => {
                this.$emit("CloseDrawer");
                this.$myEvent.emit("onPurchaseList", true);
                sendChatData({
                  toUserId: item.openId,
                  content: this.purchaseRow.purchaseId,
                  messageType: 10,
                  chatType: 0
                }).then((data) => {
                  arrs.push(item.openId);
                  if(arrs.length == arr.length) {
                    resolve()
                  }
                  this.$notify({
                    dangerouslyUseHTMLString: true,
                    message: `
                    <div class="chatNotificationBox">
                      <img src="${this.userInfo.avatarUrl}" />
                      <div class="content">
                        <div class="title">
                          ${this.$tc('Successfully sent 1 message to who', item.nickName)}
                        </div>
                        <div class="message">
                          ${this.$t('Purchase.title')}
                        </div>
                      </div>
                    </div>
                    `,
                    customClass: "ec-notification",
                    onClick: () => {
                      this.$store.dispatch("user/setMessageDialogOpen", {
                        visible: true,
                        isElastic: true,
                        chatId: null,
                        otherParty: item
                      });
                    }
                  });
                });
              }).catch(() => {
                this.$emit("CloseDrawer");
                this.loading_button = false;
              })
            }
          } else if(this.type == 'shopServer') {
            sendChatData({
              toUserId: item.openId,
              content: this.shopServerId,
              messageType: this.messageType,
              chatType: 0
            }).then(() => {
              arrs.push(item.openId);
              if(arrs.length == arr.length) {
                resolve()
              }
              this.$store.dispatch("user/setMessageDialogOpen", {
                visible: true,
                isElastic: true,
                chatId: null,
                otherParty: item
              });
            }).catch(() => {
              reject();
            })
          } else if(this.type == 'shopServerInternal') {
            sendChatData({
              toUserId: item.openId,
              content: this.shopServerId,
              messageType: this.messageType,
              chatType: 0
            }).then(() => {
              arrs.push(item.openId);
              if(arrs.length == arr.length) {
                resolve()
              }
              this.$notify({
                dangerouslyUseHTMLString: true,
                message: `
                <div class="chatNotificationBox">
                  <img src="${this.userInfo.avatarUrl}" />
                  <div class="content">
                    <div class="title">
                      ${this.$tc('Successfully sent 1 message to who', item.nickName)}
                    </div>
                    <div class="message">
                      ${this.$t('noticeServer.Shop')}/${this.$t('noticeServer.Server')}
                    </div>
                  </div>
                </div>
                `,
                customClass: "ec-notification",
                onClick: () => {
                  this.$store.dispatch("user/setMessageDialogOpen", {
                    visible: true,
                    isElastic: true,
                    chatId: null,
                    otherParty: item
                  });
                }
              });
            }).catch(() => {
              reject();
            })
          } else {
            sendChatData({
              toUserId: item.openId,
              content: this.$t('Files.shared the file'),
              messageType: ['jpg','png','jpeg'].includes(this.file.fileSuffix.toLowerCase) ? 3 : 4,
              fileId: this.file.id,
              chatType: 0
            }).then(() => {
              arrs.push(item.openId);
              if(arrs.length == arr.length) {
                resolve()
              }
              this.$notify({
                dangerouslyUseHTMLString: true,
                message: `
                <div class="chatNotificationBox">
                  <img src="${this.userInfo.avatarUrl}" />
                  <div class="content">
                    <div class="title">
                      ${this.$t('Files.shared the file')}
                    </div>
                    <div class="message">
                      ${this.file.fileName}
                    </div>
                  </div>
                </div>
                `,
                customClass: "ec-notification",
                onClick: () => {
                  this.$store.dispatch("user/setMessageDialogOpen", {
                    visible: true,
                    isElastic: true,
                    chatId: null,
                    otherParty: item
                  });
                }
              });
            }).catch(() => {
              reject();
            })
          }
        });
      }).then(() => {
        this.loading_button = false;
        this.$message.success(this.$t('home.Sent successfully'));
        this.$emit("update");
        this.onClose();
      }).catch(() => {
        this.loading_button = false;
      })
    },
    getRowKey(row) {
      return row.businessUserId;
    },
  },
};
</script>
<style lang="less" scoped>
.inviteBox {
  height: calc(100vh - 250px);
  overflow: overlay;
  margin-top: 24px;
  padding-bottom: 16px;
  .item {
    padding: 6px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: all .3s;
    &:hover {
      background: #F7F9FC;
    }
    .radioBox {
      width: 16px;
      height: 16px;
      background: #004C97;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        color: #ffffff;
        font-size: 12px;
      }
    }
    .content {
      flex: 1;
      min-width: 0;
      overflow: hidden;
      margin-left: 10px;
      .name {
        font-size: 14px;
        line-height: 20px;
        color: #122545;
        font-weight: 500;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 4px;
      }
      .job {
        font-size: 12px;
        line-height: 17px;
        color: #757D8A;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
.inside_box {
  padding: 20px 0;
  .inside_box_search {
    display: flex;
    align-items: center;
    gap: 0 14px;
    padding: 0 20px;
  }
}
</style>
