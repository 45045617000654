<template>
  <div>
    <el-dialog
      :visible="visible"
      width="358px"
      :title="$route.query.orderId ? $tc('home.OrderRelatedPersonnel', $isOrderTitle()) : $t('home.allMember')"
      :modal-append-to-body="false"
      @open="open"
      @close="close"
      custom-class="forwarderDialog"
      v-move-outside
    >
      <div class="searchBox">
        <el-input
          suffix-icon="el-icon-search"
          size="small"
          :placeholder="$t('memberList.FindMember')"
          v-model="search"
        />
      </div>
      <div class="inviteBox">
        <div class="item" v-for="(item, idx) in companyUsers.filter(row => row.nickName.toLowerCase().indexOf(search.toLowerCase()) !== -1)" :key="idx">
          <member :info="item" :size="48" />
          <div class="content">
            <div class="name">
              {{ item.nickName }}
              <div class="tag" v-if="!item.type">{{ $t('memberList.manage') }}</div>
            </div>
            <div class="job">{{ item.position }}</div>
          </div>
          <el-button 
            size="small" 
            icon="iconfont icon-shanchu" 
            circle 
            @click="removeMember(item)"
            v-if="item.canDelete"
          ></el-button>
          <el-popover
            ref="popover_auth"
            class="el-pop"
            placement="bottom"
            trigger="click"
            @hide="item.authCheck = false"
            popper-class="ec-popper"
          >
            <div 
              class="popperMenuItem"
              :class="row.value == item.type ? 'active' : ''" 
              v-for="(row, idx) in memberType.filter(row => row.value != 0)" 
              :key="idx"
              @click="setRole(item.openId, row.value)">
              <span>{{ row.key }}</span>
            </div>
            <div 
              slot="reference"
              class="authSetting" 
              v-if="(manageCompany && $route.query.companyId && ($route.query.companyId == companyId)) && (item.openId != businessUserId)"
              :class="{
                active: item.authCheck
              }"
              @click.stop="activePopover(companyUsers, item, 'popover_auth')">
              {{ $isFindData(memberType, item.type) }}
              <i class="el-icon-arrow-down"></i>
            </div>
          </el-popover>
          <div class="manageTranfer" v-if="(manageCompany && $route.query.companyId && ($route.query.companyId == companyId)) && (item.openId == businessUserId) && item.type == 0" @click="openManageTranfer(item.openId)">
            {{ $t('memberList.Manage Transfer') }}
          </div>
        </div>
      </div>
      <div v-if="$route.query.orderId" slot="footer" class="dialog-footer">
        <p @click="seeInviteMap">{{ $t('order.Invitation Map') }}</p>
        <el-button
          type="primary"
          size="small"
          icon="el-icon-circle-plus-outline"
          v-if="isCeoAcount"
          @click="isSendVisibleTwo = !isSendVisibleTwo"
          >{{ $t('people.NewMessage') }}
        </el-button>
      </div>
    </el-dialog>
    <el-dialog
      :visible="isSendVisible"
      width="358px"
      :wrapperClosable="true"
      :title="$t('people.createAccount')"
      :before-close="() => isSendVisible = !isSendVisible"
      v-move-outside
    >
      <el-input
        :placeholder="$t('people.customerName')"
        v-model="profileSendForm.name"
        style="margin-bottom: 12px;"
      />
      <el-input
        :placeholder="$t('people.Email')"
        v-model="profileSendForm.email"
      />
      <div slot="footer">
        <el-button
          class="cancel_button"
          @click="copy"
          size="small"
          style="color: #076F49;">
          {{ $t('people.copyLink').split('，')[1] }}
        </el-button>
        <el-button
          size="small"
          type="primary"
          @click="invitation">
          {{ $t('people.send') }}
        </el-button>
      </div>
    </el-dialog>
    <inside
      :isInside="isSendVisibleTwo"
      type="member"
      @onClose="isSendVisibleTwo = false"
      @updateData="getOrderDetailPeople"
    />
    <manageTranfer ref="manageTranfer" @update="getCompanyInfo($route.query.companyId)" />
    <el-dialog
      :visible="isInviteMapShow"
      width="800px"
      custom-class="dialogDragStyle"
      :wrapperClosable="true"
      :title="$t('order.Invitation Map')"
      :before-close="() => isInviteMapShow = false"
      v-move-outside
      v-dialogDrag="false"
    >
      <vue2-org-tree 
        :data="inviteMapData[0] || {}"
        :renderContent="renderInviteContent"
      />
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import {
  deleteEmployee,
  getCompanyEmployeeList,
} from '@/api/customer';
import { getInviteMap } from "@/api/order";
import { getRegisterLink, sendEmail } from '@/api';
import {
  removeUser,
  getInviteUserlist,
  inviteUser,
} from '@/api/order';
import { updateMemberRole } from "@/api/common";

import inside from "../fileInside/inside";
import manageTranfer from "./manageTranfer.vue";
import member from '@/components/member/member.vue';

export default {
  props: {
    visible: Boolean,
  },
  components: {
    inside,
    manageTranfer
  },
  data() {
    return {
      buttonLoading: false,
      addPeopleList: [],
      inviteMapData: [],
      people: [],
      companyUsers: [],
      search: "",
      isInviteMapShow: false,
      isSendVisible: false,
      isSendVisibleTwo: false,
      profileSendForm: {
        name: '',
        email: ''
      },
      profileSendRules: {
        name: [
          {
            required: true,
            message: 'Please input name',
            trigger: 'blur',
          },
        ],
        email: [
          {
            required: true,
            message: 'Please input email',
            trigger: 'blur',
          },
          {
            type: 'email',
            message: 'Please input correct email',
            trigger: 'blur',
          },
        ],
        roleCode: [
          {
            required: true,
            message: 'Please select role',
            trigger: 'blur',
          },
        ],
      }
    }
  },
  computed: {
    ...mapGetters(["businessUserId", "manageCompany", "companyId", "memberType"]),
    isCeoAcount() {
      return this.companyUsers.find((item, index) => item.openId == this.businessUserId);
    }
  },
  mounted() {
    
  },
  methods: {
    open() {
      const res = this.$route.query;
      if (res.companyId) {
        this.getCompanyInfo(res.companyId);
      } else if (res.orderId) {
        this.getOrderDetailPeople(res.orderId);
      }
    },
    //复制邀请链接
    copy() {
      getRegisterLink().then(
        (data) => {
          const theClipboard = navigator.clipboard;
          theClipboard.writeText(data).then(() => {
            this.$message.success(this.$t('home.Copysucceeded'));
          });
        }
      );
    },
    renderInviteContent(h, data) {
      return (
        <div class="memberBox">
          <member info={data} size="48" />
          <p>{data.nickName}</p>
        </div>
      )
    },
    seeInviteMap() {
      this.isInviteMapShow = true;
      getInviteMap(this.$route.query.orderId).then((data) => {
        console.log(data);
        this.inviteMapData = data.map(item => this.treeEvent(item));
        console.log(this.inviteMapData);
      })
    },
    treeEvent(row) {
      if(!(row.children && row.children.length)) return {
        ...row,
        id: row.openId,
        label: row.nickName,
        children: []
      };
      return {
        ...row,
        id: row.openId,
        label: row.nickName,
        children: row.children.map(item => this.treeEvent(item))
      }
    },
    activePopover(arr, row, key) {
      row.authCheck = !row.authCheck;
      this.$nextTick(() => {
        arr.forEach((item, index) => {
          if(item.openId != row.openId) {
            item.authCheck = false;
            this.$refs[key][index].showPopper = false;
          }
        });
      });
    },
    openManageTranfer(openId) {
      this.$refs.manageTranfer.open(openId);
    },
    setRole(openId, type) {
      this.$confirm(
        this.$t("Do you want to modify the current member role"),
        this.$t("Tips"),
        {
          confirmButtonText: this.$t("Confirm"),
          cancelButtonText: this.$t("Cancel"),
          type: "warning",
        }
      ).then(() => {
        updateMemberRole({
          openId,
          type
        }).then(() => {
          this.$message.success(this.$t("memberList.Operation succeeded"));
          this.getCompanyInfo(this.$route.query.companyId);
        }).finally(() => {});
      }).catch(() => {});
    },
    getCompanyInfo(companyId) {
      getCompanyEmployeeList(companyId).then(data => {
        this.companyUsers = data.memberList.map(item => ({
          ...item,
          authCheck: false,
        }));
      })
    },
    getOrderDetailPeople(orderId) {
      getInviteUserlist(orderId).then(data => {
        this.people = data;
        this.companyUsers = data;
        this.$emit(
          'updateFun',
          data
        );
      })
    },
    closeSendAuth() {
      this.isSendVisible = false;
      this.$refs.profileSendFormRef.resetFields();
    },
    closeSendAuthTwo() {
      this.isSendVisibleTwo = false;
      this.$refs.profileSendFormRef.resetFields();
    },
    SendRegistrationEmail() {
      if (this.addPeopleList.length == 0) {
        this.$message.warning(this.$t('memberList.Please select a person'));
      } else {
        let i = this.addPeopleList.map(v => v.openId);
        inviteUser({
          groupUsers: i.join(","),
          orderId: this.$route.query.orderId,
        }).then(() => {
          this.getOrderDetailPeople(this.$route.query.orderId);
          this.$message.success(this.$t('home.Invitethesuccessful'));
          this.isSendVisibleTwo = false;
        });
      }
    },
    invitation() {
      if (!this.profileSendForm.name) {
        this.$message.error(this.$t('people.Please input name'));
        return;
      }
      if (!this.profileSendForm.email) {
        this.$message.error(this.$t('people.Please input email'));
        return;
      }
      var emailReg = /^\w{3,}(\.\w+)*@[A-z0-9]+(\.[A-z]{2,5}){1,2}$/;
      if(!emailReg.test(this.profileSendForm.email)) {
        this.$message.warning(this.$t('login.PleaseinputaSuccessEmail'))
        return;
      }
      sendEmail(this.profileSendForm).then(() => {
        this.isSendVisible = false;
        this.$message.success(this.$t('home.Invitation sent successfully'));
        this.profileSendForm = {
          name: '',
          email: ''
        };
      });
    },
    close() {
      this.$emit('close');
      this.search = "";
    },
    handleSelectionChange(e) {
      this.addPeopleList = e;
    },
    removeMember({ openId }) {
      this.$confirm(
        this.$t('deleteEmployee.deleteEmployee'),
        this.$t('deleteEmployee.Tips'),
        {
          confirmButtonText: this.$t('deleteEmployee.Confirmz'),
          cancelButtonText: this.$t('deleteEmployee.Cancel'),
          type: 'warning',
        }
      ).then(() => {
        const res = this.$route.query;
        if (res.companyId) {
          deleteEmployee({ openId }).then(() => {
            this.$message.success(this.$t("todo.Delete Successful"));
            this.getCompanyInfo(res.companyId);
          });
        } else if (res.orderId) {
          removeUser({
            userId: openId,
            unid: res.orderId,
          }).then(() => {
            this.$message.success(this.$t("todo.Delete Successful"));
            this.getOrderDetailPeople(res.orderId);
          }).catch(() => {
            console.log('catch')
          })
        }
      }).catch(() => {});
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .forwarderDialog {
  .el-dialog__body {
    padding: 0;
  }
}
.searchBox {
  padding: 0 16px;
}
.inviteBox {
  max-height: 300px;
  overflow: overlay;
  padding-bottom: 16px;
  margin-top: 10px;
  .item {
    padding: 10px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: all .3s;
    &:hover {
      background: #F7F9FC;
    }
    .content {
      flex: 1;
      min-width: 0;
      overflow: hidden;
      margin-left: 6px;
      .name {
        font-size: 14px;
        line-height: 20px;
        color: #122545;
        font-weight: 500;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        gap: 5px;
        .tag {
          padding: 2px 6px;
          font-size: 12px;
          line-height: 17px;
          background: #F7F9FC;
          border-radius: 4px;
          color: #076F49;
        }
      }
      .job {
        font-size: 12px;
        line-height: 17px;
        color: #757D8A;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .authSetting {
      font-size: 12px;
      color: #637381;
      &:hover {
        color: #076F49;
        opacity: .8;
      }
      i {
        transition: all .3s;
      }
      &.active {
        i {
          transform: rotate(180deg);
        }
      }
    }
    .manageTranfer {
      font-size: 12px;
      color: #076F49;
      &:hover {
        opacity: .8;
      }
    }
  }
}
.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    font-size: 14px;
    color: #076F49;
    cursor: pointer;
  }
}

/deep/ .org-tree-container {
  width: 100%;
  height: 100%;
  padding: 0;
  .org-tree {
    margin: 0 auto;
  }
  .org-tree-node {
    &.is-leaf {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .org-tree-node-label-inner {
    box-shadow: none;
    white-space: nowrap;
    padding: 10px;
  }
}
/deep/ .memberBox {
  p {
    margin-top: 5px;
    font-size: 14px;
    font-weight: 500;
    color: #637381;
  }
}
</style>
