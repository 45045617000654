module.exports = {
  undefined: "未定义",
  VENDOR: "供应商",
  FORWARDER: "货代",
  PURCHASER: "客户",
  "New Customer": "新建客户",
  "Input Company": "公司名",
  "Input People": "人名",
  Search: "搜索",
  "Clear All Filter": "清除所有条件",
  "New Order": "新建订单",
  Export: "导出",
  No: "No.",
  Logo: "图标",
  Company: "公司名",
  Contact: "电话",
  Email: "邮箱",
  Country: "国家",
  Admin: "管理员",
  Member: "成员",
  "Order Total": "订单数",
  Action: "操作",
  "Unhide Successful": "公司设置可见成功",
  "Hide Successful": "公司设置隐藏成功",
  Name: "公司",
  VAT: "增值税号",
  Address: "地址",
  more: "更多",
  "Find Member": "查找",
  Phone: "电话",
  Title: "职务",
  "Please write ...": "写…",
  Send: "发送",
  "Company Name ": "公司名",
  "Account type": "账户类型",
  "VAT No.": "增值税号",
  Location: "国家",
  "Company address": "详细地址",
  "Time zone": "时区",
  Website: "官网",
  Schedule: "日程",
  Message: "公告",
  "Message Board": "公告栏",
  MessageBoards: "公告",
  Connection: "合作",
  "Input CI": "CI号",
  "Input PI": "PI号",
  "Start from": "开始",
  "End to": "结束",
  CI: "发票号",
  "Create Time": "创建日期",
  Currency: "货币",
  Amount: "金额",
  Status: "状态",
  details: "详情",
  "Change Password": "更改密码",
  "Original password": "原始密码",
  "New password": "新密码",
  "Confirm password": "再次确认密码",
  "Please input password": "请输入密码",
  "Password must be at least 8 characters": "密码必须大于8个字符",
  "Password must contain at least one letter and one number":
    "密码必须包含字母和数字",
  "Two passwords are inconsistent!": "两次密码不一致！",
  cm: "确认修改",
  "Update successful": "修改成功",
  "Save Changes": "保存修改",
  "Please input name": "请输入姓名",
  "Length should be 2 to 64": "长度应在2-64个字符之间",
  "Please input email": "请输入邮箱",
  "Please input correct email": "请输入正确的邮箱",
  "Please input title": "请输入职务",
  "Please select country": "请选择国家",
  "Please select timezone": "请选择时区",
  "Please input phone": "请输入电话号码",
  "Please input correct phone": "请输入正确的电话",
  "Please input code": "请输入验证码",
  "Your phone number": "电话号码",
  "Verification code": "验证码",
  OR: "或",
  "Are you sure to delete this company?": "确定删除该公司吗？",
  "delete company Name": "公司名",
  "delete company Type": "公司类型",
  "delete company Country": "国家",
  Cancel: "取消",
  "Yes, delete this company": "是的，删除该公司",
  "Creating Company Account": "创建公司账户",
  "Choose the timezone": "选择时区",
  "Choose the country": "选择国家",
  "Company Please input VAT": "请输入增值税号",
  "Company Please input correct VAT": "请输入正确的增值税号",
  "Company Please input alias name": "请输入公司别名",
  "Company Please upload logo": "请上传公司logo",
  "Company Please select company type": "请选择公司类型",
  "Company Please input contact": "请输入联系方式",
  "Company Please select country": "请选择国家",
  "Company Please input address": "请输入详细地址",
  "Company Please input website": "请输入公司官网",
  "Company Please input email": "请输入邮箱",
  "Company Please input correct email": "请输入正确的邮箱",
  "Company Please input name": "请输入公司名",
  "Company Please select timezone": "请选择时区",
  Profile: "主页",
  Ping: "发消息",
  at: "在",
  "Choose the company": "选择公司",
  "Create Company after you complete and save your changes":
    "保存修改后创建公司",
  ctf: "找不到？",
  "create account now": "现在创建公司",
  Delete: "删除",
  weak: "弱",
  medium: "中",
  strong: "强",
  "Password Update Successful": "密码更改成功",
  "Update Successful": "修改成功",
  "Delete Successful": "删除成功",
  "Create Successful": "创建成功",
  "Upload Successful": "上传成功",
  "Copy Successful": "复制成功",
  "Picture must be JPG or PNG or GIF!": "图片必须是JPG或PNG或GIF格式！",
  "Picture size can not exceed 2MB!": "图片大小不能超过2MB！",
  "Please complete the information first": "请先完善信息",
  "Upload success": "上传成功",
  "Create success": "创建成功",
  "Thali detail": '套餐详情',
  customerNumber: "供应商/客户数量",
  storageSize: "云存储空间(GB)",
  memberNumber: "成员数量",
  orderNumber: "订单数量",
  'become due': '到期',
  Used: '已用',
  ExpiredDay: (week) => {
    return `已过期${week.values.n}天`;
  },
};
