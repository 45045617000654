<template>
  <div class="commentChidlrenBox">
    <template v-if="item.messageType != 10 && item.messageType != 12 && item.messageType != 13 && item.messageType != 14 && item.messageType != 15">
      <div class="lmiBox" v-if="!item.chatFileUrl">
        <article :style="{
          maxWidth: maxWidth,
          padding: content.match(/<img /i) && $route.path != '/order/orderDetails' ? 0 : '8px 12px',
          background
        }">
          <div class="lmi-wrapper_htmls" ref="lmiWrapperHtmls">
            <slot name="call"></slot>
            <span class="htmlText" ref="htmlText" v-html="content" @click="replayImgShow($event)"></span>
            <span v-if="isOpen" class="open" @click="openEvent">{{ isShow ? $t('memberList.more') : $t('retract') }}</span>
          </div>
          <div class="translation-result" v-if="textTranslation">
            <div class="translation-text" v-html="textTranslation"></div>
          </div>
        </article>
        <i class="el-icon-loading" v-if="item.loading"></i>
        <i class="iconfont icon-a-fanyi1" @click.stop="translateText"></i>
      </div>
      <div class="lmiBox" v-if="item.chatFileUrl">
        <fileBlocks
          type="message"
          :background="background"
          :embedUrl="item.chatFileUrl"
          :embedId="item.chatFileId"
        />
      </div>
    </template>
    <template v-if="item.messageType == 10">
      <div class="purchaseBox">
        <div class="box">
          <div class="header">
            <div class="tag" :class="purchaseItem.purchaseStatus == 3 ? 'grey' : ''">{{ purchaseItem.purchaseStatus != 3 ? $t('Purchase.title') : purchaseItem.purchaseStatusName }}</div>
            <div class="text line1" v-showTootip>{{ purchaseItem.purchaseNo || '' }}</div>
          </div>
          <div class="main" v-if="purchaseItem.purchaseNo">
            <div class="previewBtn">
              <div class="btn" @click="openPurchaseDialog">{{ $t('Click to preview') }}</div>
            </div>
            <div class="item">
              <div class="label">{{ $t('Dashboard.Product') }}</div>
              <div class="content" v-showTootip>
                <span v-for="(row, index) in purchaseItem.detailJson" :key="index">
                  {{index+1}}.{{(row.productCode+row.productName) || '--'}}
                </span>
              </div>
            </div>
            <div class="item">
              <div class="label">{{ $t('order.cny') }}</div>
              <div class="content" v-showTootip>
                {{ purchaseItem.totalPrice || '--' }} {{ purchaseItem.totalPriceUnit || '--' }}
              </div>
            </div>
            <div class="itemFlex">
              <div class="item">
                <div class="label">{{ $t('Purchase.Mode of transport') }}</div>
                <div class="content" v-showTootip>
                  <i :class="`iconfont icon-purchase-${purchaseItem.deliveryMethod}`"></i>
                  {{ $isFindData(transportMode, purchaseItem.deliveryMethod, 'value', 'key') || '--' }}
                </div>
              </div>
              <div class="item">
                <div class="label">{{ $t('Purchase.Delivery terms') }}</div>
                <div class="content" v-showTootip>
                  {{ $isFindData(payMentData, purchaseItem.transactionTerms, 'value', 'key') || '--' }}
                </div>
              </div>
            </div>
            <div class="item">
              <div class="label">{{ $t('Purchase.Delivery date') }}</div>
              <div class="content" v-showTootip>
                {{ purchaseItem.deliveryDate || '--' }} {{ purchaseItem.deliveryTimezone ? $isFindData(timezone, purchaseItem.deliveryTimezone, 'value', 'key').split(" ")[0] : '--' }}
              </div>
            </div>
            <div class="item">
              <div class="label">{{ $t('todo.Notes') }}</div>
              <div class="content line1" v-showTootip>
                {{ purchaseItem.remark || '--' }}
              </div>
            </div>
          </div>
          <div class="main" v-else>
            <el-empty
              :description="$t('Purchase.Purchase order deleted')"
              :image="$emptyImage"
              :image-size="90"
            />
          </div>
        </div>
        <fileBlocks
          v-if="purchaseItem.attachmentUrl"
          type="message"
          :background="background"
          :embedUrl="purchaseItem.attachmentUrl"
          :embedId="purchaseItem.attachmentId"
        />
      </div>
    </template>
    <template v-if="item.messageType == 12">
      <div class="purchaseBox">
        <div class="box">
          <div class="header" v-if="serverItem">
            <div class="tag blue">
              <i class="iconfont icon-gongsi2"></i>
            </div>
            <div class="text line1" v-showTootip>{{ serverItem.systemShippingCompanyDTO.companyEnName }}</div>
          </div>
          <div class="main" v-if="serverItem">
            <div class="previewBtn">
              <div class="btn" @click="openServerDrawer">{{ $t('Click to preview') }}</div>
            </div>
            <serverItem :item="serverItem" />
          </div>
          <div class="main" v-else>
            <el-empty
              :description="$t('Data has been deleted')"
              :image="$emptyImage"
              :image-size="90"
            />
          </div>
        </div>
      </div>
    </template>
    <template v-if="item.messageType == 13">
      <div class="purchaseBox">
        <div class="box">
          <div class="header" v-if="caseItem">
            <div class="tag blue">
              <i class="iconfont icon-gongsi2"></i>
            </div>
            <div class="text line1" v-showTootip>{{ caseItem.caseName }}</div>
          </div>
          <div class="main" v-if="caseItem">
            <div class="previewBtn">
              <div class="btn" @click="openServerDrawer">{{ $t('Click to preview') }}</div>
            </div>
            <caseItem :item="caseItem" />
          </div>
          <div class="main" v-else>
            <el-empty
              :description="$t('Data has been deleted')"
              :image="$emptyImage"
              :image-size="90"
            />
          </div>
        </div>
      </div>
    </template>
    <template v-if="item.messageType == 14">
      <div class="purchaseBox">
        <div class="box">
          <div class="header" v-if="fileItem">
            <div class="tag blue">
              <i class="iconfont icon-gongsi2"></i>
            </div>
            <div class="text line1" v-showTootip>{{ fileItem.fileName }}</div>
          </div>
          <div class="main" v-if="fileItem">
            <div class="previewBtn">
              <div class="btn" @click="openServerDrawer">{{ $t('Click to preview') }}</div>
            </div>
            <fileItem :item="fileItem" />
          </div>
          <div class="main" v-else>
            <el-empty
              :description="$t('Data has been deleted')"
              :image="$emptyImage"
              :image-size="90"
            />
          </div>
        </div>
      </div>
    </template>
    <template v-if="item.messageType == 15">
      <div class="purchaseBox">
        <div class="box">
          <div class="header" v-if="shopItem">
            <div class="tag blue">
              <i class="iconfont icon-gongsi2"></i>
            </div>
            <div class="text line1" v-showTootip>{{ shopItem.goodsName }}</div>
          </div>
          <div class="main" v-if="shopItem">
            <div class="previewBtn">
              <div class="btn" @click="openServerDrawer">{{ $t('Click to preview') }}</div>
            </div>
            <shopItem :item="shopItem" />
          </div>
          <div class="main" v-else>
            <el-empty
              :description="$t('Data has been deleted')"
              :image="$emptyImage"
              :image-size="90"
            />
          </div>
        </div>
      </div>
    </template>
    <serverDrawer ref="serverDrawer" isShare />
    <shopDrawer ref="shopDrawer" isShare />
    <caseDrawer ref="caseDrawer" isShare />
    <fileDrawer ref="fileDrawer" isShare />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { translateMessage } from "@/api/message";
import { queryMessageRecord } from '@/api/news';
import { getEventImages } from "@/api/event";
import { getPurchaseDetailApi } from "@/api/order";
import { getForwarderServerDetail, getCaseDetail, getFileDetail, getShopDetail } from "@/api/shopServer.js";
import serverItem from "../shopServerManage/itemComponents/server.vue";
import shopItem from "../shopServerManage/itemComponents/shop.vue";
import caseItem from "../shopServerManage/itemComponents/case.vue";
import fileItem from "../shopServerManage/itemComponents/file.vue";
import serverDrawer from "../shopServerManage/detailDrawer/server.vue";
import caseDrawer from "../shopServerManage/detailDrawer/case.vue";
import fileDrawer from "../shopServerManage/detailDrawer/file.vue";
import shopDrawer from "../shopServerManage/detailDrawer/shop.vue";
export default {
  name: "ConversationBubble",
  components: {
    serverItem,
    serverDrawer,
    fileItem,
    shopItem,
    caseItem,
    caseDrawer,
    fileDrawer,
    shopDrawer
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    maxWidth: {
      type: String | Number,
      default: '275px'
    },
    otherParty: {
      type: Object,
      default: null
    },
    background: {
      type: String,
      default: '#f7f9fc'
    }
  },
  inject: ['isOneClickTranslate'],
  data() {
    return {
      textTranslation: "",
      loading: false,
      imgPreview: "",
      chatId: "",
      content: "",
      orderImages: [],
      imageIndex: 0,
      isOpen: true,
      isShow: false,
      purchaseItem: {
        purchaseNo: "",
        detailJson: [{
          productCode: "",
          productName: ""
        }],
        totalPrice: "",
        totalPriceUnit: "",
        deliveryMethod: "",
        transactionTerms: "",
        deliveryDate: "",
        deliveryTimezone: "",
        remark: ""
      },
      serverItem: null,
      caseItem: null,
      shopItem: null,
      fileItem: null
    }
  },
  watch: {
    isOneClick: {
      handler(val) {
        if(val) {
          this.translateText();
        } else {
          this.textTranslation = "";
        }
      },
      deep: true,
      immediate: true
    },
    item: {
      handler() {
        if(this.item.chatId) {
          this.chatId = JSON.parse(JSON.stringify(this.item.chatId));
        }
        this.textTranslation = "";
        let pattern = /<img [^>]*src=['"]([^'"]+)[^>]*>/g;
        this.content = (this.item.content || this.item.msg).replace(pattern, val => val);
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapGetters(["messageDialog", "transportMode", "payMentData", "timezone"]),
    isOneClick() {
      return this.isOneClickTranslate();
    }
  },
  mounted() {
    if(this.item.chatId) {
      this.chatId = JSON.parse(JSON.stringify(this.item.chatId));
    }
    this.$nextTick(() => {
      let queryDom = this.$refs.lmiWrapperHtmls
      if(queryDom) {
        // this.isOpen = false;
        if(queryDom.offsetHeight >= 145) {
          this.isOpen = true;
        }
        const resizeObserver = new ResizeObserver((entries) => {
          if(queryDom.offsetHeight >= 145) {
            this.isOpen = true;
          } else {
            this.isOpen = false;
          }
        });
        resizeObserver.observe(queryDom);
      }
    });
    this.getChatTypeDetail();
  },
  methods: {
    openEvent() {
      this.isShow = !this.isShow;
      if(!this.isShow) {
        this.$refs.htmlText.style.maxHeight = "none";
      } else {
        this.$refs.htmlText.style.maxHeight = "145px";
      }
    },
    getChatTypeDetail() {
      if(this.item.messageType == 10) {
        getPurchaseDetailApi(this.item.content).then((data) => {
          this.purchaseItem = data || {};
        })
      }
      if(this.item.messageType == 12) {
        getForwarderServerDetail(this.item.content).then((data) => {
          this.serverItem = data ? {
            ...data,
            shippingCompanyName: data.systemShippingCompanyDTO?.companyEnName,
            shippingCompanyId: data.systemShippingCompanyDTO?.shippingCompanyId,
            shippingCompanyLogoUrl: data.systemShippingCompanyDTO?.logoUrl,
          } : null;
        })
      }
      if(this.item.messageType == 13) {
        getCaseDetail(this.item.content).then((data) => {
          this.caseItem = data ? {
            ...data,
            tags: JSON.parse(data.tags)
          } : null;
        })
      }
      if(this.item.messageType == 14) {
        getFileDetail(this.item.content).then((data) => {
          this.fileItem = data ? {
            ...data,
            tags: JSON.parse(data.tags)
          } : null;
        })
      }
      if(this.item.messageType == 15) {
        getShopDetail(this.item.content).then((data) => {
          this.shopItem = data ? data : null;
        })
      }
    },
    replayImgShow(e) {
      if(e.target.tagName == "A") {
        e.target.click();
        this.$emit("close");
      }
      if(e.target.tagName == 'IMG') {
        e.stopPropagation();
        this.imgPreview = e.target.src;
        if(this.$route.query.orderId) {
          getEventImages({
            orderId: this.$route.query.orderId
          }).then((data) => {
            this.orderImages = data;
            this.imageIndex = this.orderImages.findIndex(item => item == this.imgPreview);
          })
        }

        let content = document.createElement('div');
        content.className = "imgDolg";
        content.id = "imgPreview";

        let imageContent = document.createElement('div');
        imageContent.className = "imageContent";

        // 切换轮播
        if(this.item.chatId || this.$route.query.orderId) {
          let prev = document.createElement('div');
          prev.className = "prev";
          prev.id = "imgPreviewPrev";
          let prevIcon = document.createElement('i');
          prevIcon.className = "el-icon-arrow-left";
          prev.appendChild(prevIcon);
          prev.onclick = (e) => {
            e.stopPropagation();
            this.switchChange(false, e.target.src);
          }

          let next = document.createElement('div');
          next.className = "next";
          next.id = "imgPreviewNext";
          let nextIcon = document.createElement('i');
          nextIcon.className = "el-icon-arrow-right";
          next.appendChild(nextIcon);
          next.onclick = (e) => {
            e.stopPropagation();
            this.switchChange(true, e.target.src);
          }

          imageContent.appendChild(prev);
          imageContent.appendChild(next);
        }

        let imageControl = document.createElement('div');
        imageControl.className = "imageControl";
        
        let enlarge = document.createElement('div');
        enlarge.className = "controlItem";
        let enlargeIcon = document.createElement('i');
        enlargeIcon.className = "el-icon-zoom-in";
        let scaleValue = 1;
        enlarge.onclick = (e) => {
          e.stopPropagation();
          scaleValue+=0.1;
          document.getElementById("previewImage").style.transform = `scale(${scaleValue})`
        }
        enlarge.appendChild(enlargeIcon);

        let shrink = document.createElement('div');
        shrink.className = "controlItem";
        let shrinkIcon = document.createElement('i');
        shrinkIcon.className = "el-icon-zoom-out";
        shrink.onclick = (e) => {
          e.stopPropagation();
          scaleValue-=0.1;
          document.getElementById("previewImage").style.transform = `scale(${scaleValue})`
        }
        shrink.appendChild(shrinkIcon);

        let maximize = document.createElement('div');
        maximize.className = "controlItem";
        let maximizeIcon = document.createElement('i');
        maximizeIcon.className = "el-icon-full-screen";
        maximize.onclick = (e) => {
          e.stopPropagation();
          const image = new Image();
          image.src = this.imgPreview;
          image.style.width = "100%";
          const imgWindow = window.open(this.imgPreview);
          imgWindow.document.write(image.outerHTML);
        }
        maximize.appendChild(maximizeIcon);

        imageControl.appendChild(enlarge);
        imageControl.appendChild(shrink);
        imageControl.appendChild(maximize);

        let img = document.createElement('img');
        img.id = "previewImage";
        img.src = this.imgPreview;
        img.onclick = (e) => {
          e.stopPropagation();
        }
        imageContent.appendChild(img);

        let close = document.createElement('div');
        close.className = "close";
        let closeIcon = document.createElement('i');
        closeIcon.className = "el-icon-close";
        close.appendChild(closeIcon);
        close.onclick = (e) => {
          e.stopPropagation();
          this.orderImages = [];
          document.getElementById("imgPreview").remove();
        }
        
        content.appendChild(imageContent);
        content.appendChild(close);
        content.appendChild(imageControl);

        content.onclick = () => {
          this.orderImages = [];
          document.getElementById("imgPreview").remove();
        }

        document.body.appendChild(content);
      }
    },
    switchChange(type, src) {
      if(this.item.chatId) {
        queryMessageRecord({
          size: 1,
          next: type,
          messageType: 3,
          locateChatId: this.chatId,
          chatUser: this.otherParty?.openId
        }).then(data => {
          if(!data.length) {
            if(type) {
              document.querySelector("#imgPreviewNext").style.display = "none";
            } else {
              console.log(document.querySelector("#imgPreviewPrev"));
              document.querySelector("#imgPreviewPrev").style.display = "none";
            }
            this.$message.warning(this.$t('There are no pictures left'));
            return;
          }
          data[0].content.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, (asd,capture) => {
            this.imgPreview = capture;
            this.chatId = data[0].chatId;
            document.getElementById("imgPreview").querySelector("img").src = this.imgPreview;
          });
        }).finally(() => this.isLoading = false);
      }
      if(this.$route.query.orderId) {
        if(type) {
          if(!this.orderImages[this.imageIndex+1]) {
            document.querySelector("#imgPreviewNext").style.display = "none";
            this.$message.warning(this.$t('There are no pictures left'));
            return;
          }
          document.querySelector("#imgPreviewNext").style.display = "flex";
          document.querySelector("#imgPreviewPrev").style.display = "flex";
          this.imgPreview = this.orderImages[this.imageIndex+1];
          document.getElementById("imgPreview").querySelector("img").src = this.imgPreview;
          this.imageIndex += 1;
        } else {
          if(!this.orderImages[this.imageIndex-1]) {
            document.querySelector("#imgPreviewPrev").style.display = "none";
            this.$message.warning(this.$t('There are no pictures left'));
            return;
          }
          document.querySelector("#imgPreviewNext").style.display = "flex";
          this.imgPreview = this.orderImages[this.imageIndex-1];
          document.getElementById("imgPreview").querySelector("img").src = this.imgPreview;
          this.imageIndex -= 1;
        }
      }
    },
    openPurchaseDialog() {
      this.$myEvent.emit("openPurchase", {
        mode: 3,
        otherParty: this.otherParty,
        row: this.purchaseItem
      });
    },
    openServerDrawer() {
      if(this.item.messageType == 12) {
        this.$refs.serverDrawer.open(this.serverItem);
      }
      if(this.item.messageType == 13) {
        this.$refs.caseDrawer.open(this.caseItem);
      }
      if(this.item.messageType == 14) {
        this.$refs.fileDrawer.open(this.fileItem);
      }
      if(this.item.messageType == 15) {
        this.$refs.shopDrawer.open(this.shopItem);
      }
    },
    async translateText() {
      if (this.textTranslation) {
        this.textTranslation = "";
        return;
      }
      this.loading = true;
      const data = await translateMessage({
        text: this.item.msg || this.item.content
      });
      this.textTranslation = data;
      this.loading = false;
    }
  }
};
</script>
<style lang="less" scoped>
.commentChidlrenBox {
  font-size: 14px;
  color: #17181c;
  overflow: hidden;
  .purchaseBox {
    flex: 1;
    float: left;
    .box {
      background: #ffffff;
      border-radius: 4px;
      padding: 12px;
      margin-bottom: 8px;
      min-width: 280px;
      max-width: 300px;
      .header {
        display: flex;
        align-items: center;
        gap: 4px;
        .tag {
          padding: 2px 5px;
          background: #FF8311;
          border-radius: 4px;
          color: #ffffff;
          font-size: 12px;
          &.grey {
            background: #A3B0C6;
          }
          &.blue {
            background: #004C97;
          }
        }
        .text {
          flex: 1;
          font-size: 14px;
          font-weight: 500;
          color: #122545;
          word-break: break-all;
        }
      }
      .main {
        padding: 14px 12px;
        background: #F7F9FC;
        border-radius: 4px;
        margin-top: 8px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        position: relative;
        .previewBtn {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          z-index: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 0;
          .btn {
            padding: 8px 16px;
            background: rgba(20, 39, 70, 0.60);
            border-radius: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            font-weight: 500;
            font-size: 12px;
            cursor: pointer;
          }
        }
        /deep/ .caseItemBox {
          padding: 0;
          // width: 230px;
          height: 187px;
          margin: 0;
          .elImage {
            height: 100%;
          }
          .title {
            display: none;
          }
          .description {
            display: none;
          }
          .bottom {
            display: none;
          }
        }
        /deep/ .serverItemBox {
          padding: 0;
          // width: 230px;
          height: 150px;
          margin: 0;
          display: flex;
          align-items: center;
          .header {
            display: none;
          }
          .content {
            margin-top: 0;
            width: 100%;
            img {
              display: none;
            }
            .right {
              zoom: .8;
            }
          }
          .bottom {
            display: none;
          }
        }
        &:hover {
          .previewBtn {
            opacity: 1;
          }
        }
        .item {
          display: flex;
          align-items: center;
          .label {
            width: 70px;
            font-size: 14px;
            line-height: 22px;
            color: #637381;
          }
          .content {
            flex: 1;
            font-size: 14px;
            color: #122545;
            font-weight: 600;
            i {
              color: #637381;
            }
          }
        }
        .itemFlex {
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }
    }
  }
  .lmiBox {
    display: flex;
    align-items: center;
    gap: 10px;
    .icon-a-fanyi1 {
      cursor: pointer;
      transition: all 0.3s;
      opacity: 0;
    }
    &:hover {
      .icon-a-fanyi1{
        opacity: 1;
      }
    }
    article {
      border-radius: 6px;
      background-color: #f7f9fc;
      padding: 8px 12px;
      position: relative;
      transition: all .3s;
      cursor: pointer;
      overflow: hidden;
      &:hover {
        background: #E0E4EA;
      }
      .lmi-wrapper_htmls {
        position: relative;
        .htmlText {
          word-break: break-all;
          // max-height: 145px;
          overflow: hidden;
          display: inline-block;
          vertical-align: bottom;
          /deep/ a {
            color: #076F49;
            text-decoration: underline;
          }
          /deep/ img {
            width: 100%;
            // height: 144px;
          }
        }
        .open {
          text-align: right;
          display: block;
          color: #076F49;
        }
      }
      .translation-result {
        border-top: 1px solid #ededed;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding-top: 3px;
        .translation-text {
          flex: 1;
          color: #122545;
          word-break: break-all;
          /deep/ img {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
