<template>
  <div
    class="file-block-wrapper"
    :class="type == 'message' ? 'message' : type == 'history' ? 'history' : ''"
    :style="{
      background
    }"
    @click="fileEventClick"
    ref="reference"
  > 
    <span class="iconfont_file icon-Pdf" v-if="fileFontType == 'pdf'"></span>
    <span class="iconfont_file icon-zip" v-if="fileFontType == 'zip'"></span>
    <span class="iconfont_file icon-tupian" v-if="fileFontType == 'picture'"></span>
    <span class="iconfont_file icon-Word" v-if="fileFontType == 'word'"></span>
    <span class="iconfont_file icon-Excel" v-if="fileFontType == 'excel' || fileFontType == 'csv'"></span>
    <span class="iconfont_file icon-PPT" v-if="fileFontType == 'powerpoint'"></span>
    <span class="iconfont_file icon-shipin" v-if="fileFontType == 'video'"></span>
    <span v-if="!audioPlayStatus && fileFontType == 'audio'" class="iconfont icon-weixinyuyin"></span>
    <img class="playIcon" v-else-if="audioPlayStatus && fileFontType == 'audio'" src="https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/audioPlay.gif" />
    <div class="file_block_name">
      <span>{{ fileFontType != 'audio' ? fileName : fileAudio.duration+'s' }}</span>
    </div>
    <Teleport v-if="!isShare" to="body" :disabled="embedShow">
      <fileView
        :row="{
          id: embedId,
          fileName: fileName,
          fileSuffix: fileFontType,
          savePath: embedUrl
        }"
        :embedShow="embedShow"
        :embedUrl="embedUrl"
        @close="embedShow = false"
      ></fileView>
    </Teleport>
  </div>
</template>
<script>
import { defineComponent, provide, ref, watch, inject, nextTick, reactive, onBeforeUnmount } from "@vue/composition-api";
import { Message } from "element-ui";
import { getTemporaryFile } from "@/api/files";
var events = require('events');
const myEvent = new events.EventEmitter();
export default defineComponent({
  name: "fileBlocks",
  props: {
    background: {
      type: String,
      default: 'transparent'
    },
    type: {
      type: String,
      default: "event",
    },
    embedUrl: {
      type: [String, Object],
      default: "",
    },
    embedId: {
      type: String | Number,
      default: ""
    },
    ci: {
      type: [String, Number],
      default: "",
    },
  },
  emits: ["close"],
  setup(props, { emit }) {
    const fileFontType = ref("");
    const fileName = ref("");
    const embedShow = ref(false);
    const reference = ref(null);
    const isShare = inject('isShare', () => false)();
    const audioPlayStatus = ref(false);
    const audioContarn = ref(null);
    const fileAudio = reactive({
      duration: 0
    })
    provide("parent", {
      toSourceDom: (el) => {
        reference.value.$el.appendChild(el);
      },
    });

    function fileViewFun(url) {
      let text = url?.split("/")?.pop();
      let index_ = text.lastIndexOf("_");
      let indexdian = text.lastIndexOf(".");
      fileName.value = (text.substring(0, index_) + text.substring(indexdian, text.length)).split("?")[0];
      const type = url?.split(".")?.pop().split("?")[0];
      const types = {
        pdf: ["pdf", "PDF"],
        picture: ["jpg", "jpeg", "png", "gif", "bmp", "svg"],
        office: ["doc", "docx", "xls", "xlsx", "ppt", "pptx", "csv"],
        video: [
          "mp4",
          "avi",
          "rmvb",
          "rm",
          "flv",
          "3gp",
          "mkv",
          "mov",
          "wmv",
          "mpg",
          "mpeg",
          "m4v",
          "webm",
        ],
        audio: [
          "mp3",
          "wav",
          "ogg",
          "wma",
          "aac",
          "flac",
          "ape",
          "m4a",
          "mid",
          "midi",
          "mka",
          "mp2",
          "mpc",
          "ra",
          "tta",
          "vqf",
          "ac3",
          "dts",
        ],
        zip: ["zip", "rar", "7z", "tar", "xz", "etc", "dwg"]
      };
      if (types.pdf.includes(type.toLowerCase())) {
        fileFontType.value = "pdf";
      } else if (types.picture.includes(type.toLowerCase())) {
        fileFontType.value = "picture";
      } else if (types.office.includes(type.toLowerCase())) {
        const officeType = {
          doc: "word",
          docx: "word",
          xls: "excel",
          xlsx: "excel",
          ppt: "powerpoint",
          pptx: "powerpoint",
          csv: "csv"
        };
        fileFontType.value = officeType[type.toLowerCase()];
      } else if (types.video.includes(type.toLowerCase())) {
        fileFontType.value = "video";
      } else if (types.audio.includes(type.toLowerCase())) {
        fileFontType.value = "audio";
      } else if (types.zip.includes(type.toLowerCase())) {
        fileFontType.value = "zip";
      } else {
        Message.warning("暂不支持该文件类型");
      }
      if(fileFontType.value == 'audio') {
        getTemporaryFile(props.embedUrl).then((data) => {
          audioContarn.value = new Audio(data);
          audioContarn.value.addEventListener('canplaythrough', () => {
            fileAudio.duration = audioContarn.value.duration.toFixed(0)
          })
          audioContarn.value.addEventListener('ended', function() {
            audioPlayStatus.value = false;
          });
          myEvent.on("messageAudioStop", (url) => {
            if(url == props?.embedUrl) return;
            audioContarn.value.pause();
            audioPlayStatus.value = false;
          })
        })
      }
    }

    watch(
      () => props?.embedUrl,
      (val) => {
        if (val) {
          fileViewFun(val);
        }
      },
      { immediate: true }
    );

    onBeforeUnmount(() => {
      if(audioContarn.value) {
        audioContarn.value.pause();
      }
    })

    const fileEventClick = () => {
      if(fileFontType.value == 'audio') {
        myEvent.emit("messageAudioStop", props?.embedUrl);
        nextTick(() => {
          if(audioPlayStatus.value) {
            audioContarn.value.pause();
            audioPlayStatus.value = false;
            return;
          }
          audioPlayStatus.value = true;
          audioContarn.value.play();
        });
        return;
      }
      embedShow.value = !embedShow.value
    }

    return {
      fileFontType,
      fileName,
      isShare,
      embedShow,
      fileEventClick,
      audioPlayStatus,
      reference,
      fileViewFun,
      fileAudio
    };
  },
});
</script>
<style lang="less" scoped>
.file-block-wrapper {
  width: auto;
  // padding: 3px 10px;
  border-radius: 4px;
  /* background-color: #F7F9FC; */
  display: inline-flex;
  // align-items: center;
  column-gap: 5px;
  cursor: pointer;
  margin-top: 8px;
  transition: all .3s;
  color: #122545;
  font-size: 12px;
  &.message {
    align-items: center;
    max-width: 220px;
    background: #ffffff;
    padding: 8px 12px;
    margin: 0;
    border-radius: 6px;
    .iconfont_file {
      font-size: 30px;
    }
    .el-icon-refresh-left {
      font-size: 24px;
      animation: spin 2s linear infinite;
    }
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
    .file_block_name {
      color: #122545;
      font-size: 13px;
      // padding-top: 2px;
      word-break: break-all;
      margin: 0;
    }
  }
  &.history {
    .iconfont_file {
      font-size: 35px;
    }
    .file_block_name {
      color: #757D8A;
      font-size: 12px;
      word-break: break-all;
    }
  }
}

.file_block_name {
  line-height: 17px;
  word-break: break-all;
  margin-top: 2px;
}

.iconfont_file {
  font-size: 20px;
  /* color: #2e3f4f; */
}
.icon-weixinyuyin {
  transform: rotate(180deg);
}
.playIcon {
  width: 18px;
  transform: scale(1.1);
  transform: rotate(180deg);
}
</style>
