<template>
  <div class="serverItemBox" v-if="item">
    <div class="header">
      <div class="left">
        <div class="iconBox">
          <i class="iconfont icon-gongsi2"></i>
        </div>
        {{ item.shippingCompanyName }}
      </div>
      <div class="right">
        <span>{{$t('Lifespan')}}</span>
        {{ $dayjs(item.startTime).format("YYYY/MM/DD") }} - {{ $dayjs(item.endTime).format("YYYY/MM/DD") }}
      </div>
    </div>
    <div class="content">
      <img :src="item.shippingCompanyLogoUrl" />
      <div class="right">
        <div class="item">
          <div class="time">{{ $dayjs(item.startTime).format("YYYY/MM/DD") }}</div>
          <div class="address line1">{{ item.departureName }}</div>
          <div class="country">{{ item.departureCountry }}</div>
        </div>
        <div class="lineBox">
          <div class="topText">{{ item.transferName ? $t('noticeServer.Transfer') : $t('noticeServer.Direct') }}</div>
          <div class="line"></div>
          <div class="topText">
            <span class="line1" v-if="item.transferName">{{ item.transferName + '·' || '' }}</span>
            {{ $dayjs(item.endTime).diff(item.startTime, 'day')+this.$t('day') }}
          </div>
        </div>
        <div class="item">
          <div class="time">{{ $dayjs(item.endTime).format("YYYY/MM/DD") }}</div>
          <div class="address line1">{{ item.destinationName }}</div>
          <div class="country">{{ item.destinationCountry }}</div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="otherBox" v-if="companyId != item.companyId">
        <el-popover
          ref="popover"
          v-model="item.check"
          :disabled="!item.serviceId"
          placement="top"
          trigger="manual"
          :append-to-body="false"
          popper-class="ec-popper"
          @click.native.stop
          @hide="item.check = false">
          <div class="popperMenuItem" @click="operation(0)">
            <i class="iconfont icon-fenxiang2" />
            <span>{{ $t("order.Internal sharing") }}</span>
          </div>
          <div class="popperMenuItem" @click="operation(1)">
            <i class="iconfont icon-zhanwaifenxiang" />
            <span>{{ $t("order.External sharing") }}</span>
          </div>
          <div class="btn" @click.stop="activePopover(item, 'popover')" slot="reference">{{ $t('order.share') }}</div>
        </el-popover>
        <div class="btn primary" @click.stop="consultEvent">{{ $t('noticeServer.Consult') }}</div>
      </div>
      <el-popover
        v-else
        v-model="item.check"
        class="el-pop"
        placement="right-start"
        trigger="manual"
        @hide="item.check = false"
        popper-class="ec-popper"
      >
        <div class="popperMenuItem" @click="topItem">
          <i class="el-icon-download rotate180" />
          <span>{{ item.top ? $t("home.Cancel Topping") : $t("Topping") }}</span>
        </div>
        <div class="popperMenuItem" @click="editItem">
          <i class="iconfont icon-Vector" />
          <span>{{ $t("home.Edit") }}</span>
        </div>
        <el-popover
          ref="popover"
          placement="right-start"
          trigger="click"
          :append-to-body="false"
          popper-class="ec-popper"
          @hide="popoverShow = false">
          <div class="popperMenuItem" @click="operation(0)">
            <i class="iconfont icon-fenxiang2" />
            <span>{{ $t("order.Internal sharing") }}</span>
          </div>
          <div class="popperMenuItem" @click="operation(1)">
            <i class="iconfont icon-zhanwaifenxiang" />
            <span>{{ $t("order.External sharing") }}</span>
          </div>
          <li 
            class="popperMenuItem" 
            slot="reference"
            :class="popoverShow ? 'active' : ''"
            @click="popoverShow = true">
            <i class="iconfont icon-fenxiang"></i>
            <span>{{ $t('order.share') }}</span>
          </li>
        </el-popover>
        <div class="popperMenuItem" @click="deleteItem">
          <i class="iconfont icon-shanchu" />
          <span>{{ $t("home.Delete") }}</span>
        </div>
        <i
          class="iconfont icon-Ellipsis downEllipsis"
          :class="item.check ? 'active' : ''"
          slot="reference"
          @click.stop="activePopover(item, 'popover')"
        />
      </el-popover>
    </div>
    <inside
      :isInside="isInside"
      @onClose="isInside = false"
      type="shopServerInternal"
      :shopServerId="item.serviceId"
      :messageType="12"
    />
    <externalShare ref="externalShare" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { topForwarderServer, deleteForwarderServer, getForwarderServerShareUrl } from "@/api/shopServer.js";
import inside from "@/components/fileInside/inside.vue";
import externalShare from '../share/externalShare.vue';
export default {
  components: {
    inside,
    externalShare
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      popoverShow: false,
      isInside: false
    }
  },
  computed: {
    ...mapGetters(['companyId'])
  },
  mounted() {
    document.body.addEventListener("click", () => {
      this.item.check = false;
      this.$nextTick(() => {
        this.$emit("closePopover", this.item.serviceId);
      });
    })
  },
  destroyed() {
    document.body.removeEventListener("click", () => {
      this.item.check = false;
      this.$nextTick(() => {
        this.$emit("closePopover", this.item.serviceId);
      });
    })
  },
  methods: {
    consultEvent() {
      this.$emit("consult", this.item.serviceId);
    },
    activePopover(row, key) {
      row.check = !row.check;
      this.$nextTick(() => {
        this.$emit("closePopover", row.serviceId);
      });
    },
    editItem() {
      this.$emit("edit", this.item);
    },
    topItem() {
      topForwarderServer(this.item.serviceId).then(() => {
        this.$emit("update", true)
      })
    },
    operation(type) {
      switch (type) {
        case 0:
          this.isInside = true;
          break;
        case 1:
          this.$refs.externalShare.open(this.item.serviceId, this.item.companyId, 3);
          break;
      }
    },
    deleteItem() {
      this.$confirm(
        this.$t('Cannot reply after deletion'), 
        this.$t('Are you sure to delete this product/service'), 
        {
          confirmButtonText: this.$t('Confirm'),
          cancelButtonText: this.$t('Cancel'),
          type: 'warning'
        }
      ).then(() => {
        deleteForwarderServer(this.item.serviceId).then(() => {
          this.$message.success(this.$t('event.Delete successfully'));
          this.$emit("update", false)
        })
      }).catch(() => {});
    }
  }
}
</script>

<style lang="less" scoped>
.serverItemBox {
  width: 100%;
  height: 216px;
  background: #F7F9FC;
  border-radius: 4px;
  padding: 20px 24px;
  margin-bottom: 24px;
  cursor: pointer;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 14px;
      font-weight: 500;
      color: #122545;
      .iconBox {
        width: 24px;
        height: 24px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #004C97;
        i {
          font-size: 16px;
          color: #ffffff;
        }
      }
    }
    .right {
      font-size: 14px;
      span {
        color: #122545;
        font-weight: 500;
      }
    }
  }
  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    margin-top: 24px;
    img {
      width: 48px;
      height: 48px;
      border-radius: 4px;
      object-fit: contain;
    }
    .right {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 13px;
      .item {
        text-align: center;
        max-width: 116px;
        .time {
          font-size: 12px;
          color: #637381;
          line-height: 20px;
        }
        .address {
          font-size: 24px;
          font-weight: 500;
          line-height: 24px;
          margin: 12px 0;
        }
        .country {
          font-size: 12px;
          color: #637381;
          line-height: 20px;
        }
      }
      .lineBox {
        flex: 1;
        text-align: center;
        .line {
          border-bottom: 1px dashed #A3B0C6;
          margin: 7px 0 13px 0;
        }
        .topText {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          color: #637381;
          span {
            display: inline-block;
            max-width: 70px;
          }
        }
      }
    }
  }
  .bottom {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 14px;
    .otherBox {
      display: flex;
      gap: 8px;
      .btn {
        padding: 10px 20px;
        color: #637381;
        font-size: 12px;
        line-height: 14px;
        border: 1px solid #E0E4EA;
        border-radius: 50px;
        &.primary {
          border: 1px solid #004C97;
          background: #004C97;
          color: #ffffff;
        }
      }
    }
    .downEllipsis {
      width: 26px;
      height: 26px;
    }
  }
}
</style>